import { generateUniqueId } from '@acre/utils'
import { CreateGeneralInsuranceProductDetails, GeneralInsuranceQuoteStage } from '@acre/graphql'

import { isSourcedGIProduct } from '../../../../pages/GeneralInsurance/GeneralInsurance.helpers'
import { getProviderName } from '../../../../pages/GeneralInsurance/GeneralInsuranceQuoting/GeneralInsuranceQuotingItem/GeneralInsuranceQuotingItem.helpers'

export const getPredictedRevenueFromGI = (giProducts: CreateGeneralInsuranceProductDetails[]) => {
  const selectedGIProducts = giProducts.filter(
    (product) =>
      product.commission &&
      (product.status === GeneralInsuranceQuoteStage.Selected ||
        product.status === GeneralInsuranceQuoteStage.ApplicationInProgress),
  )

  return selectedGIProducts.map((product) => ({
    id: generateUniqueId(),
    value: product.commission,
    fee_type: 'giProcFee',
    payer: product.provider && isSourcedGIProduct(product) ? getProviderName(product.provider) : 'insurer',
    commission: null,
    frequency: null,
    commission_period: null,
  }))
}
