import { orgRegulatingFiltersDiscovery } from '../api/org_regulating_filters'
import { Resolvers } from '../generated/resolvers'

const OrgRegulatingFilter: Resolvers = {
  Query: {
    orgRegulatingFiltersDiscovery,
  },
}

export default OrgRegulatingFilter
