import React, { forwardRef, PropsWithChildren } from 'react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import classnames from 'classnames'
import { Location } from 'history'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'

import withDisabled from '../../hoc/withDisabled'
import { getColourHex } from '../../utils/colourHex'
import { BUTTON_PRIMARY, BUTTON_TYPE_BUTTON, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { getTextColourHex } from '../../utils/textColourHex'
import { ButtonTheme, ButtonType, ColourId } from '../../utils/types'
import { CenteredLoadingSpinner } from '../LoadingSpinner'
import { trackEventFiltered } from '../UserTracker'
import {
  ButtonClear,
  ButtonClearSmall,
  ButtonColoured,
  ButtonDanger,
  ButtonDangerSecondary,
  ButtonDashed,
  ButtonDropdownMenu,
  ButtonLink,
  ButtonLinkSmall,
  ButtonOverflow,
  ButtonPopOver,
  ButtonPopOverDanger,
  ButtonPopOverDangerOnHover,
  ButtonPrimary,
  ButtonSecondary,
  ButtonSecondarySmall,
  ButtonTag,
  ToggleButton,
} from './Button.styles'

const ButtonStyles = {
  primary: ButtonPrimary,
  secondary: ButtonSecondary,
  secondarySmall: ButtonSecondarySmall,
  clear: ButtonClear,
  clearSmall: ButtonClearSmall,
  link: ButtonLink,
  linkSmall: ButtonLinkSmall,
  overflow: ButtonOverflow,
  toggle: ToggleButton,
  dashed: ButtonDashed,
  danger: ButtonDanger,
  popOver: ButtonPopOver,
  popOverDanger: ButtonPopOverDanger,
  tag: ButtonTag,
  dropdownMenu: ButtonDropdownMenu,
  dangerSecondary: ButtonDangerSecondary,
  popOverDangerOnHover: ButtonPopOverDangerOnHover,
  coloured: ButtonColoured,
}

export type ButtonProps = PropsWithChildren<{
  id?: string
  type?: ButtonType
  form?: string
  className?: string
  fullWidth?: boolean
  isLoading?: boolean
  disabled?: boolean
  buttonStyle?: ButtonTheme
  onClick?: (args: any) => any
  variant?: Variant
  colourID?: ColourId
  tooltip?: string
  height?: string
  width?: string
}>

// any used because of https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35834
const Button = forwardRef<any, ButtonProps>(function Button(
  {
    id,
    type = BUTTON_TYPE_BUTTON,
    form,
    disabled,
    isLoading = false,
    onClick,
    children,
    buttonStyle = BUTTON_PRIMARY,
    fullWidth = false,
    className = '',
    variant = 'small',
    colourID,
    tooltip = '',
    height = '',
    width = '',
  },
  ref,
) {
  const { trackEvent } = useMatomo()
  // Yes, this is insane but there's no good way to mock `useLocation` with react-router v5
  let location: Location
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    location = useLocation() as Location
  } catch {
    // no-op
  }
  const colourHex = colourID ? getColourHex(colourID) : undefined
  const textColourHex = colourID ? getTextColourHex(colourID) : undefined
  const Button = ButtonStyles[buttonStyle]
  const dataTestId = id ? testHandle(id) : null
  const allClassNames = classnames({
    fullWidth,
    loading: isLoading,
  }).concat(' ', className) // Adding a concat with a space, so we have a space between each class name, otherwise the browser wont' read it

  const onClickWrapped = (args: any) => {
    trackEventFiltered(trackEvent, location, 'buttonClick', id)
    if (onClick && !disabled) {
      onClick(args)
    }
  }

  // If it the button is disabled and the tooltip is not set return normal button
  // otherwise return the spanned button so tooltip can be shown
  return disabled && !isEmpty(tooltip) ? (
    <span title={tooltip}>
      <Button
        id={id}
        form={form}
        data-testid={dataTestId}
        type={type}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        className={allClassNames}
        onClick={onClickWrapped}
        ref={ref}
        variant={variant}
        color={colourHex}
        textColour={textColourHex}
        height={height}
        width={width}
      >
        {isLoading ? (
          <span>
            <CenteredLoadingSpinner inButton inverted />
            <span style={{ visibility: 'hidden' }}>{children}</span>
          </span>
        ) : (
          children
        )}
      </Button>
    </span>
  ) : (
    <Button
      id={id}
      form={form}
      data-testid={dataTestId}
      type={type}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      className={allClassNames}
      onClick={onClickWrapped}
      ref={ref}
      variant={variant}
      color={colourHex}
      textColour={textColourHex}
      height={height}
      width={width}
      style={{
        // override the pointerEvents: none; from the Button.styles.ts to make cursor: not-allowed works
        pointerEvents: 'auto',
      }}
    >
      {isLoading ? (
        <span>
          <CenteredLoadingSpinner inButton inverted />
          <span style={{ visibility: 'hidden' }}>{children}</span>
        </span>
      ) : (
        children
      )}
    </Button>
  )
})

export default withDisabled(Button)
export { Button as ButtonNotDisabled }
