import diff from 'deep-diff'
import { isEqual, isObject, set, transform } from 'lodash'

import { ExactObject } from '../types'

export const differenceInObjects = (object: ExactObject, base: ExactObject) => {
  const changes = (object: ExactObject, base: ExactObject) => {
    return transform<ExactObject, ExactObject>(object, (result, value, key) => {
      if (!isEqual(value, base?.[key])) {
        // If this is an array, then return the updated version of that array instead of the difference in array items
        if (Array.isArray(value) && Array.isArray(base?.[key])) {
          result[key] = value
        } else {
          result[key] = isObject(value) && isObject(base?.[key]) ? changes(value, base?.[key]) : value
        }
      }
    })
  }
  return changes(object, base)
}

const setValuesForArray = <T extends Record<string, unknown>, R extends Record<string, unknown> = T>(
  diff: diff.DiffArray<T, T>,
  objIn: R,
) => {
  if (diff.item.kind === 'E') {
    set(objIn, diff.item.path!, diff.item.rhs)
  } else if (diff.item.kind === 'N') {
    set(objIn, diff.item.path!, diff.item.rhs)
  } else if (diff.item.kind === 'D') {
    set(objIn, diff.item.path!, null)
  } else if (diff.item.kind === 'A') {
    setValuesForArray(diff.item, objIn)
  }
}

export const differenceInObjects2 = <T extends Record<string, unknown>>(lhs: T, rhs: T) => {
  const difference = diff(lhs, rhs)

  const change = difference?.reduce((acc, diff) => {
    if (diff.path) {
      if (diff.kind === 'E') {
        set(acc, diff.path, diff.rhs)
      } else if (diff.kind === 'N') {
        set(acc, diff.path, diff.rhs)
      } else if (diff.kind === 'D') {
        set(acc, diff.path, null)
      } else if (diff.kind === 'A') {
        setValuesForArray(diff, acc)
      }
    }

    return acc
  }, {} as T)

  return change
}
