import { formatAsCurrency, SpecifiedItemType } from '@acre/graphql'
import { DropdownOption } from '@acre/design-system'

export type FilterBarProps = {
  stateCallback: (state: FilterState) => void
  loading?: boolean
  btl: boolean
}

export enum InsuranceType {
  BuildingsAndContents,
  ContentsOnly,
  BuildingsOnly,
}

export type SpecificItem = {
  ItemType: string
  ItemDescription: string
  ItemValue: number
  CoveredAtHome: boolean
  CoveredOutsideTheHome: boolean
}

export type FilterState = {
  insuranceType: InsuranceType
  excess: number
  desiredBuildingsExcessIfDifferent: number
  claimFreeYears: number
  includePersonalPossessionsCover: boolean
  contentsPersonalUnspecifiedValue: number
  contentsPersonalPossessions: Array<SpecificItem>
  includeAccidentalCover: boolean
  includeContentsPlus: boolean
  includeFamilyLegalProtection: boolean
  skipReload: boolean
  showComplexExcess: boolean
  includeHomeEmergencyCover: boolean
  // landlord fields
  rentGuarantee: boolean
  manufactureOfDrugsCover: boolean
  tenantsWillBeStudents: boolean
  tenantsWillBeOnBenefits: boolean
  moreThanSixTenants: boolean
  propertyContainsBedsits: boolean
  directTenancyAgreement: boolean
  tenantsAreOver18: boolean
  tenancyRequirementsMet: boolean
}

export type Props = {
  open: boolean
  isManualGI: boolean
  onClose?: (item: SpecificItem) => void
  onSubmit?: (item: SpecificItem) => void
  setOpen: (open: boolean) => void
  item?: SpecificItem
}

type ItemType = { name: string; available: Array<string>; value: SpecifiedItemType }

export const reducer = (
  prevState: FilterState,
  action: { field: keyof FilterState | 'reload'; value: any; skipReload?: boolean },
) => {
  const newState = { ...prevState }
  switch (action.field) {
    case 'reload':
      newState.skipReload = false
      break
    case 'includePersonalPossessionsCover':
      newState.contentsPersonalUnspecifiedValue = !prevState.includePersonalPossessionsCover ? 200000 : 0
      newState.includePersonalPossessionsCover = !prevState.includePersonalPossessionsCover
      break
    case 'includeAccidentalCover':
      newState.includeAccidentalCover = !prevState.includeAccidentalCover
      break
    case 'includeContentsPlus':
      newState.includeContentsPlus = !prevState.includeContentsPlus
      break
    case 'includeFamilyLegalProtection':
      newState.includeFamilyLegalProtection = !prevState.includeFamilyLegalProtection
      break
    case 'claimFreeYears':
      newState.claimFreeYears = action.value
      break
    case 'contentsPersonalUnspecifiedValue':
      newState.contentsPersonalUnspecifiedValue = action.value
      break
    case 'excess':
      newState.excess = action.value
      break
    case 'desiredBuildingsExcessIfDifferent':
      newState.desiredBuildingsExcessIfDifferent = action.value
      break
    case 'showComplexExcess':
      newState.showComplexExcess = !prevState.showComplexExcess
      newState.desiredBuildingsExcessIfDifferent = newState.showComplexExcess ? prevState.excess : 0
      break
    case 'insuranceType':
      newState.insuranceType = action.value
      break
    case 'contentsPersonalPossessions':
      newState.contentsPersonalPossessions = action.value
      break
    case 'includeHomeEmergencyCover':
      newState.includeHomeEmergencyCover = !prevState.includeHomeEmergencyCover
      break
    case 'manufactureOfDrugsCover':
      newState.manufactureOfDrugsCover = !prevState.manufactureOfDrugsCover
      break
    case 'tenantsWillBeStudents':
      newState.tenantsWillBeStudents = !prevState.tenantsWillBeStudents
      break
    case 'tenantsWillBeOnBenefits':
      newState.tenantsWillBeOnBenefits = !prevState.tenantsWillBeOnBenefits
      break
    case 'moreThanSixTenants':
      newState.moreThanSixTenants = !prevState.moreThanSixTenants
      break
    case 'propertyContainsBedsits':
      newState.propertyContainsBedsits = !prevState.propertyContainsBedsits
      break
    case 'directTenancyAgreement':
      newState.directTenancyAgreement = !prevState.directTenancyAgreement
      break
    case 'tenantsAreOver18':
      newState.tenantsAreOver18 = !prevState.tenantsAreOver18
      break
    case 'tenancyRequirementsMet':
      newState.tenancyRequirementsMet = !prevState.tenancyRequirementsMet
      break
    case 'rentGuarantee':
      newState.rentGuarantee = !prevState.rentGuarantee
      break
  }
  if (action.skipReload) {
    newState.skipReload = true
  }
  return newState
}

export const initialState = {
  excess: 25000,
  desiredBuildingsExcessIfDifferent: 0,
  insuranceType: InsuranceType.BuildingsAndContents,
  contentsPersonalPossessions: [],
  claimFreeYears: 0,
  includeAccidentalCover: false,
  includeFamilyLegalProtection: false,
  includeContentsPlus: false,
  includePersonalPossessionsCover: false,
  contentsPersonalUnspecifiedValue: 0,
  includeHomeEmergencyCover: false,
  skipReload: false,
  showComplexExcess: false,
  rentGuarantee: false,
  manufactureOfDrugsCover: false,
  tenantsWillBeStudents: false,
  tenantsWillBeOnBenefits: false,
  moreThanSixTenants: false,
  propertyContainsBedsits: false,
  directTenancyAgreement: true,
  tenantsAreOver18: true,
  tenancyRequirementsMet: true,
} as FilterState

export const getSpecifiedItemList = (): { [k: string]: ItemType } => {
  return {
    Clocks: { name: 'Clocks', available: ['AtHome'], value: SpecifiedItemType.Clocks },
    CoinCollection: { name: 'Coin collection', available: ['AtHome'], value: SpecifiedItemType.CoinCollection },
    CupsSheildsTrophiesandMasonicRegalia: {
      name: 'Cups, shields, trophies and masonic regalia',
      available: ['AtHome'],
      value: SpecifiedItemType.CupsSheildsTrophiesAndMasonicRegalia,
    },
    Curios: { name: 'Curios', available: ['AtHome'], value: SpecifiedItemType.Curios },
    Furs: { name: 'Furs', available: ['AtHome', 'AwayFromHome'], value: SpecifiedItemType.Furs },
    GoldItems: { name: 'Gold items', available: ['AtHome', 'AwayFromHome'], value: SpecifiedItemType.GoldItems },
    Guns: { name: 'Guns', available: ['AtHome'], value: SpecifiedItemType.Guns },
    JewelleryWatches: {
      name: 'Jewellery / watches',
      available: ['AtHome', 'AwayFromHome'],
      value: SpecifiedItemType.JewelleryWatches,
    },
    MedalCollection: { name: 'Medal collection', available: ['AtHome'], value: SpecifiedItemType.MedalCollection },
    MusicalInstrumentsAmateur: {
      name: 'Musical instruments (amateur)',
      available: ['AtHome', 'AwayFromHome'],
      value: SpecifiedItemType.MusicalInstrumentsAmateur,
    },
    Paintings: { name: 'Paintings', available: ['AtHome'], value: SpecifiedItemType.Paintings },
    Pearls: { name: 'Pearls', available: ['AtHome', 'AwayFromHome'], value: SpecifiedItemType.Pearls },
    PhotographicEquipmentAmateur: {
      name: 'Photographic equipment (amateur)',
      available: ['AtHome', 'AwayFromHome'],
      value: SpecifiedItemType.PhotographicEquipmentAmateur,
    },
    Pianos: { name: 'Pianos', available: ['AtHome'], value: SpecifiedItemType.Pianos },
    Pictures: { name: 'Pictures', available: ['AtHome'], value: SpecifiedItemType.Pictures },
    PreciousMetals: {
      name: 'Precious metals',
      available: ['AtHome', 'AwayFromHome'],
      value: SpecifiedItemType.PreciousMetals,
    },
    Sculptures: { name: 'Sculptures', available: ['AtHome'], value: SpecifiedItemType.Sculptures },
    SilverItems: { name: 'Silver items', available: ['AtHome'], value: SpecifiedItemType.SilverItems },
    SportingGunsandShootingEquipment: {
      name: 'Sporting guns and shooting equipment',
      available: ['AtHome', 'AwayFromHome'],
      value: SpecifiedItemType.SportingGunsAndShootingEquipment,
    },
    StampCollection: { name: 'Stamp collection', available: ['AtHome'], value: SpecifiedItemType.StampCollection },
    Statues: { name: 'Statues', available: ['AtHome'], value: SpecifiedItemType.Statues },
    Tapestries: { name: 'Tapestries', available: ['AtHome'], value: SpecifiedItemType.Tapestries },
    WorksofArt: { name: 'Works of art', available: ['AtHome'], value: SpecifiedItemType.WorksOfArt },
    Clothing: { name: 'Clothing', available: ['AwayFromHome'], value: SpecifiedItemType.Clothing },
    ContactLenses: { name: 'Contact lenses', available: ['AwayFromHome'], value: SpecifiedItemType.ContactLenses },
    DenturesincludingCrowns: {
      name: 'Dentures (including crowns)',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.DenturesIncludingCrowns,
    },
    ElectricWheelchairs: {
      name: 'Electric wheelchairs',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.ElectricWheelchairs,
    },
    Gokart: { name: 'Gokart', available: ['AwayFromHome'], value: SpecifiedItemType.Gokart },
    GolfBuggy: { name: 'Golf buggy', available: ['AwayFromHome'], value: SpecifiedItemType.GolfBuggy },
    HearingAids: { name: 'Hearing aids', available: ['AwayFromHome'], value: SpecifiedItemType.HearingAids },
    HomeComputerEquipmentegLaptop: {
      name: 'Home computer equipment (eg laptop)',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.HomeComputerEquipmentEgLaptop,
    },
    MedicalEquipment: {
      name: 'Medical equipment',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.MedicalEquipment,
    },
    ModelAircraft: { name: 'Model aircraft', available: ['AwayFromHome'], value: SpecifiedItemType.ModelAircraft },
    PedalCyclesOver1000: {
      name: 'Pedal cycles over £1000',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.PedalCyclesOver_1000,
    },
    PortableElectronicItemegiPodiPad: {
      name: 'Portable electronic item (eg iPod/iPad)',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.PortableElectronicItemEgIpodIpad,
    },
    PramsAndPushchairs: {
      name: 'Prams and pushchairs',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.PramsAndPushchairs,
    },
    SoundEquipment: { name: 'Sound equipment', available: ['AwayFromHome'], value: SpecifiedItemType.SoundEquipment },
    Spectacles: { name: 'Spectacles', available: ['AwayFromHome'], value: SpecifiedItemType.Spectacles },
    SportsEquipmentExcludingWinterSportsandPedalCycles: {
      name: 'Sports qquipment (excluding winter sports and pedal cycles',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.SportsEquipmentExcludingWinterSportsAndPedalCycles,
    },
    SportsEquipmentWinterSports: {
      name: 'Sports equipment (winter sports)',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.SportsEquipmentWinterSports,
    },
    WheelchairsSelfPropelled: {
      name: 'Wheelchairs (self-propelled)',
      available: ['AwayFromHome'],
      value: SpecifiedItemType.WheelchairsSelfPropelled,
    },
    MobilePhone: { name: 'Mobile phone', available: ['AwayFromHome'], value: SpecifiedItemType.MobilePhone },
  }
}

export const getExcessDropdownOptions = (
  bands: Record<number, string[]> | undefined,
  panels: string[] | undefined,
): Array<DropdownOption> => {
  if (!bands || !panels) {
    return excessDropdownOptions
  }
  return Object.entries(bands).map(([k, v]) => {
    return {
      value: (parseInt(k) * 100).toString(),
      label: `${formatAsCurrency(k, false)}${panels.length != v.length ? ' *' : ''}`,
    }
  })
}

export const getPersonalPossessionsDropdownOptions = (
  bands: Record<number, string[]> | undefined,
  panels: string[] | undefined,
): Array<DropdownOption> => {
  if (!bands || !panels) {
    return outsideTheHomeAmountOptions
  }
  return Object.entries(bands).map(([k, v]) => {
    return {
      value: (parseInt(k) * 100).toString(),
      label: `${formatAsCurrency(k, false)}${panels.length != v.length ? ' *' : ''}`,
    }
  })
}

const excessDropdownOptions: Array<DropdownOption> = [
  { value: '10000', label: '£100' },
  { value: '15000', label: '£150' },
  { value: '20000', label: '£200' },
  { value: '25000', label: '£250' },
  { value: '30000', label: '£300' },
  { value: '35000', label: '£350' },
  { value: '40000', label: '£400' },
  { value: '45000', label: '£450' },
  { value: '50000', label: '£500' },
]

export const outsideTheHomeAmountOptions: Array<DropdownOption> = [
  { value: '200000', label: '£2,000' },
  { value: '300000', label: '£3,000' },
  { value: '400000', label: '£4,000' },
  { value: '500000', label: '£5,000' },
  { value: '600000', label: '£6,000' },
  { value: '700000', label: '£7,000' },
  { value: '800000', label: '£8,000' },
  { value: '900000', label: '£9,000' },
  { value: '1000000', label: '£10,000' },
]
