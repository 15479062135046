import { ChangeEvent, Fragment, ReactNode } from 'react'
import { Box } from '@mui/material'

import testHandle from '../../utils/testHandle'
import { FlagReview, InputNote } from './Shared.styles'

export type NoteBodyItemProps = {
  id: string
  value: string
  onChange: ((event: ChangeEvent<HTMLTextAreaElement>) => void) | undefined
  placeholder?: string
  createdDate?: ReactNode
  disabled?: boolean
}

const NoteBodyItem = ({ id, createdDate = null, value, onChange, disabled, placeholder }: NoteBodyItemProps) => {
  if (disabled) {
    return (
      <FlagReview mt={4} data-testid={testHandle(id)}>
        {value.split('\n').map((line, index) => (
          <Fragment key={index}>
            <p>{line}</p>
            <br />
          </Fragment>
        ))}
        <Box mt={1}>{createdDate}</Box>
      </FlagReview>
    )
  }

  return (
    <FlagReview mt={4}>
      <InputNote
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={testHandle(id)}
        disabled={disabled}
      />
      {createdDate}
    </FlagReview>
  )
}

export default NoteBodyItem
