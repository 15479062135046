import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { fieldParserFn } from '@broker-crm-common'
import { useCaseContext } from '@broker-crm-contexts'
import { addMinutes, format, getHours, getMinutes } from 'date-fns'
import { Field, FormSpy, useForm } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useIntl } from 'react-intl'

import { formatDigits, getFormMetaErrors, useFormatMessage, UserContext } from '@acre/utils'
import {
  DefaultAssignee,
  GetMetaStateDocument,
  GetMetaStateQuery,
  GetOrganisationDocument,
  GetOrganisationQuery,
  GetUsersDocument,
  GetUsersQuery,
  Maybe,
  MortgageReason,
  NoteInput,
  NoteStatus,
  NoteType,
  OrganisationType,
  TemplateAssigneeType,
  useGetArOrganisationsQuery,
  useGetGroupsForOrgQuery,
  User,
} from '@acre/graphql'
import {
  COMPACT_LEFT_ALIGNED,
  // DateFieldFF,
  DateInput,
  Dropdown,
  DropdownOption,
  OptionGroup,
  Radio,
  RadioGroup,
  SearchSelector,
  Stack,
  TextAreaNew,
  TimeInput,
} from '@acre/design-system'

import { getGroupDropdownOptions, userDropDownOptions as getUserDropDownOptions } from './AddReminderModal.helper'

export type ReminderLayout = 'reminder' | 'review'

type Props = {
  status?: NoteStatus
  layout?: ReminderLayout
  submitFailed?: Boolean
  createdAt?: string | null | undefined
  isEdit?: Boolean
  initialValues: NoteInput
}

const ReminderFields = ({ status, createdAt, isEdit, initialValues, layout = 'reminder' }: Props) => {
  const intl = useIntl()
  const formatMessage = useFormatMessage()
  const form = useForm()
  const values = form.getState().values
  const [activeTemplateId, setActiveTemplateId] = useState<string>('')
  const [customDelay, setCustomDelay] = useState<string>('')

  const { organisation_id, id: user_id, organisation } = useContext(UserContext) || {}
  const { details } = useCaseContext()

  const daId = useMemo(
    () => (organisation?.type === OrganisationType.Da ? organisation.id : null),
    [organisation?.id, organisation?.type],
  )

  const representative_of = useMemo(() => organisation?.representative_of, [organisation?.representative_of])

  const { data: userData, loading: loadingUsers } = useQuery<GetUsersQuery>(GetUsersDocument, {
    variables: { id: organisation_id },
    skip: !organisation_id,
  })

  const { data: orgData, loading: loadingNetworkUsers } = useGetArOrganisationsQuery({
    variables: {
      id: daId!,
      includeUsers: true,
    },
    skip: !daId,
  })

  const { data: groupsData, loading: loadingGroups } = useGetGroupsForOrgQuery({
    variables: { filter_organisation_id: organisation_id, show_disabled: true },
    skip: !organisation_id,
  })

  const { data: parentGroupsData, loading: loadingParentGroups } = useGetGroupsForOrgQuery({
    variables: { filter_organisation_id: representative_of, show_disabled: true, fetchUsers: false },
    skip: !representative_of || representative_of === organisation_id,
  })

  const { data: metaState } = useQuery<GetMetaStateQuery>(GetMetaStateDocument, {
    fetchPolicy: 'cache-only',
  })

  // add loading: organisationLoading to query destructuring
  const { data: organisationData } = useQuery<GetOrganisationQuery>(GetOrganisationDocument, {
    variables: { id: organisation_id },
    skip: !organisation_id || Boolean(metaState),
  })

  const { data: parentOrrganisationData } = useQuery<GetOrganisationQuery>(GetOrganisationDocument, {
    variables: { id: representative_of },
    skip: Boolean(metaState) || !representative_of || representative_of === organisation_id,
  })

  const noteTemplates = useMemo(
    () => [
      ...(organisationData?.organisation?.note_templates || []),
      ...(parentOrrganisationData?.organisation?.note_templates || []),
    ],
    [organisationData?.organisation?.note_templates, parentOrrganisationData?.organisation?.note_templates],
  )

  // Set template ID if a template has been selected
  // in handleSubmit function we parse the payload in a way where we repalce the template id with reminder enum
  // and save the template id in the template_id field
  // If no custom template is selected, set state to empty string
  useEffect(() => {
    if (isEdit && initialValues.template_id) {
      setActiveTemplateId(initialValues.template_id)
    } else
      setActiveTemplateId(
        values.primary_type === NoteType.Reminder || values.primary_type === NoteType.Review ? '' : values.primary_type,
      )
  }, [initialValues.template_id, isEdit, values.primary_type])

  const activeTemplate = useMemo(() => {
    return noteTemplates?.find((template) => template?.id === activeTemplateId)
  }, [activeTemplateId, noteTemplates])

  const defaultAssignee: DefaultAssignee | undefined | null =
    activeTemplate?.default_assignees && activeTemplate?.default_assignees[0]

  // Update form fields if a reminder template is chosen
  useEffect(() => {
    const date = createdAt ? new Date(createdAt) : new Date()
    const dueDate = addMinutes(date, activeTemplate?.default_delay_minutes || 0)
    const formattedDueDate = format(dueDate, 'yyyy-MM-dd')
    const hours = getHours(dueDate)
    const mins = getMinutes(dueDate)
    if (activeTemplate) {
      setCustomDelay(initialValues?.dueTime || `${formatDigits(hours.toString())}:${formatDigits(mins.toString())}`)
      if (!isEdit) {
        form.batch(() => {
          form.change('title', activeTemplate?.title)
          form.change('template_id', activeTemplate?.id)
          form.change('deadline', formattedDueDate)
          form.change(
            'assignee_type',
            defaultAssignee?.type === TemplateAssigneeType.Group ? defaultAssignee?.type : TemplateAssigneeType.User,
          )
          form.change('priority', activeTemplate.default_priority)
        })
      }
    } else setCustomDelay('')
  }, [activeTemplateId, noteTemplates, form])

  // Changing assignee_id must be in a separate useEffect, as it needs to wait for the assignee_type to update
  useEffect(() => {
    if (activeTemplate && !isEdit)
      form.change('assignee_id', defaultAssignee?.type === TemplateAssigneeType.Creator ? user_id : defaultAssignee?.id)
  }, [values.assignee_type, activeTemplateId])

  const baseNoteTypeOptions = [
    {
      label: formatMessage('reminders.general'),
      value: NoteType.Reminder,
    },
    {
      label: formatMessage('reminders.review'),
      value: NoteType.Review,
    },
  ]

  const noteTypeOptions = useMemo(() => {
    if (!noteTemplates) return baseNoteTypeOptions
    const orgNoteTypeOptions = noteTemplates
      // remove any templates that don't match the case type or are disabled
      ?.filter(
        (template) =>
          (template?.case_types?.includes(details?.preference_mortgage_reason as Maybe<MortgageReason>) ||
            !template?.case_types?.length) &&
          !template?.disabled,
      )
      // change the format to value & label key value pairs
      .map((template) => {
        return { label: template?.title || '', value: template?.id || '' }
      })
      // sort alphabetically
      .sort((a, b) => a.label.localeCompare(b.label))
    return [...baseNoteTypeOptions, ...orgNoteTypeOptions]
  }, [details?.preference_mortgage_reason, noteTemplates, formatMessage])

  const users = userData?.organisation?.users
  const groups = useMemo(
    () => [...(groupsData?.getGroupsForOrg || []), ...(parentGroupsData?.getGroupsForOrg || [])],
    [groupsData?.getGroupsForOrg, parentGroupsData?.getGroupsForOrg],
  )
  const disabled = status === NoteStatus.Complete

  // Remove groups that are disabled & can't accept reminders
  const filteredGroups = useMemo(() => {
    return groups?.filter((group) => group.accept_reminders && !group.disabled)
  }, [groups])

  const userDropdownOptions = getUserDropDownOptions(formatMessage, loadingUsers, users)

  const networkUserOptions = useMemo(
    () =>
      orgData?.organisation.arOrganisations?.organisations?.reduce((acc, org) => {
        if (org.id === details?.owner?.organisation?.id || org.id === organisation_id) {
          const orgName = org.name || ''
          const userNetworkDropdownOptions = getUserDropDownOptions(
            formatMessage,
            loadingNetworkUsers,
            org.users as User[],
          )

          return [
            ...acc,
            {
              label: orgName || '',
              options: userNetworkDropdownOptions,
            },
          ]
        }
        return acc
      }, [] as OptionGroup[]),
    [
      details?.owner?.organisation?.id,
      formatMessage,
      loadingNetworkUsers,
      orgData?.organisation.arOrganisations?.organisations,
      organisation_id,
    ],
  )

  const userOptions = useMemo(
    () => (daId ? networkUserOptions : userDropdownOptions),
    [daId, networkUserOptions, userDropdownOptions],
  )

  const groupDropdownOptions = useMemo(
    () => getGroupDropdownOptions(formatMessage, loadingGroups || loadingParentGroups, filteredGroups),
    [filteredGroups, formatMessage, loadingGroups, loadingParentGroups],
  )

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values, form }) => (
        <Stack>
          <Field name="primary_type">
            {({ input, meta }) => (
              <Dropdown
                {...input}
                variant={COMPACT_LEFT_ALIGNED}
                {...getFormMetaErrors({ meta, intl })}
                id="NoteType"
                options={isEdit && !activeTemplateId ? baseNoteTypeOptions : noteTypeOptions}
                label={formatMessage('reminders.reminderType')}
                disabled={isEdit && !!activeTemplateId}
                value={isEdit && activeTemplateId ? activeTemplateId : input.value}
              />
            )}
          </Field>

          <Field name={layout === 'reminder' ? 'title' : 'body'} parse={fieldParserFn}>
            {({ input, meta }) => (
              <TextAreaNew
                value={input.value}
                id="NoteBody"
                {...getFormMetaErrors({ meta, intl })}
                onChange={input.onChange}
                placeholder={formatMessage('reminders.placeholder')}
                disabled={disabled}
                rows={5}
                className="reminder"
                variant={COMPACT_LEFT_ALIGNED}
                label={formatMessage('reminders.reminder')}
              />
            )}
          </Field>

          <Field name="deadline">
            {({ input, meta }) => (
              <DateInput
                {...input}
                {...getFormMetaErrors({ meta, intl })}
                id="SetDueDate"
                label={formatMessage('reminders.dueDate')}
                disabled={disabled}
                variant={COMPACT_LEFT_ALIGNED}
                includeDateShortcutButtons
              />
            )}
          </Field>

          {/* using camelCase for field name here as it is not being used by the BE */}
          <Field name="dueTime">
            {({ input, meta }) => (
              <TimeInput
                {...input}
                {...getFormMetaErrors({ meta, intl })}
                id="SetDueTime"
                label={formatMessage('reminders.dueTime')}
                variant={COMPACT_LEFT_ALIGNED}
                deadline={values.deadline}
                customValue={values.dueTime ? values.dueTime : customDelay}
              />
            )}
          </Field>

          <RadioGroup
            variant={COMPACT_LEFT_ALIGNED}
            id="AssignReminderTo"
            label={formatMessage('reminders.assignReminder')}
          >
            <Field name="assignee_type" type="radio" value={TemplateAssigneeType.User}>
              {({ input }) => (
                <Radio
                  variant={COMPACT_LEFT_ALIGNED}
                  {...input}
                  label={formatMessage('reminders.individual')}
                  disabled={false}
                />
              )}
            </Field>
            <Field name="assignee_type" type="radio" value={TemplateAssigneeType.Group}>
              {({ input }) => (
                <Radio
                  variant={COMPACT_LEFT_ALIGNED}
                  {...input}
                  label={formatMessage('reminders.group')}
                  disabled={false}
                />
              )}
            </Field>
          </RadioGroup>
          <OnChange name="assignee_type">{() => form.change('assignee_id', null)}</OnChange>
          <Field name="assignee_id">
            {({ input, meta }) => (
              <SearchSelector
                {...getFormMetaErrors({ meta, intl })}
                {...input}
                id="AdvisorSearch"
                variant={COMPACT_LEFT_ALIGNED}
                label={formatMessage('reminders.assignee')}
                data={
                  values.assignee_type === TemplateAssigneeType.Group
                    ? groupDropdownOptions
                    : (userOptions as DropdownOption[])
                }
                disabled={loadingUsers || loadingGroups || loadingParentGroups || loadingNetworkUsers}
                isLoading={loadingUsers || loadingGroups || loadingParentGroups || loadingNetworkUsers}
                isMulti={false}
                showSelectAllOptions={Boolean(daId)}
                useTagsOnMulti={true}
              />
            )}
          </Field>
        </Stack>
      )}
    </FormSpy>
  )
}

export default ReminderFields
