import { CaseDetailsFlagFlagType } from '@acre/graphql'

const messagePrefixFlags = 'cases.compliance.flags'

const amlFlags = [
  CaseDetailsFlagFlagType.FlagFinancialCrime,
  CaseDetailsFlagFlagType.FlagPep,
  CaseDetailsFlagFlagType.FlagNationality,
  CaseDetailsFlagFlagType.FlagAml,
]
export const flagToMessage = (flag: CaseDetailsFlagFlagType) => {
  return `${messagePrefixFlags}.${flag}`
}

export const canEditFlag = (flag: CaseDetailsFlagFlagType, amlPermission: boolean, advicePermission: boolean) => {
  return amlFlags.includes(flag) ? amlPermission : advicePermission
}
