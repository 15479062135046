import { Locales } from '../types'

// Translations that are common to two or more products
export default {
  [Locales.GB]: {
    customiseColumns: 'Customise columns',
    clickAndDragColumns: 'Click and drag columns in the table to re-arrange them',
    generic: {
      or: 'Or',
      by: 'by',
      add: 'Add',
      open: 'Open',
      openInNewTab: 'Open in new tab',
      signIn: 'Sign in',
      required: 'Required',
      archived: 'Archived',
      apply: 'Apply',
      reset: 'Reset',
      whichWere: 'Which were',
      loading: 'Loading...',
      calendar: 'Calendar',
      reInState: 'Reinstate',
      saveAndContinue: 'Save and continue',
      saveChanges: 'Save changes',
      select: 'Select',
      discardChanges: 'Discard changes',
      close: 'Close',
      saveAndClose: 'Save and close',
      pleaseSelect: 'Please select',
      confirmAndRunChecks: 'Confirm and run checks',
      confirmAndContinue: 'Confirm and continue',
      insurer: 'Insurer',
      clients: 'Clients',
      client: 'Client',
      male: 'Male',
      female: 'Female',
      notSpecified: 'Not specified',
      invalid: 'Invalid',
      total: 'Total',
      continue: 'Continue',
      complete: 'Complete',
      reSubmit: 'Resubmit',
      missing: 'Missing',
      view: 'View',
      use: 'Use',
      show: 'Show',
      showDetails: 'Show details',
      hide: 'Hide',
      hideDetails: 'Hide details',
      submit: 'Submit',
      overview: 'Overview',
      name: 'Name',
      back: 'Back',
      phoneNumber: 'Phone number',
      mobilePhone: 'Mobile phone',
      homePhone: 'Home phone',
      emailAddress: 'Email address',
      email: 'Email',
      website: 'Website',
      companyEmail: 'Company email',
      companyName: 'Company name',
      limitedCompany: 'Limited Company',
      companyDirector: 'Company Director',
      phone: 'Phone',
      currentAddress: 'Current address',
      dateOfBirth: 'Date of birth',
      dependants: 'Dependants',
      YES: 'Yes',
      NO: 'No',
      yes: 'Yes',
      you: 'You',
      no: 'No',
      ok: 'OK',
      included: 'Included',
      notIncluded: 'Not included',
      typeHere: 'Type here',
      typeToSearch: 'Type to search',
      unknown: 'Unknown',
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete',
      deleteWithValue: 'Remove {name}',
      removeClients: 'Remove clients',
      mins: 'Minutes',
      hours: 'Hours',
      today: 'Today',
      lastWeek: 'Last week',
      day: 'Day',
      days: 'Days',
      month: 'Month',
      months: 'Months',
      monthsLowerCase: 'months',
      next: 'Next',
      nDays: '{n} day(s)',
      nWeeks: '{n} week(s)',
      nMonths: '{n} month(s)',
      nYears: '{n} year(s)',
      nYearsmMonths: '{n} year(s) {m} month(s)',
      ageN: 'Age {n}',
      slashMo: '{amount} / mo',
      slashMonth: '{amount} / month',
      slashYear: '{amount} / yr',
      slashLumpSum: '{amount}',
      slashAnnual: '{amount} / annual',
      showArchived: 'Show archived',
      combinedExpenditure: 'Combined expenditure',
      monthly: 'Monthly',
      annually: 'Annually',
      quarterly: 'Quarterly',
      lumpSum: 'Lump sum',
      year: 'Year',
      years: 'Years',
      yearTerm: '{term} year term',
      noInformation: 'No information',
      summary: 'Summary',
      unsavedChanges: 'You have unsaved changes. Are you sure you want to leave?',
      unresolvedErrors:
        'You have unresolved errors. These must be resolved to prevent from losing any unsaved changes. Are you sure you want to continue?',
      nothingToShow: 'Nothing to show',
      thisField: 'This field',
      posted: 'Posted',
      edited: 'Edited',
      remove: 'Remove',
      cancel: 'Cancel',
      confirm: 'Confirm',
      leaveWithoutSaving: 'Yes, leave without saving',
      selectAnItem: 'Select an item',
      recommend: 'Recommend',
      thisWillAppearInTheSuitabilityReport: 'This will appear in the suitability report',
      perWeek: 'Per week',
      perMonth: 'Per month',
      perYear: 'Per year',
      doItLater: 'Do it later',
      term: 'Term',
      for: 'for',
      somethingWentWrong: 'Something went wrong. If problem persists, contact support at hello@acre.software.',
      none: 'None',
      other: 'Other',
      evidenceOfResearch: 'Evidence of research',
      percent: '{n}%',
      copy: 'Copy',
      unassigned: 'Unassigned',
      optional: 'Optional',
      optionalLabel: 'Optional:',
      saveToShortlist: 'Save to shortlist',
      moreInfo: 'More info',
      yesRemove: 'Yes, remove',
      showMore: '+ Show more',
      showLess: '- Show less',
      captureAtLeastOne: 'You need to capture at least one of the following:',
      employees: 'Employees',
      viewCase: 'View case',
      clearAll: 'Clear all',
      selectAll: 'Select all',
      twelveMonths: '12 months',
      legalForm: {
        llp: 'LLP (Limited Liability Partnership)',
        limited: 'LTD (Limited company)',
        plc: 'PLC (Public Limited Company)',
        soleTrader: 'Sole Trader',
        partnership: 'Partnership',
        division: 'Division',
        governmental: 'Governmental',
      },
      notAvailable: 'Not available',
    },
    time: {
      daysPlural: 'days',
      daySingular: 'day',
      weeksPlural: 'weeks',
      weekSingular: 'week',
      monthsPlural: 'months',
      monthSingular: 'month',
    },
    accessibility: {
      closeModal: 'Close modal',
    },
    notes: {
      titlePlaceholder: 'Type subject...',
      bodyPlaceholder: 'Write your note...',
    },
    select: {
      message: 'Select an Item',
      loading: 'Loading...',
    },
    conveyancingPDF: {
      summary: 'Your inclusive conveyancing quote: {amount}',
      greeting: 'Dear',
      teamedUp:
        "{name} has teamed up with Acre and Premier Property Lawyers to bring you access to the UK's largest and most respected conveyancing firm at a great, inclusive price.",
      disclaimer: `{name} is authorised and regulated by the Financial Conduct Authority with registration number {fca}. Premier Property Lawyers Limited registered office: 1 Frances Way, Leicester, LE19 1SH. Practice Licence number 11315. Registered in England and Wales 04323405. VAT number 231 822924. The Introducer Acre Platforms Limited, who has provided this quotation, has suggested that you use a particular Conveyancer for your conveyancing. The Introducer is required to advise you that it has a financial arrangement with the Conveyancer and that the Conveyancer will pay a referral fee of up to £400.00 + VAT to the Introducer. The Conveyancer is totally independent from the Introducer and the Conveyancer will act only on your instructions and always in your best interests. The Conveyancer will provide you with impartial, independent advice when providing this service and you are able to raise any questions with them about any aspect of your case. *We will only charge you our fee if you move. If for any reason your sale or purchase falls through we will not charge you anything for our time with the exception of the File Opening Fee which is payable once a sale or purchase has been agreed (subject to contract). If we have paid out any of the additional costs listed above on your behalf we will still need payment for these. Any upfront payments are not refundable but will be credited against costs incurred.`,
      disclaimerRemo: `{name} is authorised and regulated by the Financial Conduct Authority with registration number {fca}. Premier Property Lawyers Limited registered office: 1 Frances Way, Leicester, LE19 1SH. Practice Licence number 11315. Registered in England and Wales 04323405. VAT number 231 822924. The Introducer Acre Platforms Limited, who has provided this quotation, has suggested that you use a particular Conveyancer for your conveyancing. The Introducer is required to advise you that it has a financial arrangement with the Conveyancer and that the Conveyancer will pay a referral fee of up to £150.00 + VAT to the Introducer. The Conveyancer is totally independent from the Introducer and the Conveyancer will act only on your instructions and always in your best interests. The Conveyancer will provide you with impartial, independent advice when providing this service and you are able to raise any questions with them about any aspect of your case.`,
      noSale: 'No property sale specified',
      noSurprises: "No hidden fees, no surprises. {amount} + VAT. That's it.",
      noPurchase: 'No property to purchase/remortgage specified',
      yourQuote: 'Your inclusive conveyancing quote: {amount}',
      yourPrice:
        "This price has been generated based on everything we know about your particular circumstances. Providing the information you have given us is correct, the only other money you will pay to the conveyancer is any Stamp Duty Land Tax that's due on the transaction plus the applicable Land Registration fee.",
      includes: {
        header: 'This quote includes:',
        searches: 'Standard property search pack including local, water and environmental searches',
        id: 'Identity and funds checks',
        mortgageFee: 'Mortgage fee',
        legal: 'All standard legal fees for the sale of your old property',
        leasehold: 'Leasehold fee if appropriate',
      },
      dedicated:
        'Dedicated conveyancer: You will be assigned your own dedicated conveyancer so there is always someone on hand to guide and assist you through the entire process. You will be given the personal extension number for your conveyancer, helping you to contact the right person every time you call.',
      straightforward:
        'Straightforward pricing: One fee, including local, water and environmental searches, mortgage fee, archiving and funds transfer.',
      straightforwardRemo:
        'Straightforward pricing: All usual charges and fees including identity checks, funds transfer and mortgage fee included.',
      online:
        "Online case management: PPL's revolutionary online service lets you manage your case whenever you want, from wherever you are, using a smartphone, tablet or PC.",
      updates:
        'Regular status updates: You will receive regular status updates via email, SMS, phone call or letter, whichever you prefer.',
      noFee: `No move, no legal fee: If your move falls through completely, for any reason whatsoever, you won't be charged our legal fee.*`,
      unrivalled: `Unrivalled expertise: We ensure that your dedicated conveyancer has the specialist knowledge and expertise required to manage the complexities of your case.`,
      hesitate:
        "If you have any questions or when you're ready for us to refer you to the conveyancer, please don't hesitate to let me know.",
      signOff: 'Yours,',
      assumptions: 'Assumptions:',
      allFees: {
        intro: `The following additional fees and charges are not taken into account in our inclusive pricing due to their rarity and difficulty to predict but may occasionally be charged depending on the circumstances of your case.`,
        purchasing: 'When purchasing a property',
        selling: 'When selling a property',
        remo: 'When remortgaging a property',
      },
      yourQuotation: 'Your quotation',
      properties: 'Properties:',
      assumptionPrice:
        'This quote assumes that the property at {address} is being {action} with a {moneyType} of {amount}',
      assumptionSalePrice: ' and the property at {address} is being sold for a price of {amount}',
      allRelevant: `Additionally, it is assumed that all relevant details of the transaction have been supplied as part of the property fact find including particulars of the property type(s), tenure(s), shared ownership/equity status and the details of all occupants and dependants.`,
      limited: `In certain, very limited circumstances, there may be rare fees for additional work that we're unable to predict. You can find full details of these in the section 'Fees that are not included' below. Additionally, if fees are charged by outside parties, e.g. the freeholder of a flat, these will be passed on to you.`,
      otherFees: 'Other fees and charges payable at the time of completion:',
      noOtherFees: 'Based on the information provided, we do not believe any other payments will need to be made.',
      estimates:
        'These fees and charges are estimates based on the information provided in the fact find and will be confirmed by your conveyancer.',
      creating: 'Creating...',
      failed: 'Could not generate PDF',
      download: 'Download this quote as a PDF',
      preQuote: {
        summary: 'Your inclusive conveyancing quote',
        intro:
          "We'll normally produce your inclusive quote as soon as we have details of the property you're looking to purchase. Knowing about the property helps us understand exactly which legal services you will require and means we can avoid any nasty surprises down the line. ",
        hesitate: "If you have any questions, please don't hesitate to let me know.",
      },
      redBox: {
        beware: 'BEWARE!',
        first: `You may have seen some unbelievably low prices on the internet for conveyancing services. These are, in most cases, literally too good to be true and translate into a myriad of unexpected charges, surprises and slow service.`,
        second: `Unlike this inclusive quote, they probably don't include things as simple as VAT, basic searches and dealing with a mortgage lender and can often end up costing nearly three times as much as the headline fee.`,
      },
      forYouTitle: 'What you get',
    },
  },
}
