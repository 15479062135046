import React, { ReactNode } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import { ValidationErrors } from 'final-form'
import { isEqual } from 'lodash'
import { useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FormattedMessage } from 'react-intl'

import { Button, Card, Divider, H4 } from '@acre/design-system'

import { UnsetMarginWrapper } from '../Common.styles'

type Props = {
  id?: string
  children: (name: string, errors: ValidationErrors, index: number) => ReactNode
  headingText: string
  buttonText: string
  fieldName: string
  validate?: (values: any) => object
}

const InlineCardList = ({ id, children, headingText, buttonText, validate, fieldName }: Props) => {
  const theme = useTheme()
  const { mutators } = useForm()

  return (
    <>
      <FieldArray name={fieldName} validate={validate} isEqual={isEqual}>
        {({ fields, meta }) => {
          const errors = meta?.error as unknown as ValidationErrors
          return fields.map((name, index) => {
            return (
              <Box key={name} mb={theme.spacers.size16}>
                <Card padding={theme.spacers.size16}>
                  {/* Card heading */}
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <H4 styledAs="h5">{headingText + ` ${index + 1}`}</H4>
                    <Button
                      id={`Remove${id}${index}`}
                      variant="compact"
                      buttonStyle="dangerSecondary"
                      onClick={() => {
                        mutators.remove(fieldName, index)
                      }}
                    >
                      <FormattedMessage id="generic.remove" />
                    </Button>
                  </Box>
                  <Box my={theme.spacers.size16}>
                    <UnsetMarginWrapper margin="size16">
                      <Divider />
                    </UnsetMarginWrapper>
                  </Box>
                  {/* Card content */}
                  {children(name, errors, index)}
                </Card>
              </Box>
            )
          })
        }}
      </FieldArray>

      <Button
        onClick={() => mutators.push(fieldName, {})}
        buttonStyle="dashed"
        variant="compact"
        fullWidth
        id={`Add${id}`}
      >
        + {buttonText}
      </Button>
    </>
  )
}

export default InlineCardList
