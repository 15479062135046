import { styled } from '@mui/material'

import { ColourCardTypes } from '..'
import { setCardBackground, setColour, setHoverBackground, setRowBackground } from './ColouredItemCard.helpers'
import { disabledClassName } from '../../styles/disabled.styles'

export const CardWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'colourVariant',
})<{ disabled?: boolean; colourVariant?: ColourCardTypes }>(
  ({ theme, colourVariant, disabled }) => `
  border: ${`1px solid ${setColour(colourVariant, theme)}`};
  border-radius: ${theme.display.borderRadius};
  padding: ${theme.spacing(2)};
  width: 100%;
  background: ${setCardBackground(colourVariant, theme)};
  ${disabled && disabledClassName(theme)};
  &:disabled,
  &.disabled {
    div, p, button {
        color: ${theme.colours.baseMid};
        background-color: ${theme.colours.baseExtraLight};
    }
    h2 {
      color: ${theme.colours.baseMid};
      background-color: ${theme.colours.baseLight};
    }
    h3 {
      color: ${theme.colours.baseMid};
    }
    svg {
      path {
        fill: ${theme.colours.baseMid};
      }
    }
  }
`,
)

export const ClickableCardWrapper = styled(CardWrapper)<{ colourVariant?: ColourCardTypes }>(
  ({ theme, colourVariant }) => `
  &:hover {
    cursor: pointer;
    transition: all 0.1s ease-out;
    background: ${setHoverBackground(colourVariant, theme)}
  }
`,
)

export const AddItemCard = styled(ClickableCardWrapper)<{ colourVariant?: ColourCardTypes; disabled?: boolean }>(
  ({ theme, colourVariant, disabled }) => `
  display: flex;
  justify-content: center;
  padding: ${theme.spacing(1)};
  h3 {
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: ${setColour(colourVariant, theme)};
  }
  ${disabled && disabledClassName(theme)};
`,
)

export const CardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const Title = styled('h2')<{ colourVariant?: ColourCardTypes }>(
  ({ theme, colourVariant }) => `
  font-size: 12px;
  color: ${setColour(colourVariant, theme)};
  margin-bottom: 0;
  padding: ${`${theme.spacing(0.5)} ${theme.spacing(1)}`};
  background-color: ${setRowBackground(colourVariant, theme)};
  border-radius: ${theme.display.borderRadius};
`,
)

export const ListItemWrapper = styled('div')<{ colourVariant?: ColourCardTypes }>(
  ({ theme, colourVariant }) => `
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(0.5)};
  &:nth-of-type(odd) {
    background: ${setRowBackground(colourVariant, theme)};
    border-radius: ${theme.display.borderRadius};
  }
`,
)

export const Key = styled('div')<{ colourVariant?: ColourCardTypes }>(
  ({ theme, colourVariant }) => `
  width: 33%;
  padding-right: ${theme.spacing(0.5)};
  font-size: 12px;
  color: ${setColour(colourVariant, theme)};
  overflow-wrap: break-word;
`,
)

export const Value = styled('div')<{ colourVariant?: ColourCardTypes }>(
  ({ theme, colourVariant }) => `
  width: 67%;
  padding-left: ${theme.spacing(0.5)};
  text-align: right;
  font-size: 12px;
  color: ${setColour(colourVariant, theme)};
  overflow-wrap: break-word;
  font-weight: bold;
`,
)
