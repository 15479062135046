import { Box, Stack, Typography } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '@acre/utils'
import { FlagReviewGrade } from '@acre/graphql'
import { COMPACT_LEFT_ALIGNED, Dropdown, NoteBodyItem } from '@acre/design-system'

import { createCaseFlagTimeStamp } from '../CaseFlagNotes.helpers'

const messagePrefix = 'cases.flags.note'

type Props = {
  fieldName: string
  showSubmit: boolean
  hasApprovePermission: boolean
  displayGrade?: boolean
}

const CaseFlagNoteFields = ({ fieldName, showSubmit, hasApprovePermission, displayGrade }: Props) => {
  const formatMessage = useFormatMessage()

  return (
    <FieldArray name={`${fieldName}.reviews`}>
      {({ fields }) =>
        fields?.map((name, index) => {
          const disabled = !(Boolean(index >= Number(fields.length || 0) - 1) && showSubmit) || !hasApprovePermission
          const review = fields.value[index]
          return (
            <Stack spacing={disabled ? 0.5 : 2} mb={2} key={`CaseDetailsFlagReview${index}`}>
              {/* Reviewer notes */}
              <Field name={`${name}.reviewer_notes`}>
                {({ input }) => {
                  return (
                    <>
                      <NoteBodyItem
                        {...input}
                        id={`NoteBodyItem${index}`}
                        placeholder={formatMessage('generic.typeHere')}
                        createdDate={
                          <Field name={`${name}.created_at`}>
                            {({ input: { value } }) =>
                              value && (
                                <Typography>
                                  <FormattedMessage
                                    id={`${messagePrefix}.reviewCreatedAt`}
                                    values={createCaseFlagTimeStamp(value)}
                                  />
                                </Typography>
                              )
                            }
                          </Field>
                        }
                        disabled={disabled}
                      />
                      {disabled && (
                        <Typography>
                          {review.review_passed ? (
                            <FormattedMessage id={`${messagePrefix}.caseFlagPassedYES`} />
                          ) : (
                            <FormattedMessage id={`${messagePrefix}.caseFlagPassedNO`} />
                          )}
                        </Typography>
                      )}
                    </>
                  )
                }}
              </Field>
              {/* Case grade */}
              {displayGrade && (
                <Box width="250px" sx={{ '& select': { width: '100px' } }}>
                  <Field name={`${name}.review_grade`}>
                    {({ input }) => {
                      if (disabled) {
                        const parsedGrade = !isEmpty(input.value) ? input.value?.replace('GRADE_', '') : '-'
                        return (
                          <Typography>
                            <FormattedMessage id={`${messagePrefix}.caseGrade`} values={{ value: parsedGrade }} />
                          </Typography>
                        )
                      }
                      return (
                        <Dropdown
                          {...input}
                          label={formatMessage(`${messagePrefix}.caseGradeLabel`)}
                          selectMessageRaw={formatMessage('generic.select')}
                          options={[
                            { label: 'A', value: FlagReviewGrade.GradeA },
                            { label: 'B', value: FlagReviewGrade.GradeB },
                            { label: 'C', value: FlagReviewGrade.GradeC },
                            { label: 'D', value: FlagReviewGrade.GradeD },
                          ]}
                          variant={COMPACT_LEFT_ALIGNED}
                          disabled={false}
                        />
                      )
                    }}
                  </Field>
                </Box>
              )}
            </Stack>
          )
        })
      }
    </FieldArray>
  )
}

export default CaseFlagNoteFields
