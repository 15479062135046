import { FieldReadFunction, Reference } from '@apollo/client'

import {
  AdditionalLoans,
  CreateGeneralInsuranceProduct,
  CreateGeneralInsuranceProductDetails,
  FeeType,
  GeneralInsuranceProductsResponse,
  LoanType,
  Maybe,
  Mortgage,
  MortgageCalculatedValues,
  MortgageProduct,
  MortgageStatus,
  NewFee,
  PredictedRevenueEntry,
  ProtectionProductDetails,
} from '@acre/graphql'

import { getPredictedRevenueFromClient } from './getPredictedRevenueFromClient'
import { getPredictedRevenueFromGI } from './getPredictedRevenueFromGI/getPredictedRevenueFromGI'
import { getPredictedRevenueFromMortgage } from './getPredictedRevenueFromMortgage'
import { getPredictedRevenueFromProtection } from './getPredictedRevenueFromProtection'

const read: FieldReadFunction<PredictedRevenueEntry[]> = (_, { readField, variables, toReference }) => {
  const caseRef = toReference(`Case:${variables!.caseId}`)
  const mortgagesRef = readField<undefined | Reference[]>('mortgages', caseRef)
  const preferenceLoanAmount = readField<Maybe<string> | undefined>('preference_loan_amount', caseRef)

  const mortgages = (mortgagesRef || []).map((mortgageRef) => {
    const mortgageProductRef = readField<MortgageProduct>('mortgage_product', mortgageRef)
    const lenderName = readField<string>('lender_name', mortgageProductRef)
    return {
      status: readField<MortgageStatus>('status', mortgageRef),
      calculated_values: readField<MortgageCalculatedValues>('calculated_values', mortgageRef),
      mortgage_amount: readField<string>('mortgage_amount', mortgageRef),
      mortgage_product: {
        lender_name: lenderName,
      },
    }
  })

  // Getting all case fees
  const newFees = readField<NewFee[]>('fees', caseRef)?.map((feeRef) => ({
    fee_type: readField('fee_type', feeRef) as FeeType,
    value: readField('value', feeRef) as string,
  }))

  // Getting all protection products
  const protectionProductsRef = readField<undefined | Reference[]>({
    fieldName: 'protectionProducts',
    args: {
      protectionProductDetails: true,
      filterCaseIds: [variables!.caseId],
    },
  })

  // Getting all GI products
  const giProductsResponse = readField<GeneralInsuranceProductsResponse | undefined>({
    fieldName: 'generalInsuranceProducts',
    args: {
      input: { filter_case_ids: [variables!.caseId] },
    },
  })

  // Cases should include additional borrowing to fees
  const additionalLoans = readField<undefined | AdditionalLoans[]>('additional_loans', caseRef)?.map(
    (additionalLoansRef) => ({
      loan_amount: readField('loan_amount', additionalLoansRef) as string,
      loan_purpose: readField('loan_purpose', additionalLoansRef) as LoanType,
    }),
  )

  const protectionProducts = protectionProductsRef?.map((ref) => {
    return readField('details', ref) as ProtectionProductDetails
  })

  const giProducts = readField<CreateGeneralInsuranceProduct[] | undefined>(
    'general_insurance_products',
    giProductsResponse || {},
  )?.map((ref) => {
    return readField('details', ref) as CreateGeneralInsuranceProductDetails
  })

  const res = calculatePredictedRevenue({
    mortgages,
    protectionProducts,
    newFees,
    preferenceLoanAmount,
    additionalLoans,
    giProducts,
  })

  return res
}

type Props = {
  newFees?: NewFee[]
  mortgages?: Partial<Mortgage>[]
  protectionProducts?: ProtectionProductDetails[]
  preferenceLoanAmount?: Maybe<string>
  additionalLoans?: AdditionalLoans[]
  giProducts?: CreateGeneralInsuranceProductDetails[]
}

const calculatePredictedRevenue = ({
  mortgages,
  protectionProducts,
  newFees,
  preferenceLoanAmount,
  additionalLoans,
  giProducts,
}: Props): PredictedRevenueEntry[] => {
  const res = [
    ...getPredictedRevenueFromGI(giProducts || []),
    ...getPredictedRevenueFromProtection(protectionProducts || []),
    ...getPredictedRevenueFromClient(newFees, mortgages, preferenceLoanAmount, additionalLoans),
  ] as PredictedRevenueEntry[]

  const revenueFromMortgageProducts = getPredictedRevenueFromMortgage(mortgages)
  if (revenueFromMortgageProducts) {
    res.push(revenueFromMortgageProducts)
  }

  return res
}

export default read
