import { Locales } from '@acre/utils'

export const messagesBrokerCRM = {
  [Locales.GB]: {
    nav: {
      dashboard: 'Home',
      dashboards: 'Dashboards',
      clients: 'Clients',
      company: 'Company',
      companyDashboard: 'Company',
      networkDashboard: 'Network',
      performanceDashboard: {
        user: 'Performance dashboard',
        company: 'Firm performance dashboard',
        network: 'Network performance dashboard',
        userSubheading: 'Your personal sales metrics',
        companySubheading: 'Sales metrics for everyone in the firm',
        networkSubheading: 'Sales metrics for all firms in the network',
      },
      complianceDashboard: {
        user: 'Consumer duty dashboard',
        company: 'Firm consumer duty dashboard',
        network: 'Network consumer duty dashboard',
        userSubheading: 'Your personal consumer duty metrics',
        companySubheading: 'Consumer duty metrics for everyone in the firm',
        networkSubheading: 'Consumer duty metrics for all firms in the network',
      },
      cases: 'Cases',
      reminders: 'Reminders',
      introducers: 'Introducers',
      subTableIntroducers: "Introducer's organisations",
      accounting: 'Accounting',
      casesCompliance: 'Compliance',
      sourcing: {
        title: 'Quick Source',
        mortgageSourcing: 'Mortgage Sourcing',
        mortgages: 'Mortgages',
        mortgagesBeta: 'Mortgages (beta)',
        protection: 'Protection (coming soon)',
        GI: 'GI (coming soon)',
      },
      reports: 'Reports',
      clientOverview: 'Client overview',
      profile: 'Profile',
      templates: 'Templates',
      products: 'Products',
      notifications: 'Notifications',
      settings: {
        title: 'Settings',
        companySettings: 'Company Settings',
        knowledgeBase: 'Knowledge Base',
        enablePreviewMode: 'Switch preview mode on',
        disablePreviewMode: 'Switch preview mode off',
        mySettings: 'My Settings',
        signOut: 'Sign Out',
        reportProblem: 'Report Problem',
      },
      signedIn: 'Signed in as {fullName}',
      search: {
        search: 'Search',
        enterCaseId: 'Enter case ID',
        typeClientsNameOrEmailAddress: "Type client's name or email address",
        noResults: 'No results found',
      },
      previewMode: {
        tooltip:
          "You're in preview mode. This means that you're using the newest version of Acre. Click this icon to go back to standard mode.",
      },
    },

    errorPage: {
      text: 'Looks like something went wrong!',
      button: 'Go back',
    },

    login: {
      title: 'Welcome to acre',
      goToDashboard: 'Go to dashboard',
      goToZendesk: 'Go to help desk',
      form: {
        email: {
          label: 'Email address',
          placeholder: 'you@email.com',
          error: {
            one: 'The email address entered was not recognised',
            two: `
              The email address entered was not recognised, please
              retry or {getInTouch}
            `,
            getInTouch: 'get in touch',
          },
        },
        button: 'Next',
        rememberMe: 'Remember me',
      },
    },

    propertyInfo: {
      backToProperties: 'Back to properties',
      backToProperty: 'Back to property',
      goBack: 'Go back',
      loadPropertyErrorTitle: 'Could not load property',
      loadPropertyErrorDesc: 'An error occurred loading this property:',
      unknownError: 'Unknown error',
    },

    onboarding: {
      footer: {
        poweredBy: 'Powered by',
        letsStart: "Let's start",
        verifyCreds: 'Verify credentials',
      },
      header: {
        org: {
          companyDetails: 'Company details',
          panels: 'Panels',
          documents: 'Documents',
          fees: 'Fees',
          users: 'Users',
        },
        user: {
          contactDetails: 'Contact details',
          credentials: 'Credentials',
          emailAndCalendar: 'Email and Calendar',
        },
      },
      welcomeOrg: {
        header: 'Hi {name}, welcome to Acre!',
        body: "To start using Acre, you'll need to fill in some details to set up your workspace. This should take about <strong>{time} minutes</strong>:",
        bulletPoints: {
          companyDetails: 'Your company details',
          privacyPolicy: 'Upload your privacy policy',
          sourcingPanels: 'Configure your lender panel',
          firmsFeeGrids: "Set up your firm's fee grids",
          addUsers: 'Add users',
        },
      },
      welcomeUser: {
        header: "Hi {name}, let's set up your profile",
        body: 'Setting up your profile should take about <strong>{time} minutes</strong>. All you need to do is review your:',
        bulletPoints: {
          contactDetails: 'Contact details',
          credentials: 'Protection and home insurance platform login details',
          calendar: 'Calendar to schedule meetings from Acre',
        },
      },
      cantOnboardUser: {
        header: "Hi {name}, it looks like your company hasn't been set up on Acre yet...",
        body: "Before you can start using Acre, a principal will need to sign in and complete the setup process. Once this is done, you'll be able to sign in and get started.",
      },
      bannerText: {
        addCompanyAddress: "Add your company's address using postcode lookup or manually.",
        addCompanyLogo: "Add your company's logo and website to personalise your document templates.",
        uploadPrivacyPolicy: "Upload your company's privacy policy document to use in client documents.",
        iddPartsExplained: 'IDD parts explained',
        secondChargeAdvice:
          "<strong>Second charge advice</strong> is when you recommend and advise on second charge mortgages after assessing your clients' needs and circumstances.",
        additionalBtlDisclaimer:
          '<strong>Additional Buy-to-let disclaimer</strong> lets the customer know your BTL recommendation is restricted to arranging a suitable mortgage, not investment advice.',
        discretionaryFund:
          '<strong>Discretionary fund</strong> means a fee is due if the client cancels or withdraws at any stage prior to completion. The fee may be transferable to another application if made within six months of the original signed fee arrangement.',
        suitabilityReportPartsExplained: '<strong>Suitability Report parts explained</strong>',
        factFindAppendix:
          '<strong>An overview of the information entered in the fact find</strong>, which can help meet the requirements of external compliance service providers, will be provided at the end of the suitability report, if you select the first option.',
        debtConsolidationAddendum:
          '<strong>The Debt Consolidation addendum</strong> is applicable if you provide your clients with a Debt Consolidation Supplement and Debt Consolidation Comparison form. This will appear in your Suitability Report.',
        mortgageFees: 'You need to have at least one fee scenario. <a>How do I add a fee grid?</a>',
        addUsers:
          'Start adding other users to give them access to Acre. You can add or edit users from the company settings section later on. <a>How do I add a user?</a>',
        anyOtherClubs: `Selecting your preferred clubs will help you differentiate between your proc fee options in sourcing. You can now select different stages at where your company gets paid. Where one isn't selected, the default option is on case completion.`,
        baseMortgage: {
          header: '<strong>This also sets which Initial Disclosure Document your company uses.</strong>',
          bulletPoint1:
            '<strong>Whole of market including direct lenders</strong> is when you aim to select from every possible product for your customers when making a recommendation, including lenders outside your selected clubs and products only available direct from lenders',
          bulletPoint2:
            '<strong>Whole of market excluding direct lenders (just club)</strong> means your company uses a club or network to source mortgages direct from lenders',
          bulletPoint3:
            '<strong>Comprehensive</strong> means your company sources mortgages from most lenders but not all of the market',
        },
      },
      complete: {
        org: {
          title: 'Your company is all set up!',
          body: 'Now you just need to set up your profile, then you can start creating your first case.',
        },
        user: {
          title: 'All done!',
          body: 'Your profile is now set up. Press continue to start using the system.',
        },
        orgAndUserComplete: `Your organisation's settings are all set up. Press continue to start using the system.`,
      },
    },

    eula: {
      header: 'Acre Acceptable Use Policy',
      confirmation: 'I accept the terms in the Acceptable Use Policy',
      submit: 'Confirm and continue',
    },

    dashboard: {
      hi: 'Hi {name},',
      heresThePipeline: "Here's the pipeline of your active cases today",
      noActiveCases: 'No active cases',
    },

    rollbackCaseTransitions: {
      toPreRecommendation: {
        modalMessage:
          "Please confirm that you want to move this case to 'Pre-recommendation' status - this will archive your existing suitability report.",
      },
      toPreRecommendationGI: {
        modalMessage: "Please confirm that you want to move this case to 'Pre-recommendation' status.",
      },
      toPreApplication: {
        modalMessage: "Please confirm that you want to move this case to 'Pre-application' status.",
      },
      toApplicationSubmitted: {
        modalMessage: "Please confirm that you want to move this case to 'Application submitted' status.",
      },
      toAwaitingValuation: {
        modalMessage: "Please confirm that you want to move this case to 'Awaiting valuation' status.",
      },
      toAwaitingOffer: {
        modalMessage: "Please confirm that you want to move this case to 'Awaiting offer' status.",
      },
      toOffered: {
        modalMessage: "Please confirm that you want to move this case to 'Offered' status.",
      },
      toExchange: {
        modalMessage: "Please confirm that you want to move this case to 'Exchanged' status.",
      },
      toLead: {
        modalMessage: "Please confirm that you want to move this case to 'Lead' status.",
      },
    },

    caseFeeTypes: {
      preMortgageAdvice: 'Pre Mortgage Advice',
      preMortgageRecommendation: 'Pre Mortgage Recommendation',
      preMortgageRecommendationPercent: 'Pre Mortgage Recommendation',
      preMortgageApplication: 'Pre Mortgage Application',
      preMortgageApplicationPercent: 'Pre Mortgage Application',
      advisorMortgageTierCommission: 'Advisor Mortgage Tier Commission',
      advisorProtectionTierCommission: 'Advisor Health/Protection Tier Commission',
      offer: 'Mortgage Offer',
      offerPercent: 'Mortgage Offer',
      legalCompletion: 'Mortgage Legal Completion',
      legalCompletionPercent: 'Mortgage Legal Completion',
      legalExchangeFixed: 'Mortgage Legal Exchange',
      legalExchangePercent: 'Mortgage Legal Exchange',
      invalidFeeType: 'Invalid Fee Type',
    },

    orgStatuses: {
      active: 'Active',
      disabled: 'Disabled',
      pending: 'Pending',
      starter: 'Starter',
    },

    caseTransitions: {
      confirmExchanged: 'Confirm exchanged',
      toComplete: {
        next: 'Next',
        indemnifiedCommission: 'Health and Protection commission (indemnified)',
        indemnifiedCommissionX: 'Health and Protection commission {x} (indemnified)',
        nonIndemnifiedCommission: 'Health and Protection commission (non-indemnified)',
        nonIndemnifiedCommissionX: 'Health and Protection commission {x} (non-indemnified)',
        markCaseCompleted: 'Mark case completed',
        completeCase: 'Complete case',
        mortgageDetails: 'Mortgage details',
        confirmMortgageDetails: 'Confirm mortgage details',
        confirmFinalCaseFees: 'Confirm final case fees',
        lender: 'Lender',
        mortgage: 'Mortgage',
        product: 'Product',
        startDate: 'Start date',
        confirmMortgageDetailsCorrect: 'Confirm mortgage details are correct',
        mortgageAmount: 'Mortgage amount',
        procFee: 'Proc fee',
        preAdviceFee: 'Pre-advice fee',
        preRecommendationFee: 'Pre-recommendation fee',
        preApplicationFee: 'Pre-application fee',
        offerFee: 'Offer fee',
        legalFee: 'Legal fee',
        total: 'Total',
        feeType: 'Fee type',
        feeAmount: 'Fee amount',
        noFinalCaseFeesFound: 'No final case fees were found',
        amendedAmountError:
          'Mortgage amount cannot be higher/lower than 10% of the previous amount. If required please rollback.',
        updateClientAddressModal: {
          heading: 'Update client address',
          confirmationQuestion: 'Do you want to update the address history for the clients on this case?',
          purchasedProperty: 'Purchased property',
          clientOptions: 'Which clients will be moving to this address?',
          tooltip: 'The current address for {name} has already been set to {formattedPurchasedPropertyAddress}',
          moveDate: 'Move date',
          cancel: 'Cancel',
          updateAddress: 'Update address',
          warning:
            "Updating a client's address while they have an active case may invalidate identity checks. This could trigger a compliance review and delay the case proceeding.",
        },
      },
    },
    companySettings: {
      title: 'Company settings',
      subnav: {
        information: 'Company information',
        mortgageFees: 'Mortgage fees',
        insuranceFees: 'Insurance fees',
        templates: 'Templates',
        users: 'Users',
        userGroups: 'User groups',
        groupPermissions: 'Group Permissions',
        process: 'Process',
        mortgagePanel: 'Mortgage panel',
        featureFlags: 'Feature flags',
        giPanel: 'Panels',
        regulatory: 'Regulatory',
        fcaNumber: 'FCA Register Number:',
        firms: 'Firm management',
        integrations: 'Integrations',
        commercialDetails: 'Commercial details',
        appointedRepresentatives: 'Appointed Representatives',
        permissions: 'Advanced permissions',
        creditSupportTools: 'Credit support tools',
        calendarStructures: 'Calendar structures',
        payments: 'Payments',
        documentRequirements: 'Document requirements',
      },
      pages: {
        advancedPermissions: {
          enableAll: 'Enable all',
          disableAll: 'Disable all',
          update: 'Update',
          permissionsUserScope: 'Advanced permissions (user scope)',
          permissions: 'Advanced permissions',
          name: 'Name',
          description: 'Description',
          status: 'Status',
          warnings: 'Warnings',
          alert:
            'Removing this permission will block the user from making permission changes. Are you sure you want to remove it?',
          banner: 'Full list of permissions displayed',
          showDAsPermissions: `Show DA's permissions`,
        },
        ar: {
          heading: 'Appointed Representatives',
        },
        firmManagement: {
          heading: 'Firm management',
          addFirm: 'Add firm',
          noFirms: 'No firms currently known for this organisation',
          signInAsEditor: 'Sign in as an editor',
          signInAsViewer: 'Sign in as a viewer only',
          table: {
            header: {
              name: 'Company',
              email: 'Email',
              phone: 'Phone',
              address: 'Address',
            },
          },
        },
        groupPermissions: {
          title: 'Group Permissions',
          nameThisGroup: 'Name this group',
          buttons: {
            createUserGroup: 'Create group',
          },
          statusControls: 'Status controls',
          enabledPerms: `All ARs have this permission enabled`,
          disabledPerms: `All ARs have this permission disabled`,
          enabledPermsCount: `ARs with this permission: {count}`,
          disabledPermsCount: `ARs without this permission:  {count}`,
          columnCountInfo: `{enabledCount} out of {totalCount} ARs have this permission enabled`,
          attention:
            'Permissions will be assigned to all ARs. To reapply permissions to the recent list of ARs, you will need to disable and enable the permissions again.',
        },
        commercialDetails: {
          commercialDetails: 'Commercial details',
          activeSince: 'Active Since',
          rates: 'Rates',
          rebates: 'Rebates',
          rebatesEnd: 'Rebate end',
          csDirector: 'Account Manager',
          exptVolume: 'Expected volume',
          pmsBillable: 'PMS Billable',
          pmsRebate: 'PMS Rebate',
          minimum: 'Minimum charge',
          sponsor: 'Executive sponsor',
          bdManager: 'BDM',
          commercialCap: 'Commercial and Bridging Cap',
          additionalServices: 'Additional Services (JSON)',
          fixedPrice: 'Fixed Price',
          commercialFix: 'Commercial and Bridging Fix',
        },
        arManagement: {
          tableHeading: 'Appointed Representatives',
          invalidARRequest: 'Any AR create request just include a parent organisation',
          noARs: 'No AR currently known for this organisation',
        },
        regulatory: {
          regulatoryTitle: 'Regulatory information',
          representativeTitle: 'Representative of',
          complaintsTitle: 'Complaints address',
          button: 'Log out',
          dataList: {
            organisationID: 'Organisation ID',
            organisationExternalID: 'Organisation External ID',
            organisationLegalForm: 'Organisation Legal form',
            FCARegistrationNumber: 'FCA Registration number',
            joinedAcre: 'Joined Acre',
            status: 'Status',
            complianceEmail: 'Compliance email',
            clientPortal: 'Client portal',
            representativeOf: 'Representative of',
            complaintsAddress: 'Complaints address',
            complaintsEmail: 'Complaints email',
            complaintsPhoneNumber: 'Complaints phone number',
            complaintsPhoneNumberNotProvided: 'No phone number provided',
            complaintsUrl: 'Complaints website',
            noComplaintsUrlProvided: 'No website provided',
            representativeBusinessAddress: 'Business/Representative address',
          },
        },
        documentRequirements: {
          heading: 'Document requirements',
          showDisabled: 'Show disabled',
          requirementName: 'Requirement name',
          created: 'Created',
          lastEdited: 'Last edited',
          status: 'Status',
          disabled: 'Disabled',
          optional: 'Optional',
          required: 'Required',
        },
        companyInformation: {
          companyInformation: 'Company information',
          companyName: 'Company name',
          uploadLogo: 'Upload logo',
          removeLogo: 'Remove logo',
          contact: 'Contact',
          phoneNumber: 'Phone number',
          website: 'Website',
          brandColour: 'Brand colour for client portal',
        },
        panels: {
          title: 'Home insurance',
          conveyancing: 'Conveyancing',
          doYouSell: 'Do you advise on general insurance?',
          which: 'Which panels do you use?',
          paymentShield: 'PaymentShield',
          branchNumber: 'Branch number (optional)',
          uinsure: 'Uinsure',
          homeInOne: 'HomeInOne',
          none: 'No panel',
          banner1: 'If you advise on home insurance, select all the panels you use to compare quotes.',
          banner2: {
            header: 'Do I need a branch number?',
            body: `Entering a branch number is optional and is only important if you have several and wish to use a specific one. You can find your branch number, by logging into PaymentShield Intermediaries. Go to 'My Account' and expand 'Firm, Agencies & Roles'. Your branch number will appear before the legal name of your company.`,
          },
          banner3: {
            header: 'What is auto-refer?',
            body: 'When auto-refer is selected, we will automatically refer any case once a mortgage has been applied for where GI has not been sold or referred and the broker has not stated that the customer does not require insurance.',
          },
          banner4: {
            header: 'Where can I find my Premier Property Lawyers branch code?',
            body: 'PPL branch codes are allocated by Acre. Your customer success contact will be able to supply you with this number if you do not have it.',
          },
          banner4a: {
            header: 'What is auto-refer for conveyancing?',
            body: 'When auto-refer is selected, we will automatically refer any case where the lender has not offered free legals and no solicitor has been entered.',
          },
          banner5: {
            header: 'What is auto refer for wills?',
            body: "When auto-refer is selected, we will automatically refer any case where the client doesn't have an up-to-date will in place which has not already been referred or self-referred by the client.",
          },
          conveyancingCode: 'Premier Property Lawyers branch code',
          autoRefer: 'Enable auto refer to Uinsure',
          suppress: 'Hide conveyancing commissions from ARs',
          willsTitle: 'Wills and probate',
          willsAutoRefer: 'Enable auto-refer to Honey Legal',
          conveyancingAutoRefer: 'Enable auto-refer to Premier Property Lawyers',
          commissionPercentage: {
            label: 'Percentage to be accounted when creating protection commission',
            placeholder: '% to be deducted from commission',
            bannerTitle: 'What is the protection club information?',
            bannerDescription:
              'Acre receives gross information data from Pipeline when creating accounting commission ledgers. If you would like Acre to produce a net amount add the % to be deducted when creating the ledger.',
          },
        },
        process: {
          header: 'Workflows',
          enhanced: 'Enhanced workflow',
          optional: 'Optional workflow',
          options: {
            enabled: 'Enabled',
            disabled: 'Disabled',
            soft: 'Optional',
          },
          tooltip: {
            enhanced: `<p>Enhanced workflow contains additional automated checks related to income, 
      bank accounts, credit history and property details. We recommend this workflow
      is enabled if you wish to use Acre Lender DIP/FMA integrations as the data 
      validated is required by several lenders.</p>
            <br />
            <p>Enabled - Check must be satisfied before case can proceed</p>
            <br />
            <p>Disabled - Check not required and will not be flagged to advisors</p>
            <br />
            <p>Optional - Check will flag to users but not required for case to proceed</p>
            <br />
            <p>Please see Acre Knowledge Base for full list of checks in Enhanced Workflow.</p>`,
            optional: `<p>Optional workflow contains additional automated checks related to employer 
      details, accountants details and property details.</p>
          <br />
          <p>Enabled - Check must be satisfied before case can proceed</p>
          <br />
          <p>Disabled - Check not required and will not be flagged to advisors</p>
          <br />
          <p>Optional - Check will flag to users but not required for case to proceed</p>
          <br />
          <p>Please see Acre Knowledge Base for full list of checks in Optional Workflow.</p>`,
          },
          defaultReminders: {
            defaultReminders: 'Default reminders',
            subHeader: 'These will be the predefined reminders your team can select when creating a reminder',
            addAReminder: 'Add a reminder',
            name: 'Name',
            defaultAssignee: 'Default assignee',
            priority: 'Priority',
            defaultDeadline: 'Default deadline',
            enabled: 'Enabled',
            creator: 'Creator',
            individuals: 'Individual',
            groups: 'Groups',
            noDefaultReminders: 'No templates found',
            caseType: 'Case type',
            reminderCreator: 'Reminder creator',
            priorityOptions: {
              veryHigh: 'Very High',
              high: 'High',
              medium: 'Medium',
              low: 'Low',
              veryLow: 'Very Low',
            },
            frequencyOptions: {
              hours: 'Hours',
              days: 'Days',
              weeks: 'Weeks',
              years: 'Years',
            },
          },
          calendarTemplates: {
            title: 'Calendar templates',
            description: 'These will be the predefined calendar events your team can select when scheduling an event',
            addCalendarTemplate: 'Add calendar template',
            table: {
              name: 'Name',
              defaultAssignee: 'Default assignee',
              description: 'Description',
              caseType: 'Case type',
              enabled: 'Enabled',
              noCalendarTemplates: 'No calendar templates',
            },
            modal: {
              title: 'Default calendar template',
              name: 'Name',
              defaultAssignee: 'Default assignee',
              description: 'Description',
              caseType: 'Case type',
            },
          },
          caseStatus: {
            header: 'Case status',
            useExchange: 'Use Exchanged case status',
            tooltip: `Do you wish to use the 'Exchanged' status on your cases? Cases in 'Offered' will transition to 'Exchanged' before they can transition to 'Complete'`,
          },
        },
        calendarStructures: {
          title: 'Calendar structures',
          addCalendarPeriod: 'Add Calendar Period',
          table: {
            header: {
              period: 'Period',
              fromDate: 'From',
              toDate: 'To',
              status: 'Status',
            },
            label: {
              active: 'Active',
              inActive: 'Inactive',
            },
          },
          modal: {
            name: 'Name',
            toDate: 'To',
            fromDate: 'From',
          },
        },
        mortgageFees: {
          title: 'Mortgage fee structure',
          paragraph: `
            This will be the standard fee structure presented to clients
            in your IDD. These can be changed at any time during a case.
          `,
          listTitle: 'Fees',
          feeTypes: {
            preAdviceFixed: 'Pre-advice fixed',
          },
          columnHeading: {
            feeType: 'Fee type',
            feeAmount: 'Fee amount',
          },
          form: {
            label: 'Add a pre-advice fixed',
          },
          buttons: {
            setDefaultFee: 'Set as default fee',
            removeFeeScenario: 'Remove fee scenario',
            createFeeScenario: '+ New fee scenario',
            save: 'Save changes',
            remove: 'Remove',
            add: 'Add a fee',
          },
          feeScenario: 'Fee scenario',
          enterFeeScenario: 'Enter fee scenario...',
        },
        templates: {
          title: 'Templates',
          paragraph: `
            The following are the templates that have been configured for
            your company. Templates enable content to be automatically
            generated for clients during the advice process.
          `,
          iddSelectionHeading: 'Which IDD does your company use?',
          iddSectionSelectionHeading: 'Which parts of the Initial Disclosure Document (IDD) do you want to appear?',
          srSettingsHeading: 'Suitability report settings',
          networkPrivacyPolicy: 'Network privacy policy',
          uploadPrivacyPolicy: 'Privacy policies',
          updateToLatestVersion: 'Update to latest version',
          showCurrentVersion: 'Show current version',
          previewLatestVersion: 'Preview latest version',
          previewingLatestVersion: 'Previewing latest version',
          previewScenariosWarning: `Compare the latest version of this document with the current version, including updates made across all scenarios by using the choices below. If you choose to update, all scenarios will also be replaced and updated. Please ensure you're satisfied with the changes before proceeding.`,
          deploySuccess: 'You have successfully updated your template',
          enforcedByDA: 'Enforced by {org_name}',
          providedByDA: 'Provided by {org_name}',
          scenario: 'Scenario:',
          scenarios: {
            btl: 'Buy-to-Let',
            commercial: 'Commercial mortgage',
            ftb: 'FTB',
            houseMove1: 'House move scenario 1',
            houseMove2: 'House move scenario 2',
            remortgage1: 'Remortgage scenario 1',
            remortgage2: 'Remortgage scenario 2',
            manyPreferences: 'Many preferences',
            multipleClients: 'Multiple clients',
            protectionIncluded: 'Protection included',
            withAdditionalBorrowing: 'With additional borrowing',
            lendingIntoRetirement: 'Lending into retirement',
            interestOnly: 'Interest only mortgage',
            partAndPart: 'Part and part mortgage',
            withPorting: 'With porting',
            withoutPorting: 'Without porting',
            sharedOwnership: 'Shared ownership',
            withoutSharedOwnership: 'Without shared ownership',
            helpToBuy: 'Help to buy equity loan',
            retainedEquity: 'Retained equity',
            continuingAlongsideMortgage: 'Continuing alongside mortgage',
            mortgageTermIncrease: 'Mortgage term increase',
            incurringERCs: 'Incurring ERCs',
            higherTrueCost: 'Higher true cost than existing',
          },
          table: {
            noDescription: 'No description provided',
            icon: 'Icon',
            fileName: 'File name',
            action: 'Action',
            name: 'Name',
            notes: 'Notes',
            uploaded: 'Uploaded',
            publish: 'Publish',
            updates: 'Updates',
            newUpdates: 'New version available',
          },
          options: {
            whole_inc_direct: 'Whole of market <strong>including</strong> Direct Lenders',
            whole_exc_direct: 'Whole of market <strong>excluding</strong> Direct Lenders (just club)',
            comprehensive: 'Comprehensive',
            second_charge: 'Second charge advice',
            addn_btl_disclaimer: 'Additional Buy-to-Let disclaimer',
            discretionary_refund: 'Discretionary refund',
            sr_factfind: 'Include the Fact Find in Suitability Reports',
            disable_debt_con_addendum: 'Include Debt Consolidation addendum in Suitability Reports',
            network_privacy_policy: `Don't generate network privacy policy for new cases`,
            network_privacy_policy_da: 'No network privacy policy will be generated on new cases.',
          },
          networkPolicyHelper:
            'This option is dictated by your network and it will decide if a network privacy policy will be generated on new cases',
        },
        users: {
          title: 'Manage Users',
          titleWithCompanyName: '{name} users',
          you: 'you',
          label: {
            active: 'Active',
            inactive: 'Inactive',
          },
          table: {
            header: {
              name: 'Name',
              role: 'Role',
              status: 'Status',
              phone: 'Phone',
              email: 'Email',
            },
            filter: {
              ascending: 'Ascending',
              descending: 'Descending',
            },
          },
          tags: {
            none: 'None',
          },
          noUsers: 'You currently have no contacts for this organisation, to add contacts please contact us',
        },
        userGroups: {
          title: 'User groups',
          buttons: {
            createUserGroup: 'Create user group',
            openPermissionsModal: 'Permissions',
          },
          canBeAssignedReminders: 'Can be assigned reminders',
          cantBeAssignedReminders: "Can't be assigned reminders",
          addUsersToGroup: 'Add users to this group',
          nameThisGroup: 'Name this group...',
          noGroups: 'No groups',
          table: {
            header: {
              name: 'Name',
              role: 'Role',
              email: 'Email',
              phone: 'Phone',
            },
          },
        },
        firms: {
          addFirm: 'Add Firm',
          addFirmModal: {
            heading: 'Add firm',
            companyHeading: 'Company details',
            managerHeading: 'Company principal',
            companyAddress: 'Company address',
            complaintsAddress: 'Complaints address',
            complaintsCheckbox: 'Complaints address same as company address?',
          },
          addARModal: {
            heading: 'Add Appointed Representative',
            companyHeading: 'Appointed Representative details',
            managerHeading: 'Appointed Representative firm manager',
            companyAddress: 'Appointed Representative address',
            complaintsAddress: 'Appointed Representative complaints address',
            complaintsCheckbox: 'Complaints address same as company address?',
          },
        },
      },
    },

    clients: {
      generic: 'Client',
      title: 'My clients',
      buttonText: 'Create new client',
      createNewClient: 'Create new client',
      confirmClientDetails: 'Confirm client details',
      confirmClientDetailsMessage:
        'Confirm one of the following pieces of information for <span>{name}</span> to access the correct client record:',
      clientDetails: 'Client details',
      errorMessage: `Confirmation failed, the details you provided do not match the client's record.`,
      subnav: {
        cases: 'Cases',
        communication: 'Communication',
        documents: 'Documents',
        health: 'Health',
        overview: 'Client Overview',
        profile: 'Profile',
        properties: 'Properties',
        protection: 'Health and Protection',
        settings: 'Settings',
      },
      settings: {
        heading: 'Data privacy',
        description: `This is a list of other people who have visibility of this client’s information because they were on a case together. You can remove access for anyone who shouldn’t see their data.`,
        cta: 'Remove',
        confirmModal:
          'By pressing confirm, {removed_client_name} will no longer be able to access any data for {current_client_name} on any cases.',
        credit: {
          heading: 'Credit refresh',
          description: 'Automatically update this client’s credit report every 3 months',
          label: 'Credit refresh',
          enabled: 'Enabled',
          disabled: 'Disabled',
        },
      },
      table: {
        header: {
          name: 'Name',
          type: 'Type',
          email: 'Email',
          phone: 'Phone',
          actions: 'Actions',
        },
        emptyField: '-',
        noResults: 'No Clients found',
      },
      newAddress: {
        header: 'New Address',
        currentAddress: 'Current Address',
        residentialStatus: 'Residential status',
        buttonText: 'Save address',
        residentialStatusOptions: {
          tenant: 'Tenant',
          owner_occupier: 'Owner/Occupier',
          living_with_family: 'Living with family',
          correspondence_only: 'Correspondence only',
        },
      },
      profile: {
        toggle: 'Show missing',
        personalInfo: {
          heading: 'Personal info',
          headingCompany: 'Company info',
          newHeading: 'Personal information',
          headingDualFactFind: "{name}'s personal info",
          personalInfo: 'Client Information',
          idVerification: 'ID Verification',
          protectionReferral: 'Health and Protection referral',
          idChecks: 'ID checks',
          documentAndImpersonationChecks: 'Document and impersonation checks',
          credit: 'Credit',
          income: 'Income',
          employment: 'Employment',
          outgoingsNavigationSection: 'Expenditure (after start of mortgage)',
          dependants: {
            addDependant: '+ Add dependants',
            addADependant: '+ Add a dependant',
            heading: 'Dependants',
            headingDualFactFind: "{name}'s dependants",
            copyDependantsTo: 'Copy dependants to ',
            form: {
              modalHeader: 'New dependant',
              dependantsHeader: 'Dependant',
              dependantsSubHeader: 'Relationship',
              detailsHeader: 'Details',
              detailsSubHeader: 'Date of birth',
              additionalSubHeader: 'Relationship to other',
              title: 'Title',
              name: 'Name',
              endDate: 'If this dependency will end, when will this be?',
              inputPlaceholder: 'Please specify ',
            },
            status: {
              child: 'Child',
              spouse: 'Spouse',
              sibling: 'Sibling',
              parent: 'Parent',
              other: 'Other',
            },
            card: {
              name: 'Name',
              born: 'Born',
              relationship: 'Relationship',
              age: 'Age',
              dateOfBirth: 'Date of birth',
              dependantUntil: 'Dependant until',
            },
          },
          previousNames: {
            previousName: 'Previous name',
            date: 'Date',
            reason: 'Reason',
            nameChange: 'Name change',
            addNameChange: '+ Add a name change',
          },
          form: {
            title: {
              label: 'Title',
              mr: 'Mr',
              mrs: 'Mrs',
              miss: 'Miss',
              ms: 'Ms',
              mx: 'Mx',
              dr: 'Dr',
              dame: 'Dame',
              prof: 'Prof',
              sir: 'Sir',
              lord: 'Lord',
              lady: 'Lady',
              invalid: 'Invalid title',
            },
            firstName: {
              label: 'First name',
              placeholder: "Client's first name",
            },
            middleName: {
              label: 'Middle name(s)',
              placeholder: "Client's middle name(s)",
            },
            surname: {
              label: 'Last name',
              placeholder: "Client's surname",
            },
            formerSurname: {
              label: 'Former surname',
              placeholder: "Client's former surname",
            },
            dateOfNameChange: {
              label: 'Date of name change',
              placeholder: 'Date of name change',
            },
            nationalInsuranceNumber: {
              label: 'National insurance no.',
              placeholder: "Client's national insurance no.",
            },
            isHighTaxPayer: 'Is client a higher rate taxpayer?',
            gender: {
              label: 'Sex',
              male: 'Male',
              female: 'Female',
              other: 'Other',
            },
            dateOfBirth: {
              label: 'Date of birth',
            },
            age: {
              label: 'Age',
            },
            maritalStatus: {
              label: 'Marital status',
              married: 'Married',
              single: 'Single',
              divorced: 'Divorced',
              separated: 'Separated',
              widowed: 'Widowed',
              cohabit: 'Cohabit',
              civilPartnership: 'Civil partnership',
            },
            countryOfBirth: {
              label: 'Country of birth',
            },
            nationalities: {
              label: 'Countries of citizenship',
            },
            retirementAge: {
              label: 'Expected retirement age',
            },
            vulnerability: {
              label: 'Is this a potentially vulnerable customer?',
              reasonsLabel: 'Select the reason(s) your client may be potentially vulnerable:',
              additionalInfoLabel: 'Additional vulnerability information:',
              reasons: {
                ADDICTION: 'Addiction',
                AGE: 'Age',
                CARER: 'Carer',
                CRIME: 'Victim of crime',
                DOMESTIC_ABUSE: 'Domestic abuse',
                FOREIGN_LANGUAGE: 'Speaks foreign language',
                HEARING_VISUAL_IMPAIRMENT: 'Hearing or visual Impairment',
                INDEBTEDNESS: 'Indebtedness',
                LONG_TERM_ILLNESS: 'Long-term illness',
                LOW_INCOME: 'Low income',
                MAJOR_LIFE_CHANGE: 'Major life change',
                MENTAL_CAPACITY: 'Mental capacity',
                MENTAL_HEALTH: 'Mental health',
                SKILLS: 'Digital, financial or other life skills limitation',
                STRESS: 'Stress',
                OTHER: 'Other',
              },
            },

            nameChange: {
              label: 'Name change',
              previousFirstName: 'Previous first name',
              previousSurname: 'Previous surname',
              reason: 'Reason for name change',
            },
            contactDetails: 'Contact details',
            emailAddress: 'Email address',
            homePhone: 'Home phone',
            mobilePhone: 'Mobile phone',
            workPhone: 'Work phone',
            bankAccountDetails: {
              title: 'Bank account for mortgage payments',
              protection: {
                title: 'Bank account details',
              },
              accountType: 'Type of account',
              accountTypes: {
                current: 'Current account',
                savings: 'Savings account',
                unknown: 'Unknown account type',
              },
              accountNumber: 'Account number',
              sortCode: 'Sort code',
              timeWithBank: 'Time with bank',
            },
            wills: {
              title: 'Estate planning',
              hasWill: 'Does the client have a will?',
              hasReviewedWill: 'Has the client reviewed their will in the last five years?',
            },
          },
          errors: {
            retirementAgeTooHigh: 'Expected retirement age should be equal to or below 125 years',
            retirementAgeTooLow: 'Expected retirement age should be equal to or above 18 years',
            invalidRetirementAge: 'Invalid expected retirement age',
            nIformatinvalid: 'NI number should be in the format AB123456C',
          },
          retirementIncome: 'Anticipated retirement income',
        },
        contactDetails: {
          heading: 'Contact details',
          headingDualFactFind: "{name}'s contact details",
          addressHeader: 'Address history',
          homeInsuranceAddressHeader: 'Current address',
          addressHistoryRequired: '3 years address history required',
          address: {
            copyAddressDetails: 'Copy address details',
            headingDualFactFind: "{name}'s address history",
            buttonText: '+ Add address',
            dateMovedIn: 'Date moved in',
            dateMovedOut: 'Date moved out',
            currentAddress: 'Current Address',
            addCurrentAddress: '+ Add a current Address',
            isCurrentAddress: 'This is the current address',
            addPreviousAddress: '+ Add a previous Address',
            previousAddress: 'Previous Address',
            isLessThanThreeYears: '*A current address and 3 years of continuous address history are required',
            copyAddressTo: 'Copy address to {name}',
          },
          card: {
            address: 'Address',
            location: 'Location',
            status: 'Status',
            moveIn: 'Move in',
            moveOut: 'Move out',
          },
          options: {
            edit: 'Edit',
            delete: 'Delete',
          },
        },
        IdVerificationNew: {
          heading: 'ID Verifications',
          manualOverride: 'Manual override',
          missingInfoTitle: 'Not enough info',
          missingInfoTextSingle: "We couldn't run checks on this client as {fields} is missing",
          missingInfoTextMultiple: "We couldn't run checks on this client as {fields} are missing",
          name: 'Name',
          dateOfBirth: 'Date of birth',
          currentAddress: 'Current address',
          countryOfCitizenship: 'Country of citizenship',
          runChecks: 'Run checks',
          reviewIdReport: 'Review ID report',
          lastCheckRunTitle: 'Last check run',
          lastCheckRunText: '{completedDate} - valid until {expiryDate}',
          requiredDocuments: 'Required documents',

          invalidResultTitle: 'Invalid result',
          invalidResultText: `The client's details have been modified which has invalidated the previous result. Please run the check again.`,
          identityCheck: 'Identity Check',
          pepAml: 'PEP/AML',
          documents: {
            uploadProofOfAddress: 'Upload proof of address',
            uploadProofOfId: 'Upload proof of ID',
            proofOfAddress: 'Proof of address',
            proofOfId: 'Proof of ID',
            upload: 'Upload',
            verify: 'Verify',
            view: 'View',
          },
          identityCheckStatuses: {
            verified: 'Verified',
            inconclusive: 'Inconclusive',
            manuallyVerified: 'Manually verified',
            failed: 'Failed',
            requiresRerunning: 'Requires rerunning',
            unavailable: 'Unavailable',
          },
          identityCheckStatusDescriptions: {
            inconclusive: {
              title: 'ID Check Inconclusive',
              body: 'Please check that the address details for this client are correct. Alternatively, you can verify the client details by uploading Proof of ID and Proof of their current address.',
            },
            failed: {
              title: 'ID Check Failed',
              body: "We couldn't find a match for this client. Please check that the client's name, date of birth and address are correct and re-run the check. Alternatively, verify the client details by uploading Proof of ID and Proof of their current address. A compliance review will be required.",
            },
            unavailable: {
              title: 'Service unavailable',
              body: 'Equifax is currently unavailable to run EIDV, please try again later. Alternatively, verify the client details by uploading Proof of ID and Proof of their current address. A compliance review will be required.',
            },
          },
          pepAmlStatuses: {
            manuallyVerified: 'Manually verified',
            manualApprovalRequired: 'Manual approval required',
            pending: 'Pending',
            reviewPending: 'Pending review',
            noMatches: 'No matches',
            review: 'Review',
          },
          pepAmlStatusDescriptions: {
            review1: {
              body: 'Before this case can proceed to the next stage it will need to be sent to a compliance officer for review.',
            },
            review2: {
              body: 'This case is currently under review by a compliance officer. You will not be able to progress this case until the review is complete.',
            },
            pending: {
              body: 'Unable to run checks until the client has been identified.',
            },
            reviewPending: {
              body: 'Pending a review',
            },
            manualApprovalRequired: {
              body: 'Manual approval by someone with compliance permissions is required.',
            },
          },
          resultDetails: {
            heading: 'Result details',
            noResultDetails: 'No result details to show',
          },
          idVerificationReport: {
            unflagCase: 'Unflag case',
            reviewAllFlags: 'Review Flags',
            title: 'ID verification report',
            watchlistReports: 'Full Watchlist reports',
            noWatchlistReports: 'No full watchlist reports found',
            watchlistCheckDetailsN: 'Watchlist check {n} details',
            watchlistCheckDetails: 'Watchlist check details',
            watchlistMatches: 'Watchlist matches',
            additionalInfo: 'Additional details',
            watchlistNonMatches: 'Watchlist non-matches',
          },
        },
        // TODO: To be removed when the old EIDV is turned-off/removed
        IdVerification: {
          heading: 'ID Verification',
          headingDualFF: `{name}'s ID Verification`,
          navDisabledLead: 'Proceed case to pre-recommendation to use ID verification functionality',
          clientCard: {
            title: 'Client details',
            details: {
              name: 'Name',
              address: 'Address',
            },
          },
          results: {
            heading: 'ID results',
            headingDualFF: `{name}'s ID results`,
            identityCheck: 'Identity check',
            pepAml: 'PEP/AML',
            pending: 'Pending',
            expires: 'Expires',
          },
          resultDetails: {
            heading: 'Result details',
            noResultDetails: 'No result details to show',
          },
          clearToastMessage: 'ID verification check has been run successfully.',
          insufficientRecords:
            'There are insufficient records available for this client to complete eIDV. Please upload and verify valid identity documents to continue.',
          unidentified:
            'Client could not be found, please confirm their name, date of birth and address details are correct and re-run.',
          nonCritical:
            'A flag has been raised which will be checked by compliance prior to case completion. Please upload and verify valid identity documents.',
          criticalFlag:
            'A flag has been raised which will be checked by compliance. Please upload and verify valid identity documents.',
          invalidatedResult: `The client's details have been modified which has invalidated the previous result. Please run the check again.`,
        },
        impersonation: {
          sendClientPortalLink: 'Send client portal link',
          documentCheck: 'Document check',
          livePhotoCheck: 'Live photo check',
          notRequired: {
            status: 'Not Required',
            description: 'Client has already verified their identity using Knowledge-based Authentication.',
          },
          notStarted: {
            status: 'Not started',
            description: 'Your client can complete {documentType} checks in the client portal.',
          },
          expired: {
            status: 'Expired',
            description: `Your client's {documentType} checks result has expired, they need to complete it again in the client portal.`,
          },
          pending: {
            status: 'Pending',
            description: 'Your client has uploaded their {documentType}, and the result will come back shortly.',
          },
          failed: {
            status: 'Failed',
            description:
              'Your client exceeded the maximum number of attempts. If you need to verify their ID they can upload a copy to their documents.',
          },
          pass: {
            status: 'Pass',
            description: 'Your client has passed {documentType} checks.',
          },
          rejected: {
            status: 'Rejected',
            description: {
              nameMismatch: `Your client's name doesn't match their ID They can retry this in the portal.`,
              firstNameMismatch: `Your client's first name doesn't match their ID They can retry this in the portal.`,
              lastNameMismatch: `Your client's last name doesn't match their ID They can retry this in the portal.`,
              dobMismatch: `Your client's Date of Birth doesn't match their ID They can retry this in the portal.`,
              genderMismatch: `Your client's sex doesn't match their ID They can retry this in the portal.`,
              issuingCountryMismatch: `Your client's ID doesn't match the issuing country they selected at the start of Onfido. They can retry this in the portal.`,
              idMismatch: `Your client's ID doesn't match the document type they selected at the start of Onfido. They can retry this in the portal.`,
              IdExpired: `Your client's ID has expired. They can retry this in the portal.`,
              blackAndWhiteImage:
                'Your client is trying to use a scan or take a black and white image. They can retry this in the portal.',
              pictureNotClearEnough:
                'The picture your client took was not clear enough. They can retry this in the portal.',
              documentNotSupported:
                'We do not accept the document your client is trying to use. They can retry this in the portal.',
              documentIsScan: 'Your client is trying to use a scan. They can retry this in the portal.',
              personalInformationMismatch: `Your client's information doesn't match their ID, they need to check their basic information is correct. They can retry this in the portal.`,
              multipleDocumentsPresent:
                'Your client is trying to take a photo of multiple forms of ID, they need to a clear picture of the ID they choose to use at the start of Onfido. They can retry this in the portal.',
              contactAcreSupport: 'Please contact Acre Support.',
            },
          },
          kba: {
            title: 'Knowledge-based authentication',
            notRequired: {
              status: 'Not Required',
              description: 'Client has already verified their identity using document and live photo check.',
            },
            notStarted: {
              status: 'Not started',
              description: 'Knowledge based authentication checks have not been run for this client.',
            },
            notCompleted: {
              status: 'Not completed',
              description: `The client has attempted KBA but the attempt was not completed.`,
            },
            rejected: {
              status: 'Rejected',
              description: `The client has attempted KBA but the attempt failed.`,
            },
            failed: {
              status: 'Failed',
              description: `Your client exceeded the maximum number of knowledge-based authentication attempts. You must use another method to verify the client's identity.`,
            },
            pass: {
              status: 'Pass',
              description: 'Your client has passed knowledge-based authentication checks.',
            },
            expired: {
              status: 'Expired',
              description: `The client passed KBA but the check has now expired.`,
            },
            unknown: {
              status: 'Unknown',
              description: 'The KBA status is unexpected.',
            },
          },
          deviceVerification: {
            title: 'Device verification',
            failed: 'Failed',
            description:
              'Your client has failed device verification, so we will not be able to retrieve their credit report. Please contact Acre Support for further advice.',
          },
        },
        employment: {
          heading: 'Employment',
          employmentStatus: 'Employment status',
          saveButton: 'Save employment details',
          forms: {
            employed: {
              jobTitleLookup: 'Client occupation',
              jobTitleSpecific: 'Specific job title',
              jobIndustry: 'Industry they work in',
            },
          },
          unearnedIncome: {
            heading: 'Additional income',
            headingDualFactFind: "{name}'s additional income",
            description: 'Additional incomes earned outside of employment',
            addButton: 'Add additional income',
            forms: {
              heading: 'Source {n}',
              label: {
                type: 'Income type',
                amount: 'Amount',
                frequency: 'Frequency',
                other_type: 'Other type',
                unearnedIncomeType: {
                  stateBenefits: 'State benefits',
                  investment: 'Investment',
                  dividends: 'Dividends',
                  rentalIncome: 'Rental income',
                  universalCredits: 'Universal credit',
                  statePension: 'State pension',
                  pensionCredits: 'Pension credits',
                  disabilityBenefits: 'Disability benefits',
                  taxCredits: 'Tax credits',
                  childBenefits: 'Child benefits',
                  other: 'Other',
                },
                incomeFrequencyType: {
                  weekly: 'Weekly',
                  monthly: 'Monthly',
                  quarterly: 'Quarterly',
                  annually: 'Annually',
                },
              },
            },
          },

          netIncome: {
            heading: 'Net income',
            headingDualFactFind: "{name}'s net income",
            label: 'Monthly net income',
          },
          history: {
            heading: 'Employment history',
            headingDualFactFind: "{name}'s employment history",
            description: 'At least one year of employment history required',
            addButton: 'Add employment',
            currentEmployment: 'Current employment',
            previousEmployment: 'Previous employment',
            forms: {
              heading: 'Employment',
              employmentType: 'Employment type',
              selectEmployed: 'Employed',
              selectSelfEmployed: 'Self-employed',
              selectRetired: 'Retired',
              selectHomemaker: 'Not in employment',
              saveAndClose: 'Save and close',
              employed: {
                employmentType: 'Employment basis',
                employmentBasis: 'Contract type',
                radioPermanent: 'Permanent',
                radioFixedTerm: 'Fixed term',
                radioPartTime: 'Part time',
                radioFullTime: 'Full time',
                jobTitle: 'Job title',
                jobTitleLookup: 'Job title lookup',
                jobTitleSpecific: 'Specific job title',
                current: 'Current employment',
                startDate: 'Start date',
                endDate: 'End date',
                onProbation: 'On probation?',
                onProbationYes: 'Yes',
                onProbationNo: 'No',
                probationDetails: 'Probation details',
                addEmployerDetails: 'Add employer details',
                employmentDetails: 'Employment details',
                employerDetails: 'Employer Details',
                employer: 'Employer name',
                employerPhoneNumber: 'Employer phone number',
                employerEmail: 'Employer email address',
                income: 'Income',
                additionalIncome: 'Additional income',
                remove: 'Remove',
                addAdditionalIncome: 'Add additional income',
                annualSalary: 'Basic annual salary',
                grossAnnualPensionIncome: 'Gross annual pension income',
                otherAnnualIncome: 'Other annual employment income',
                additionalIncomeFromJob: 'Other income from this job',
                incomeTypes: {
                  label: 'Income type',
                  details: 'Details',
                  income: 'Income',
                  basic: 'Basic salary',
                  commission: 'Commission',
                  overtime: 'Overtime',
                  bonus: 'Bonus',
                  other: 'Other',
                },
                incomeFrequency: {
                  label: 'Frequency',
                  monthly: 'Monthly',
                  quarterly: 'Quarterly',
                  annual: 'Annual',
                },
                guaranteed: 'Guaranteed',
                monthlyIncome: {
                  oneMonthAgo: '1 month ago',
                  twoMonthsAgo: '2 months ago',
                  threeMonthsAgo: '3 months ago',
                },
                annualIncome: {
                  date: 'Last paid date',
                },
              },
              selfEmployed: {
                detailsHeading: 'Self-employment details',
                businessName: 'Business name',
                dateEstablished: 'Date business established',
                employedForTaxPurposes: 'Is your client employed for tax purposes?',
                companyType: 'Company type',
                selectSoleTrader: 'Sole trader',
                selectPartnership: 'Partnership',
                selectLlp: 'Limited Liability Partnership (LLP)',
                selectLtd: 'Limited company',
                accountingHeading: 'Accounting',
                noOfYearsAccounts: 'Number of years accounts available',
                pleaseProvidePreTaxNetProfits: 'Please provide pre-tax net profits for the last {n} year(s)',
                netProfitsLastYear: 'Net profits last year',
                netProfitsTwoYearsAgo: 'Net profits two years ago',
                netProfitsThreeYearsAgo: 'Net profits three years ago',
                pleaseProvideSalaryAndDividends: 'Please provide salary and dividends details for the last {n} year(s)',
                taxableIncomeLastYear: 'Taxable income last year',
                taxableIncomeTwoYearsAgo: 'Taxable income two years ago',
                taxableIncomeThreeYearsAgo: 'Taxable income three years ago',
                pleaseProvidePercentageShare: 'Please provide shareholding percentage details for the last {n} year(s)',
                percentageShareLastYear: 'Percentage share last year',
                percentageShareTwoYearsAgo: 'Percentage share two years ago',
                percentageShareThreeYearsAgo: 'Percentage share three years ago',
                shareholdingPercentage: 'What is your current shareholding percentage?',
                doesClientHaveAccountant: 'Do you have an accountant?',
                hasAccountant: 'Yes',
                hasNoAccountant: 'No',
                accountantName: 'Accountant name',
                accountantNumber: 'Accountant work phone',
                accountantEmail: 'Accountant email address',
                accountantAddress: 'Accountant address',
                incomeHeading: 'Income',
                guaranteedAnnualIncome: 'Guaranteed annual self-employment income',
                otherAnnualIncome: 'Other annual employment income',
              },
              retired: {
                dateOfRetirement: 'Date of retirement',
                pensionHeading: 'Pension',
                pensionType: 'Pension type',
                annualAmount: 'Annual amount',
                pensionTypePrivate: 'Private retirement',
                pensionTypeState: 'State retirement',
                pensionTypeWar: 'War disability',
                pensionTypeInjuriesDisablement: 'Industrial injuries disablement',
                pensionTypeWidows: 'Widows',
                pensionTypeOther: 'Other',
                pensionIncome: 'Income',
                providerName: 'Provider name',
              },
              incomeChange: {
                incomeChangeExpected: 'Is your client expecting a change in income?',
                incomeIncreaseOrDecrease: 'Is your client expecting their income to increase or decrease?',
                incomeChangeIncrease: 'Increase',
                incomeChangeDecrease: 'Decrease',
                newIncomeAmount: 'New income amount',
                newIncomeMonths: 'How many months until this change occurs?',
                incomeIncreaseDetails: 'Details of the increase in income',
                incomeDecreaseDetails: 'Details of the decrease in income',
              },
            },
          },
        },
        financials: {
          heading: 'Financials',
          amountOfSavings: 'Amount of savings',
          submitButton: 'Save financial details',
          accounts: {
            heading: 'Bank accounts',
            headingDualFactFind: "{name}'s bank accounts",
            subHeading: "Details of client's current and savings accounts",
            addButton: '+ Add bank account',
            modalHeading: 'New bank account',
            form: {
              heading: 'Bank account details',
              accountType: 'Type of account',
              accountTypes: {
                current: 'Current account',
                savings: 'Savings account',
                unknown: 'Unknown account type',
              },
              accountNumber: 'Account number',
              sortCode: 'Sort code',
              timeWithBank: 'Time with bank',
              submitButton: 'Save and close',
            },
          },
          deposits: {
            heading: 'Deposits',
            headingDualFactFind: "{name}'s deposits",
            deposit: 'Deposit {num}',
            subHeading: 'Add all available deposits',
            addButton: '+ Add deposit',
            modalHeadingText: 'Add a deposit',
            depositType: 'Type',
            amount: 'Amount',
            source: 'Source of funds',
            section: {
              donor: {
                heading: 'Donor',
                subheading: 'Find or create new client',
                createANewClient: 'Create a new client',
                placeholder: `Type client's name`,
                createDonor: 'Add donor',
                editDonor: 'Edit donor',

                status: {
                  heading: 'Status',
                  giftDonor: 'Gift donor',
                },
                gift: {
                  heading: 'Gift',
                  deposit: 'Deposit {range}',
                },
              },
            },
            types: {
              CASH_SAVINGS: 'Cash savings',
              INVESTMENT: 'Investment',
              GIFT: 'Gift',
              OTHER: 'Other',
              VENDOR_INDUCEMENT: 'Vendor inducement',
            },
          },
          commitments: {
            addCommitment: '+ Add commitment',
            heading: 'Commitments',
            headingDualFactFind: "{name}'s commitments",
            subHeading: 'Credit cards, store cards, loans etc.',
            addButton: '+ Add commitment',
            form: {
              modalHeadingText: 'Add a commitment',
              heading: 'Commitment details',
              amountOutstanding: 'Amount outstanding',
              monthlyRepayment: 'Monthly repayment amount',
              nameOfCreditor: 'Name of creditor',
              paysMonthly: 'Do you pay off the outstanding balance in full each month?',
              outstandingLastMonth: 'What was the outstanding balance last month?',
              finalRepaymentDate: 'When is the final repayment date?',
              toBeConsolidated: 'Is this commitment to be consolidated as part of the new mortgage?',
              repaidBeforeMortgageCommences: 'Repaid before mortgage commences?',
              submitButton: 'Save and close',
              debtType: 'Type of commitment',
              interestRate: 'Interest rate',
              debtTypes: {
                creditCard: 'Credit card',
                overdraft: 'Overdraft',
                hirePurchase: 'Hire purchase',
                loan: 'Loan',
                secureLoan: 'Secure loan',
                storeCard: 'Store card',
                unknown: 'Unknown commitment type',
                utilities: 'Utilities',
                mailOrder: 'Mail order',
                payDay: 'Pay day',
                communications: 'Communications',
                invalidCommitment: 'Invalid commitment type',
              },
            },
          },
        },
        companyClient: {
          confirmClientDeletion:
            'Are you sure you want to remove this client? All associated information will be deleted from the case, and this action cannot be undone.',
          role: 'Role',
          client: 'Client',
          companyInformation: 'Company information',
          prefilledInfo: 'Some information has been pre-filled from Companies House',
          orgName: 'Organisation name',
          orgType: 'Organisation type',
          orgSic: 'Organisation SIC',
          companyRegNum: 'Company Reg. no.',
          companyPhone: 'Company telephone',
          companyEmail: 'Company email',
          numberOfEmployees: 'Number of employees',
          companyAddressLookup: 'Address lookup',
          companyDirectors: 'Relevant persons',
          bankAccountHeader: 'Bank account for mortgage payments (optional)',
          confirmation: `Are you sure you want to change the company to {name}?`,
          addCompanyToCase: 'Are you sure you want to add the company {name} to the case?',
          accountType: 'Type of account',
          accountNumber: 'Account number',
          sortCode: 'Sort code',
          timeWithBank: 'Time with bank',
          noDirectors: 'No directors',
          atLeastOneNaturalClientIsRequired:
            'At least one natural client is required as a person related to the company, therefore this client cannot be removed.',
          accountTypes: {
            current: 'Current',
            savings: 'Savings',
          },
          INVALID_NON_NATURAL_ORGANISATION_TYPE: '-',
          NON_NATURAL_ORGANISATION_TYPE_OTHER: 'Other',
          LIMITED: 'Limited',
          PLC: 'Public Limited Company',
          LLP: 'Limited Liability Partnership',
          CHARTERED_BODY: 'Chartered Body',
          INCORPORATED_TRUST: 'Incorporated Charitable Trust',
          GOVERNMENTAL_BODY: 'Government Body',
        },
        credit: {
          creditUtilisation: 'Credit utilisation increased',
          newCredit: 'New line of credit taken out',
          arrears: 'New missed payment or Arrears',
          heading: 'Credit',
          unsecuredSection: 'Unsecured',
          adverseCreditSection: 'Public records',
          securedSection: 'Secured',
          closedAccountsSection: 'Closed accounts',
          publicRecord: 'Public records',
          sendCPInvite: 'Send client portal invite',
          kbaCardHeader: 'Retrieve credit information from Equifax',
          retrieveWithKba: 'Retrieve with KBA',
          additionalInfo: 'Additional info required for KBA',
          toGoClientDetails: 'Go to client details',
          dateOfBirth: 'Date of birth (for {name})',
          email: 'Email address (for {name})',
          currentAddress: 'Current address (for {name})',
          cpInviteMessage:
            'In order for your client to gain access to a copy of their credit report, an invite to the Acre client portal will be sent.',
          additionalInfoKbaBody:
            'To retrieve this clients credit file using Knowledge-Based Authentication (KBA) the following information is needed:',
          kbaCardSubtext:
            'You can invite your client to retrieve their credit report via the client portal or use Knowledge-Based Authentication (KBA) to retrieve it for them.',
          kbaCardSubtextNoKBABbtn: 'You can invite your client to retrieve their credit report via the client portal.',
          creditSearchDesc: `Once you've read the terms of service to your client, you can run a soft credit search.`,
          graphic: {
            score: `Credit score - {label}`,
            scoreLabel: 'Provided by Equifax on - {date}',
          },
          table: {
            addAdditionalCredit: 'Add additional credit',
            addCommitment: '+ Add commitment',
            addClosedAccount: 'Add closed account',
            addDebtManagement: 'Add debt management',
            creditType: 'Credit type',
            amountOutstanding: 'Amount outstanding',
            monthlyRepaymentAmount: 'Monthly repayment amount',
            referenceAccountNo: 'Account number',
            repaymentAmount: 'Repayment amount',
            lender: 'Lender',
            originalTermSecured: 'Original term',
            termInMonths: '{term} months',
            mortgage: 'Mortgage',
            securedDebtTitle: 'Secured debt - mortgage',
            mortgageDetails: 'Mortgage details',
            termRemaining: 'Term remaining',
            satisfactoryConduct: 'Were payments made on time?',
            creditorName: 'Creditor name',
            consolidate: 'Consolidate?',
            repaidBeforeMortgageStarts: 'Repaid before mortgage starts?',
            addButton: '+ Add commitment',
            paymentFrequency: 'Payment frequency',
            endDate: 'End date',
            startDate: 'Start date',
            term: 'Term (months)',
            type: 'Type',
            countyCourtJudgement: 'County court judgement',
            ccj: 'CCJ',
            addCcj: 'Add CCJ',
            addCcjPlus: '+ Add CCJ',
            registered: 'Registered',
            satisfied: 'Satisfied',
            amount: 'Amount',
            creditor: 'Creditor',
            courtReference: 'Court reference',
            debtManagementPlans: 'Debt management plans',
            dmp: 'DMP',
            iva: 'IVA',
            addIvaDmp: '+ Add IVA/DMP',
            dateSatisfied: 'Date satisfied',
            dateRegistered: 'Date registered',
            dateCompleted: 'Date completed',
            monthlyPayment: 'Monthly payment',
            payedOnTime: 'Paid on time',
            creditRefusal: 'Credit refusal',
            refusalDate: 'Refusal date',
            reason: 'Reason',
            details: 'Details',
            addCreditRefusal: 'Add credit refusal',
            addCreditRefusalPlus: '+ Add credit refusal',
            bankruptcy: 'Bankruptcy',
            dateOfBankruptcy: 'Date of bankruptcy',
            dateOfDischarge: 'Date of discharge',
            courtRegistered: 'Court registered',
            addBankruptcy: 'Add bankruptcy',
            addBankruptcyPlus: '+ Add bankruptcy',
            propertyRepossession: 'Property repossession',
            hasPropertySurrendered: 'Has the client ever had a property surrendered or repossessed?',
            detailsForSuitability: 'Details (will appear in suitability report)',
            detailsForSuitabilityBudget:
              'Does the client expect any changes in their circumstances that might affect their outgoings?',
            refusalCreditor: 'Refusal creditor',
            whatWasTheApplicationFor: 'What was the application for?',
            startEndDate: 'Start - end date',
            termMonths: 'Term (months)',
            status: 'Status',
            closedAccountsDetails: 'Closed accounts details',
            originalTerm: 'Original term (months)',
            debtConsolidation: 'Debt consolidation',
            adverseDetails: 'Adverse details',
            maxArrearsBalance: 'Max arrears balance',
            defaultBalance: 'Default balance',
            paymentHistory: 'Payment history',
            repaymentHistory: 'Repayment history',
            arrearsLastDate: 'Date of last arrears',
            arrearsClearedDate: 'Date arrears cleared',
            maxArrearsCurrency: 'Max arrears balance',
            maxArrearsMonths: 'Max arrears months',
            interestRate: 'Interest rate',
            repaidEachMonth: 'Is the outstanding balance repaid each month?',
            paymentStatusSummary: {
              upToDate: 'Up to date',
              arrears: 'Arrears',
              pastArrears: 'Past arrears',
              defaulted: 'Defaulted',
            },
            debtType: {
              creditCard: 'Credit card',
              storeCard: 'Store card',
              mortgage: 'Mortgage',
              overdraft: 'Overdraft',
              loan: 'Loan',
              hiredPurchase: 'Hire purchase',
              secureLoan: 'Secure loan',
              unknown: 'Unknown commitment type',
              securedExchange: 'Secured exchange',
              utilities: 'Utilities',
              communications: 'Communications',
              mailOrder: 'Mail order',
              payDay: 'Pay day',
            },
            repaymentFrequency: {
              weekly: 'Weekly',
              monthly: 'Monthly',
              quarterly: 'Quarterly',
              annually: 'Annually',
              once: 'Once',
              notProvided: 'Not provided',
            },
            typeOfApplication: {
              creditCard: 'Credit card',
              hirePurchaseAgreement: 'Hire purchase agreement',
              mortgage: 'Mortgage',
              unsecuredLoan: 'Unsecured loan',
            },
            error: {
              termExceeded: 'Original term (months) should not exceed 999 months',
            },
          },
          kba: {
            confirmContinue: 'Confirm and Continue',
            iddHeading: 'Terms of service - Credit search',
            iddConfirmUnderstanding:
              'I confirm that the below client has been read this disclosure on a recorded phone line and has confirmed their understanding and acceptance of the terms:',
            tryAgain: 'Try again',
            finish: 'Finish',
            backToFactFind: 'Back to fact find',
            deviceVerificationFailedMessage:
              'Your client has passed {checkType} but has failed device verification, so we are unable to retrieve their credit report using this method. Please contact Acre Support for further advice.',
            outcomes: {
              success: {
                heading: 'Authentication successful',
                text: `Your client's credit information is now in the fact find. You'll need to add the creditor names to each credit type as these aren't supplied by the soft credit search. `,
              },
              error: {
                heading: 'Authentication failed',
                text1:
                  'It looks like there was a technical issue causing the authentication to fail, <strong>please try again.</strong>',
                text2: `If the problem continues, contact Acre support and we'll get to the bottom of it. `,
              },
              failed: {
                warning: {
                  heading: 'Authentication failed',
                  text1: `Sorry, we can't get your client's credit report. The answers given don't match the information on the credit file.`,
                  text2: `You can try again or send your client an invitation to retrieve their credit report from the client portal by uploading proof of ID.`,
                },
                fail: {
                  heading: `You've exceeded the max number of attempts`,
                  text1: `Sorry, we can't get your client's credit report via knowledge-based authentication`,
                  text2: `To get your client's credit report now, they'll have to upload proof of ID from their client portal. You can send your client an invitation to do this from the credit fact find page.`,
                },
                client: {
                  heading: `We can't find your client`,
                  text1: `We haven't been able to run a credit search as we can't find your client based off the information we have so far. Please ask your client to check their information before retrying. `,
                },
              },
              clientNotFound: {
                heading: `We can't find your client`,
                text: `We haven't been able to run a credit search as we can't find your client based off the information we have so far. Please ask your client to check their information before retrying. `,
              },
            },
          },
        },
        income: {
          title: 'Income',
          table: {
            title: 'Sources of income ({num})',
            retirementTitle: 'Anticipated retirement income sources ({num})',
            total: 'Total',
            now: 'now',
            addIncomeSource: '+ Add income source',
            incomeSources: {
              employment: 'Employment',
              selfEmployment: 'Self-employment',
              retirement: 'Retirement',
              unemployment: 'Not in employment',
              additionalIncome: 'Additional income',
            },
            headers: {
              type: 'Type',
              companyName: 'Company name',
              status: {
                title: 'Status',
                current: 'Current',
                previous: 'Previous',
              },
              startEnd: 'Start / end',
              netMonthlyIncome: 'Net monthly income',
              grossAnnualIncome: 'Gross annual income',
            },
          },

          employed: {
            title: 'Employment',
            employmentBasis: 'Employment basis',
            contractType: 'Contract type',
            permanent: 'Permanent',
            fixedTerm: 'Fixed term',
            jobTitleLookup: 'Job title lookup',
            jobTitleSpecific: 'Specific job title',
            startDate: 'Start date',
            onProbation: 'On probation?',
            probationDetails: 'Probation details',
            isCurrent: 'Current employment',
            endDate: 'End date',
            willContractBeRenewed: 'Will contract be renewed?',
            dateOfRetirement: 'Date of retirement',
            employerName: 'Employer name',
            employerPhoneNumber: 'Employer phone number',
            employerEmailAddress: 'Employer email address',
            addressLookup: 'Address lookup',
            employerDetails: 'Employer details',
            employmentDetails: 'Employment details',
            fullTime: 'Full time',
            partTime: 'Part time',
            income: 'Income',
            addIncome: '+ Add additional income',
            basicSalary: 'Basic salary',
            bonus: 'Bonus',
            overtime: 'Overtime',
            commission: 'Commission',
            type: 'Type',
            details: 'Details',
            amount: 'Amount',
            paymentFrequency: 'Payment frequency',
            guaranteed: 'Guaranteed',
            other: 'Other',
            netMonthlyIncome: 'Net monthly income',
            netAnnualIncome: 'Net annual income',
          },
          selfEmployment: {
            title: 'Self-employment',
            companyDetails: {
              title: 'Company details',
              numberOfYearsAccountsAvailable: 'Number of years accounts available',
              shareHolding: {
                currentShareHolding: 'Current shareholding',
                thisYear: 'Shareholding this year',
                lastYear: 'Shareholding last year',
                twoYearsAgo: 'Shareholding 2 years ago',
                threeYearsAgo: 'Shareholding 3 years ago',
              },
              changeInIncome: 'Is client expecting a change in income?',
              decreaseOrIncrease: 'Is income expected to increase or decrease?',
              detailsOfIncrease: 'Details of increase',
              detailsOfDecrease: 'Details of decrease',
              newIncomeAmount: 'New income amount',
              monthsUntilIncomeChange: 'How many months until this change occurs?',
              increase: 'Increase',
              decrease: 'Decrease',
              haveAccountant: 'Does client have an accountant?',
            },
            accountantDetails: {
              accountantName: 'Accountant name',
              accountantWorkPhone: 'Accountant work phone',
              accountantEmail: 'Accountant email',
              accountantAddress: 'Accountant address',
            },
            selfEmploymentDetails: {
              title: 'Self-employment details',
              jobTitleLookup: 'Job title lookup',
              specificJobTitle: 'Specific job title',
              businessName: 'Business name',
              businessEstablished: 'Business established',
              taxPurposes: 'Is client employed for tax purposes?',
              companyType: 'Company type',
              addressLookup: 'Address lookup',
              currentEmployment: 'Current employment',
              startDate: 'Start date',
              endDate: 'End date',
              companyTypes: {
                soleTrader: 'Sole trader',
                partnership: 'Partnership',
                llp: 'LLP',
                psc: 'PSC',
                ltd: 'Ltd.',
              },
            },
            incomeTables: {
              income: 'Income',
              totalGrossAnnualIncome: 'Total gross annual income',
              grossAnnualIncome: 'Gross annual income',
              netMonthlyIncome: 'Net monthly income',
              grossSalary: 'Gross salary',
              grossDividends: 'Gross dividends',
              clientShare: `Client's share`,
              clientShareLabel: 'Enter a percentage',
              companyPretaxProfits: 'Company Net Profit',
              partnershipPreTaxProfit: 'Partnership pre-tax profit',
              partnershipPreTaxProfitLastYear: 'Partnership pre-tax profit last year',
              partnershipPreTaxProfitTwoYears: 'Partnership pre-tax profit 2 years ago',
              partnershipPreTaxProfitThreeYears: 'Partnership pre-tax profit 3 years ago',
              companyGrossProfits: 'Company Gross Profit',
              companyIncome: 'Company income',
              preTaxProfit: 'Pre-tax profit',
              salaryAndDividends: 'Salary and dividends',
              enterDetailsLastYear: 'Enter Pre-tax profit and share for last year to calculate share',
              enterDetailsTwoYears: 'Enter Pre-tax profit and share for two years ago to calculate share',
              enterDetailsThreeYears: 'Enter Pre-tax profit and share for three years ago to calculate share',
              enterDetailsAbove: 'Enter details above',
              shareOfIncome: 'Share of profit',
              headers: {
                type: 'Type',
                lastYear: 'Last year',
                twoYears: '2 years ago',
                threeYears: '3 years ago',
              },
            },
          },
          retirement: {
            title: 'Retirement',
            date: 'Date of retirement',
            providerName: 'Provider name',
            grossAnnualPensionIncome: 'Gross annual pension income',
            netMonthlyIncome: 'Net monthly income',
            pensionType: 'Pension type',
            pensionTypes: {
              INDUSTRIAL_INJURIES_DISABLEMENT: 'Industrial injuries disablement',
              PRIVATE_RETIREMENT: 'Private retirement',
              STATE_RETIREMENT: 'State retirement',
              WAR_DISABILITY: 'War disability',
              WIDOWS: 'Widows',
              OTHER: 'Other',
            },
          },
          additionalIncomeSource: {
            title: 'Additional income source',
            incomeTypes: {
              label: 'Income type',
              STATE_BENEFITS: 'State benefits',
              INVESTMENT: 'Investment',
              DIVIDENDS: 'Dividends',
              RENTAL_INCOME: 'Rental income',
              UNIVERSAL_CREDITS: 'Universal credit',
              STATE_PENSION: 'State pension',
              PENSION_CREDITS: 'Pension credits',
              DISABILITY_BENEFITS: 'Disability benefits',
              TAX_CREDITS: 'Tax credits',
              CHILD_BENEFITS: 'Child benefits',
              CHILD_MAINTENANCE: 'Child maintenance',
              FOSTERING: 'Fostering',
              TRUST_FUND: 'Trust fund',
              ARMED_FORCES_INDEPENDENCE_PAYMENT: 'Armed forces independence payment',
              ALIMONY_OR_SPOUSAL_MAINTENANCE: 'Alimony or spousal maintenance',
              OTHER: 'Other',
            },
            otherIncomeType: 'Other income type',
            netAmount: 'Net amount',
            grossAmount: 'Gross amount',
            frequencies: {
              label: 'Frequency',
              weekly: 'Weekly',
              monthly: 'Monthly',
              quarterly: 'Quarterly',
              annually: 'Annually',
            },
          },
          notInEmployment: {
            currentlyEmployed: 'Is client currently not in employment?',
            startDate: 'Start date',
            endDate: 'End date',
          },
        },

        budgetPlanner: {
          heading: 'Budget planner',
        },
        outgoings: {
          heading: 'Outgoings',
          monthlyBudgetPlanner: 'Monthly budget planner',
          monthlyBudgetPlannerDualFF: `{name}'s monthly budget planner`,
          agreedBudgets: 'Agreed budgets',
          agreedBudgetsDualFF: `{name}'s agreed budgets`,
          expenditure: 'Expenditure',
          amount: 'Amount',
          net_monthly_income: 'Net monthly income',
          debts: 'Debts',
          committedExpenditure: 'Committed expenditure',
          committedExpenditureDualFactFind: "{name}'s committed expenditure",
          discretionaryExpenditure: 'Discretionary expenditure',
          discretionaryExpenditureDualFactFind: "{name}'s discretionary expenditure",
          circumstances: 'Circumstances',
          circumstancesDualFF: `{name}'s circumstances`,
          outgoingChanges: 'Does {name} expect any changes in their circumstances that might affect their outgoings?',
          committedExpenditureTooltip:
            'This is for future or projected expenditure. Committed expenditure is used to calculate the maximum disposable income. Clients are likely to want to use disposable income for discretionary expenditure as well as mortgage costs.',
          discretionaryExpenditureTooltip:
            'This is for future or projected expenditure. Discretionary expenditure is used to record other expenditure that could be reduced in the future if necessary.',
          disposableIncome: 'Disposable income',
          monthlyMortgageBudget: 'Agreed monthly mortgage budget',
          monthlyProtectionBudget: 'Agreed monthly Health and Protection budget',
          total: 'Total: {total}',
          fields: {
            maintenancePayments: 'Spousal / Child maintenance',
            mortgageRent: 'Other mortgage / Rent',
            food: 'Food',
            clothing: 'Clothing',
            schoolChildcareFees: 'School / Childcare fees',
            sports: 'Sports',
            holidays: 'Holidays',
            entertainment: 'Entertainment',
            transport: 'Transport',
            fuel: 'Fuel',
            carTax: 'Car tax',
            insurance: 'Insurance',
            commuting: 'Commuting',
            electricity: 'Electricity',
            gasOil: 'Gas / Oil',
            communications: 'Communications',
            carCosts: 'Car costs',
            otherTransportCosts: 'Other transport costs',
            household: 'Household',
            water: 'Water',
            television: 'Television',
            internetPhone: 'Internet / Phone',
            councilTax: 'Council tax',
            homeInsurance: 'Home insurance',
            pensionContributions: 'Pension contributions',
            investments: 'Investments',
            otherSpending: 'Other spending',
            childCare: 'Child care',
            electricityGasOil: 'Electricity / Gas / Oil',
            tvLicense: 'TV licence',
            ownVehicle: 'Own vehicle',
            serviceChargeGroundRent: 'Service charge / Ground rent',
            foodAndPersonalGoods: 'Food and personal goods',
            householdMaintenance: 'Household maintenance',
            savingsAndInvestments: 'Savings and investments',
            insurances: 'Insurances',
          },
          saveButton: 'Save budget details',
          ons: {
            heading: 'ONS Estimates (beta)',
            body: "Acre can estimate the monthly spending for your client's household composition, employment status, income and region using ONS data.",
            buttonText: 'Pre-fill from ONS (beta)',
          },
        },
        protectionReferral: {
          firstCard: {
            protectionNeeds: {
              title: 'Health and Protection needs',
              notAbleToWork:
                'How long would you be able to pay your mortgage and other outgoings should you not be able to work?',
              notAbleToWorkDropdown: {
                LESS_ONE_MONTH: 'Less than one month',
                ONE_TO_THREE_MONTHS: ' One to three months',
                THREE_TO_SIX_MONTHS: 'Three to six months',
                SIX_TO_TWELVE_MONTHS: 'Six to twelve months',
                ONE_YEAR_PLUS: 'One year plus',
                UNSURE: 'Unsure',
              },
              passAway: 'Would you like the mortgage to be paid off if you were to pass away?',
              smokerStatusMulti: 'Which clients are smokers or have used nicotine products in the last 12 months?',
              smokerStatusSingle: 'Has client smoked or used nicotine products in the last 12 months?',
              smokerStatusPerClient: 'Has {clientName} smoked or used nicotine products in the last 12 months?',
              healthQuestions: 'Health questions',
            },
            existingProvisions: {
              title: 'Existing provisions',
              protectMortgage: 'Do you have any existing provision in place to protect your mortgage?',
              addExistingProtectionPolicy: '+ Add an existing Health/Protection policy',
            },
            sickPay: {
              title: 'Sick pay',
              employed: 'Employed',
              selfEmployed: 'Self employed',
              client: 'Client',
              netMonthlyIncome: 'Net monthly income',
              employmentDetails: 'Employment details',
              type: 'What type of sick pay would you receive from this source of income?',
              amount: 'How much sick pay would you receive per month?',
              numberOfMonths: 'How many months would you receive this for?',
              details: 'Additional details',
              typeDropdown: {
                STATUTORY_SICK_PAY: 'Statutory sick pay',
                EMPLOYER_SICK_PAY: 'Employer sick pay',
                EXTERNAL_POLICY: 'External policy',
                NONE: 'None',
              },
            },
          },
          secondCard: {
            createProtectionCase: 'Create a separate Health and Protection case',
            conductProtectionReview: 'Would you like to conduct a Health and Protection review as part of this case?',
            removeProductsFirst: 'Please remove all Health and Protection products from the case first.',
          },
          removeProducts:
            'Selecting no will remove the clients existing non-employment Health and Protection product(s). Please confirm that you would like to continue.',
          newProtectionCaseModal: {
            title: 'New Health and Protection case',
            caseDetails: 'Case details',
            setReminder: 'Set reminder',
            assignCaseTo: 'Assign case to',
            clients: 'Clients to  carry {br} through',
            note: 'Add a note to case',
            includeReminder: 'Set reminder?',
            alertText: 'Referral case created successfully. <a>Please click here to access it.</a>',
          },
        },

        creditHistory: {
          heading: 'Credit history',
          headingDualFactFind: "{name}'s credit history",
          form: {
            hasAdverseCredit: 'Does {name} have any adverse credit history?',
            repossession: {
              label: 'Has the client ever had a property surrendered or repossessed?',
              details: 'Details',
            },
            creditRefusal: {
              label: 'Has the client ever had a mortgage or loan refused?',
              cardHeading: 'Loan refusal',
              modalHeading: 'Credit refusal',
              creditRefusalDetails: 'Credit refusal details',
              applicationRefusalDate: 'Application refusal date',
              refusalDate: 'Refusal date',
              applicationRefusalCreditor: 'Application refusal creditor',
              creditor: 'Creditor',
              whatWasTheApplicationFor: 'What was the application for?',
              additionalDetails: 'Additional details',
              button: 'Add a Credit refusal',
              typeOfApplication: {
                creditCard: 'Credit card',
                hirePurchaseAgreement: 'Hire purchase agreement',
                mortgage: 'Mortgage',
                unsecuredLoan: 'Unsecured loan',
              },
            },
            ccj: {
              label: 'Has the client ever had a CCJ issued against them?',
              cardHeading: 'County Court Judgement',
              modalHeading: 'County Court Judgement',
              button: 'Add a CCJ',
              dateRegistered: 'Date registered',
              dateSatisfied: 'Date satisfied',
              amount: 'Amount',
              creditorName: 'Creditor name',
              ccjDetails: 'CCJ details',
            },
            defaults: {
              label: 'Has the client ever defaulted on a credit agreement?',
              cardHeading: 'Credit default',
              modalHeading: 'Credit default',
              button: 'Add a credit default',
              dateRegistered: 'Date registered',
              dateSatisfied: 'Date satisfied',
              amount: 'Amount',
              creditorName: 'Creditor name',
              details: 'Credit default details',
            },
            bankruptcy: {
              label: 'Has the client ever been declared bankrupt?',
              cardHeading: 'Bankruptcy',
              modalHeading: 'Bankruptcy',
              button: 'Add a bankruptcy',
              bankruptcyDate: 'Date of bankruptcy',
              dischargeDate: 'Date of bankruptcy discharge',
              court: 'Court where bankruptcy was registered',
              bankruptcyDetails: 'Bankruptcy details',
              dateOfBankruptcy: 'Date of bankruptcy',
              dateOfDischarge: 'Date of discharge',
            },
            debtManagementPlan: {
              label: 'Has the client ever entered into a debt management plan?',
              cardHeading: 'Debt management plan',
              modalHeading: 'Debt management plan',
              button: 'Add a debt management plan',
              dateRegistered: 'Date registered',
              dateCompleted: 'Date completed / Target completion date',
              details: 'Debt management plan details',
              monthlyPaymentAmount: 'Monthly payment amount',
              satisfactoryConduct: 'Were payments made on time?',
            },
            arrears: {
              label: 'Has the client fallen into payment arrears on a previous or current credit agreement?',
              cardHeading: 'Payment arrears',
              modalHeading: 'Payment arrears',
              arrearsDetails: 'Payment arrears details',
              button: 'Add payment arrears details',
              hasCleared: 'Have arrears been cleared?',
              amount: 'Amount of arrears',
              maxNumberOfMonthlyPaymentsInArrears: 'Number of payments in arrears',
              typeOfCredit: 'Type of creditor',
              creditorName: 'Creditor name',
              dateOfArrear: 'Date arrears began',
              dateCleared: 'Date cleared',
              typeOfCreditor: {
                communications: 'Communications',
                creditCard: 'Credit card',
                hirePurchase: 'Hire purchase',
              },
            },
            individualVoluntaryArrangement: {
              label: 'Has the client ever entered into an IVA?',
              cardHeading: 'Individual Voluntary Arrangement',
              modalHeading: 'Individual Voluntary Arrangement',
              button: 'Add an IVA',
              dateRegistered: 'Date registered',
              dateCompleted: 'Date completed',
              details: 'IVA details',
              monthlyPaymentAmount: 'Monthly payment amount',
              satisfactoryConduct: 'Were payments made on time?',
            },
            missedPayments: {
              label: 'Has the client ever missed a payment on a previous or current credit agreement?',
              numberOfMissedPayments: 'Number of missed payments?',
              haveMissedPaymentsBeenCleared: 'Have missed payments been cleared?',
              additionalDetails: 'Additional details',
            },
          },
        },
        company: {
          heading: 'Company information',
          organisationName: 'Organisation name',
          organisationSic: 'Organisation SIC',
          companyTelephone: 'Company contact telephone',
          companyEmail: 'Company contact email',
          companyAddress: 'Company address',
        },
        directors: {
          heading: 'Directors',
          clientSearch: 'Find or create new client',
        },
        retirement_income: {
          title: 'Retirement income',
        },
      },
      health: {
        nicotine: {
          nicotine: 'Nicotine',
          hasTheClientSmoked:
            'Has the client smoked or used any tobacco or nicotine replacement products in the last 12 months?',
          thisIncludesCigarettes: 'This includes cigarettes, e-cigarettes, vaping, cigars or pipes',
        },
      },
      cases: {
        activeCases: 'Active cases',
        noCases: 'No cases available',
        createNewCase: 'Create new case',
        sourceMortgages: 'Sourcing mortgages 27T',
        sourceMortgagesBeta: 'Source mortgages',
        viewAll: 'View all',
        cards: {
          sections: {
            clients: 'Clients',
            started: 'Started',
            status: 'Status',
          },
          overflow: {
            goToCase: 'Go to case',
          },
        },
      },
      properties: {
        properties: 'Properties',
        addProperty: 'Add property',
        noProperties: 'No properties available',
        info: 'Info (beta)',
      },
      communication: {
        heading: 'Marketing preferences',
        clientHasGivenPermissionToBeContactedByOurCompany:
          'Client has given permission to be contacted by our company using the following channels:',
        clientHasGivenPermissionToBeContactedByThirdParty:
          'Client has given permission to be contacted by third parties using the following channels:',
        checkboxes: {
          phone: 'Phone',
          email: 'Email',
          sms: 'SMS',
          mail: 'Mail',
        },
      },
      protection: {
        policyNumber: 'Policy Number',
        productBenefitType: 'Product Benefit Type',
        provider: 'Provider',
        coverAmount: 'Cover Amount',
        payoutType: 'Payout Type',
        deferredPeriod: 'Deferred Period',
        startDate: 'Start Date',
        endDate: 'End Date',
        monthlyPremium: 'Monthly Premium',
        status: 'Status',
        protectionProducts: 'Health and Protection products',
        statuses: {
          cancelled: 'Cancelled',
          current: 'In force',
          invalidStatus: 'Invalid',
          proposed: 'Shortlisted',
          selected: 'Recommended',
          not_proceeding: 'Not proceeding',
        },
      },
    },

    documents: {
      allDocuments: 'All Documents',
      checkbox: 'Proof of address',
      verifyDocument: 'Verify document',
      whichAddress: 'Which address?',
      title: 'documents',
      clientTitle: 'Client documents',
      caseTitle: 'Case documents',
      callOriginator: 'Call Originator',
      callRecipient: 'Call Recipient',
      callTime: 'Call Time',
      callLength: 'Call Length',
      commissionType: 'Commission Type',
      commissionAmount: 'Commission Amount',
      upload: 'Upload document',
      uploadButton: 'Drag files or click here',
      uploaded: 'Uploaded',
      uploadedBy: 'Uploaded by',
      remove: 'Remove document',
      addButton: 'Add document',
      associatedProduct: 'Associated product',
      finalUnderwrittenPremium: 'Final underwritten premium (monthly)',
      indemnityCommissionAmount: 'Indemnity Commission Amount',
      renewalCommissionAmount: 'Renewal Commission Amount (monthly)',
      renewalCommissionStartMonth: 'Renewal Commission Start Month',
      numberOfMonthsReceivedInitialCommission: 'Number of months received for',
      documentType: 'Document type',
      noClientsError: 'At least 1 client must be selected before uploading this document',
      documentName: 'Document name',
      otherDetails: 'Details',
      status: 'Status',
      signedBy: 'Signed by',
      name: 'Name',
      requirement: 'Requirement',
      added: 'Added',
      uploadSource: 'Uploaded by',
      selectProduct: 'Select product',
      applicationDate: 'Application date',
      offerExpiryDate: 'Offer expiry date',
      noProductsToAttach: 'There are no selected products to attach this document to',
      download: 'Download',
      emailViewerComingSoon:
        'An integrated email viewer is coming soon! In the meantime, click Download to view email documents.',
      client: 'Client',
      generateDocument: 'Generate document',
      generateFactFind: 'Generate fact find',
      generateIdd: 'Generate IDD',
      unassignedToCase: 'Unassigned',
      signedDate: 'Date Signed',
      types: {
        factFind: 'Fact find document',
        template: 'Template',
        driversLicenseFront: 'Drivers license front',
        driversLicenseBack: 'Drivers license back',
        bankStatement: 'Bank statement',
        payslip: 'Payslip',
        investmentStatement: 'Investment statement',
        p60: 'P60',
        sa302: 'SA302',
        companyAccounts: 'Company accounts',
        customerCommunication: 'Customer communication',
        birthCertificate: 'Birth certificate',
        passport: 'Passport',
        utilityBill: 'Utility bill',
        letterOfGift: 'Letter of gift',
        templateComplianceEmail: 'Template compliance email',
        offerLetter: 'Mortgage Offer letter',
        applicationLetter: 'Mortgage Application Form',
        livePhoto: 'Live photo',
        nationalIdentityCard: 'National identity card',
        agreementInPrinciple: 'Agreement in principle',
        policyLetter: 'Policy letter',
        insuranceIllustration: 'Insurance illustration',
        insuranceKeyFacts: 'Insurance key facts',
        insuranceApplication: 'Insurance application',
        thirdPartyCommunication: 'Third party communication',
        thirdPartyPrivacyNotice: 'Third party privacy notice',
        internal: 'Internal',
        mortgageStatement: 'Mortgage statement',
        mortgageApplication: 'Mortgage application',
        pensionStatement: 'Pension statement',
        other: 'Other',
        debtConsolidationComparisonForm: 'Debt consolidation comparison form',
        debtConsolidationSupplement: 'Debt consolidation supplement',
        importedIdd: 'Imported IDD',
        importedEsis: 'Imported ESIS',
        importedSuitabilityReport: 'Imported Suitability Report',
        verbalIdd: 'Verbal IDD',
        idd: 'IDD',
        organisationPrivacyNotice: 'Privacy Notice',
        networkPrivacyNotice: 'Network Privacy Notice',
        esis: 'ESIS',
        suitabilityReport: 'Suitability Report',
        clientPortalIdd: 'Online IDD',
        protectionSuitabilityReport: 'Health and Protection Suitability Report',
        creditReport: 'Credit report',
        affordabilityScreenshot: 'Affordability',
        propertyTitle: 'Property Title',
        propertyTitlePlan: 'Title Plan',
        externalEvidenceOfResearch: 'External evidence of research',
        statementOfNeeds: 'Statement of needs',
        directDebitMandate: 'Direct Debit Mandate',
        legalCharge: 'Legal Charge',
        incomeDeclaration: 'Income Declaration',
        creditSearch: 'Credit Search',
        valuation: 'Valuation',
        mortgageQuestionnaire: 'Mortgage Questionnaire',
        consent: 'Consent',
        deedOfPostponement: 'Deed of Postponement',
        redemptionFigure: 'Redemption Figure',
        btlPortfolio: 'BTL Portfolio',
        lendingIntoRetirementDeclaration: 'Lending Into Retirement Declaration',
        occupiersConsentForm: 'Occupiers Consent Form',
        independentLegalAdviceForm: 'Independent Legal Advice Form',
        purposeOfLoanDeclaration: 'Purpose of Loan Declaration',
        interestOnlyDeclaration: 'Interest Only Declaration',
        limitedCompanyAccounts: 'Limited Company Accounts',
        managementAccounts: 'Management Accounts',
        employmentContract: 'Employment Contract',
        affordabilityCalculator: 'Affordability Calculator',
        loanSettlementFigure: 'Loan Settlement Figure',
        creditCardStatement: 'Credit Card Statement',
        letterOfExplanation: 'Letter of Explanation',
        propertyLease: 'Property lease',
        groundRentServiceChargeConfirmation: 'Ground Rent / Service Charge Confirmation',
        lastWillAndTestament: 'Last will and testament',
        declarationOfTrust: 'Declaration of trust',
        surveyReport: 'Survey report',
        powerOfAttorney: 'Power of Attorney',
        epcReport: 'Energy Performance Certificate (EPC)',
        visa: 'Visa / Indefinite leave to remain',
        taxYearOverview: 'Tax year overview',
        proofOfDeposit: 'Proof of deposit',
        esisGeneratedByLender: 'ESIS generated by Lender',
        memorandumOfSale: 'Memorandum of Sale',
        lenderEsis: 'Lender ESIS',
        callRecording: 'Call recording',
        costOfMove: 'Cost of move',
        budgetStressTest: 'Budget stress test',
        wellbeingAssessment: 'Wellbeing assessment',
        insuranceDeclaration: 'Insurance declaration',
        benefitReport: 'Benefit report',
        riskInformationPack: 'Risk information pack',
        equityReleaseFactFind: 'Equity release fact find',
        witnessDeclaration: 'Witness declaration',
        feeElection: 'Fee election',
        insuranceComparisonReport: 'Insurance comparison report',
        commissionComparisonReport: 'Commission comparison report',
      },
      categories: {
        DEFAULT: 'Default',
        ADVICE: 'Advice',
        AFFORDABILITY: 'Affordability',
        BANK_STATEMENTS: 'Bank Statements',
        CREDIT_SEARCH: 'Credit Search',
        COMMUNICATION: 'Communication',
        DEBT_CONSOLIDATION: 'Debt Consolidation',
        DEPOSIT: 'Deposit',
        ID: 'ID',
        INCOME: 'Income',
        INTERNAL: 'Internal',
        LEGAL: 'Legal',
        LENDER_DOCUMENTS: 'Lender Documents',
        OTHER: 'Other',
        PRIVACY: 'Privacy',
        PRODUCT: 'Product',
        PROPERTY: 'Property',
        PROTECTION: 'Health and Protection',
        REFERENCE: 'Reference',
        SYSTEM: 'System',
        VALUATION: 'Valuation',
        DISCLOSURE: 'Disclosures',
      },
      source: {
        GENERATED: 'Generated',
        TWENTY_7_TEC: 'Twenty7Tec',
        ONFIDO: 'Onfido',
        TRUELAYER: 'Truelayer',
        PAYMENTSHIELD: 'PaymentShield',
        UINSURE: 'Uinsure',
        KEY: 'Key',
        IPIPELINE: 'iPipeline',
        LENDER: 'Lender',
        LAND_REGISTRY: 'HM Land Registry',
        CALL_RECORDER: 'Telephone Call Recorder',
      },
      verify: {
        genericVerifyOption: 'I, {brokersName}, verify that I have seen this document and confirm it to be valid',
        iddOrPrivacyVerifyOption:
          'I, {brokersName}, verify that the client has viewed this document and accepts the terms stated therein',
        verificationButton: 'Verify document',
        verified: 'Verified',
        verificationPending: 'Uploaded',
        signed: 'Signed',
        requiresSignature: 'Requires Signature',
      },
      clients: {
        notFound: 'Client not found',
        label: 'Clients',
        selectPlaceholder: 'Select clients',
      },
      donors: {
        notFound: 'Donor not found',
        label: 'Donors',
        selectPlaceholder: 'Select donors',
      },
      deposits: {
        notFound: 'Deposit not found',
        label: 'Deposit',
        selectPlaceholder: 'Select deposits',
      },

      overflow: {
        open: 'Open document',
        download: 'Download',
        archive: 'Archive',
        verify: 'Verify document',
        getSignature: 'Get SR signed by client',
      },
    },

    cases: {
      caseType: 'Case type',
      general: 'General',
      mortgageHeading: 'Mortgage',
      protectionHeading: 'Health and Protection',
      title: 'Cases',
      addNewCase: 'Create new case',
      error: 'Failed to load case.',
      backToList: 'Back to cases list',
      markAsNotProceeding: 'Mark as not proceeding',
      rollBackStatus: 'Roll back status',
      reopenCase: 'Reopen case',
      areYouSureYouWantToReopen: 'Are you sure you want to reopen this case?',
      reassignCase: 'Reassign case',
      bulkAssignCases: 'Bulk assign cases',
      addAdvisor: 'Select advisor',
      assignCase: 'Assign case',
      assignSingleCase: 'Assign {number} case',
      assignMultipleCases: 'Assign {number} cases',
      createRemoLeads: {
        heading: 'Create remortgage leads',
        createSingleRemoLead: 'Create {number} remortgage lead',
        createMultipleRemoLead: 'Create {number} remortgage leads',
        keepExistingAdvisor: 'Keep existing advisor',
        createInUnassigned: 'Create in unassigned',
        assignTo: 'Assign to',
      },

      editCaseFees: 'Edit case fees',
      areYouSureYouWantToMark:
        'Are you sure you want to mark the case as not proceeding? This will change the case to read-only.',
      yesReopenThisCase: 'Yes, reopen this case',
      expenditure: 'Expenditure',
      conveyancing: 'Conveyancing',
      clients: 'Clients',
      tooltipMessage:
        'You can only bulk assign cases with a status of lead or pre-recommendation. One or more of your selected cases is past pre-recommendation. Filter by case status lead or pre-recommendation to see only cases that can be bulk assigned',
      tooltipMessageCreateRemoLeads: {
        general: 'You can bulk create up to {maxCaseNumber} complete mortgage cases simultaneously.',
        maxLimit: 'Check you have selected less than the {maxCaseNumber} maximum cases.',
        caseStatus: 'Check all cases are in status complete.',
        caseType: 'Check all cases are mortgage case types.',
      },
      updatesSuccessful: 'Updates successful',
      updatesCompleted: 'Your updates have been completed.',
      bannerMessage:
        'At times of high volume it can take up to a minute for the changes to be reflected on the case list, but the updates have all been made correctly.',
      loadingBannerMessage:
        'Please do not close this modal or navigate to a different screen. Doing so while your cases are being created will result in some cases failing to be generated.',
      finished: 'Finished',
      flagButtonText: 'Flag for {flagName}',
      manualReview: 'manual review',
      thirdPartyReview: 'third party review',
      financialCrime: 'financial crime',
      flagsPermissionTooltipText: 'You do not have permission to add a {flagName} flag to the case',
      flagAlreadyOnCaseTooltipText: 'is already present on this case',
      flagWillbeAddedToCase: `You're about to add a {flag} flag. Are you sure you want to do this?`,
      tags: {
        disabledTag1: 'Selecting this tag combination is not supported.',
        disabledTag2:
          'Each additional tag narrows your results further. Combinations that will not find results are disabled.',
      },
      flags: {
        note: {
          heading: 'Case flag',
          clearFlag: 'Clear flag',
          writeAnotherReview: 'Write another review',
          sendToAdviser: 'Send to advisor',
          saveReview: 'Save review',
          submitForCaseChecks: 'Submit for case checks',
          reviewCreatedAt: 'Sent {day} {month} {year}, {time}',
          confirmation: {
            yesClearFlag: 'Yes, clear flag',
            areYouSureMessage:
              'Are you sure you want to clear this flag? This will allow the case to proceed if no other flags are present. This cannot be reversed.',
          },
          allowCaseToProceed: 'Allow case to proceed',
          noFurtherReviewRequired: 'No further review required',
          caseFlagPassedYES: 'Case Flag Passed: Yes',
          caseFlagPassedNO: 'Case Flag Passed: No',
          caseGrade: 'Case Grade: {value}',
          caseGradeLabel: 'Case grade',
          grade: 'Grade: {value}',
        },
      },
      notProceeding: 'Not proceeding',
      notProceedingLabels: {
        areYouSure: 'Are you sure?',
        cancellationReason: 'Cancellation reason',
        notes: 'Notes',
        yesMarkAsNotProceeding: 'Yes, mark as not proceeding',
        markAsNotProceeding: 'Mark as not proceeding',
        noteBody: 'Not proceeding - {cancellationReason} \n {note}',
      },
      cancellationReasons: {
        clientDeclinedProduct: 'Customer declined product',
        noProductAvailable: 'No product available',
        duplicateCase: 'Duplicate case',
        incorrectContactDetails: 'Incorrect contact details',
        clientNoLongerRequiresProduct: 'Client no longer requires product',
        clientObtainedProductElsewhere: 'Client obtained product elsewhere',
        other: 'Other',
        noResponse: 'No response',
        invalidCancellationReason: 'Invalid cancellation reason',
      },
      status: {
        preRecommendation: 'Pre-recommendation',
        preApplication: 'Pre-application',
        postApplication: 'Post-application',
        notProceeding: 'Not proceeding',
        review: 'Review',
        importedComplete: 'Imported Complete',
        importing: 'Importing',
        postRecommendationReview: 'Review',
        lead: 'Lead',
        applicationSubmitted: 'Application Submitted',
        awaitingValuation: 'Awaiting Valuation',
        awaitingOffer: 'Awaiting Offer',
        offered: 'Offered',
        exchange: 'Exchanged',
        complete: 'Complete',
        postRecomendationReview: 'Post recommendation review',
        invalid: 'Invalid case status',
      },
      iconToolTip: {
        loggedInToClientPortal: 'Client(s) logged into client portal',
        noClientLoggedInToClientPortal: 'No client has logged into client portal',
        creditReportDone: 'Credit report has been retrieved',
        noCreditReportDone: 'No credit report present on case',
        meetingBooked: 'A meeting has been booked',
        noMeetingBooked: 'No meetings booked',
        daysInColumn: 'Time at current case status',
        remindersCount: 'Number of reminders on the case',
      },
      chaseInfo: {
        heading: 'Chase info',
        security: 'Security',
        address: 'Address',
        valuation: 'Property value',
        recommendedMortgage: 'Recommended mortgage',
        loan: 'Loan amount',
        additionalBorrowing: 'Additional borrowing',
        rate: 'Product rate',
        applicationType: 'Application type',
        applicationReference: 'Application reference number',
        mortgageAccount: 'Mortgage account number',
        currentMortgage: 'Current mortgage',
        clientHeading: 'Client {index}',
        name: 'Name',
        dob: 'Date of birth',
        currentAddress: 'Current address',
        companyName: 'Company name',
        companyAddress: 'Company address',
        sicCode: 'SIC Code',
        brokerHeading: 'Broker',
        fcaNumber: 'FCA number',
        firmName: 'Name of firm',
        firmAddress: 'Firm office address',
      },
      sortBy: {
        createdDateOldest: 'Created date (Oldest first)',
        createdDateNewest: 'Created date (Newest first)',
        timeInStageOldest: 'Time in stage (Oldest first)',
        timeInStageNewest: 'Time in stage (Newest first)',
        remindersDue: 'Reminders due',
      },
      remindersDue: {
        any: 'Any',
        overdue: 'Overdue',
        today: 'Today',
        thisWeek: 'This week',
        thisMonth: 'This month',
        noRemindersDue: 'No reminders due',
      },
      board: {
        preRecommendation: 'Pre-recommendation',
        preApplication: 'Pre-application',
        submitted: 'Submitted',
        offered: 'Offered',
        exchange: 'Exchanged',
      },
      card: {
        latestNote: 'Latest note',
        showMore: 'Show more',
      },
      nav: {
        list: 'All cases',
        board: 'Board view',
        compliance: 'Compliance',
        editBoard: 'Edit board',
        dashboard: 'Dashboard',
      },
      filterBar: {
        sortBy: 'Sort by',
        remindersDue: 'Reminders due',
        advisor: 'Advisor',
        status: 'Status',
        caseType: 'Case types',
        case_flags: 'Case flags',
        casesWithAmendedFees: 'Fee scenario',
        casesWithFeesAddedToLoan: 'Fee timing',
        lenders: 'Lenders',
        introducers: 'Introducers',
        resetAll: 'Reset all',
        saveChanges: 'Save changes',
        applyFilters: 'Apply filters',
        relatedUsers: 'Related users',
        relatedUser: 'Related user',
        relationshipType: 'Relationship type',
        oneSelected: '1 selected',
        select: 'Select...',
        filterByFlags: 'Flags',
        organisations: 'Organisations',
        date: 'Date',
        moreFilters: 'More filters',
        clearAll: 'Clear all',
        withFees: 'Amended fee scenario',
        withoutFees: 'Standard fee scenario',
        withFeesAdded: 'Added to loan',
        withoutFeesAdded: 'Not added to loan',
        productTransferRemos: 'Product transfer',
        productTransfer: 'Product transfer',
        noProductTransfer: 'No product transfer',
        productCoverage: 'Product coverage',
        productCoverageAdvice: 'Advice',
        productCoverageType: 'Type',
        protection: 'Protection',
        homeInsurance: 'Home insurance',
        conveyancing: 'Conveyancing',
        surveys: 'Surveys',
        willsAndProbate: 'Wills and probate',
        eligible: 'Eligible',
        advised: 'Advised',
        referred: 'Referred',
        vulnerable: 'Vulnerable clients',
        nonVulnerable: 'No vulnerable clients',
        org_ids: 'Organisations',
        lender_ids: 'Lenders',
        introducer_ids: 'Introducers',
        cases_with_amended_fees: 'Fee scenario',
        cases_with_fees_added_to_loan: 'Fee timing',
        relationship_type: 'Related users',
        product_coverage_advice: 'Product coverage',
        product_transfer: 'Product transfer',
      },
      settings: {
        stage: 'Stage',
        statusesIncluded: 'Statuses included',
        columnTitle: 'Column title',
        addColumnBefore: 'Add column before',
        addColumnAfter: 'Add column after',
        emptyColumnTooltip: 'Only empty columns can be deleted',
        minTwoColumnsTooltip: 'Minimum two columns',
        noTileOnPanelTooltip: 'One or more of your columns does not have a status',
        emptyTextInputTooltip: 'One or more of your columns does not have a title',
        banner: `You are editing case boards for the whole company. Any changes will affect everyone's case board.`,
      },
      caseList: 'Case list',
      caseBoard: 'Case board',
      casesCount: '{count} cases',
      loadingCases: 'Loading cases',
      loading: 'Loading...',
      table: {
        header: {
          clients: 'Clients',
          applicants: 'Applicants',
          caseType: 'Case type',
          postCode: 'Postcode',
          lender: 'Lender',
          loanAmount: 'Loan amount',
          predictedRevenue: 'Predicted revenue',
          property: 'Property',
          introducer: 'Introducer',
          caseManager: 'Case manager',
          administrator: 'Administrator',
          leadQualifier: 'Lead qualifier',
          referrer: 'Referrer',
          reviewer: 'Reviewer',
          watcher: 'Watcher',
          status: 'Status',
          caseOwner: 'Case owner',
          created: 'Created',
          transitionAt: 'Status changed',
          submitted: 'Submitted',
          recommended: 'Recommended',
          reviewed: 'Reviewed',
          completed: 'Completed',
        },
        noInformation: '-',
        noData: 'No cases found',
        noClients: 'No clients added yet',
        filters: {
          all: 'All',
          applyFilters: 'Apply filters',
        },
      },
      subnav: {
        overview: 'Overview',
        caseDetails: 'Case details',
        contacts: 'Contacts',
        factFind: 'Fact find',
        property: 'Property',
        properties: 'Properties',
        products: 'Products',
        protection: 'Health and Protection',
        documents: 'Documents',
        ledger: 'Accounting',
        protectionSourcing: 'Protection sourcing',
        notes: 'Notes',
        suitability: 'Suitability',
        sourcing: 'Sourcing',
        goToSourcing: 'Mortgage sourcing',
        lenderProposed: 'Lender proposed',
        gi: 'Home insurance',
        giSourcing: 'GI sourcing',
        giProducts: 'Products ({noOfProducts})',
        wills: 'Wills and LPAs',
      },
      products: {
        sections: {
          name: {
            statusRepaid: 'Repaid',
            statusCurrent: 'Active',
            statusProposed: 'Sourced',
            statusSelected: 'Selected',
            statusLenderProposed: 'Product transfer',
          },
        },
        noProductsSelected: 'No products selected',
        noVerifiedDocuments: 'No verified documents',
        noProducts: 'No products. Run sourcing to add products',
        title: 'Products',
        dropdown: 'Source products',
        card: {
          keys: {
            rate: 'Rate',
            mortgageClass: 'Class',
            period: 'Period',
            monthlyPayment: 'Monthly payment',
            initialPeriodEndDate: 'Initial period end date',
            fees: 'Fees',
            rateType: 'Rate type',
            term: 'Term',
            paymentMethod: 'Repayment method',
            months: '{number} months',
            makeRecommendationButtonTooltip: 'Please source mortgages before making a product recommendation',
          },
          values: {
            interestOnly: 'Interest Only',
            capitalRepayment: 'Capital',
            partAndPart: 'Part and Part',
          },
        },
        options: {
          removeProduct: 'Remove product',
          makeRecommendation: 'Make recommendation',
          recommended: 'Recommended',
          cannotRecommend: 'Cannot Recommend',
          generateIllustration: {
            title: 'Generate illustration',
            illustrationGenerated: 'Illustration generated',
            productFees: 'Product Fees',
            adviserFees: 'Advisor Fees',
            mortgageClub: 'Mortgage Club',
            selectMortgageClub: 'Select mortgage club',
            noAlternativeProcFeeOptionAvailable: 'No alternative proc fee options available',
            procFeeMissing: 'Unable to generate illustration - no proc fee has been set',
            addButton: '+ Add fee',
            download: 'Download Illustration',
            recommend: 'Make Recommendation',
            arrangement: 'Arrangement fee',
            booking: 'Booking fee',
            chaps: 'CHAPS fee',
            deedsRelease: 'Deeds release fee',
            disbursement: 'Disbursement fee',
            homebuyers: 'Other',
            legal: 'Legal fee',
            valuation: 'Valuation fee',
            term: 'Term',
            amount: 'Amount',
            preMortgageAdvice: 'Pre Mortgage Advice (£)',
            preMortgageRecommendation: 'Pre Mortgage Recommendation (£)',
            preMortgageRecommendationPercent: 'Pre Mortgage Recommendation (%)',
            preMortgageApplication: 'Pre Mortgage Application (£)',
            preMortgageApplicationPercent: 'Pre Mortgage Application (%)',
            offer: 'Mortgage Offer (£)',
            offerPercent: 'Mortgage Offer (%)',
            legalCompletion: 'Mortgage Legal Completion (£)',
            legalCompletionPercent: 'Mortgage Legal Completion (%)',
            legalExchangeFixed: 'Mortgage Legal Exchange (£)',
            legalExchangePercent: 'Mortgage Legal Exchange (%)',
            withoutEsisID1:
              'Before you can make final recommendations, you must generate an illustration for the product.',
            withoutEsisID2: 'Check the fees and then click below to generate an illustration for your recommendation.',
            changeFeesConfirmation:
              'Are you sure you want to change the fees on this case? A new initial disclosure document will replace the previous one and your client(s) will need to accept the new fees.',
            changeFeesContinue: 'Yes, continue with the new fees',
            notAvailableMessage:
              'This mortgage is not available through any of the clubs configured in your panel, it is however available through the following clubs:',
            changeOptionsMessage:
              'If you believe one of these options should be available to you, they can be changed by a Principal user in Company Settings.',
          },
        },
      },
      notes: {
        noteTitles: {
          generalNote: 'General',
          calendar: 'Meeting',
          email: 'Email',
          call: 'Call',
          sms: 'SMS',
          legal: 'Legal',
          review: 'Review',
          unknown: 'Unknown note type',
          messaging: 'Message',
        },
        secureComms: {
          recipients: 'Recipients:',
          sentMessage: 'Sent message',
          sendMessage: 'Send message',
          receivedMessage: 'Received message',
          from: 'From: {name}',
          to: 'To: {name}',
          markRead: 'Mark as read',
          markUnread: 'Mark unread',
          pagination: '{page} of {pagesLength}',
        },
        emailModal: {
          title: 'Additional info required',
          clientDetails: 'Go to client details',
          desc: 'To send a message to your client, the following information is needed:',
          missingEmail: 'Email address (for {name})',
        },
        showAll: 'Show all',
        bodyPlaceholder: 'Write your note...',
        addNote: 'Add note',
        createdWhen: '{dateTime}',
        createdBy: '{dateTime} by {creatorName}',
        pin: 'Pin',
        unpin: 'Unpin',
        title: 'Notes',
        capturedIn: 'Captured in:',
        factFind: 'Fact find',
        factFindNavItems: {
          caseDetails: 'Case details',
          clients: 'Clients',
          idVerification: 'ID Verification',
          credit: 'Credit',
          properties: 'Properties',
          income: 'Income',
          expenditure: 'Expenditure',
          conveyancing: 'Conveyancing',
          protection: 'Health and protection',
          protectionNeeds: 'Health and protection needs',
          existingProtection: 'Existing protection',
          protectionRequirements: 'Protection requirements',
          sourcingPreferences: 'Sourcing preferences',
          protectionProducts: 'Protection products',
          protectionRecommendation: 'Protection recommendation',
          sourcingProducts: 'Sourcing products',
          giProducts: 'GI products',
          giSourcing: 'GI sourcing',
          documents: 'Documents',
          caseAccounting: 'Case accounting',
          contacts: 'Contacts',
        },
      },
      contacts: {
        title: 'Contacts',
        tableHeading: 'Case contacts ({count})',
        headerPlus: '+ Add contact',
        addContact: 'Add contact',
        editContact: 'Edit contact',
        contact: 'Contact',
        contactType: 'Contact type',
        introducerOrg: 'Introducer organisation',
        introducerContact: 'Introducer contact',
        commissionShare: 'Commission share',
        commissionShareBasisPoints: 'Set percentage commission share',
        noContacts: 'There are no contacts on this case',
        ADMINISTRATOR: 'Administrator',
        ADVISOR: 'Advisor',
        CASE_MANAGER: 'Case Manager',
        INTRODUCER: 'Introducer',
        LEAD_QUALIFIER: 'Lead Qualifier',
        REFERRER: 'Referrer',
        REVIEWER: 'Reviewer',
        WATCHER: 'Watcher',
        ESTATE_AGENT: 'Estate agent',
        VALUER: 'Valuer',
        CONVEYANCER: 'Conveyancer',
        contactName: 'Contact name',
        type: 'Type',
        phone: 'Phone',
        email: 'Email',
        company: 'Company',
        firmName: 'Firm name',
        percentadgeError:
          'Commission splits on related users cannot equal more than 100% - please adjust the commission split and try again',
        contactInUse: 'This contact and role is already in use',
        conveyancingPage: 'Conveyancers are set up on the <a>Conveyancing</a> page',
      },
      documents: {
        title: 'Case',
        protectionTitle: 'Health and Protection documents',
        noDocs: 'No documents. Click above to upload',
        archiveAreYouSure: 'Are you sure you want to archive this document?',
        archiveYes: 'Yes, archive',
        archiveEsisRecommended:
          'Archiving this ESIS will remove the recommendation of the associated product, do you wish to continue?',
        archivePreApplication: 'You cannot archive this document as the case is in Pre-Application status',
      },
      ledgers: {
        addOneOffPayments: 'Add one off payments',
        addRecurringPayments: 'Add recurring payment',
        oneOffPayments: 'One-off payments',
        emptyOneOffPayments: 'You currently have no one-off payments',
        recurringPayments: 'Recurring payments',
        emptyRecurringPayments: 'You currently have no recurring payments',
        total: 'Total',
        amount: 'Amount',
        name: 'Type',
        source: 'Payer/payee',
        paymentDirection: 'Credit/debit',
        dueDate: 'Due by',
        settledDate: 'Settled',
        status: 'Status',
        endDate: 'End date',
        nextPayment: 'Next payment',
        amountPaid: 'Amount paid',
        monthlyPayment: 'Monthly payment',
        item: 'Item',
        areYouSure: 'Are you sure you want to delete this ledger?',
        paymentDirections: {
          CREDIT: 'Credit',
          DEBIT: 'Debit',
        },
        statuses: {
          CANCELLED: 'Cancelled',
          CLOSE: 'Close',
          DUE: 'Due',
          IN_FORCE: 'In force',
          LIVE: 'Live',
          OVERDUE: 'Overdue',
          PARTIALLY_PAID: 'Partially paid',
          PARTIALLY_WRITTEN_OFF: 'Partially written off',
          SETTLED: 'Paid',
          WRITTEN_OFF: 'Written off',
          AWAITING: 'Waiting for client',
          FAILED: 'Client payment failed',
        },
      },

      requirements: {
        clients: {
          heading: 'Clients',
          deliverVerbalDisclosure: 'Deliver verbal disclosure',
          verbalDisclosureAccepted: 'Verbal disclosure accepted',
          introducedBusiness: 'Introduced business',
          didThisBusinessOriginateThroughAnIntroducer: 'Did this business originate through an introducer?',
          notes: 'Notes',
          nameEmailOfTheIntroducingIndividualOrBusiness: 'Name/Email of the introducing individual or business',
        },
        donors: {
          heading: 'Donors',
          subheading: 'These will be captured in fact find when adding deposits',
        },
        preferences: {
          heading: 'Preferences',
          subHeader: 'Preference details',
          runSourcing: 'Run sourcing',
          preferencesBanner: 'All preferences are automatically pulled through to sourcing to save time filtering',
          mortgagePreferences: 'Mortgage preferences',
          optionsHeading: 'Which payment options are important to the client?',
          incentiveHeading: 'Which incentives are important to the client?',
          submitButton: 'Save preference details',
          preferenceDetails: 'Preference details',
          loanAmountRequired: 'Loan amount must be set before this field can be completed',
          repayment: {
            label: 'Repayment method',
            repayment: 'Repayment',
            interestOnly: 'Interest only',
            partAndPart: 'Part and part',
          },
          rateType: {
            fixed: 'Fixed',
            tracker: 'Tracker',
            discount: 'Discount',
            label: 'Rate preference',
          },
          radio: {
            preference_fixed_rate: 'Does the client want to fix their mortgage costs for a certain period?',
            preference_early_year_discount:
              'Does the client want a discount in the early years of their mortgage term?',
            preference_no_early_repayment_charge: 'No ERCs',
            preference_no_tie_in: 'No ERCs after initial period',
            preference_fee_to_loan: 'Add product fees to loan',
            preference_vary_repayment_amount: 'Underpayments allowed',
            preference_payment_holiday: 'Repayment holidays',
            preference_link_finances: 'Reduce interest via a linked account (offset)',
            preference_cashback: 'Cashback',
            preference_free_conveyancing: 'Free legals',
            preference_free_conveyancing_warning:
              'Selecting free conveyancing can sometimes result in no products being available when sourcing. If this happens, you can switch off the free legals filter.',
            preference_free_valuations: 'Free Valuation',
            preference_completion_speed_important: 'Speed of completion',
            preference_tracker:
              'Does the client want a product where the interest rate of the mortgage moves in line with an external index?',
            preference_overpayment: 'Over-payments allowed',
          },
          input: {
            interest_only_repayment_strategy: 'Repayment strategy for interest only mortgage',
            upper_limit: `What is the client's upper limit on mortgage costs for the initial period?`,
            preference_details: 'This will appear in the Suitability Report',
            preference_term: 'Term',
            preference_term_years: 'Years',
            preference_term_months: 'Months',
            preference_repayment_percentage: 'Capital repayment percentage',
            preference_initial_period_months: 'Fixed rate length',
            completion_speed_days: 'Is speed of completion important to client?',
          },
          dropdown: {
            preference_mortgage_class: 'Has the client expressed a preference for a particular mortgage class?',
            options: {
              fixed: 'Fixed',
              discount: 'Discount',
              variable: 'Variable',
              capped: 'Capped',
              libor: 'LIBOR',
              tracker: 'Tracker',
              stepped: 'Stepped',
            },
          },
        },
        products: {
          products: 'Products',
          productTransfer: 'Product transfer',
          productDetails: 'Product details',
          mortgageDetails: 'Mortgage details',
          addLenderProposedProduct: 'Add lender proposed product',
          lenderProposedProduct: 'Lender proposed product',
          addExistingMortgage: 'Add existing mortgage',
          existingMortgage: 'Existing mortgage',
          productTransferAvailableFromLender: 'Product transfer available from lender',
        },
        details: {
          heading: 'Requirements',
          caseDetails: 'Case details',
          protectionAdvice: 'Health and Protection advice',
          submitButton: 'Save changes',
          caseApplicants: 'Applicants',
          addApplicant: 'Add applicant',
          addCompany: 'Add company',
          directors: 'Directors',
          reasonForFurtherAdvance: 'Reason for further advance',
          input: {
            type: 'Case type',
            propertyValue: 'Property value',
            purchasePrice: 'Purchase price',
            newPropertyPurchasePrice: 'New property purchase price',
            deposit: 'Deposit',
            revisedEquity: 'Revised equity',
            loanAmount: 'Loan amount',
            loanToValue: 'Loan to value',
            previousProperty: 'Has previously owned property',
            buyToLet: {
              doneThroughCompany: 'Is the buy to let being done through a company?',
              hasLived: 'Have the client(s) previously lived in the property?',
              willLive: 'Do client(s) or a relative plan to live in the property?',
              operatingABusiness: 'Is the property being let by way of business?',
            },
          },
          debtConsolidation: {
            heading: 'Debt consolidation',
            input: {
              isDebtConsolidation: 'Does the client want to consolidate their debts into their mortgage?',
            },
          },
          lenderProposed: {
            heading: 'Lender proposed',
            input: {
              isLenderProposed: 'Has the lender proposed a retention product?',
            },
            addLenderProposedProduct: 'Add lender proposed product',
          },
        },
        property: {
          header: 'Property',
          propertyPortfolio: 'Property portfolio',
          portfolio: 'portfolio',
          goToPortfolio: 'Go to portfolio',
          noPortfolio: 'No properties found. Go to {clientName} portfolio',
          newProperty: 'New property',
          addProperty: 'Add new property',
          viewProperty: 'View property',
          clientProperty: 'Client property',
          selectProperty: 'Select property',
          mortgageProperty: 'Mortgage property',
          propertyToPurchase: 'Property to be purchased',
          propertyBeingSold: 'Property to be sold',
          propertyEquity: 'Property for equity release',
          autoPopulated: 'Auto populated from last sale price',
          lastSalePrice: 'Last sale price: {price}',
          delete: {
            modalHeaderText: 'Remove property',
            modalBodyText:
              'Deleting this property will remove it from the case, do you wish to delete it from the client profile(s) too?',
            genericConfirmation: 'Are you sure you want to delete this property?',
            no: 'No, only from the case',
            yes: 'Yes, remove from client(s) also',
          },

          form: {
            propertyAddress: 'Property address',
            propertyDetails: 'Property details',
            mortgage: 'Mortgage',
            product: 'Product',
            tenancy: 'Tenancy',
            buildingDetails: 'Building details',
            currentMortgagesDetails: 'Current mortgage',
            mortgageOnProperty: 'Mortgage on this property',
            tenancyDetails: 'Tenancy details',
            purchasePrice: 'Purchase price',
            propertyValue: 'Property value',
            rebuildCost: 'Rebuild cost',
            propertyValuation: 'Property valuation',
            valuationOptions: {
              client: 'Client',
              localAuthority: 'Local authority',
              bank: 'Bank',
              survey: 'Survey',
              sale: 'Sale',
            },
            tenancyType: 'Target tenancy type',
            tenancyTypes: {
              ast: 'Assured shorthold tenancy',
              assuredTenancy: 'Assured tenancy',
              nonAssuredTenancy: 'Non assured tenancy',
              excludedTenancy: 'Excluded tenancy',
              regulatedTenancy: 'Regulated tenancy',
              companyLet: 'Company let',
            },
            tenantType: 'Target tenant type',
            tenantTypes: {
              dss: 'Department of Social Services',
              student: 'Student',
              professional: 'Professional',
              commercial: 'Commercial',
              family: 'Family',
            },
            propertyUse: 'Property use',
            propertyUses: {
              holidayHome: 'Holiday home',
              primaryResidence: 'Primary residence',
              secondHome: 'Second home',
              tenanted: 'Tenanted',
            },
            leaseType: 'Tenure',
            leaseTypes: {
              freehold: 'Freehold',
              leasehold: 'Leasehold',
              flyingFreehold: 'Flying freehold',
              commonhold: 'Commonhold',
              feudal: 'Feudal',
              feuhold: 'Feuhold',
              crossoverLease: 'Crossover Lease',
            },
            propertyType: 'Property type',
            propertyTypes: {
              houseBoat: 'House boat',
              house: 'House',
              flat: 'Flat',
              maisonette: 'Maisonette',
              bungalow: 'Bungalow',
            },
            propertySubType: 'Sub type',
            propertySubTypes: {
              midTerraced: 'Mid-terrace',
              endTerraced: 'End terrace',
              linkDetached: 'Link detached',
              semiDetached: 'Semi-detached',
              detached: 'Detached',
              townHouse: 'Town house',
              conversion: 'Converted',
              purposeBuilt: 'Purpose-built',
            },
            monthlyRentalIncome: 'Anticipated rental income per month',
            isPropertyFurnished: 'Will the property be let furnished?',
            isPropertyExLocalAuthority: 'Is property ex-local authority?',
            numberOfBedrooms: 'Number of bedrooms',
            numberOfBathrooms: 'Number of bathrooms',
            yearsRemaining: 'Years remaining',
            groundRent: 'Annual ground rent',
            conversion: 'Has the property been converted in the last ten years?',
            commercialPremises: 'Is the property above commercial premises?',
            clearFloor: 'Is there a clear floor between the property and retail unit?',
            numOfFloors: 'How many storeys does the building have?',
            floor: 'What floor is the property on?',
            numOfFlats: 'How many properties in the block?',
            lift: 'Is there a lift in the property?',
            yearsOnLease: 'Years remaining on lease',
            leaseGroundRent: 'Annual ground rent',
            leaseServiceCharge: 'Annual service charge',
            sharedOwnership: 'Is the property a part of an affordable home ownership scheme?',
            ownershipType: 'Scheme type',
            sharedOwnershipType: 'Shared ownership',
            sharedEquityType: 'Shared equity',
            rightToBuyType: 'Right to buy',
            familyAssistType: 'Family Assistant Scheme',
            mortgageGuaranteeType: 'Mortgage Guarantee Scheme',
            rightToBuyValuation: 'Right to buy valuation',
            newBuyType: 'New Buy Scheme',
            firstHomesType: 'First Homes Scheme',
            sharedEquityRepaymentDetails: 'Equity loan repayment terms',
            helpToBuyOwnershipType: 'Help to Buy equity loan',
            ownershipShare: 'Share of the property the client will own',
            ownershipMonthlyRent: 'Shared ownership monthly rent',
            helpToBuyLoanAmount: 'Help to Buy loan amount',
            sharedEquityLoan: 'Shared equity loan',
            helpToBuySchemeProvider: 'Help to Buy agent',
            sharedEquityProvider: 'Shared equity provider',
            sharedOwnershipSchemeProvider: 'Shared ownership scheme provider',
            convertedProperty: 'Has the property been converted in the last ten years?',
            floorError: "Value cannot be greater than 'How many storeys does the building have?' but can be equal",
            floorPositiveError: 'Value should be able to be set to 0 or any positive number',
            flyingFreeholdPercentage: 'Flying freehold percentage',
            isBusinessIntended: 'Do clients intend to let or run a business from the property?',
            businessDescription: 'Description',
            newBuild: 'Is the property a new build?',
            yearBuilt: 'Year built',
            monthlyManagementExpenses: 'Monthly management expenses',
            monthlyManagementTooltip:
              'Monthly costs associated with the management and associated income of the property, averaged over a year. Including for example, Management Agent Fees, Landlord Insurance, Gas Certificates, Wear and Tear, Accountancy Fees.',
            builderName: 'Property builder name',
            buildersRegistrationScheme: 'Builder registration scheme',
            targetOwners: 'New property owner(s)',
            registeredOwners: 'Registered property owner(s)',
            buildersRegistrationSchemeTypes: {
              nationalHouseBuildingCouncil: 'National house building council',
              zurichNewBuild: 'Zurich new build',
              premierGuaranteeNewHomeWarranty: 'Premier guarantee new home warranty',
              localAuthorityBuildingControlHomeWarranty: 'Local authority building control home warranty',
              buildingLifePlansInsurance: 'Building life plans insurance',
              buildingZoneNewHomeWarrantySelfBuild: 'Building zone new home warranty self build',
              castle10NewHomeWarranty: 'Castle 10 new home warranty',
              otherBuilderRegistrationScheme: 'Other builder registration scheme',
            },
            wallType: 'Wall type',
            wallTypes: {
              steel: 'Steel',
              cobb: 'Cobb',
              standardBrick: 'Standard brick',
              singleSkinBrick: 'Single skin brick',
              woolaway: 'Woolaway',
              cornishType1: 'Cornish type 1',
              cornishType2: 'Cornish type 2',
              otherConcrete: 'Other concrete',
              wimpeyNoFines: 'Wimpey no fines',
              laingEasiform: 'Laing easiform',
              timberFramed: 'Timber framed',
              stone: 'Stone',
              other: 'Other',
            },
            roofType: 'Roof type',
            roofTypes: {
              flatRoof: 'Flat roof',
              thatchedRoof: 'Thatched roof',
              tileRoof: 'Tile roof',
              slateRoof: 'Slate roof',
            },
            location: 'Property location',
            locationTypes: {
              englandWales: 'England and Wales',
              scotland: 'Scotland',
              northernIreland: 'Northern Ireland',
              england: 'England',
              wales: 'Wales',
            },
            epcRating: {
              a: 'A',
              b: 'B',
              c: 'C',
              d: 'D',
              e: 'E',
              f: 'F',
              g: 'G',
              label: 'EPC rating',
            },
            is_property_habitable: 'Is the property habitable?',
            isWorkPlannedOnProperty: 'Is work planned on the property?',
            workPlannedOnProperty: 'Work planned on the property',
            grossDevelopmentValue: 'Gross development value',
            costOfWorks: 'Cost of works',
            propertyWorkTypes: {
              majorConstruction: 'Major construction',
              refurbishment: 'Refurbishment',
              structuralAlterations: 'Structural alterations',
            },
            is_unencumbered: 'Does property have attached mortgage(s)?',
            isHouseOfMultipleOccupancy: 'Is property House of Multiple Occupancy (HMO)?',
            numberOfLettableRooms: 'Number of lettable rooms',
            isMultiUnitFreeholdBlock: 'Is property Multi Unit Freehold Block (MUFB)?',
            numFlatsInBlock: 'Number of units',
            numberOfKitchens: 'Number of kitchens',
            numberOfReceptionRooms: 'Number of reception rooms',
            hasAnnex: 'Does the property have an annex?',
            propertyParking: 'Parking type',
            doesThePropertyHaveDeckAccess: 'Does the flat have balcony access?',
            solarPanelsOnProperty: 'Does the property have solar panels?',
            parkingType: {
              none: 'None',
              driveway: 'Driveway',
              singleGarage: 'Single garage',
              doubleGarage: 'Double garage',
              parkingSpace: 'Parking space',
            },
          },
          survey: {
            acre: 'Acre{br}Surveying',
            title: 'Need a survey?',
            htbTitle: 'Redeeming Help To Buy?',
            needHTB: `If your client is redeeming a Help To Buy mortgage, they'll need an RICS specialist valuation. Acre Surveying can help.`,
            teamedUp:
              "We've teamed up with some of the UK's leading surveyors to help you get your clients a high quality, RICS approved survey at a great value price.",
            whatsMore: "And what's more, we pay a commission of £100 for every one sold!",
            refer: 'Refer now',
            findOutMore: 'Learn more',
            findOutEvenMore: 'Visit SBVS to learn more',
            referred:
              'This case has been referred to Acre Surveying who will contact the client in order to provide a suitable survey.',
            referred2: ' Their reference is {ref} - please quote if contacting them.',
            badFields: 'You must complete the field(s) "{missing}" before referring.',
            saveFirst: 'Please save the property before referring.',
          },
        },
        idVerification: {
          heading: 'KYC',
          subHeading: 'Anti-impersonation',
        },
        protectionNeeds: {
          heading: 'Health and Protection Needs',
        },
        existingProtection: {
          heading: 'Existing Health and Protection Policies',
          subHeading: 'Record any pre-existing protection policies here',
          existingProtectionProducts: 'Existing Health and Protection products',
          employeeBenefits: 'Employee benefits',
          doClientsHaveAnyExistingProtectionProducts: 'Do clients have any existing Health and Protection products?',
          dateCancelled: 'Date cancelled',
          addAProtectionProduct: '+ Add an existing Health and Protection product',
          addAnEmployeeBenefit: '+ Add an employee benefit',
          ifTheClientHasAnyPreexisting:
            'If the client has any pre-existing Health and Protection products, record them here',
          protectionProductModal: 'Health and Protection product details',
          protectionProductModalCancellation: 'Cancel product',
          employeeBenefitModal: 'Employee benefit details',
          client: 'Client',
          provider: 'Provider',
          providerName: 'Provider Name',
          policyNumber: 'Policy number',
          payoutType: 'Payout type',
          productName: 'Policy name',
          productType: 'Product type',
          indexation: 'Indexation',
          deferredPeriod: 'Deferred period',
          monthlyPremiumCost: 'Monthly premium cost',
          policyInterestRate: 'Policy interest rate',
          policyStartDate: 'Policy start date',
          policyExpiry: 'Policy expiry',
          policyStart: 'Policy start',
          coverAmount: 'Cover amount',
          benefitFrequency: 'Benefit frequency',
          notes: 'Notes',
          productTypeName: 'Product type name',
          keepPolicy: 'Should the client keep this policy?',
          cancelPolicyTooltip: 'This is required for re-broking and will be shown in the suitability',
        },
        employeeBenefits: {
          heading: 'Employee benefits',
          employmentCircumstancesCanChange:
            'Employment circumstances can change in the short term. If the client has opted to include employee benefits in their shortfall analysis, ensure they are aware of the risks involved in depending on them.',
          areThereAnyEmployeeBenefits:
            'Are there any employee benefits that should be included as part of shortfall calculations?',
          benefitsShortfallAnalysis: 'Are these being taken into account for shortfall analysis?',
          suitableDecision: 'Why is this decision suitable?',
          suitableDecisionPlaceholder:
            'Employee benefits benefits were/were not considered as part of your shortfall analysis because...',
          modalTitle: 'Employee benefit',
          benefitType: 'Benefit type',
          productTypeName: 'Benefit name',
          policyInterestRate: 'Policy interest rate',
          payoutType: 'Payout type',
          deferredPeriod: 'Deferred period',
        },
        protectionRequirements: {
          add: '+ Add a requirement',
          heading: 'Requirements',
          objectives: 'Objectives',
          objectivesExplanation: 'Client(s) Health and Protection objectives and circumstances',
          lifeCover: 'Life and Critical Illness',
          lifeCoverExplanation: 'Lump sum payout in case of death and/or critical illness for a fixed term',
          fibCover: 'Family Income Benefit',
          fibCoverExplanation: 'Regular income in case of death and/or critical illness',
          incomeCover: 'Income Protection',
          incomeCoverExplanation: ' Regular income in case of long-term illness or disability',
          wholeOfLifeCover: 'Whole of Life',
          wholeOfLifeCoverExplanation:
            'Lump sum payout to family in case of death that provides cover for the entirety of a clients life',
          privateMedicalInsurance: 'Private Medical Insurance',
          privateMedicalInsuranceExplanation: 'Private medical insurance covers the costs of private healthcare',
          objectivesAndCircumstances: 'Objectives and circumstances',
          termYears: 'Term (years)',
          termAgeYears: 'To age (years)',
          termYearsReason: 'Reason for term length',
          areTheseNeedsAlreadyMetByPreexistingPolicies: 'Are these needs already met by pre-existing policies?',
          benefitType: 'Benefit type',
          deferralPeriod: 'Deferral period',
          addARequirement: 'Add a requirement',

          requirementsCard: {
            scenario: 'Scenario',
            preExistingPolicy: `Met by pre-existing policy?`,
            coverAmount: 'Cover amount',
            coverAmountType: 'Cover amount type',
            reasonForAmount: 'Reasons for amount',
            term: 'Term',
            termAge: 'To age',
            reasonForTerm: 'Reason for term',
            years: 'years',
            benefitType: 'Benefit type',
            LEVEL_TERM: 'Level term',
            DECREASING_TERM: 'Decreasing term',
            coverAmountTypeEnum: {
              MONTHLY: 'Monthly',
              MAXIMUM: 'Maximum',
            },
          },

          deferralPeriodDropdownOptions: {
            zero: 'Zero',
            oneWeek: 'One week',
            twoWeeks: 'Two weeks',
            oneMonth: 'One month',
            twoMonths: 'Two months',
            threeMonths: 'Three months',
            sixMonths: 'Six months',
            oneYear: 'One year',
            twoYears: 'Two years',
            zeroNumerical: '0',
            oneWeekNumerical: '1 week',
            twoWeeksNumerical: '2 weeks',
            oneMonthNumerical: '1 month',
            twoMonthsNumerical: '2 months',
            threeMonthsNumerical: '3 months',
            sixMonthsNumerical: '6 months',
            oneYearNumerical: '1 year',
            twoYearsNumerical: '2 years',
          },
          scenario: {
            title: 'Scenario',
            criticalIllness: 'Critical illness',
            life: 'Life',
            lifeOrEarlierCriticalIllness: 'Life or earlier critical illness',
          },
          clients: 'Client(s)',
          coverAmount: 'Amount of cover',
          coverAmountType: 'Cover amount type',
          lumpSumCoverAmount: 'Lump-sum cover amount',
          annualCoverAmount: 'Annual cover amount',
          monthlyCoverAmount: 'Monthly cover amount',
          coverAmountReason: 'Reason for cover',
          limitProtectionReview: 'Does the client want to limit their Health and Protection review in any way?',
          limitProtectionReviewDetails: 'Details about limiting Health and Protection review',
        },
        recommendation: {
          menu: 'Recommendation',
          heading: 'Recommendation details',
          advisedOnInheritanceTax: 'Was the client(s) advised in regards to inheritance tax?',
          body: 'These will appear in your Suitability Report.',
          placedInTrust: 'Have you recommended any policies be placed in a trust?',
          particularTrust: 'Is there a trust in particular that you have recommended?',
          trustDetails: 'Why is this decision suitable?',
          trustDetailsPlaceholder: 'Further details about trusts',
          affordablePastRetirement:
            "If any policy terms go past the client's expected retirement age, has the client(s) confirmed that it will still be affordable?",
        },
      },
      suitability: {
        preferences: 'Preferences',
        rationale: 'Rationale',
        term: 'Term',
        affordability: 'Affordability',
        emailClientQuestion: 'Send email to client(s) for digital signature?',
        selectClients: 'Which clients do you want to email?',
        clientMissingEmail: `This client doesn't have an email address on their file.`,
        srNotificationEmailSubject: 'You have been invited to view your suitability report',
        publishReport: 'Publish report',
        publishReportAndEmail: 'Publish and email client(s)',
        publishSuitabilityReport: 'Publish suitability report',
        emailClientsForSignature: 'Send email to client(s) for digital signature',
        sendEmail: 'Send email',
        howWerePrefs: 'How were client preferences taken into consideration?',
        suitabilityReportIntroduction: 'Suitability report Introduction',
        reasonsHeader: 'What was the basis of this recommendation?',
        reasonsAdditionalBorrowing: 'Reason for additional borrowing',
        reasonForTermLength: 'Reason for overall term length',
        reasonForIncrease: 'Reason for increase in term following remortgage',
        reasonTermRetirement: 'How will mortgage costs be covered in retirement?',
        reasonRetirementInterestOnly: `Justify client's ability to afford an interest only mortgage extending into retirement?`,
        reasonForCostIncrease: 'Reason for increase in costs following remortgage',
        howIsRecommendationAffordable: "Justify client's ability to afford mortgage repayments",
        howMortgageCoveredVoidPeriod: 'How will the mortgage repayments be covered during void period?',
        howClientRepayHelpToBuy: 'How is the client able to repay the Help to Buy loan alongside the mortgage?',
        fieldPlaceholder: 'Type something...',
        optionalFieldPlaceholder: 'Provide additional detail (optional)',
        SUITABILITY_REPORT: 'Mortgage advice suitability report',
        PROTECTION_SUITABILITY_REPORT: 'Health and Protection advice suitability report',
        alternatives: {
          heading: 'Alternatives',
          whyWasRec: 'Reason for not selecting alternative products',
        },
        navHeadings: {
          recommendation_reason_for_recommendation: 'Reason for recommendation',
          recommendation_unmet_preferences: 'Reason for unmet preferences',
          reason_for_recommendation_product_type: 'Reason for product type',
          reason_for_recommendation_initial_term: 'Reason for initial term',
          recommendation_term_reason: 'Term reason',
          recommendation_reason_for_remortgage_cost_increase: 'Reason for cost increase',
          recommendation_reason_for_recommendation_term_increase: 'Reason for term increase',
          recommendation_strategy_for_payment_erc: 'ERC repayment strategy',
          recommendation_reason_for_equity_disparity: 'Reason for equity disparity',
          recommendation_reason_term_into_retirement: 'Reason for term into retirement',
          recommendation_reason_for_affordability: 'Reason for affordability',
          recommendation_reason_additional_borrowing: 'Reason for additional borrowing',
          suitability_report_recommendation_introduction: 'Introduction',
          suitability_report_recommendation_introduction_protection: 'Introduction',
          recommendation_reason_help_to_buy_loan_repayment_strategy: 'Reason for HTB repayment strategy',
          debt_consolidation_alternative_options: 'Debt consolidation options considered',
          debt_consolidation_reason_for_consolidated_debts: 'Reason for consolidated debts',
          recommendation_reason_strategy_for_btl_void_period_coverage: 'Reason for void period coverage',
          recommendation_reason_retirement_interest_only: 'Reason for retirement interest only',
          preference_interest_only_repayment_strategy: 'Reason for interest only repayment strategy',
          preference_details: 'Reason for preferences',
          pay_rise_expected_details: 'Reason for increase in income',
          recommendation_reason_for_further_advance: 'Reason for further advance',
          recommendation_reason_entitlement_to_state_benefits: 'Reason for entitlement to state benefits',
          outgoings_material_change_details: 'Outgoing change details ',
          income_likely_to_reduce_in_future_description: 'Reason for reduction in income',
          shared_equity_repayment_details: 'Shared equity repayment details',
          client_advice_limit_details: 'Review of scope',
          client_protection_objectives_circumstances: 'Objectives',
          employee_benefits_shortfall_reasoning: 'Employee benefits',
          death_needs_lump_sum_term_justification: 'Lump sum requirements',
          death_needs_income_term_justification: 'Regular income (life or CI) requirements',
          illness_needs_term_justification: 'Regular income (illness) requirements',
          product_details: 'Recommendation',
          justify_remaining_shortfalls: 'Remaining shortfalls',
          additional_notes: 'Additional notes',
          trust_details: 'Use of trusts',
          rebroke_decision_advantages_and_disadvantages: 'Re-broking',
          funeral_needs_justification: 'Funeral requirements',
          policy_number: 'Policy number',
          product_recommendation_justification: 'Recommendation requirements',
          additional_budget_details: 'Budget',
          additional_considerations_detail: 'Considerations',
          death_needs_lump_sum_amount_justification: 'Lump sum amount justification',
          death_needs_income_amount_justification: 'Regular income (life or CI) amount justification',
          illness_needs_income_amount_justification: 'Regular income (illness) justification',
          life_and_critical_illness: 'Life and critical illness',
          family_income_benefits: 'Family income benefits',
          income_protection: 'Income protection',
          whole_of_life: 'Whole of life',
          cover_reason: 'Cover reason',
          term_reason: 'Term reason',
          protection_objectives_circumstances: 'Objectives and Circumstances',
          private_medical_insurance: 'Private medical insurance',
          not_proceeding_details_reason: 'Reason for not proceeding',
        },
        protection: {
          heading: 'Health and Protection',
          whyWasYourProductRecommendation: 'Why was your product recommendation the most appropriate possible advice?',
          ifThereWereAnyShortfallsRemaining:
            'If there are any shortfalls remaining after your recommendation, please justify them here',
          additionalProtectionNotes: 'Additional Health and Protection notes',
          someOfTheRecommendedProtectionPolicies: `Some of the recommended Health and Protection policies exceed the client's expected retirement date. They must confirm that their premiums will still be affordable`,
          trusts: 'Trusts',
          doYouWantToRecommendPlacingAnyPoliciesInTrust: 'Do you want to recommend placing any policies in trust?',
          doYouHaveASpecificReferralOrRecommendation: 'Do you have a specific referral or recommendation?',
          giveDetailsAndJustification: 'Give details and justification',
          useThisSectionToJustify:
            'Use this section to justify relevant criteria, e.g., why was a certain term length suitable, why was dual deferment recommended, why was a joint policy recommended over two single policies etc.',
          useThisSectionToAddAnyAdditional:
            'Use this section to add any additional information you want to declare, e.g., referring the client to a tax specialist, or highlighting important features of policies which the client expressed uncertainty over.',
        },
        review: {
          heading: 'Review',
          body: `Clicking the button below will generate a PDF version of the Reasons Why Letter and save it to the Documents section of this case. Please ensure you've captured all the details correctly before generating.`,
          button: 'Generate Reasons Why Letter',
        },
        suitabilityNotAvailable: 'Suitability not available yet',
        mortgageSuitabilityNotAvailable: 'Mortgage suitability not available yet',
        recommendMortgageProduct: 'You need to recommend a mortgage product',
        protectionSuitabilityNotAvailable: 'Protection suitability not available yet',
        recommendProtectionProduct: 'You need to recommend a protection product',
        tasksRequiredEdit: 'The following tasks need to be completed before you can edit a suitability report:',
        completePropertyDetails: 'Complete property details',
        completeCaseDetails: 'Complete case details',
        completeMortgageDetails: 'Complete mortgage details',
        recommendShortlistedMortgage: 'Recommend a shortlisted mortgage',
        viewTasksCaseOverview: 'View tasks on case overview',
        cannotEditLeadCase: `The suitability report can't be edited on lead status cases`,
        cannotEditCompleteCase: `The suitability report can't be edited on complete status cases`,
        caseOwnerPublishOnly: 'Only the case owner can publish a Suitability Report',
        hasActiveProtectionSr: 'The case already has an active Protection Suitability Report',
        mustHaveActiveSrToPublishPsr:
          'You must have a published mortgage suitability report to publish your protection suitability report',
        mustBePreRec: 'The case must be at pre-recommendation to publish a Suitability Report',
        cannotBePublished: `Suitability can't be published`,
        cannotPublishCaseStatus: 'Cannot publish Protection Suitability Report on a {status} case',
        hasActiveSr: 'The case already has an active Suitability Report',
        verificationsModal: {
          title: 'You need to complete outstanding tasks first',
          completeTasks:
            'These are tasks that need to be completed on this case before you can publish a suitability report:',
          tasks: 'Tasks',
        },
      },
      compliance: {
        title: 'Cases compliance',
        table: {
          header: {
            caseType: 'Case type',
            clients: 'Clients',
            flagsOnCase: 'Flags on case',
            caseOwner: 'Case owner',
            organisation: 'Organisation',
            caseCreated: 'Case created',
          },
        },
        flags: {
          flags: '{number} Flags',
          invalid_flag_type: 'Invalid flag',
          flag_aml: 'AML',
          flag_debt_consolidation: 'Debt Consolidation',
          flag_equity_release: 'Equity Release',
          flag_financial_crime: 'Financial Crime',
          flag_gifted_deposit: 'Gifted Deposit',
          flag_high_commission: 'High Commission',
          flag_lending_into_retirement: 'Lending Into Retirement',
          flag_nationality: 'Nationality',
          flag_pep: 'PEP',
          flag_provisional_competency: 'Provisional Competency',
          flag_redemption_penalty: 'Redemption Penalty',
          flag_residential_interest_only: 'Residential Interest Only',
          flag_right_to_buy: 'Right To Buy',
          flag_second_job: '2nd Job',
          flag_vulnerable_client: 'Vulnerable Client',
          flag_vulnerable: 'Vulnerable',
          flag_protection_provisional_competency: 'Health and Protection Provisional Competency',
          flag_manual_review: 'Manual Review',
          flag_third_party_review: 'Third Party Review',
          flag_automated_sample: 'Automated Sample',
        },
        loadFail: 'Could not load cases. Please wait a moment and reload the page.',
        shareCase: {
          title: 'Share case',
          content:
            'Would you like to share this case with {complianceOrgName}? Once you have shared, simply send the case URL below to {complianceOrgName} for them to review the case.',
          shareUrl: 'https://crm.myac.re/cases/{caseId}/overview',
        },
      },
      initialDisclosure: {
        heading: 'Verbal disclosure document',
        confirmationHeading: 'Client confirmation',
        confirmationCopy:
          'I confirm that the following clients have heard, understood and accepted this initial disclosure',
      },
      mortgageDetails: {
        title: 'Case details',
        titleNew: 'Case details New',
        footer: {
          save: 'Save changes',
          cancel: 'Cancel',
          pristine: 'No change',
        },
        sections: {
          requirements: {
            caseInfo: 'Case info',
            caseSource: 'Case source',
            needsAttention: 'Needs attention',
            cannotProgressCaseWithoutVerification: `Unless you have seen your clients face to face or had them perform an ID check, you won’t be able to progress
        the case`,
            goToIdVerificationPage: 'Go to ID Verification page',
            sendImpersonationCheck:
              'If you have not seen all clients face to face, you can send them an impersonation check from the ID Verification page.',
            table: {
              total: 'Total borrowing requirement: ',
            },
            title: 'Requirements',
            titleNew: 'Loan requirements',
            fields: {
              productType: 'Product type',
              preferenceTerm: 'Term',
              isIntroducedClient: 'Introduced client?',
              introducerOrganisation: 'Introducer organisation',
              introducerContacts: 'Introducer contact',
              hasLenderProposedNewProducts: 'Has lender proposed new product?',
              hasClientPreviouslyOwnedProperty: 'Has client previously owned property?',
              hasSeenFaceToFace: 'Have you seen all clients on the case face to face?',
              impersonationCheckPerformed:
                'Have you performed an impersonation check for the clients on the case that you have not seen face to face?',
              impersonationCheckDetails: 'Impersonation check details',
              type: {
                label: 'Product type',
              },
              term: {
                label: 'Term',
              },
              loanAmount: {
                label: 'Loan amount for purchase',
              },
              hasClientAcceptedProtectionAdvice: {
                label: 'Do clients want to receive Health and Protection advice?',
              },
              hasPreviouslyOwnedProperty: {
                label: 'Has client previously owned property?',
              },
              hasLenderProposedNewProduct: {
                label: 'Has lender proposed new product?',
              },
              isIntroduced: {
                label: 'Introduced client?',
              },
              introducerDetails: {
                label: 'Introducer notes',
              },
              introducerOrg: {
                label: 'Introducer organisation',
              },
              introducerContact: {
                label: 'Introducer contact',
              },
            },
          },
          propertyBeingSold: {
            title: 'Property being sold & equity',
            add: '+ Add equity usage',
            noProperty: 'No property to be sold on case yet',
            equityAvailableForPurchase: 'Equity available for purchase',
            addProperty: 'Add property',
            propertyValue: 'Property value',
            totalEquity: 'Total equity',
            totalEquityUsage: 'Total equity usage',
            propertyCard: {
              title: 'Property',
            },
          },
          propertyBeingPurchased: {
            title: 'Property being purchased',
            noProperty: 'No property to be bought on case yet',
            noPropertyRemortgaged: 'No property to be remortgaged on case yet',
            fields: {
              propertyPrice: {
                label: 'Property price',
              },
              propertyValuation: {
                label: 'Property valuation',
              },
              sharedOwnershipShare: {
                label: 'Shared ownership share',
              },
              helpToBuyEquityLoan: {
                label: 'Help to buy equity loan',
              },
              sharedEquity: {
                label: 'Shared equity',
              },
            },
            propertyCard: {
              title: 'Property',
            },
            goToProperties: 'Go to properties',
          },
          propertyBeingRemortgaged: {
            title: 'Property being remortgaged',
          },
          loanPurposes: {
            title: 'Loan purposes',
            table: {
              amount: 'Amount',
              purpose: 'Purpose',
              total: 'Total loan amount: ',
            },
            add: '+ Add additional borrowing',
            fields: {
              type: {
                label: 'Product Type',
              },
            },
          },
          buyToLetDetails: {
            title: 'Buy to let details',
            companyAddedMessage: {
              text: 'Company has been added to ',
              link: 'clients section',
            },
            fields: {
              btlThroughCompany: {
                label: 'Is this a limited company buy to let?',
              },
              btlCompany: {
                label: 'Company name',
                placeholder: 'Search Companies House',
              },
            },
          },
          deposits: {
            title: 'Deposits / Inducements',
            titleNew: 'Deposits / Down payments',
            deposit: 'Deposit',
            total: 'Total: ',
            depositCardTitle: 'Deposit {index} {incomplete}- {amount}',
            add: '+ Add a deposit',
            addDeposit: 'Add deposit',
            editDeposit: 'Edit deposit',
            source: 'Source',
            donor: 'Donor',
            client: 'Client',
            type: 'Type',
            amount: 'Amount',
            description: 'Description',
            relationship: 'Relationship',
            table: {
              total: 'Total deposits',
            },
            unlinkedDepositsText:
              'We found some existing deposits that were previously created. You can select the ones you want to link to this case.',
            tooltipText:
              'Deposits cannot be added to a case with a published suitability report. Please roll case back to Pre-Recommendation to add a new deposit.',
            types: {
              CASH_SAVINGS: 'Cash savings',
              INVESTMENT: 'Investment',
              GIFT: 'Gift',
              OTHER: 'Other',
              VENDOR_INDUCEMENT: 'Vendor inducement',
            },
            donors: {
              giftDonor: '{num} gift donor',
              firstName: 'Donor first name',
              surname: 'Donor surname',
              relationshipToClient: 'Relationship to client',
            },
          },
          recommendedMortgages: {
            title: 'Recommended mortgages',
            goToProducts: 'Go to products page',
            mortgageAmount: 'Mortgage amount',
            productName: 'Product name',
            status: 'Status',
            totalMortgageAmount: 'Total recommended mortgage amount',
            noRecommendationTitle: 'No recommendations yet',
            noRecommendationDesc: 'Once you have sourced and made a recommendation it will show here.',
            unknownProvider: 'Unknown provider',
          },
        },
      },
      sidebarInfo: {
        advisor: 'Advisor',
        address: 'Address',
        purchasePrice: 'Purchase price',
        valuation: 'Valuation',
        loanAmount: 'Loan amount',
        term: 'Term',
        snapshotInfo: 'Show snapshot info',
        unassigned: 'Unassigned',
        years: 'years',
      },
      reviewMode: {
        reviewModeOn: 'Review mode on',
        reviewModeOff: 'Review mode off',
        protectedFieldDisabledTooltip: 'You do not have permission to show protected fields',
        tasks: 'Tasks',
        pageNotes: {
          pageNotes: 'Page notes',
          saveNoteToPage: 'Save note to page',
          seeAllNotes: 'See all notes ({length})',
          seeLessNotes: 'See less notes ({length})',
          noPageNotes: 'There are no notes on this page',
          typeANote: 'Type a note',
        },
        subnav: {
          explanations: 'Explanations',
          factFind: 'Fact find',
          idd: 'IDD',
          suitability: 'Suitability',
          esis: 'ESIS',
          verifications: 'Verifications',
          verificationsNavDisabled: 'There are no verifications on lead status cases',
          idVerification: 'ID Verification',
          preferences: 'Preferences',
          recommendation: 'Recommendation',
          documentRequirements: 'Document requirements',
        },
        preferences: {
          mortgagePreferences: 'Mortgage preferences',
          paymentOptions: 'Payment options',
          incentives: 'Incentives',
          requirements: 'Requirements',
          paymentOptionsQuestion: 'Which payment options are important to the client?',
          incentivesQuestion: 'Which incentives are important to the client?',
          repaymentMethod: 'Repayment method',
          term: 'Term',
          ratePreference: 'Rate preference',
          fixedRateLength: 'Fixed rate length',
          capitalRepaymentAmount: 'Capital repayment amount',
          repaymentStrategy: 'Repayment strategy for interest only mortgage',
          noErcs: 'No ERCs',
          noErcsAfterInitialPeriod: 'No ERCs after initial period',
          underpaymentsAllowed: 'Underpayments allowed',
          addProductFeestoLoan: 'Add product fees to loan',
          overPaymentsAllowed: 'Over-payments allowed',
          repaymentHolidays: 'Repayment holidays',
          reduceInterest: 'Reduce interest via a linked account (offset)',
          cashback: 'Cashback',
          freeLegals: 'Free legals',
          freeValuation: 'Free valuation',
          speedOfCompletion: 'Speed of completion',
          rateType: {
            fixed: 'Fixed',
            discount: 'Discount',
            tracker: 'Tracker',
          },
        },
        recommendation: {
          viewEsis: 'View ESIS',
          acreEOR: 'Acre evidence of research',
          externalEOR: 'External evidence of research',
        },
        factFindNavItems: {
          caseDetails: 'Case details',
          client: 'Clients',
          credit: 'Credit',
          income: 'Income',
          expenditure: 'Expenditure',
          property: 'Properties',
          mortgage: 'Mortgage',
        },
        idVerifications: {
          idChecks: 'ID checks',
          reviewIdReport: 'Review ID report',
          documentAndImpersonationChecks: 'Document and impersonation checks',
          lastCheckRunOn: 'Last check run on',
          identityCheck: 'Identity check',
          pepAml: 'PEP/AML',
          eidvDate: '{completed} - valid until {expiry}',
          documentCheck: 'Document check',
          livePhotoCheck: 'Live photo check',
          kbaCheck: 'Knowledge-based authentication',
        },
        explanations: {
          suitability_report_recommendation_introduction: 'Introduction',
          recommendation_reason_for_equity_disparity:
            'Reason all equity from sale is not being used for purchase of new property',
          preference_details: 'Reason for these preferences',
          recommendation_reason_strategy_for_btl_void_period_coverage:
            'Reason repayments can be maintained if the property is not let for a period',
          recommendation_reason_for_affordability: 'Reason for affordability of any future monthly repayment increases',
          recommendation_term_reason: 'Reason for recommended mortgage term',
          recommendation_reason_term_into_retirement: 'Reason for affordability when lending into retirement',
          recommendation_reason_for_recommendation_term_increase:
            'Reason for a recommendation with a longer term than the original mortgage',
          recommendation_reason_for_recommendation: 'Reason for recommendation',
          recommendation_reason_for_remortgage_cost_increase:
            'Reason for a recommendation with a higher true cost over initial term',
          recommendation_reason_additional_borrowing: 'Reason for additional borrowing',
          reason_for_further_advance: 'Reason for a further advance over a remortgage ',
          recommendation_reason_retirement_interest_only:
            'Reason an interest only mortgage is suitable post retirement',
          debt_consolidation_reason_for_consolidated_debts: 'Reason for debt consolidation',
          recommendation_reason_help_to_buy_loan_repayment_strategy: 'Reason for help to buy loan repayment strategy',
          reason_for_recommendation_product_type: 'Reason for recommendation product type',
          reason_for_recommendation_initial_term: 'Reason for recommendation initial term',
        },
        verifications: {
          heading: 'Verifications',
          unknownCollection: 'Unknown collection',
          completed: 'Completed',
          outstanding: 'Outstanding',
        },
        versionHeader: {
          heading: 'Review mode',
          status: 'Status: {status}',
          documentStatus: 'Document status: {status}',
          user: 'User: {userName}',
          transitionedAt: 'Transitioned at: {dateTime}',
          created: 'Created: {dateTime}',
          loading: 'Loading...',
        },
        versionHistory: {
          caseHistory: 'Case history',
          version: 'Version {version}',
          transitionedOn: 'Transitioned on {date}',
        },
        documentHistory: {
          sidebarTitle: 'Document version history',
          sidebarSubtitle: 'Click a version to view it',
          signed: 'Signed',
          notSigned: 'Not signed',
          archived: 'Archived',
          invalid: 'Invalid',
          noDocumentsForType: 'No {type} documents have been generated for the case to date',
          IDD: 'IDD',
          ESIS: 'ESIS',
          SUITABILITY_REPORT: 'Suitability Report',
        },
        relatedCases: {
          title: 'Related cases ({count})',
          advisor: 'Advisor',
          linkedCase: 'Linked case',
          linkedProtectionCase: 'Linked Health and Protection case',
          linkedRemortgageLead: 'Linked remortgage case',
          noRelatedCases: 'There are no related cases',
        },
        caseDetails: {
          heading: 'Case details',
          productType: 'Product type',
          term: 'Term',
          receiveProtectionAdvice: 'Do clients want to receive Health and Protection advice?',
          introducedClient: 'Introduced client?',
          introducerCompany: 'Introducer company',
          introducerContact: 'Introducer contact',
          preferenceLoanAmount: 'Loan amount for purchase',
          outstandingBalance: 'Outstanding balance',
          additionalLoan: 'Additional loan',
          totalAdditionalBorrowing: 'Total additional borrowing',
          totalRequiredLoan: 'Total required loan',
          loanPurpose: 'Loan purpose',
          requirements: 'Requirements',
          loans: 'Loans',
          client: 'Client',
          amount: 'Amount',
          deposit: 'Deposit',
          type: 'Type',
        },
        clientDetails: {
          heading: 'Client information',
          personalInfo: 'Personal information',
          gender: 'Gender',
          maritalStatus: 'Marital status',
          estatePlanning: 'Estate planning',
          nameChange: 'Name change',
          countryOfBirth: 'Country of birth',
          countriesOfCitizenship: 'Countries of citizenship',
          retirementAge: 'Expected retirement age',
          nationalInsurance: 'National insurance number',
          highRateTaxPayer: 'High rate taxpayer?',
          vulnerable: 'Is this a potentially vulnerable customer?',
          vulnerableReasons: 'Select the reason(s) your client may be potentially vulnerable:',
          additionalVulnerabilityInfo: 'Additional vulnerability information:',
          hasWill: 'Does the client have a will?',
          hasWillReviewed: 'Has the client reviewed their will in the last five years?',
          emailAddress: 'Email address',
          homePhone: 'Home phone',
          mobilePhone: 'Mobile phone',
          workPhone: 'Work phone',
          previousFirstName: 'Previous first name',
          previousSurname: 'Previous surname',
          dateOfNameChange: 'Date of name change',
          reasonForNameChange: 'Reason for name change',
          relationship: 'Relationship',
          dependantUntil: 'Dependant until',
          dependant: 'Dependant',
          address: 'Address',
          moveInDate: 'Move in date',
          moveOutDate: 'Move out date',
          status: 'Status',
          currentCorrespondence: 'Is current correspondence?',
          bankAccount: 'Bank account for mortgage payments',
          accountType: 'Type of account',
          accountNumber: 'Account number',
          sortCode: 'Sort code',
          timeWithBank: 'Time with bank',
          maritalStatusMap: {
            civilPartnership: 'Civil partnership',
            cohabit: 'Cohabit',
            divorced: 'Divorced',
            married: 'Married',
            single: 'Single',
            seperated: 'Separated',
            widowed: 'Widowed',
          },
          residentialStatusMap: {
            correspondenceOnly: 'Correspondence only',
            livingWithFamily: 'Living with family',
            ownerOccupier: 'Owner/Occupier',
            tenant: 'Tenant',
          },
          bankAccountTypeMap: {
            current: 'Current',
            savings: 'Savings',
          },
          dependantRelationshipMap: {
            child: 'Child',
            parent: 'Parent',
            spouse: 'Spouse',
            other: 'Other',
            sibling: 'Sibling',
          },
          vulnerableReasonsMap: {
            addiction: 'Addiction',
            age: 'Age',
            carer: 'Carer',
            crime: 'Victim of crime',
            domesticAbuse: 'Domestic abuse',
            foreignLanguage: 'Speaks foreign language',
            hearingVisualImpairment: 'Hearing or visual impairment',
            indebtedness: 'Indebtedness',
            longTermIllness: 'Long-term illness',
            lowIncome: 'Low income',
            majorLifeChange: 'Major life change',
            mentalCapacity: 'Mental capacity',
            mentalHealth: 'Mental health',
            other: 'Other',
            skills: 'Digital, financial or other life skills limitation',
            stress: 'Stress',
          },
        },
        credit: {
          heading: 'Credit information',
          creditCard: 'Credit card',
          overdraft: 'Overdraft',
          storeCard: 'Store card',
          loan: 'Loan',
          hiredPurchase: 'Hired purchase',
          securedExchange: 'Secured exchange',
          utilities: 'Utilities',
          communications: 'Communications',
          mailOrder: 'Mail order',
          payDay: 'Pay day',
          mortgage: 'Mortgage',
          status: 'Status',
          paymentStatus: 'Payment status',
          arrears: 'Arrears',
          defaulted: 'Defaulted',
          pastArrears: 'Past arrears',
          upToDate: 'Up to date',
          amountOutstanding: 'Amount outstanding',
          interestRate: 'Interest rate',
          monthlyRepaymentAmount: 'Monthly repayment amount',
          creditorName: 'Creditor name',
          accountNumber: 'Account number',
          startDate: 'Start date',
          endDate: 'End date',
          finalRepaymentDate: 'Final repayment date',
          paymentFrequency: 'Payment frequency',
          term: 'Original term',
          repaidEachMonth: 'Balance repaid each month?',
          consolidating: 'Consolidating',
          repaidBeforeMortgageCommences: 'Repaid before mortgage starts?',
          open: 'Open',
          settled: 'Settled',
          annual: 'Annual',
          monthly: 'Monthly',
          once: 'Once',
          quarterly: 'Quarterly',
          weekly: 'Weekly',
          hirePurchaseAgreement: 'Hire purchase agreement',
          unsecuredLoan: 'Unsecured loan',
          amount: 'Amount',
          dateRegistered: 'Date registered',
          dateSatisfied: 'Date satisfied',
          dateCompleted: 'Date completed',
          monthlyPaymentAmount: 'Monthly payment amount',
          paidOnTime: 'Paid on time?',
          bankruptcyDate: 'Bankruptcy date',
          courtRegistered: 'Court registered',
          dischargeDate: 'Discharge date',
          refusedDate: 'Date refused',
          refusedDetails: 'Details',
          refusedType: 'Type',
          countyCourtJudgement: 'County court judgement',
          debtManagementPlan: 'Debt management plan',
          individualVoluntaryArrangements: 'Individual voluntary arrangements',
          bankruptcy: 'Bankruptcy',
          applicationRefusal: 'Credit refusal',
          propertyRepossession: 'Property repossession',
          hasClientHadPropertySurrenderedOrRepossessed:
            'Has the client ever had a property surrendered or repossessed?',
          hasClientHadPropertySurrenderedOrRepossessedDetails: 'Details',
        },
        expenditureDetails: {
          heading: 'Expenditure',
          discretionaryExpenditure: 'Discretionary expenditure',
          committedExpenditure: 'Committed expenditure',
          agreedBudgets: 'Agreed budgets',
          mortgageRent: 'Other mortgage / Rent',
          maintenance: 'Spousal / Child maintenance',
          fuel: 'Electricity / Gas / Oil',
          water: 'Water',
          communications: 'Communications',
          television: 'Television',
          councilTax: 'Council tax',
          carCosts: 'Car costs',
          otherTransportCosts: 'Other transport costs',
          groundRentServiceCharge: 'Service charge / Ground rent',
          food: 'Food',
          clothing: 'Clothing',
          householdRepairs: 'Household maintenance',
          entertainment: 'Entertainment',
          childCare: 'Child care',
          holidays: 'Holidays',
          pension: 'Pension contributions',
          investments: 'Investments',
          insurance: 'Insurances',
          agreedBudget: 'Agreed monthly mortgage budget',
          agreedBudgetProtection: 'Agreed monthly protection budget',
          materialChange:
            'Does the client expect any changes in their circumstances that might affect their outgoings?',
        },
        esisGrid: {
          heading: 'ESIS documents',
          lender: 'Lender',
          productName: 'Product name',
          productStatus: 'Product status',
          esisStatus: 'ESIS status',
          sourced: 'Sourced',
          recommended: 'Recommended',
          lenderProposed: 'Lender proposed',
        },
        incomeDetails: {
          heading: 'Income',
          employment: 'Employment',
          selfEmployment: 'Self employment',
          retirement: 'Retirement',
          notInEmployment: 'Not in employment',
          additionalIncome: 'Additional Income',
          netAmount: 'Net amount',
          frequency: 'Frequency',
          incomeType: 'Income type',
          fullTime: 'Full time',
          partTime: 'Part time',
          fixedTerm: 'Fixed term',
          permanent: 'Permanent',
          employmentBasis: 'Employment basis',
          contractType: 'Contract type',
          jobTitle: 'Job title',
          specificJobTitle: 'Specific job title',
          startDate: 'Start date',
          endDate: 'End date',
          roleEndDate: 'Role end date',
          currentEmployment: 'Current employment',
          incomeLikelyToReduce: 'Income likely to reduce in the future?',
          incomeLikelyToReduceDescription: 'Reason for reduction in income',
          payRiseExpected: 'Pay rise expected in the future?',
          payRiseExpectedDetails: 'Details of increase',
          payRiseNewSalary: 'New income amount',
          payRiseDueMonths: 'How many months until this change occurs?',
          employerName: 'Employer name',
          employerWorkPhone: 'Employer work phone',
          employerMobilePhone: 'Employer mobile phone',
          employerEmail: 'Employer email',
          employerAddress: 'Employer address',
          totalGuaranteedSalary: 'Total guaranteed salary (annual)',
          totalAdditionalSalary: 'Total additional salary (annual)',
          netMonthlyIncome: 'Net monthly income',
          companyType: 'Company type',
          businessName: 'Business name',
          clientEmployedForTaxPurposes: 'Is client employed for tax purposes?',
          businessAddress: 'Business address',
          numberOfYearsAccountsAvailable: 'Number of years accounts available',
          retirementDate: 'Retirement date',
          pensionType: 'Pension type',
          providerName: 'Provider name',
          grossAnnualPensionIncome: 'Gross annual pension income',
          selfEmploymentBasis: {
            llp: 'LLP',
            ltd: 'Limited company',
            partnership: 'Partnership',
            psc: 'PSC',
            soleTrader: 'Sole trader',
          },
          pensionTypeMap: {
            privateRetirement: 'Private retirement',
            stateRetirement: 'State retirement',
            industrialInjuries: 'Industrial injuries disablement',
            warDisability: 'War disability',
            widows: 'Widows',
            other: 'Other',
          },
          taxableIncomeLastYear: 'Taxable income last year',
          taxableIncomeTwoYearsAgo: 'Taxable income two years ago',
          taxableIncomeThreeYearsAgo: 'Taxable income three years ago',
          percentageShareLastYear: 'Percentage share last year',
          percentageShareTwoYearsAgo: 'Percentage share two years ago',
          percentageShareThreeYearsAgo: 'Percentage share three years ago',
          netProfitsLastYear: 'Net profits last year',
          netProfitsTwoYearsAgo: 'Net profits two years ago',
          netProfitsThreeYearsAgo: 'Net profits three years ago',
          salaryIncomeLastYear: 'Salary income last year',
          salaryIncomeTwoYearsAgo: 'Salary income two years ago',
          salaryIncomeThreeYearsAgo: 'Salary income three years ago',
          dividendIncomeLastYear: 'Dividend income last year',
          dividendIncomeTwoYearsAgo: 'Dividend income two years ago',
          dividendIncomeThreeYearsAgo: 'Dividend income three years ago',
          hasAccountant: 'Does client have an accountant?',
          accountantName: 'Accountant name',
          accountantEmail: 'Accountant email',
          accountantWorkPhone: 'Accountant work phone',
          accountantAddress: 'Accountant address',
        },
        nonNaturalClientDetails: {
          companyInfo: 'Company information',
          relatedIndividual: 'Related individual',
          bankAccount: 'Bank account for mortgage payments',
          companyName: 'Company name',
          organisationType: 'Organisation type',
          organisationSic: 'Organisation SIC',
          companyRegNo: 'Company Reg. no.',
          companyTelephone: 'Company telephone',
          companyEmail: 'Company email',
          role: 'Role',
          address: 'Address',
          accounts: {
            accountType: 'Type of account',
            accountNumber: 'Account number',
            sortCode: 'Sort code',
            timeWithBank: 'Time with bank',
          },
        },
        property: {
          property: 'Property',
          propertyToPurchase: 'Property - To purchase',
          propertyToSell: 'Property - To sell',
          propertyToRemortgage: 'Property - To remortgage',
          propertySecurityAddress: 'Property - Security address',
          propertyDetails: 'Property details',
          address: 'Address',
          propertyLocation: 'Property location',
          epcRating: 'EPC rating',
          yearBuilt: 'Year built',
          propertyType: 'Property type',
          propertySubType: 'Sub type',
          tenure: 'Tenure',
          flyingFreeholdPercentage: 'Flying freehold percentage',
          yearsLeftOnLease: 'Years remaining on lease',
          leaseholdServiceCharge: 'Annual service charge',
          leaseholdGroundRent: 'Annual ground rent',
          numberOfBedrooms: 'Number of bedrooms',
          numberOfBathrooms: 'Number of bathrooms',
          numberOfReceptionRooms: 'Number of reception rooms',
          numberOfKitchens: 'Number of kitchens',
          parkingType: 'Parking type',
          wallType: 'Wall type',
          roofType: 'Roof type',
          deckAccess: 'Does the flat have balcony access?',
          propertyConverted: 'Has the property been converted in the last ten years?',
          exLocalAuthority: 'Is property ex-local authority?',
          hasAnnex: 'Does the property have an annex?',
          solarPanels: 'Does the property have solar panels?',
          overRetail: 'Is the property above commercial premises?',
          clearFloorBetweenRetail: 'Is there a clear floor between the property and retail unit?',
          numberFloorsInBuilding: 'How many storeys does the building have?',
          floorOfFlat: 'What floor is the property on?',
          hasALift: 'Is there a lift in the property?',
          financials: 'Financials',
          purchasePrice: 'Purchase price',
          propertyValuation: 'Property valuation',
          isSharedOwnership: 'Is the property a part of an affordable home ownership scheme?',
          isUnencumbered: 'Does property have attached mortgage(s)?',
          sharedOwnershipType: 'Scheme type',
          sharedOwnershipShare: 'Share of the property the client will own',
          sharedOwnershipMonthlyRent: 'Shared ownership monthly rent',
          sharedOwnershipSchemeProvider: 'Shared ownership scheme provider',
          helpToBuyLoanAmount: 'Help to Buy loan amount',
          sharedEquityRepaymentDetails: 'Equity loan repayment terms',
          rightToBuyValuation: 'Right to buy valuation',
          propertyUse: 'Property use',
          numberFlatsInBuilding: 'Number of units',
          intendToLetOrRunBusiness: 'Do clients intend to let or run a business from the property?',
          intendToLetOrRunBusinessDescription: 'Description',
          isPropertyHabitable: 'Is the property habitable?',
          isWorkPlanned: 'Is work planned on the property?',
          typeOfWorkPlanned: 'Work planned on the property',
          grossDevelopmentValue: 'Gross development value',
          costOfWorks: 'Cost of works',
          monthlyManagementExpenses: 'Monthly management expenses',
          tenantType: 'Target tenant type',
          tenancyType: 'Target tenancy type',
          isPropertyFurnished: 'Will the property be let furnished?',
          isHouseOfMultipleOccupancy: 'Is property House of Multiple Occupancy (HMO)?',
          numberOfLettableRooms: 'Number of lettable rooms',
          isMultiUnitFreeholdBlock: 'Is property Multi Unit Freehold Block (MUFB)?',
          monthlyRentalIncome: 'Anticipated rental income per month',
          btlHasLived: 'Have the client(s) previously lived in the property?',
          btlWillLive: 'Do client(s) or a relative plan to live in the property?',
          btlIsOperatingBusiness: 'Is the property being let by way of business?',
          registeredOwnersDetails: 'Registered property owner(s)',
          targetRegisteredOwnersDetails: 'New property owner(s)',
        },
        mortgage: {
          mortgage: 'Mortgage',
          mortgageContinuing: 'Mortgage - Continuing product',
          mortgageDetails: 'Mortgage details',
          productCode: 'Product code',
          applicationDate: 'Application date',
          startDate: 'Start date',
          outstandingBalanceDate: 'Outstanding balance date',
          mortgageAmount: 'Mortgage amount',
          monthlyPayment: 'Monthly payment',
          repaymentType: 'Repayment type',
          interestOnlyAmount: 'Interest only amount',
          revertMonthlyPayment: 'Revert monthly payment',
          mortgageTerm: 'Mortgage term',
          remainingTerm: 'Remaining term',
          isSecondCharge: 'Is mortgage second charge?',
          productDetails: 'Product details',
          lenderName: 'Lender name',
          productName: 'Product name',
          accountNumber: 'Mortgage account number',
          portable: 'Portable',
          erc: 'ERC',
          earlyRepaymentCharge: 'Early repayment charge',
          incentivesCashback: 'Cashback incentive',
          endDate: 'End date',
          fees: 'Fees',
          feeType: 'Type',
          feeAmount: 'Amount',
          feeAddedToLoan: 'Added to loan',
          feeTiming: 'Timing',
          productRates: 'Product rates',
          currentRate: 'Current rate',
          currentRateEndDate: 'Current rate end date',
          followedByRate: 'Followed by',
          rate: 'Rate',
          interestRateType: 'Interest rate type',
          arrears: 'Arrears',
          hasArrears: 'Has this mortgage ever been in arrears?',
          maxArrears48: 'Max number of arrears in the last 48 months',
          maxArrears36: 'Max number of arrears in the last 36 months',
          maxArrears24: 'Max number of arrears in the last 24 months',
          maxArrears12: 'Max number of arrears in the last 12 months',
          maxArrears6: 'Max number of arrears in the last 6 months',
          maxArrears3: 'Max number of arrears in the last 3 months',
          dateArrearsCleared: 'Date last arrears cleared',
          currentArrearsBalance: 'Current arrears balance',
          providerApplicationReference: 'Provider application reference',
          valuationType: 'Valuation type',
          maxLtvAvailable: 'Max LTV available',
          paymentRoute: 'Payment route',
          grossProcFeePercentage: 'Gross Proc fee percentage',
          grossProcFeeFixed: 'Gross Proc fee fixed',
          netProcFeePercentage: 'Net Proc fee percentage',
          netProcFeeFixed: 'Net Proc fee fixed',
          continuingProducts: 'Continuing product',
          repaymentTypes: {
            interestOnly: 'Interest only',
            partAndPart: 'Part and part',
            repayment: 'Repayment',
          },
          canBeAddedToLoanTypes: {
            mustBeAdded: 'Must be added',
            optionToBeAdded: 'Option to be added',
            paySeparately: 'Pay separately',
          },
          feeTimingTypes: {
            completionTiming: 'On completion',
            earlyRedemption: 'On early redemption',
            notSpecified: 'Not specified',
            redemption: 'On redemption',
            upFront: 'Up front',
          },
          interestRateTypes: {
            fixed: 'Fixed',
            capped: 'Capped',
            discount: 'Discount',
            libor: 'LIBOR',
            stepped: 'Stepped',
            tracker: 'Tracker',
            variable: 'Variable',
          },
          valuationTypes: {
            automatedValuationModel: 'Automated valuation model',
            desktopValuation: 'Desktop valuation',
            driveByValuation: 'Drive by valuation',
            fullBuildingSurvey: 'Full building survey',
            homeReport: 'Home report',
            homebuyersReport: 'Homebuyers report',
            noValuation: 'No valuation',
            standardValuation: 'Standard valuation',
          },
        },
      },
    },

    property: {
      addProperty: 'Add property',
      address: 'Address',
      financials: 'Financials',
      commitments: 'Commitments',
      mortgageDebt: 'Mortgage debt',
      mortgagePayment: 'Mortgage payment',
      portfolio: 'Portfolio',
      portfolioProperty: 'Portfolio property',
      purchasePrice: 'Purchase price',
      valuation: 'Valuation',
      propertyType: 'Property type',
      backToProperties: 'Back to properties',
      properties: 'Properties',
      propertiesN: 'Properties ({n})',
      property: 'Property',
      propertyDetails: 'Property details',
      propertyToBuy: 'Property to buy',
      propertyToPurchase: 'Property to purchase',
      propertyToRemortgage: 'Property to remortgage',
      propertyToSell: 'Property to sell',
      propertyToInsure: 'Property to insure',
      viewPropertyReport: 'View property report',
      securityProperty: 'Security property',
      propertyUse: 'Property use',
      propertyValue: 'Property value',
      rentalAmount: 'Rental amount',
      returnToProperties: 'Return to properties',
      saveAndReturnToProperties: 'Save changes',
      toBuy: 'To buy',
      toPurchase: 'To purchase',
      toRemortgage: 'To remortgage',
      toSell: 'To sell',
      other: 'Other',
      securityAddress: 'Security address',
      total: 'Total',
      directOwnership: 'Direct Ownership',
      companyOwnership: 'Company Ownership',
      registeredOwner: 'Registered Owner',
      typeOfOwnership: 'Type of ownership',
      actions: {
        tooltips: {
          info: 'See a full property report with details on land registration, property prices, flooding, and mining. You can also buy a copy of the title report.',
          delete: 'Remove this property from the case',
          edit: 'Edit this property',
          reminder: 'Add reminder',
        },
      },
    },
    gi_refer: {
      referTitle: 'Refer and Earn with Uinsure',
      blurb0:
        'Acre has partnered with Uinsure to help you fulfil your Home Insurance advice obligations to clients by referral, giving you the benefit of a great commission without the work of selling insurance yourself.',
      blurb1: `Uinsure's industry leading technology means you can now refer clients directly or offer the choice of self-fulfilment via an application process that takes around 60 seconds.`,
      whyPick: {
        title: 'Why refer to Uinsure?',
        a: 'Generate commission with all self-fulfilment applications',
        b: 'Meet customers where they buy today, digitally',
        c: `Uinsure's platform is easier, quicker and more convenient vs price comparison sites`,
        d: 'Applications take around a minute; no tough questions',
        e: 'Smart communications means your client is offered home insurance at the right time',
        f: 'Protect wider revenue streams by not sending clients and their data to comparison sites',
        g: 'Expert customer care for your client at every step of their journey',
      },
      referralBonus: 'Your referral bonus',
      if: '(if your customer takes out insurance)',
      blurb2:
        'And with Defaqto Five Star rated coverage, you can rest assured that your clients will be receiving the cover they need at a great price.',
      decline: 'My customer does not want advice',
      declined: 'The customer does not wish to be referred to Uinsure',
      history: {
        date: 'Date',
        message: 'Message',
        status: 'Status',
      },
      reassure:
        'The case has been referred to Uinsure as requested. You can check back to view the status here at any time.',
      referred: 'Referral status',
      refuse: 'Cancel referral',
      notAvailable: 'Home insurance is not available for this case.',
      callTimeSelector: {
        today: 'Today',
        callAsap: 'Call as soon as possible',
        call: 'Call',
        pick: 'Pick a time to call the client',
        save: 'Save',
        close: 'Close',
      },
    },
    conveyancing: {
      title: 'Conveyancing',
      referTitle: 'Or refer with Acre to Premier Property Lawyers Conveyancing',
      solicitorDetails: 'Solicitor details',
      solicitorName: 'Firm name',
      contactName: 'Contact at firm',
      emailAddress: 'Email address',
      phoneNumber: 'Telephone number',
      dxNumber: 'DX number',
      dxTown: 'DX town',
      caseReference: "Solicitor's case reference",
      providedBy: 'How will conveyancing be handled for this case?',
      noSelection: 'Not yet decided',
      lenderProvided: 'The lender will provide a solicitor',
      notNeeded: 'No conveyancing is needed as the transaction is for the same lender on the same property',
      acreReferral: 'Case has been referred',
      ownSolicitor: 'The client has provided their own solicitor',
      referred: "The case has been referred to Acre's conveyancing partner, Premier Property Lawyers.",
      blurb1:
        'Acre has partnered with Premier Property Lawyers to provide a simple, trackable conveyancing referral process that gets the best for both your clients and your firm.',
      whyPick: {
        title: 'Why refer to Acre Conveyancing?',
        a: "Premier Property Lawyers are the UK's largest provider of conveyancing services and one of the highest rated by their customers",
        b: "Cases are trackable and automatically updated, so you, and your client always know what's going on",
        c: 'Clear and transparent pricing with no hidden fees - what you see is what your client pays',
        d: 'Earn a referral bonus for every successful referral',
      },
      totalPrice: 'Your inclusive quote for this case:',
      referralBonus: 'Your referral bonus:',
      tellMeMore: 'Tell me more',
      referNow: 'Refer now',
      referNow2: 'Refer',
      moreInfo: 'How do we compare?',
      allInclusiveQuote: 'Your inclusive Acre quote:',
      noHiddenFeesNoSurprises: "No hidden fees, no surprises. <b>{price} + VAT</b>. That's it.",
      notIncluded:
        "Providing the fact find is correct, the only other money your client will pay to the conveyancer is any Stamp Duty Land Tax that's due on the transaction plus the applicable Land Registration fee.",
      tldr: "In certain, very limited circumstances, there may be rare fees for additional work that we're unable to predict. You can find full details of these in the section 'Fees that are not included' below. Additionally, if fees are charged by outside parties, e.g. the freeholder of a flat, these will be passed on to the client.",
      thingsThatAreNotIncluded: 'Fees that are not included',
      yourClient: {
        title: 'What your client gets',
        a: 'Fast, efficient service with a named contact',
        b: 'All standard searches - local authority, Land Registry, water, drainage and environmental',
        c: 'Stamp duty return fee',
        d: 'Identity and funds checks',
        e: 'Funds transfer to the seller',
        f: "The reassurance of dealing with the UK's largest and most trusted conveyancer",
        eRemo: 'Funds transfer to your old lender if appropriate',
      },
      forYou: {
        title: 'What you get',
        a: 'Fast, efficient service with a named contact',
        b: "The reassurance of dealing with the UK's largest and most trusted conveyancer",
        c: 'Regular status updates on your case',
        d: 'A fee of {fee} for referring the customer, paid to you by Acre',
      },
      otherProviders: 'Other providers:',
      promiseALot: "Panels and internet conveyancers promise a lot and often don't deliver very much.",
      unexpectedCharges:
        'Unrealistically low headline fees translate into a myriad of unexpected charges, surprises and slow service.',
      whyTakeTheRisk: 'Why take the risk with your reputation?',
      whatTheyTellYou: "What they tell you they're going to charge your client",
      whatTheyDo: 'What they will really charge your client',
      description: 'Description',
      amount: 'Amount',
      sdltHeader: 'Other payments at time of completion',
      expectedFee: 'Standard fee',
      electronicFee: 'Reduced fee if submitted electronically',
      feesCaveat:
        "These fees are estimates based on the information provided in the fact find and will be confirmed by the customer's conveyancer.",
      decline: 'Decline quotation',
      acceptQuotation: 'Accept quotation',
      referralDetails: 'Referral details',
      quotedWithRef: 'Quoted with reference {ref}. This reference should be quoted if contacting PPL about this case.',
      historyFields: {
        date: 'Date',
        description: 'Description',
        reason: 'Reason',
        actionedBy: 'Actioned by',
      },
      history: 'History',
      notReady: 'You must select a property before you can complete this part of the fact find.',
      retrieveQuoteError: 'We cannot currently retrieve a quotation from our partners. Please try again shortly.',
      retrieveStatusError: "We can't talk to our partner's system right now. Please try again shortly.",
      homeAddressMustBeSet:
        'You must enter the home address and contact email for each client in order to refer the case.',
      propertyPriceMustBeSet: 'You must enter the price to be paid for the property in order to refer the case.',
      mustSupplyPropertyToBeSold: 'You must enter the details of the property to be sold in order to refer the case.',
      badSdlt: 'We could not estimate duty or registration fees. Please add a property before continuing.',
      feeScenarios: {
        REASON_FTB: 'When purchasing a property',
        REASON_HOUSE_MOVE: 'When selling a property',
        REASON_REMORTGAGE: 'When remortgaging a property',
      },
      feeScenarioCols: {
        fee: 'Fee',
        amount: 'Amount',
      },
      additionalFees: `The following additional fees and charges are not taken into account in our inclusive pricing due to their rarity and difficulty to predict but may occasionally be charged depending on the circumstances of your client's case.`,
      scotland: {
        notYet:
          "We'll be making conveyancing quotes for Scottish properties available to more clients very soon. In the meantime, please talk to your Acre account manager for more information",
        title: 'Conveyancing quotes',
        blurb: `We're delighted to have partnered with Smoove to bring you access to high quality conveyancing
                  services in Scotland. Commissions will be paid as part of your Acre invoice and you'll have access to
                  case milestones from this page once the case is referred.`,
        reloading: 'Reloading case...',
        wait: 'Please wait, instructing case...',
        name: 'Conveyancer',
        location: 'Location',
        price: 'Quote price',
        rating: 'Rating',
        fewer: 'Show fewer quotes',
        all: 'Show all quotes',
        case: 'Case',
        core: 'Core legal fee for case',
        vat: 'Total includes VAT of',
        totalCase: 'Total price for case',
        commission: 'Commission payable for case',
        summary: 'Summary:',
        disbursements: 'Disbursements',
        legalSupplements: 'Legal supplements',
        otherSupplements: 'Other supplements',
        total: 'Total price',
        deposit: 'Deposit',
        notes: 'Notes',
        smallPrint: 'Small print',
        more: 'More details',
        instruct: 'Instruct',
        back: 'Close',
        pdf: 'Save PDF to case',
        item: 'Item',
        amount: 'Amount',
        moreHeader: 'Quotation details',
        saved: 'PDF will be saved to case',
        totalCommission: 'Total commission',
        noRating: 'No rating yet',
        hasReferral: 'This case has already been referred',
        hasReferralDetail:
          'This case has already been referred to our partners in Scotland and allocated a reference {ref}. Detailed updates will appear here as the case progresses.',
        errors: {
          noProperty: 'You must enter details of the property in order to retrieve a quote.',
          noValuation: 'The value of the property must be set in order to retrieve a quote.',
          propertyPrice: 'The purchase price of the property must be set in order to retrieve a quote.',
          tenure: 'The property tenure must be set in order to retrieve a quote.',
          reason: 'The case type must be set in order to retrieve a quote.',
        },
      },
      preQuote: {
        recommend:
          "We recommend referring cases once you have entered the property to be purchased or remortgaged, ensuring that your clients benefit from the inclusive pricing we're able to generate once we have particulars.",
        pdf: "In the interim, we've made a PDF setting out how the conveyancing process works that you can share with your client. You can also refer now if you have a compelling reason to do so.",
        referAnyway: 'Refer anyway',
        download: 'Download PDF',
      },
      redBox: {
        beware: 'BEWARE!',
        first: `You may have seen some unbelievably low prices on the internet for conveyancing services. These are, in most cases, literally too good to be true and translate into a myriad of unexpected charges, surprises and slow service.`,
        second: `Unlike this inclusive quote, they probably don't include things as simple as VAT, basic searches and dealing with a mortgage lender and can often end up costing nearly three times as much as the headline fee.`,
      },
      noCompanyBTL:
        'Conveyancing referral for company BTL cases is currently unavailable. We expect to be able to re-enable this service in the next few months.',
      quotedAmount: 'The client was quoted a fee of {amt} + VAT.',
      statusUpdates: {
        pending: 'Pending',
        title: 'Conveyancing progress',
        relatedSale: 'Related property sale',
        titles: {
          starterPack: 'Starter pack',
          titleInformation: 'Title information',
          contracts: 'Contracts',
          searchesAndEnquiries: 'Searches and enquiries',
          exchangeAndCompletion: 'Exchange and completion',
          scotlandInstruction: 'Instruction',
          missives: 'Missives',
          saleProgress: 'Sale progress',
        },
        summaryData: {
          summary: 'Conveyancing details',
          conveyancerName: 'Conveyancer',
          quote: 'Quote ID',
          caseRef: 'Case reference',
          contactNumber: 'Contact number',
          quoteAmount: 'Original quote',
          commission: 'Expected commission',
        },
        statuses: {
          CompletionMoniesRequested: 'Completion monies requested',
          CompletionSetForExpected: 'Expected completion date',
          ContractClientReceivedActual: 'Contract received from client',
          ContractClientReceivedExpected: 'Contract expected from client',
          ContractSolicitorReceivedActual: 'Contract received from counterparty',
          ContractSolicitorReceivedExpected: 'Contract expected from counterparty',
          ContractSolicitorSent: 'Contract sent to counterparty',
          DateAborted: 'Date aborted',
          DateCompleted: 'Date completed',
          DateExchanged: 'Date exchanged',
          DateInstructed: 'Date instructed',
          DateOutOfAbeyance: 'Date out of abeyance',
          DateReactivated: 'Date reactivated',
          DateReadyToExchangeEnd: 'Latest expected exchange date',
          DateReadyToExchangeStart: 'Earliest expected exchange date',
          DateSalesMemorandumReceived: 'Sales memorandum received',
          DateSentToAbeyance: 'Sent to abeyance',
          DateStarterPackReceived: 'Starter pack received',
          DateSuspended: 'Suspended',
          EnquiriesLastAnswered: 'Enquiries last answered',
          EnquiriesLastRaised: 'Enquiries last raised',
          EnquiriesRepliesApproved: 'Replies to enquiries approved',
          ExchangeCompleteTransferReceived: 'Completed transfer received',
          MortgageDeedToBorrower: 'Mortgage deed sent to borrower',
          MortgageOfferReceived: 'Offer received',
          MortgageReceivedClient: 'Client received mortgage',
          MortgageReportClient: 'Client received mortgage report',
          MortgageSignedReceived: 'Signed deed received',
          RedemptionReceived: 'Redemption statement received',
          RedemptionRequested: 'Redemption statement requested',
          SearchesReceivedActual: 'Searches received',
          SearchesReportedClient: 'Searches reported to client',
          SearchesRequested: 'Searches requested',
          StarterPackIdSatisfiedDate: 'ID completed',
          StarterPackInitialPayment: 'Initial payment made',
          StarterPackInstructionsReceived: 'Instructions received',
          StarterPackSent: 'Starter pack sent to client',
          StatusInfoReceivedBorrower: 'Status information received from borrower',
          TitleChecked: 'Title checked',
          TitleContractApproved: 'Contract approved',
          TitleDeedsReceived: 'Deeds received',
          TitleDeedsRequested: 'Deeds requested',
          TitleDocumentsClientActual: 'Client received documents',
          TitleDocumentsClientExpected: 'Documents expected to reach client',
          TitleDocumentsReceivedActual: 'Documents received',
          TitleDocumentsReceivedExpected: 'Documents expected',
          TitleDocumentsRequested: 'Documents requested',
          TitleInformationRequested: 'Information requested',
          TitleReceivedActual: 'Information received',
          TitleReceivedExpected: 'Information expected',
          TitleReportClient: 'Client received report',
          TitleRequisitionsEnquiries: 'Title enquiries made',
          ScotlandCoalReportReceived: 'Coal Authority report received',
          ScotlandCustomerAcceptance: 'Customer acceptance',
          ScotlandCustomerIDVerificationReceived: 'Customer ID verification received',
          ScotlandDeedsSentToPurchasersSolicitor: "Deeds sent to the purchaser's solicitor",
          ScotlandDepositReceived: 'Deposit received / retained from sale',
          ScotlandDispositionSignedByCustomer: 'Disposition signed by the customer',
          ScotlandDateOfEntry: 'Date of entry',
          ScotlandLoanFundsReceived: 'Loan funds received',
          ScotlandMissivesConcluded: 'Missives concluded',
          ScotlandObservationsReceivedFromPurchasersSolicitor: "Observations received from purchaser's solicitor",
          ScotlandVerballyAccepted: 'Offer verbally accepted',
          ScotlandPersonalSearchReceived: 'Personal search ordered and received',
          ScotlandQualifiedAcceptanceReceived: 'Qualified acceptance received',
          ScotlandStandardSecuritySigned: 'Standard security signed',
        },
      },
    },
    wills: {
      heading: `Refer your client for wills and estate planning – and make sure they're fully protected`,
      intro: `Acre has partnered with wills and estate planning specialist, Honey Legal to make it even easier to
        fulfil your consumer duty by protecting your clients and their assets using Honey's leading-edge
        planning and fulfilment service.`,
      bonus: 'Your potential referral bonus',
      caveat: 'Based on average case size of £1,000',
      tellMeMore: 'Tell me more',
      send: 'Send referral email',
      why: {
        title: 'Why refer?',
        a: 'All clients need wills to ensure their property is received by the people intended.',
        b: `If a client loses capacity due to illness, injury or dementia, then not many realise their assets
          will be frozen. Not even their partner can step in and make decisions for them. Without Lasting
          Powers of Attorney, they'll be blocked from renegotiating a new fix rate deal in the future.`,
        c: `LPAs are also needed to ensure clients get the medical and welfare treatment they want and not risk
          serious decisions to people they don't know or trust.`,
      },
      whyHoney: {
        title: `Why Honey Legal?`,
        a: `A trusted brand since 2010, having accumulated over £16bn of assets in our will bank`,
        b: `Our over 8,300 5 star TrustPilot reviews speak volumes about the quality of our service`,
        c: `Once purchased, our clients can store their wills and other documents free of charge`,
        d: `Clients can then update their wills and LPAs free during their lifetime`,
        e: `All documents are fully checked by one of our legal team before issue`,
        f: `Competitively priced, for example; single wills from £99 or a wills and double LPA package from £500`,
        g: `Monthly payment option to spread the cost over 12 to 60 months`,
      },
      tellMeMoreBox: {
        how: 'How referral works',
        title: 'When you click the button...',
        steps: {
          a: 'We send the client an email explaining why they need a will (see right for a preview).',
          b: 'The client clicks on the link in the email, taking them to the Honey Pro site',
          c: 'They fill in details of their situation and Honey will recommend suitable products for them',
          d: 'The client selects the products and pays',
          e: 'Honey Pro takes them through the process of creating their Will and LPAs',
          f: 'You receive your commission',
        },
        after:
          "If they don't click, we'll send them a follow up email when the case completes. They'll also be able to find the link in client portal.",
        remember: {
          title: 'Remember',
          a: `It's important in light of consumer duty requirements to ensure that all of your client's needs are
              met. This includes helping clients who don't have suitable estate planning in place to do so.`,
          b: `You'll receive 20% of the amount your clients spend on Wills and LPAs as commission for recommending
                    them. This will be paid to you by Acre.`,
          bNoFigure: `You'll receive a commission from your network for recommending them.`,
        },
        send: 'Send referral email',
        close: 'Close',
      },
      referred: 'You have already referred this case to Honey Pro',
      referredSpiel:
        "We've sent your client an email reminding them of the importance of proper estate planning and having lasting powers of attorney in place. If your client takes out a will or LPAs from Honey you'll receive your commission through the usual route.",
      lostLink: 'If your client has lost the link in the email, the special referral link for this case is below:',
      referredSentAt: 'Email sent',
      referredOpened: 'Opened',
      referredClicked: 'Clicks',
      referredLastClicked: 'Last clicked',
    },
    benefits: {
      noRecommendedProducts: 'No benefits have been selected',
      selectShortlistedProduct: 'You can add benefits by clicking on the button above',
    },

    mortgageProduct: {
      esisDiabled: 'Please complete mortgage product information',
      esisHelper:
        'Insufficient information to generate ESIS. Please ensure all mortgage details have been filled in and an advisor is present on the case.',
      currentRate: 'Current rate',
      currentRateEndDate: 'Current rate end date',
      rateEndDate: 'Rate end date',
      // this needs to be lowercase, as this message is inserted into
      // a sentence for validation
      previousRateEndDate: 'previous rate end date',
      calculateTermEnd: 'Calculate to end of term',
      interestRateType: 'Interest rate type',
      rate: 'Rate',
      firstOrSecondCharge: 'Is mortgage second charge?',
      mortgageTerm: 'Mortgage term',
      termRemaining: 'Remaining term',
      term: 'Term',
      termUnit: 'Term unit',
      continuing: 'Continuing',
      repaidContinuing: 'Continuing (Repaid)',
      outstandingBalanceDate: 'Outstanding balance date',
      revertMonthlyPayment: 'Revert monthly payment',
      monthlyPayment: 'Monthly payment',
      calculatedMonthlyPaymentNoVar: 'Calculated monthly payment',
      calculatedMonthlyPayment: 'Calculated monthly payment {payment}',
      noCalculatedMonthlyPaymentTooltip:
        'Add mortgage amount, term, rate tier details and any fees added to loan and press save to see calculated payments.',
      calculatedMonthlyPaymentTooltip:
        'Calculated monthly payments are based on the mortgage amount, term, rate tiers, and fees added to loan.',
      outstandingBalance: 'Outstanding balance',
      startDate: 'Start date',
      originalStartDate: 'Original start date',
      mortgageAmount: 'Mortgage amount',
      originalMortgageAmount: 'Original mortgage amount',
      earlyRepaymentChargeAmount: 'Early repayment charge amount',
      repaymentType: 'Repayment type',
      selectRepaymentType: 'Select repayment type',
      repaymentAmount: 'Repayment amount',
      interestOnlyAmount: 'Interest only amount',
      prefillFromProductCode: 'Pre-fill from product code',
      productCode: 'Product code',
      earlyRepaymentCharge: 'Early repayment charge',
      lenderName: 'Lender name',
      productName: 'Product name',
      mortgageAccountNumber: 'Mortgage account number',
      providerApplicationReference: 'Provider application reference',
      valuationType: 'Valuation type',
      maxLTVAvailable: 'Max LTV available',
      portable: 'Portable',
      paymentRoute: 'Payment route',
      grossProcFee: 'Gross Proc fee',
      netProcFee: 'Net Proc fee',
      deedsReleaseFee: 'Deeds release fee',
      valuationFee: 'Valuation fee',
      arrangementFee: 'Arrangement fee',
      feesTotal: 'Fees total',
      bookingFee: 'Booking fee',
      chapsFee: 'CHAPS fee',
      mortgageDischargeFee: 'Mortgage discharge fee',
      disbursementFee: 'Disbursement fee',
      fees: 'Fees',
      selectProducts: 'Select products attached to this property',
      addFee: 'Add fee',
      addIncentive: 'Add incentive',
      procFeeOptions: {
        procFee: 'Proc fee',
        procFeeType: 'Type',
        procTypeFixed: 'Pounds',
      },
      disabledLenderName:
        "You can't edit the lender name for the current product code. Please remove the product code if you would like to modify the lender name.",
      repaymentTypes: {
        interestOnly: 'Interest only',
        repayment: 'Repayment',
        partAndPart: 'Part and part',
      },
      interestRateOptions: {
        fixed: 'Fixed',
        tracker: 'Tracker',
        discounted: 'Discounted',
        capped: 'Capped',
        libor: 'LIBOR',
        stepped: 'Stepped',
        variable: 'Variable',
      },
      valuationTypeOptions: {
        standardValuation: 'Standard Valuation',
        homebuyersReport: 'Homebuyers Report',
        fullBuildingSurvey: 'Full Building Survey',
        driveByValuation: 'Drive By Valuation',
        avm: 'AVM',
        desktopValuation: 'Desktop Valuation',
        homeReport: 'Home Report',
        noValuation: 'No Valuation',
      },
      feeTypes: {
        type: 'Type',
        amount: 'Amount',
        addedToLoan: 'Added to loan',
        total: 'Total fees',
        legalFee: 'Legal',
        valuationFee: 'Valuation',
        bookingFee: 'Booking',
        arrangementFee: 'Arrangement',
        chapsTelegraphicTransferFee: 'CHAPS',
        redemptionAdminFee: 'Mortgage discharge',
        deedsReleaseFee: 'Deeds release',
        lenderConveyancingFee: 'Lender conveyancing',
        landRegistryFee: 'Land Registry',
        masterBrokerFee: 'Master broker',
        titleDeedsAdminFee: 'Title deeds admin',
        higherLendingCharge: 'Higher lending charge',
        applicationFee: 'Application',
        completionFee: 'Completion',
        administrationFee: 'Administration',
        insuranceAdministration: 'Insurance administration',
        mortgageAccountFee: 'Mortgage account',
        packagerFee: 'Packager',
        productTransferFee: 'Product transfer',
        reInspectionFee: 'Re-inspection',
        brokerFee: 'Broker',
        earlyRepaymentCharge: 'Early repayment charge',
        otherFee: 'Other',
        assessmentFee: 'Assessment',
        invalidFeeType: 'Invalid fee type',
        homebuyersFee: 'Homebuyers fee',
        disbursementFee: 'Disbursement fee',
      },
      canBeAddedToLoan: {
        mustBeAdded: 'Must be added',
        optionToBeAdded: 'Can be added',
        paySeparately: "Can't be added",
      },
      incentiveTypes: {
        type: 'Type',
        total: 'Total Incentives',
        amount: 'Amount',
        cashback: 'Cashback',
      },
      dip: {
        dip: 'DIP',
        rerunDip: 'Rerun DIP',
        nationwideDisclaimer: 'By submitting this form you are asserting that:',
        nationwidePoint1: 'All applicants are UK resident for tax purposes',
        nationwidePoint2:
          'You have the permission of all applicants to transfer their data to Nationwide Building Society',
        submittingDip: 'Submitting information for DIP... ',
        additionalInformationRequired: 'Some additional information is required to retrieve a DIP',
        dipStatus: 'DIP status: {status}',
        dipErrorMessage: '{error}',
        dipErrorFactFind: 'Please complete fact-find to enable DIP',
        amountApproved: 'Amount approved: ',
        referToBDM: 'Please refer this application to the BDM for this lender',
        dipError: 'Not supported',
        currentRent: 'Current rent',
        currentMortgageAccountNumber: 'Current mortgage account number',
        previousLender: 'Previous lender',
        dateMortgagePaidOff: 'Date previous mortgage was paid off',
        decisionInPrinciple: 'Decision In Principle',
        client0industry: 'Industry in which the first client is employed',
        client1industry: 'Industry in which the second client is employed',
        certificate: 'DIP Certificate',
        reserveFunds: 'Reserve funds',
        reserveFundsTooltip: 'Send to Nationwide for Intermediaries and reserve funds for this client',
        certTooltip: 'Click here to save the provider DIP certificate to the case',
        clickMe: 'Click here to submit a DIP request to the lender',
        clickMeAmend:
          'Click here to update the DIP already sent to the lender. If, for any reason, we cannot update the existing case, we will create a new one.',
        dipRedirectReason: 'The lender requires more information. Please visit lender portal.',
        dipRedirectButton: 'Go to lender portal',
        stillWorking: 'still working...',
        noDip: 'No DIP yet',
        proofs: 'Proofs required:',
        proof: 'Proof required for {applicant}: {name}',
        proofApplicant: {
          FirstApplicant: 'first applicant',
          SecondApplicant: 'second applicant',
        },
        dipDeclinedReasons: {
          declinedCredit: 'The client was declined because of a credit issue.',
          declinedAffordability: 'The client was declined on affordability.',
          criteriaMismatch: 'The client was declined because of a criteria mismatch.',
          productMismatch: 'The client was declined because of a product mismatch.',
          notStated: 'The lender did not state a reason why the DIP was declined.',
          systemError: 'The DIP was declined because of a system error',
          notSupported: 'The DIP was declined because the application details were not supported.',
        },
        maxBorrowingAndTerm: 'Maximum borrowing {maxBorrowing} over at least {minTerm} years',
        ifContacting: 'If contacting the lender about this application please quote reference {ref}.',
        statuses: {
          refer: 'Refer',
          pending: 'Pending',
          redirect: 'Redirect',
          accepted: 'Accepted',
          declined: 'Declined',
          error: 'Error',
        },
        breakdown: 'Decision breakdown:',
        breakdownTypes: {
          credit: 'Credit',
          affordability: 'Affordability',
          policy: 'Policy',
          property: 'Property',
        },
        validity: 'This decision is valid until {date}',
        overall: 'The following overall reason was returned:',
        bureaux: 'The lender used the following credit bureaux: {bureaux}',
        windowOpened:
          'We have opened a new window for the application. If you do not see this, please check your browser is not preventing Acre from opening new windows.',
        noURL:
          "The lender returned no response when we requested to transfer the application. Please check the lender's intermediary site.",
        internalScheme: 'Please select any Nationwide schemes which you wish to use for this application (optional)',
        asylum: 'Is this client seeking asylum in the UK?',
        refugee:
          'Does this client hold refugee status as recognised under the 1951 UN conventions and its 1967 protocol?',
        ilr: 'Does this client have indefinite leave to remain in the UK?',
        salaryUKBank: "Is this client's salary paid into a UK bank?",
        workPermit: 'Does this client have a valid work permit or visa?',
        ukResident: 'Has this client legally been a UK resident for at least three years?',
        diplomat: 'Does this client have diplomatic immunity?',
        depositOwnResources: 'Does this client have a minimum deposit/equity of 25% from their own resources?',
        helping_hand: "Do you wish to make use of Nationwide's Helping Hand scheme?",
        newLeaseTerm:
          "The current lease term is less than Nationwide's minimum. What will the new lease term be at the end of the transaction?",
        equityShareProvider: 'Who is providing the equity loan scheme?',
        equityShareRepaymentDate: 'When must the equity share be repaid? If no date, leave blank',
        equityShareMaxSaleShare: 'Is there a maximum percentage of market value for which the property can be sold?',
        firstClient: 'First applicant',
        secondClient: 'Second applicant',
        sendToNFI: 'Send to NFI',
        apply: 'Apply',
        applyTooltip: "Send to the lender's application process",
        sendToNFITooltip: 'Copy the case to Nationwide for Intermediaries to facilitate amendment or application',
        depositUnlock: 'Does your client wish to use the Deposit Unlock scheme?',
      },
      actions: 'Actions',
      saveProduct: 'Save product',
      discardProduct: 'Discard product',
      continuingProducts: 'Continuing products',
      unknownLender: 'Unknown lender',
      recommend: 'Recommend',
      noRecommendedProducts: 'You have no recommended products',
      selectShortlistedProduct: 'Select a shortlisted product to recommend',
      evidenceOfResearch: 'Research',
      viewAcreEvidenceOfResearch: 'View Acre evidence of research',
      viewAdditionalEvidenceOfResearch: 'View additional evidence of research',
      uploadEvidenceOfResearch: 'Upload evidence of research',
      esisDisabled: 'Please select product via Run Sourcing before producing an ESIS',
      esis: 'ESIS',
      sourced: 'Sourced',
      repaid: 'Repaid',
      existing: 'Existing',
      recommendation: 'Recommendation',
      lenderProposed: 'Lender Proposed',
      viewEsis: 'View ESIS',
      generateEsis: 'Generate ESIS',
      removeRecommendation: 'Remove recommendation',
      removeProduct: 'Remove product',
      shortlisted: 'Shortlisted products',
      recommendDisabledDueToNoEOR:
        'To recommend this product please either complete missing product information/source and select via Acre Sourcing or upload your own EOR document.',
      recommendDisabledDueToCaseStatus:
        'This product can only be recommended in status "Pre-Recommendation" as Suitability Report will require reissue.',
      differentProductWasRecommended:
        'There is already a recommended product, please remove that recommendation in order to recommend this product.',
      removeRecommendationDisabled:
        'Recommended Product can only be removed in status "Pre-Recommendation" as Suitability Report will require reissue.',
      recommended: 'Recommended product',
      existingProductsOnProperty: 'Existing products on property',
      runSourcing: 'Run sourcing',
      noProducts: 'There are no products shortlisted on this case',
      addAdditionalRateTier: '+ Add additional rate tier',
      recommendProductTooltip: {
        warning: 'Please provide evidence of research.',
        seeReasons: 'See how',
        title: 'Recommending manual products',
        firstPara:
          'Before you can recommend this manual product, you will need to provide evidence of research. To do this you can either:',
        secondPara:
          '<strong>Run Sourcing</strong> and save this product to automatically generate evidence of research (recommended).',
        or: 'Or...',
        thirdPara: 'Upload an evidence of research document from outside of Acre.',
      },
      mortgageTableHeaders: {
        outstandingBalance: 'Outstanding balance',
        loanAmount: 'Loan amount',
        initialTerm: 'Initial term',
        initialPayment: 'Initial payment',
        totalFees: 'Total fees',
        initialPeriodTrueCost: 'Initial period true cost',
        productStatus: 'Product status',
        dipStatus: 'DIP status',
        monthlyPayment: 'Monthly payment',
        startDate: 'Start date',
        currentRate: 'Current rate',
        status: 'Status',
        currentRateEnd: 'Current rate end',
      },
      addProductButtonLabels: {
        buttonLabels: {
          addProduct: '+ Add product',
          addEquityReleaseProduct: '+ Add equity release product',
          addCommercialProduct: '+ Add commercial product',
          addBridgingProduct: '+ Add bridging product',
        },
        buttonSubLabels: {
          addProduct: 'Add product',
          addSecondChargeProduct: 'Add 2nd charge product',
          addCommercialProduct: 'Add commercial product',
          addCommercialSecondChargeProduct: 'Add commercial 2nd charge product',
          addBridgingProduct: 'Add bridging product',
          addBridgingSecondChargeProduct: 'Add bridging 2nd charge product',
        },
      },
      erc: {
        ercHeader: 'ERC',
        ercHeaderNum: 'ERC {num}',
        ercSubHeader: 'From {date}',
        addEarlyRepaymentCharge: ' Add early repayment charge',
        charge: 'Early repayment charge',
        endDate: 'End date',
      },
      arrears: {
        arrearsNotice: 'Add the maximum number of consecutive months in arrears for the following periods:',
        hadArrears: 'Has this mortgage ever been in arrears?',
        maxArrears: 'Max number of arrears in the last {months} months',
        currentBalance: 'Current arrears balance',
        dateLastCleared: 'Date last arrears cleared',
        exceededMaxArrears: 'Months in arrears cannot be greater than {months}',
      },
    },

    mortgage: {
      addProducts: 'Add any products which will be continuing alongside',
      arrears: 'Arrears',
      fees: 'Fees',
      incentives: 'Incentives',
      mortgage: 'Mortgage',
      mortgageDetails: 'Mortgage details',
      lenderProposedMortgageDetails: 'Lender proposed mortgage',
      productDetails: 'Product details',
      currentMortgagesOnProperty: 'Current mortgages on property ({num})',
      addMortgage: 'Add mortgage',
      currentMortgages: 'Current mortgages on property',
      newMortgage: 'New mortgage',
      existingMortgage: 'Existing mortgage',
      removeMortgage: 'Are you sure you want to remove this mortgage?',
      productRate: 'Product rates',
      followedBy: 'Followed by...',
    },

    sourcing: {
      title: 'Mortgage sourcing',
      filters: {
        basicFilters: ' basic filters',
        trueCost: ' true cost and fees',
        buyToLet: ' buy to let filters',
      },
      inputFilterLabels: {
        product: 'Product',
        loanPurpose: 'Loan purpose',
        purchasePrice: 'Purchase price',
        propertyValue: 'Property value',
        loanRequired: 'Loan required',
        equity: 'Equity',
        deposit: 'Deposit',
        term: 'Loan term (years)',
        from: 'From (months)',
        to: 'To (months)',
      },
      loanPurposeOptions: {
        purchase: 'Purchase',
        remortgage: 'Remortgage',
        part: 'Part and part / split',
        noPurposeProvided: 'No loan purpose provided',
      },
      paymentMethod: {
        repayment: 'Repayment',
        interestOnly: 'Interest only',
        interestOnlyAmount: 'Interest only amount',
        part: 'Part and part / split',
        invalidPaymentMethod: 'Invalid Payment Method',
        endowment: 'Endowment',
        individualSavingsAccount: 'Individual savings account',
        partInvestment: 'Part investment',
        pension: 'Pension',
        saleOfProperty: 'Sale of property',
        saleOfOtherProperty: 'Sale of other property',
      },
      mortgageTypeOptions: {
        standardResidential: 'Standard residential',
        buyToLet: 'Buy to let',
        rightToBuy: 'Right to buy',
        sharedOwnership: 'Shared ownership',
        selfBuild: 'Self build',
        sharedEquity: 'Shared equity',
        securedLoan: 'Secured loan',
        securedLoanBtl: 'Secured loan BTL',
        bridgingLoan: 'Bridging loan',
        standardCommercial: 'Standard commercial',
        letToBuy: 'Let to buy',
        letToBuyLoan: 'Let to buy loan',
        standardOrSharedEquity: 'Standard or shared equity',
        securedLoanSharedOwnership: 'Secured loan shared ownership',
        equityRelease: 'Equity release',
        noMortgageTypeSelected: 'No mortgage type selected',
      },
      periodOptions: {
        twoYears: '2 years',
        threeYears: '3 years',
        fiveYears: '5 years',
        termProduct: 'Term product',
        custom: 'Custom',
      },
      trueCostCalculations: {
        trueCostCalculation: 'True cost calculation',
        calculateOverInitialPeriod: 'Calculate over initial period',
        calculateOverNumberOfMonths: 'Calculate over number of months',
        numOfMonths: 'Number of months',
      },
      buyToLetDetails: {
        buyToLetDetails: 'Buy to let details',
        expectedRentalIncome: 'Expected rental income (per month)',
        numberOfTenants: 'Number of tenants',
        numberOfMortgagedProperties: 'No. of mortgaged properties owned (excluding own residence)',
        totalBorrowing: 'Total borrowing on properties (excluding own residence)',
        buyToLetApplicantsAreHighTaxRatePayers: 'Applicants are high tax rate payers',
        buyToLetHouseOfMultipleOccupancy: 'House of multiple occupancy (HMO)',
        buyToLetCorporateLet: 'Corporate let',
        buyToLetLimitedCompany: 'Limited company',
        buyToLetLimitedCompanySpv: 'Limited company SPV',
        buyToLetBedsit: 'Bedsit',
        buyToLetAllowsTopSlicing: 'Allows top slicing',
      },
      buyToLetType: {
        buyToLetType: 'Buy to let type',
        standardBLT: 'Standard (business) BTL',
        consumerBLT: 'Consumer BTL',
        regulatedBLT: 'Regulated BTL',
      },
      tenantStatuses: {
        tenantStatus: 'Tenant status',
        dss: 'DSS',
        student: 'Student',
        singleAST: 'Single AST',
      },
      landlordStatuses: {
        landlordStatus: 'Landlord status',
        firstTimeNoResidential: 'First time - without existing residential property',
        firstTimeWithResidential: 'First time - with existing residential property',
        existingNoResidential: 'Existing - without existing residential property',
        existingWithResidential: 'Existing - with existing residential property',
      },
      feeDetails: {
        feeDetails: 'Fee details',
        trueCostDisableAddFeesPayableToTrueCostTotal: 'Add fees payable to true cost',
        trueCostDisableAddFeesThatWillBeAddedToLoan: 'Add fees to loan',
        trueCostDisableDeductCashback: 'Deduct cashback from true cost',
        trueCostDisableDeductRefundedFees: 'Deduct refunded fees from true cost',
      },
      checkBoxFilterLabels: {
        products: 'Product types',
        paymentMethod: 'Payment method',
        productPeriod: 'Product period',
        preferences: 'Preferences',
        situations: 'Situations',
        helpToBuy: 'Help To Buy',
      },
      productFilters: {
        mortgageClassFixed: 'Fixed',
        mortgageClassTracker: 'Tracker',
        mortgageClassLiborLinked: 'LIBOR linked',
        mortgageClassDiscount: 'Discount',
        filterDirect: 'Show direct products',
        mortgageClassVariable: 'Variable',
        mortgageClassCapped: 'Capped',
      },
      preferenceFilter: {
        filterFreeLegalFees: 'Free legals',
        filterFreeLenderFees: 'Free lenders fees',
        filterValuationFee: 'Free valuation fees',
        filterGuarantors: 'Guarantors acceptable',
        filterIsOffset: 'Offset mortgage',
        filterOverpaymentsAllowed: 'Over-payment allowed',
        filterHigherLendingCharge: 'No higher lending charge',
        filterCashback: 'Cashback',
        filterPortable: 'Portable',
        filterNoArrangementFee: 'No arrangement fee',
        filterNoBookingFee: 'No booking fee',
        filterRefundedValuationFees: 'Refunded valuation fees',
        filterRefundedLegalFees: 'Refunded legal fees',
      },
      situationFilter: {
        filterCcjsAcceptable: 'CCJs acceptable',
        filterArrearsAcceptable: 'Arrears acceptable',
        filterBankruptcyAcceptable: 'Bankruptcy acceptable',
        filterDefaultsAcceptable: 'Defaults acceptable',
        filterIvasAcceptable: 'IVAs acceptable',
        filterDmpsAcceptable: 'Debt management plans acceptable',
        filterPaydayLoansAcceptable: 'Payday loans acceptable',
      },
      helpToBuyFilter: {
        filterHelpToBuy: 'Help to Buy',
      },
      buttons: {
        searchMortgages: 'Search mortgages',
        hideFilters: 'Hide filters',
        showFilters: 'Show filters',
        filtersSelected: 'selected',
      },
      table: {
        lender: 'Lender',
        initialRate: 'Initial rate',
        rateType: 'Rate type',
        initialMonthlyPayment: 'Initial monthly payment',
        revertRate: 'Revert rate',
        MaxLTV: 'Max LTV',
        totalFees: 'Total fees',
        trueCost: 'True cost (initial period)',
        trueCostMonths: 'True cost over {months} months',
        initialPeriodMonths: 'Initial Period',
      },
      productDetails: {
        saveToCase: 'Save to case',
        productName: 'Product name',
        cashback: 'Cashback',
        earlyRepayment: 'Early repayment',
        arrangementFee: 'Arrangement fee',
        valuationFee: 'Valuation fee',
        legalFee: 'Legal fee',
        lenderContact: 'Lender contact',
        maxOverpayment: 'Max over-payment',
        lenderWebsite: 'Lender website',
      },
      resultsWereSortedBy: 'Results were sorted by {column}',
      resultsWereSortedByMonths: 'Results were sorted by true cost over {months} months',
      sortingColumns: {
        invalidColumn: '',
        initialPayRate: 'Initial pay rate',
        initialMonthlyPayment: 'Initial monthly payment',
        trueCost: 'True cost',
        trueCostOverInitialPeriod: 'True cost over initial period',
      },
      noRecommendedProducts: 'No solutions have been sourced yet',
      selectShortlistedProduct: 'You can source solutions by clicking on the ‘Get results’ button above',
      errorCard: {
        heading: 'Unfortunately iPipeline is unable to source products at this time. This may be due to the following:',
        listItem1: 'Your iPipeline account is not set up correctly.',
        listItem2: 'Your iPipeline credentials have not been added to Acre.',
        listItem3: 'iPipeline is currently experiencing technical issues and cannot retrieve any products.',
        listItem4:
          'There may be a problem with this client which is causing iPipeline to fail. Incomes may not be set up, or occupation may be missing.',
        footer: 'Please try again later, and if the problem persists, you can <a>contact customer support here</a>',
      },
    },

    clientOrganisationForm: {
      header: 'Company',
      heading: 'Company details',
      companyDetails: 'Company details',
      companyDirectors: 'Company directors',
      saveButton: 'Save company',
      noResults: 'No companies match your input',
      manualButton: {
        useManual: 'Enter manually',
        useLookup: 'Use lookup',
      },
      label: {
        companyName: 'Company name',
        natureOfBusiness: 'Nature of business (SIC code)',
      },
      placeholder: {
        companyName: 'Start typing...',
      },
      mortgagePanel: {
        whichNetwork: 'Which Network is your company a member of?',
        excludeLenders: 'Exclude lenders',
        selectLenders: `Select lenders you don't want to appear in sourcing. You can always change these later.`,
        wholeOfMarketInc: 'Whole of market (including direct)',
        wholeOfMarketExc: 'Whole of market (excluding direct)',
        comprehensive: 'Comprehensive',
        no_mortgage_advice: 'Mortgage advice not provided',
        defaultClub: 'Default club',
        defaultClubNotProvided: 'Default club is not provided',
        setYourBaseMortgagePanel: 'Set your base mortgage panel',
        companyDefaultClub: `What is your company's default club?`,
        companyMemberOf: 'Which other clubs is your company a member of?',
        basePanel: 'Which best describes your base panel?',
        clubNotFound: 'Club not found',
        lenderNotFound: 'Lender not found',
        clubName: 'Club name',
        paymentStage: 'When do we get paid?',
        paymentStages: {
          applicationSubmitted: 'Application submitted',
          awaitingOffer: 'Awaiting offer',
          awaitingValuation: 'Awaiting valuation',
          collected: 'Collected',
          complete: 'Complete',
          exchange: 'Exchange',
          importedComplete: 'Imported complete',
          importing: 'Importing',
          lead: 'Lead',
          notProceeding: 'Not proceeding',
          offerReceived: 'Offer received',
        },
      },
    },

    addClientForm: {
      searchOrCreate: 'Search or create new',
      addingClientsToCase: 'Adding client to case',
      addButton: 'Add Client',
      header: 'Add New Client',
      searchExistingClients: 'Search existing clients',
      createNewClient: 'Create new client',
      headerPlusVariant: '+ Add a client',
      cannotAddClient: 'Cannot add client',
      unableToAddClientSr: 'Unable to add a client to a case with an active Suitability Report',
      basicDetails: 'Basic details',
      address: 'Address',
      confirmDetails: 'Confirm details',
      searchExisting: 'Search existing',
      placeholder: {
        name: 'First name',
        middleName: 'Middle name(s)',
        surname: 'Surname',
        email: 'Email address',
        phone: 'Phone number',
      },
      label: {
        name: 'First name',
        middleName: 'Middle name(s)',
        surname: 'Surname',
        DOB: 'Date of birth',
        email: 'Email address',
        phone: 'Phone',
        mobilePhone: 'Mobile phone',
        homePhone: 'Home phone',
        role: 'Role',
      },
      roles: {
        director: 'Director',
        psc: 'Person of significant control (PSC)',
        directorAndPsc: 'Director and PSC',
        regularClient: 'Regular client',
      },
      phoneOrEmail: 'You need to capture at least one of the following',
      emailError: 'Invalid email',
    },

    removeCompany: {
      removeCompany: 'Remove company',
      cancel: 'Cancel',
      yesRemove: 'Yes, remove',
      modalText:
        'Are you sure you want to remove this company? This will revert the case to a regular Buy To Let with the directors as clients.',
    },

    clientOverview: {
      error: 'Failed to load client.',
      backToList: 'Back to client list',
      tasks: 'Tasks',
      history: 'History',
      taskActionButton: 'Do it now',
      taskPlaceholder: 'No upcoming tasks',
      historyPlaceholder: 'No historical tasks',
      idd: {
        showVerbalIdd: 'Show verbal disclosure',
        title: 'Verbal disclosure',
        checkbox: 'Client has heard and accepted the above',
        submit: 'Complete verbal disclosure',
      },
    },

    caseOverview: {
      headings: {
        recommendation: 'Recommendation',
        tasks: 'Tasks',
        noTasks: 'No tasks',
        markAccepted: 'Mark accepted',
        noEsisDocumentAvailable: 'No ESIS document available',
        availableProducts: 'Available products',
      },
      verifications: {
        noTasksDescription: 'There are no tasks you need to complete',
        tasksDescription: 'These are the tasks you need to complete before progressing the case onto the next stage.',
        youHaveToComplete: 'You have to complete',
        submitCaseToEnbs: 'Submit case to ENBS',
        enbsSubmitText: `You're about to submit your case to Electronic New Business Submission. Please check all details are correct and that the case is ready.`,
        complete: 'Case submitted to ENBS',
        submitForPreSales: 'Submit case for review',
        completeCase: 'Complete case',
        giTransitions: {
          recommendation: {
            taskName: 'Move case to Pre-application',
            modalMessage:
              "Are you ready to move your case to Pre-application? - Case status will be updated to 'Pre-application'.",
          },
          applicationSubmitted: {
            taskName: 'Confirm Application Submitted',
            modalMessage:
              "Please confirm the application has been successfully submitted to the insurer - Case status will be updated to 'Application Submitted'.",
          },
          complete: {
            taskName: 'Complete case',
            modalMessage:
              "Please confirm that you want to mark this case as complete - Case status will be updated to 'Completed'.",
          },
        },
        protectionTransitions: {
          applicationSubmitted: {
            taskName: 'Confirm Application Submitted',
            modalMessage:
              "Please confirm the application has been successfully submitted to the insurer - Case status will be updated to 'Application Submitted'.",
          },
          unknownTransition: {
            taskName: 'Unexpected transition for case type.',
            modalMessage: 'The request transition is unexpected. Please contact Acre support.',
          },
        },
        transitions: {
          applicationSubmitted: {
            taskName: 'Confirm Application Submitted',
            modalMessage:
              "Please confirm the application has been successfully submitted to the lender - Case status will be updated to 'Application Submitted'.",
          },
          awaitingValuation: {
            taskName: 'Confirm Valuation Instructed',
            modalMessage:
              "Please confirm the valuation has been instructed - Case status will be updated to 'Awaiting Valuation'.",
          },
          awaitingOffer: {
            taskName: 'Confirm Valuation Received',
            modalMessage: "Please confirm valuation received - Case status will be updated to 'Awaiting Offer'.",
          },
          offered: {
            taskName: 'Confirm Offer Received',
            modalMessage: "Please confirm Mortgage Offer has been received - Case status will be updated to 'Offered'.",
          },
          exchange: {
            taskName: 'Confirm Exchanged',
            modalMessage: "Please confirm exchange - Case status will be updated to 'Exchanged'.",
          },
          complete: {
            taskName: 'Complete case',
            modalMessage:
              "Please confirm that you want to mark this case as complete - Case status will be updated to 'Completed'. You cannot undo this.",
          },
          unknownTransition: {
            taskName: 'Unexpected transition for case type.',
            modalMessage: 'The request transition is unexpected. Please contact Acre support.',
          },
        },
        areYouSureYouWantToMarkThisCaseAsComplete:
          'Are you sure you want to mark this case as complete? You cannot undo this.',
        yesMarkMyCaseAsComplete: 'Yes, mark my case as complete',
      },
      buttons: {
        preSalesCheck: 'Submit for review',
        markAcceptance: 'Mark acceptance',
        markRecommendationAccepted: 'Mark recommendation accepted',
        confirmPreSalesCheck: 'Yes, submit case for review',
      },
      checkboxes: {
        labels: {
          markAcceptanceConfirmation:
            'I confirm that my client has seen and understood this recommendation and that they accept the recommendation as the one they wish to apply for.',
        },
      },
      placeholders: {
        esisVerified: 'ESIS document verified',
      },
      summary: {
        caseDetails: 'Case details',
        clients: 'Clients',
        adviser: 'Advisor',
        property: 'Property',
        status: 'Status',
        type: 'Case type',
        purchasePrice: 'Purchase price',
        deposit: 'Deposit',
        applicationDate: 'Application date',
        amend: 'Amend',
        offerExpiryDate: 'Offer expiry date',
        clientPortal: 'Client portal',
        bookAMeeting: 'Book a meeting',
        sendInvite: 'Send invite',
        sendMeetingLink: 'Send client scheduling link',
        leadSource: 'Lead source',
        assignLead: 'Assign lead',
        clientPhoneNumber: 'Client phone number',
        clientEmail: 'Client email',
        emailClient: 'Email',
        proceedCase: 'Proceed case',
        leadsDetails: 'Lead details',
        leadsSendClienPortalInvite: 'Send client portal',
        leadsMeeting: 'Meeting',
        leadsDeliverIdd: 'Deliver IDD',
        linkedCases: 'Linked cases',
        inviteWasSent: 'Invite sent on',
        noInviteSentYet: 'No invite has been sent',
        seeNotesSection: 'See the "Notes" section for more details',
        leadsEmailModal: {
          emailSubject: `Your invite from {orgName}`,
          selectTemplate: 'Select template',
          selectClients: 'Select clients to email',
          generateEmail: 'Generate email',
        },
        ONFIDO: 'Onfido',
        ACRE_DATA_IMPORTER: 'Acre data importer',
        MORTGAGES_360: 'Mortgages 360',
        INTELLIFLO: 'Intelliflo',
        PIPEDRIVE: 'Pipedrive',
        SMARTR_365: 'Smartr 365',
        XPLAN: 'XPlan',
        THE_KEY: 'The Key',
        EQUIFAX: 'Equifax',
        GOCARDLESS_MANDATE: 'GoCardless Mandate',
        ZAPIER: 'From Zapier',
        mortgageIds: 'Mortgage ID(s)',
        onlineLeadSource: 'Online Lead Source',
        onlineLeadCampaign: 'Online Lead Campaign',
        onlineLeadContent: 'Online Lead Content',
        mandateStatus: 'DD Mandate Status',
        mandateStatusNo: 'Not set up',
        mandateStatusYes: 'Active',
      },
      flagsReview: {
        title: 'Flags',
        button: {
          clear: 'Clear',
        },
        noFlags: 'No flags present',
      },
      modalConfirmations: {
        submitForPreSalesConfirmation: `Are you sure you want to submit the case for review? This will lock the case until all flags have been cleared.`,
      },
      clientInvitation: {
        missingPhoneNumber: `You can make your client's portal more secure if you add a phone number to their file. We suggest you do this before you invite them.`,
        sendEmail: 'Send invitation',
        clientPortalInvite: 'Generate a client portal invitation',
        clientInvitationModalText: 'Select the clients to invite:',
        emailSubjectLine: `Your client portal invite from {orgName}`,
        emailClients: 'Email clients',
        clientPortalInviteAlt: 'Client portal invite',
        creditReferral: 'Refer for credit report retrieval',
        clientAndNNClientHaveSameEmail:
          'Your client and Ltd company cannot have the same email address. Please change one of them, then you can send invite.',
        invitationNotSent: 'Invitation is not sent. Please check that you are sending it to the correct client.',
      },
      meetingInvite: {
        titleLabel: 'Meeting title',
        clientSelectLabel: 'Client',
        advisorSelectLabel: 'Advisor(s)',
        sendInviteButton: 'Send invite',
      },
    },

    product: {
      modal: {
        noProductNotesAvailable: 'No product notes available',
        headings: {
          productDetails: 'Product details',
          productNotes: 'Product notes',
          procurationFeeDetails: 'Procuration fee details',
          productConditions: 'Product conditions',
          mortgageRequirementDetails: 'Mortgage requirement details',
          employmentAndIncomeDetails: 'Employment and income details',
          feeDetails: 'Fee details',
          propertyDetails: 'Property details',
          cashbackDetails: 'Cashback details',
        },
        fields: {
          // Product details
          lenderProductReference: 'Lender product reference',
          lenderName: 'Lender name',
          lenderCode: 'Lender code',
          lenderTelephoneNo: 'Lender telephone no',
          lenderWebAddress: 'Lender web address',
          applyOnline: 'Apply online',
          lenderCriteriaUrl: 'Lender criteria URL',
          affordabilityCalculator: 'Affordability calculator',
          productCode: 'Product code',
          productName: 'Product name',
          productType: 'Product type',
          mortgageClass: 'Mortgage class',
          dateLastModified: 'Date last modified',
          productAvailability: 'Product availability',
          productAvailableDate: 'Product available date',
          productDiscontinuedDate: 'Product discontinued date',
          initialPayRate: 'Initial Pay rate',
          initialRatePeriodMonths: 'Initial rate period months',
          initialMonthlyPayment: 'Initial monthly payment',
          trueCostOverInitialPeriod: 'True cost over initial period',
          trueCostFullTerm: 'True cost full term',
          trueCost: 'True cost',
          aprc: 'APRC',
          standardVariableRate: 'Standard variable rate',
          fullRateDescription: 'Full Rate Description',
          maximumLtvAvailable: 'Maximum LTV Available',
          capitalRestPeriod: 'Capital rest period',
          packagerName: 'Packager name',
          packagerTelNo: 'Packager tel no',
          packagerWebAddress: 'Packager web address',
          monthlyPaymentAfterInitalPeriodPlus_1: 'Monthly payment after initial period plus 1%',
          monthlyPaymentAfterInitalPeriodPlus_2: 'Monthly payment after initial period plus 2%',
          monthlyPaymentAfterInitalPeriodPlus_3: 'Monthly payment after initial period plus 3%',
          stressTestPayments: 'Stress test payments',
          // Procuration fee details
          calculatedNetProcurationFee: 'Calculated net procuration fee',
          calculatedGrossProcurationFee: 'Calculated gross procuration fee',
          procurationFeeCode: 'Procuration fee code',
          grossProcurationFeeFixed: 'Gross procuration fee fixed',
          grossProcurationFeePercentage: 'Gross procuration fee percentage',
          grossProcurationFeeMin: 'Gross procuration fee min',
          grossProcurationFeeMax: 'Gross procuration fee max',
          // Product conditions
          isOffsetProduct: 'Is offset product',
          isFurtherAdvance: 'Is further advance',
          isPorting: 'Is porting',
          maxmimumOverpaymentNoErc: 'Maximum over-payment % no ERC',
          overpaymentsAllowed: 'Over-payments allowed',
          underpaymentsAllowed: 'Under-payments allowed',
          paymentHolidaysAllowed: 'Payment holidays allowed',
          availableToFirstTimeBuyers: 'Available to first time buyers',
          availableToPurchase: 'Available to purchase',
          availableToRemortgage: 'Available to remortgage',
          repaymentAcceptable: 'Repayment acceptable',
          optionalPartialRepayment: 'Optional partial repayment',
          helpToBuySharedEquityAllowed: 'Help to buy shared equity allowed',
          helpToBuyMortgageGuaranteeAllowed: 'Help to buy mortgage guarantee allowed',
          applicantTypeConditions: 'Applicant Type Conditions',
          applicant1MinimumAge: 'Applicant 1 minimum age',
          applicant2MinimumAge: 'Applicant 2 minimum age',
          applicantMaximumAge: 'Applicant maximum age',
          ccjMaximumNumber: 'CCJ maximum number',
          ccjMaximumAmount: 'CCJ maximum amount',
          minTimeSinceLastClientArrears: 'Min time since last client arrears',
          arrearsNotInLastMonths: 'Arrears not in last months',
          rejectReasons: 'Reject reasons',
          // Mortgage requirement details
          maximumLoanAvailable: 'Maximum loan available',
          minimumLoanAvailable: 'Minimum loan available',
          buyToLetIncomeCalculationRate: 'Buy to let income calculation rate',
          remortgageHomeImprovementsMaxLtv: 'Remortgage home improvements max LTV',
          remortgageDebtConsolidationMaxLtv: 'Remortgage debt consolidation max LTV',
          remortgageBusinessPurposesMaxLtv: 'Remortgage business purposes max LTV',
          remortgageHolidaysCarsMaxLtv: 'Remortgage Holidays/Cars max LTV',
          // Employment and income details
          applicant1IncomeMultiplier: 'Applicant 1 income multiplier',
          applicant2IncomeMultiplier: 'Applicant 2 income multiplier',
          jointApplicationIncomeMultiplier: 'Joint application income multiplier',
          minimumTimeInContinuousServiceMonths: 'Minimum time in continuous service (months)',
          minimumTimeInCurrentPositionMonths: 'Minimum time in current position (months)',
          employedProbationPeriod: 'Employed probation period',
          minimumSelfEmployedAccountsYears: 'Minimum self-employed accounts (years)',
          selfEmployedYearsAverageIncome: 'Self-employed years average income',
          // Fee details
          feesTotal: 'Fees total',
          arrangementFee: 'Arrangement fee',
          arrangementFeeConditions: 'Arrangement fee conditions',
          bookingFee: 'Booking fee',
          bookingFeeConditions: 'Booking fee conditions',
          brokerFee: 'Broker fee',
          brokerFeeConditions: 'Broker fee conditions',
          deedsReleaseFee: 'Deeds release fee',
          deedsReleaseFeeWhenToPay: 'Deeds release fee when to pay',
          disbursementFee: 'Disbursement fee',
          disbursementFeeConditions: 'Disbursement fee conditions',
          earlyRepaymentCharge: 'Early repayment charge',
          earlyRepaymentChargeApplies: 'Early repayment charge applies',
          earlyRepaymentChargeAppliesUntil: 'Early repayment charge applies until',
          earlyRepaymentChargeAppliesUntilCalculatedDate: 'Early repayment charge applies until calculated date',
          feesPayableOnApplication: 'Fees payable on application',
          feesPayableOnCompletion: 'Fees payable on completion',
          chapsFee: 'CHAPS fee',
          fundsTransferFeeRule: 'Funds transfer fee rule',
          higherLenderCharge: 'Higher lender charge',
          higherLendingChargeConditions: 'Higher lending charge conditions',
          homebuyersFee: 'Homebuyers fee',
          homebuyerFeeConditions: 'Homebuyer fee conditions',
          legalFee: 'Legal fee',
          legalFeeConditions: 'Legal fee conditions',
          freeLegalFees: 'Free legal fees',
          freeLegalFeesConditions: 'Free legal fees conditions',
          mortgageDischargeFee: 'Mortgage discharge fee',
          mortgageDischargeFeeApplies: 'Mortgage discharge fee applies',
          valuationFee: 'Valuation fee',
          valuationFeeConditions: 'Valuation fee conditions',
          // Property details
          sharedOwnershipAcceptable: 'Shared ownership acceptable',
          maxPropertyValuation: 'Max property valuation',
          studioFlat: 'Studio flat',
          flatOverShop: 'Flat over shop',
          flatOverShopNotes: 'Flat over shop notes',
          flatOverShopOwner: 'Flat over shop owner',
          flatOverShopSeparateEntrance: 'Flat over shop separate entrance',
          flatOverShopSeparateTitle: 'Flat over shop separate title',
          flatOverShopRestaurant: 'Flat over shop/restaurant',
          flatOverShopRetail: 'Flat over shop retail',
          flatOverShopFastFood: 'Flat over shop fast food',
          flatOverShopIndustryUnit: 'Flat over shop industry unit',
          flatMaxFloors: 'Flat max floors',
          flatMaxFloorsNoLift: 'Flat max floors no lift',
          productAvailableForSelfBuild: 'Product available for self build',
          freeholdAcceptable: 'Freehold acceptable',
          leaseholdAcceptable: 'Leasehold acceptable',
          commonholdAcceptable: 'Commonhold acceptable',
          feudalAcceptable: 'Feudal acceptable',
          flyingFreeholdAcceptable: 'flying freehold acceptable',
          flyingFreeholdMax: 'Flying freehold max %',
          // Cashback details
          cashback: 'Cashback',
          cashbackConditions: 'Cashback conditions',
          cashbackPaidOn: 'Cashback paid on ',
        },
      },
      evidenceOfResearch: {
        viewEvidenceOfResearch: 'View evidence of research',
        headings: {
          evidenceOfResearch: 'Evidence of research',
          requirements: 'Requirements',
          searchFilters: 'Search filters',
          trueCostDetails: 'True cost details',
          selectedProduct: 'Selected product',
          searchResults: 'Search results',
        },
        fields: {
          // Requirements
          product: 'Product',
          loanPurpose: 'Loan purpose',
          purchasePrice: 'Purchase price',
          deposit: 'Deposit',
          loanRequired: 'Loan required',
          loanToValue: 'Loan to value',
          loanTermYears: 'Loan term (years)',
          // Search filters
          productTypes: 'Product types',
          paymentMethod: 'Payment method',
          productPeriod: 'Product period',
          preferences: 'Preferences',
          situations: 'Situations',
          custom: 'Custom ({from} - {to} months)',
          customOnlyFrom: 'Custom (> {from} months)',
          customOnlyTo: 'Custom (< {to} months)',
          // True cost details
          trueCostCalculation: 'True cost calculation',
          feeDetails: 'Fee details',
          // Selected product
          lenderName: 'Lender name',
          productName: 'Product name',
          initialPayRate: 'Initial pay rate',
          initialMonthlyPayment: 'Initial monthly payment',
          standardVariableRate: 'Standard variable rate',
          productRank: 'Product rank',
          sorting: 'Sorting',
        },
        table: {
          lender: 'Lender',
          initialRate: 'Initial rate',
          rateType: 'Rate type',
          initialPeriod: 'Initial period',
          initialMonthlyPayment: 'Initial monthly payment',
          totalFees: 'Total fees',
          trueCost: 'True cost (initial period)',
          trueCostMonths: 'True cost over {months} months',
        },
      },
    },

    DateError: {
      underAge: 'Must be 18 years or older',
      overAge: 'Date must be within last 125 years',
      future: 'Date cannot be in the future',
      past: 'Date cannot be in the past',
      mustBeInFuture: 'Date must be in the future',
      input: 'Input must be number',
      day: 'Enter day of the month (1-31)',
      month: 'Enter month (1-12)',
      generic: 'Invalid date',
      before: 'Must be before the end date',
      after: 'Must be after the start date',
      beforeLabel: '{first} must be before {second}',
      afterLabel: '{first} must be after {second}',
      beforeTermEnd: 'Initial period must end before mortgage term',
      endAfterStartDate: 'End date must be after start date',
      startBeforeEndDate: 'Start date must be before end date',
      renewalCommissionStartDateMustBeGreaterThanInitialCommissionEndDate:
        '{label} must be greater than {initialCommissionEndDate}.',
      numberOfMonthsRenewalCommissionStartsIn:
        '{label} must be greater than number of months initial commission received for ({n}).',
      dateRegisteredAfterDateSatisfied: 'Default date registered cannot be after date satisfied',
      startDateAfterDateRegistered: 'Start date cannot be after date registered',
      endDateBeforeDateRegistered: 'End date cannot be before date registered',
      endDateBeforeDateSatisfied: 'End date cannot be before date satisfied',
      endDateBeforeDateLastArrear: 'End date cannot be before date of last arrears',
      endDateBeforeDateArrearsCleared: 'End date cannot be before date arrears cleared',
      startDateBeforeBusinessEstablished: 'Start date cannot be before business established',
      dateArrears: 'Date arrears cleared cannot be before date of last arrears',
      validDateRequired: 'A valid MM/YYYY date is required',
      startDateAfterLastArrears: 'Start date must be before date of last arrears',
      startDateAfterArrearsCleared: 'Start date cannot be after arrears cleared',
      balanceDateAfterStart: 'Outstanding balance date must be after mortgage start date',
      withinSevenDayRange: 'End date must be within seven days of the start date',
      withinPastNinetyDays: 'Start date must be within the past 90 days',
    },

    settings: {
      title: 'Firm settings',
      nav: {
        company: 'Company',
      },
      company: {
        upload: 'Upload logo',
        remove: 'Remove',
      },
    },

    errors: {
      amountsSetToMax: 'Amounts set to maximum',
      selectPanel: 'Please select panel before you proceed',
      validateMortgageAccountNumber: 'Please enter a valid account number',
      cannotCompleteCase: "We've encountered an error. Case cannot be completed. Please try again or contact support.",
      cannotBothBeTrue: 'Cannot have both Consolidate and Repaid before mortgage starts set to True at the same time',
      accountNumber: "Account numbers should be in the format 'xxxxxxxx'",
      clientIsNotOnThisBenefit: 'Client(s) is not on this benefit',
      couldNotRemoveRecommendation: 'Could not remove recommendation. Please try again or contact support.',
      couldNotRemoveSolution: 'Could not remove solution. Please try again or contact support.',
      couldNotSave: 'Could not save. Please try again or contact support.',
      couldNotUpload: 'Could not upload document. Please try again or contact support.',
      email: `Enter an email address in the correct format, like name@email.com`,
      noCredentialEmail: 'Unable to book event. Nylas and Graph need to be set up with the same email address.',
      generic: 'Something went wrong',
      protectedFieldError: 'Function required to clean protected fields',
      groundRentExceedsValuation: 'Ground rent exceeds property valuation',
      inputMustBeLetter: 'Input must be a letter',
      inputMustBeNumber: 'Input must be a number',
      inputMustBePositiveNumber: 'Input must be a positive number',
      insufficient: 'Amount is insufficient',
      greaterThan: '{first} must be greater than {second}',
      greaterThanZero: '{first} must be greater than zero {second}',
      mustBeGreaterThanZero: 'Must be greater than zero',
      greaterThanAbsoluteZero: '{first} must be greater than zero',
      integer: '{label} must be a whole number',
      interestRateTooHighForMortgage: 'Interest rate is too high for a mortgage (>40%)',
      invalidMonth: 'Please enter a valid number. This should be between 1 and 900',
      invalidProduct: 'Please select a valid product',
      lessThan: '{first} must be less than {second}',
      maxFloors: 'Number of floors exceeds highest UK building',
      noOptions: 'No options found',
      nonNegative: '{label} cannot be negative',
      oneOfTypeAccountNumberSortCode:
        'Please provide at least one of the following fields: account type, account number or sort code',
      percentage: 'Entered percentage must be 100% or less',
      percentage99: 'Entered percentage must be 99% or less',
      lessThanLoanAmount: 'Entered amount must be less than total loan amount',
      phone: 'Please enter a valid phone number',
      positive: '{label} must be positive',
      radio: 'Please select an option',
      required: 'This field is required',
      benefitRequired: 'At least one benefit is required',
      select: 'Please select an item',
      serviceChargeExceedsValuation: 'Service charge exceeds property valuation',
      sortCode: "Sort codes should be in the format 'xxxxxx', 'xx-xx-xx' or 'xx xx xx'",
      upload: 'Please upload a document',
      url: 'Please enter a valid URL',
      validValue: 'Please enter a valid value',
      yearBuiltTooFarInFuture: 'Year cannot be more than three years in the future',
      invalidFormat: 'Invalid format',
      loanToValueError: `Loan to value shouldn't be over 100%`,
      nameChangeDateExceedsDob: 'Year must be greater than year of birth',
      clientDobExceedsChildDob: 'Client cannot be younger than the dependant child',
      clientDobSubceedsParentDob: "Child's date of birth is before parent's",
      clientDobExceedsMoveInDate: 'Move in date cannot be before date of birth',
      missingFieldRequired: '*Required',
      incompleteField: '*Incomplete',
      missingRequiredPropertyToBuy: '*Missing required property to buy',
      missingRequiredPropertyToSell: '*Missing required property to sell',
      missingRequiredPropertyToSellAndBuy: '*Missing required property to sell and buy',
      missingRequiredPropertyToRemortgage: '*Missing required property to remortgage',
      missingRequiredRateTiers: '*Missing required product rates tiers',
      mobileOrOfficePhoneRequired: 'Mobile or office phone number is required',
      orgNameTooLong: 'Organisation name is too long. Cannot exceed 38 characters',
      userWithEmailExists: 'A user with this email address already exists',
      fcaNumber: 'FCA number must be 6-8 digits',
      greaterThan0OnPartAndPartMortgage: '{first} must be greater than zero on a part and part mortgage',
      mustSelectConveyancingType: 'You must select a conveyancing type',
      atLeastOneClientRequiredForReferral:
        'At least one client needs to be selected in order to be able to create the referral',
      coverAmount: 'Please enter a number between £5.00 and £99,999.99',
      termLength: 'Income protection terms cannot be below 5 years',
      coverAmountAnnual: 'The minimum cover amount is £1,000',
      termLengthFamilyBenefit: 'Term cannot be below 1 year',
      oneOfDebtFieldsRequired: `One of 'Creditor Name', 'Amount Outstanding' or 'Account Number' is required`,
      invalidRetirementAge: 'Expected retirement age must be a number',
      isMoreThanSeventy: 'Age cannot be above 70',
      isMoreThanNineNine: 'Cannot be above 99',
      isTermMoreThanSeventy: 'Term cannot be above 70',
      termMoreThanAge: 'The sum of age and term length, cannot exceed 70',
      thresholdsOverlap: 'Thresholds cannot overlap',
      unableToFindQuote: 'Unable to find quote results',
      unableToSourceForWOL: 'Whole of life quoting is unavailable',
      completeQuoteDetails: 'Complete requirements form to quote',
      hundredPercentNotAllowed: 'The percentage of protection commission cannot be 100% or over',
      noSupportForDebtConsolidation:
        'Debt to be consolidated is not supported on limited company BTL remortgage cases. Please contact support.',
      noSupportForDebtConsolidationAdditionalBorrowing:
        'Additional borrowing for debt consolidation is not supported on limited company BTL remortgage cases. Please contact support.',
      englandAndWales: '"England and Wales" will soon be removed as an option, please update to a specific country',
      dueDateOutOfValidYearRange: 'Due date is invalid, year must be between {startYear} and {endYear}',
    },

    templates: {
      networkPrivacyNotice: 'Network privacy notice',
      organisationPrivacyNotice: 'Organisation privacy notice',
      idd: 'IDD',
      verbalIdd: 'Initial disclosure',
      welcomeEmail: 'Welcome email',
      invalidTemplateName: 'No description provided',
      esis: 'ESIS',
      suitabilityReport: 'Suitability report',
      complianceNotificationEmail: 'Compliance notification email',
      offerLetter: 'Mortgage Offer letter',
      livePhoto: 'Live photo',
      clientPortalIdd: 'Client portal IDD',
      protectionSuitabilityReport: 'Health and Protection suitability report',
      clientEmails: 'Client Emails',
      schedulingEmail: 'Scheduling email',
      factFind: 'Fact find document',
    },

    warnings: {
      unsavedChanges: 'Unsaved changes',
    },
    buttons: {
      saveAndContinue: 'Save and continue',
      saveChanges: 'Save changes',
      saveAndClose: 'Save and close',
      confirmAndRunChecks: 'Confirm and run checks',
      hideAdditionalDetails: 'Hide additional details',
      viewAdditionalDetails: 'View additional details',
      singleFileUpload: {
        label: 'Drag and drop file, or click to select file',
        removeLabel: 'Remove all files',
      },
    },
    caseNew: {
      title: 'Create new case',
      caseDetails: 'Case details',
      caseFees: 'Case fees',
      applicants: 'Applicants',
      subApplicants: 'Create new clients or add existing ones to this case',
      addClients: 'Add client(s)',
      addNew: 'Add new',
      advisors: 'Assign to advisor',
      noAdvisors: 'No advisors available',
      subAdvisors: 'Select which advisor should own this case',
      addRelatedUser: 'Add related roles to the selected cases',
      addRelatedUserNote: '(these will be in addition to any roles already on the cases)',
      searchAdvisors: 'Search advisors',
      addAdvisor: 'Advisor',
      addReferrer: 'Referrer',
      addAdmin: 'Admin',
      addLeadQualifier: 'Lead qualifier',
      addWatcher: 'Watcher',
      addReviewer: 'Reviewer',
      noClients: 'No clients',
      setStatus: 'Set status to',
      advisorFieldHelperText:
        'You have selected 1 or more cases that are past pre-recommendation. Advisors cannot be reassigned without rolling back these cases to pre-recommendation.',
      search: {
        caseType: 'Search a case type',
        clients: 'Search clients',
        typeAdvisorsName: 'Select advisor',
        companies: 'Search Companies House',
      },
      form: {
        addClient: 'Add applicant',
        createCase: 'Create case',
        complete: 'Complete',
        input: {
          isCompanyCase: 'Is this a limited company case?',
          selectFeeScenario: 'Select fee scenario',
          mortgageReason: 'Product type',
          type: 'Case type',
          propertyValue: 'Property value',
          purchasePrice: 'Purchase price',
          newPropertyPurchasePrice: 'New property purchase price',
          deposit: 'Deposit',
          equity: 'Equity',
          loanAmount: 'Loan amount',
          previousProperty: 'Has previously owned property',
        },
        tooltip: {
          purchasePrice: 'If a Shared Ownership property, enter the value of the share the client is purchasing',
        },
      },
      getClients: {
        emailAddress: 'Email address',
        phone: 'Phone',
      },
    },

    productTypes: {
      noReasonProvided: 'No reason selected',
      firstTimeBuy: 'FTB or Purchase only',
      houseMove: 'House move',
      remortgage: 'Remortgage',
      buyToLet: 'Buy-to-Let',
      buyToLetRemortgage: 'Buy-to-Let remortgage',
      equityRelease: 'Equity release',
      furtherAdvance: 'Additional borrowing',
      businessProtection: 'Business protection',
      protection: 'Health and Protection',
      bridging: 'Bridging finance',
      commercial: 'Commercial mortgage',
      homeInsurance: 'Home insurance',
    },

    mortgageReason: {
      reasonFtb: 'First-time buy purchase',
      reasonHouseMove: 'House move',
      reasonRemortgage: 'Remortgage',
      reasonBtl: 'Buy-to-let',
      reasonBtlRemortgage: 'Buy-to-let remortgage',
      reasonEquityRelease: 'Equity release',
      reasonFurtherAdvance: 'Further advance',
      reasonBusinessProtection: 'Business protection',
      reasonProtection: 'Health and Protection ',
      invalidMortgageReason: 'Invalid mortgage reason',
      reasonCommercial: 'Commercial',
      reasonBridging: 'Bridging',
      reasonGeneralInsurance: 'Home insurance',
    },

    additionalLoanPurposes: {
      debtConsolidation: 'Debt consolidation',
      homeImprovements: 'Home improvements',
      buyOtherProperty: 'Buy other property',
      divorceSettlement: 'Divorce settlement',
      capitalRaising: 'Capital raising',
      car: 'Car',
      holiday: 'Holiday',
      schoolFees: 'School fees',
      businessPurposes: 'Business',
      staircasing: 'Staircasing',
      movingCosts: 'Moving costs',
      stampDuty: 'Stamp duty',
      other: 'Other',
      invalidLoanPurpose: 'Invalid loan purpose',
    },

    additionalBorrowing: {
      additionalBorrowingRequired: 'Additional borrowing required',
      additionalLoanPurposes: 'Additional loan purposes',
      additionalLoanAmount: 'Additional loan amount',
      additionalBorrowing: '+ Additional borrowing',
    },

    mortgageRequirements: {
      propertyValue: 'Property value',
      outstandingBalance: 'Outstanding balance',
      preferenceTargetDeposit: 'Deposit',
      preferenceTargetEquityDeposit: 'Equity/Deposit',
      preferenceLoanAmount: 'Mortgage loan amount',
    },

    houseMoveRequirements: {
      newPropertyPurchasePrice: 'New property purchase price',
      equity: 'Equity and additional deposit',
      preferenceLoanAmount: 'Mortgage loan amount',
    },

    loanDetails: {
      heading: 'Loan details',
      equity: 'Equity',
      outstandingBalance: 'Outstanding balance',
      additionalBorrowing: 'Additional borrowing',
      requiredBorrowing: 'Required borrowing',
      loanToValue: 'Loan to value',
    },

    search: {
      noResultsFound: 'No results found',
      clear: 'Clear',
      createANewClient: 'Create a new client',
      addingCompanyNotAllowed: 'A company client cannot be added to a company case',
    },

    common: {
      addressLookup: {
        line1: 'Address line 1',
        line2: 'Address line 2',
        line3: 'Address line 3',
        town: 'Town',
        postcode: 'Postcode',
        country: 'Country',
        lookupLabel: 'Address lookup',
        lookupButton: 'Lookup',
        manualButton: 'Enter manually',
        noResults: 'No results. Please enter your address manually.',
        employerLabel: 'Employer Address',
      },
      error: 'Error',
      addresses: 'Addresses',
      validation: {
        isBetweenPositive0And100: 'Please enter a value between 0 and 100',
      },
    },

    dateRangeSelector: {
      addFilter: 'Add filter',
      applyFilter: 'Apply filter',
      clearAll: 'Clear all',
      from: 'From',
      to: 'To',
      options: {
        today: 'Today',
        thisWeek: 'This week',
        thisMonth: 'This month',
        thisQuarter: 'This quarter',
        thisYear: 'This year',
        dateRange: 'Date range',
        customDateRange: 'Custom date range',
      },
      caseStatus: {
        changedStatus: 'Changed status',
        created: 'Created',
        submitted: 'Submitted',
        recommended: 'Recommended',
        reviewed: 'Reviewed',
        completed: 'Completed',
        offered: 'Offered',
        exchanged: 'Exchanged',
      },
      caseStatusOptionsNote: 'Show cases that:',
      dateRangeOptionsNote: 'Within the following date range:',
    },

    searchSelector: {
      clearAll: 'Clear all',
      selectAll: 'Select all',
      clear: 'Clear',
    },

    wizard: {
      next: 'Next',
      prev: 'Previous',
      complete: 'Complete',
    },

    protection: {
      heading: 'Health and Protection ',
      common: {
        areYouSure: 'Are you sure?',
        removeRecommendationDisabled:
          'Recommended Product can only be removed in status "Pre-Recommendation" as Suitability Report will require reissue.',
        submitRecommendationDisabled:
          'Shortlisted Product can only be recommended in status "Pre-Recommendation" as Suitability Report will require reissue.',
        submitRecommendationDisabledDuePSR:
          'Shortlisted Products can only be recommended when you have not published a Protection Suitability Report.',
        actions: 'Actions',
        markAsComplete: 'Mark as complete',
        markAsRecommended: 'Mark as recommended',
        pleaseCompleteAsNormal: 'Please complete case as normal',
        warnings: 'Warnings',
        addProduct: '+ Add a product',
        viewProduct: 'View',
        resource: 'Re-source',
        deferredPeriod: 'Deferred period',
        canAddProducts:
          'You can manually add Health and Protection products by clicking on the button above or by sourcing protection products <link>here</link>.',
        addManualProduct: '+ Add a manual product',
        createProduct: 'Create a manual Health and Protection product',
        cancellationDate: 'Cancellation date',
        products: 'Products ({n})',
        noProducts: 'You currently have no products',
        employeeBenefit: 'Employee benefit',
        noProtectionProductDetails: 'No products were available for the selected benefits.',
        protectionProductDetails: 'Product details',
        confirmProtectionDetails: 'Confirm Health and Protection details {stage}',
        benefit: 'Benefit',
        benefitCoverAmount: `Cover amount`,
        benefitX: 'Benefit {x}',
        benefitXCoverAmount: 'Benefit {x} cover amount',
        benefitXLifeCoverAmount: 'Benefit {x} cover amount',
        benefitXCicCoverAmount: 'Benefit {x} cover amount',
        benefitXLOECCoverAmount: 'Benefit {x} cover amount',
        policyNumber: 'Policy number',
        hospitalList: 'Hospital list',
        productCommissionDetails: 'Product commission details',
        underwrittenPremiumAmountMonthly: 'Final underwritten premium (monthly)',
        underwrittenPremiumAmountAnnually: 'Final underwritten premium (annually)',
        numberOfMonthsReceivedInitialCommission: 'Number of months received for',
        initialCommissionAmount: 'Initial commission amount',
        specificDate: 'Specific date',
        months: 'Months',
        status: 'Status',
        termAge: 'Term to age',
        originallyQuotedPremium: 'Originally quoted premium',
        renewalCommissionAmount: 'Renewal commission amount (monthly)',
        commissionType: 'Commission type',
        renewalCommissionStartDate: 'Renewal commission start date',
        renewalCommissionStartsIn: 'Starts in',
        numberOfMonthsRenewalCommissionStartsIn: 'Number of months renewal commission starts in',
        nonIndemnified: 'Non-indemnified',
        indemnified: 'Indemnified',
        productDetails: 'Product details',
        protectionProviderLogo: 'Protection provider logo',
        recommendation: 'Recommendation',
        notProceeding: 'No longer proceeding',
        shortlist: 'Shortlist',
        current: 'Current',
        additionalCoverAmount: 'Additional cover amount',
        additionalDeferralPeriod: 'Additional deferral period',
        makeRecommendation: 'Make recommendation',
        markAsDeclined: 'Client declined',
        moveBackToRecommended: 'Move back',
        recommend: 'Recommend',
        saveProductDetails: 'Save product details',
        expires: `Expires {date}`,
        expired: `Expired {date}`,
        all: 'All',
        apply: 'Apply',
        justification: 'Justification',
        getDocumentsFromIPipeline: 'Get documents from iPipeline',
        cic: 'CIC',
        cicTerm: 'CIC term',
        client: 'Client',
        clients: 'Client(s)',
        clientNotFound: 'Client not found',
        confirmMakeRecommendation:
          'Please confirm that you want to recommend this product, as this will archive your existing Health and Protection suitability report.',
        confirmRemoveRecommendation:
          'Please confirm that you want to remove this recommendation, as this will archive your existing Health and Protection suitability report.',
        coverAmount: 'Cover amount',
        costAmount: 'Cost amount',
        unitsOfCover: 'Level of cover',
        costAmountFrequency: 'Cost amount frequency',
        cost: 'Cost',
        details: 'Details',
        criticalIllnessCover: 'Critical illness cover',
        decreasing: 'Decreasing',
        decreasingTerm: 'Decreasing term',
        decreasingTermAssurance: 'Decreasing term assurance',
        decrTerm: 'Decr. term',
        deferralPeriod: 'Deferral period',
        excludeLowStartPlans: 'Exclude low start plans',
        fractureCover: 'Fracture cover',
        guaranteed: 'Guaranteed',
        familyIncomeBenefit: 'Family income benefit',
        fib: 'FIB',
        incomeProtection: 'Income protection',
        ip: 'IP',
        joint: 'Joint',
        level: 'Level',
        levelTerm: 'Level term',
        levelTermAssurance: 'Level term assurance',
        life: 'Life',
        lifeDecreasing: 'Life (decreasing)',
        lifeLevel: 'Life (level)',
        lifeOnly: 'Life only',
        lifeOrEarlierCic: 'Life or earlier CIC',
        lifeOrEarlierCriticalIllness: 'Life or earlier critical illness',
        payoutTypeInvalid: 'Invalid type',
        lifeTerm: 'Life term',
        loec: 'LOEC',
        maximum: 'Maximum',
        payoutType: 'Payout type',
        policyExpiry: 'Policy expiry',
        policyInterestRate: 'Policy interest rate',
        premium: 'Premium',
        premiumPayments: 'Premium payments',
        premiumType: 'Premium type',
        product: 'Product',
        productType: 'Product type',
        provider: 'Provider',
        removeRecommendations: 'Remove recommendations',
        remove: 'Remove',
        removeProduct: 'Remove product',
        reviewable: 'Reviewable',
        reasonForNotProceeding: 'Reason for not proceeding',
        rpi: 'RPI',
        term: 'Term',
        term_age: 'To age',
        termLength: 'Term length',
        totalPermanentDisability: 'Total permanent disability',
        upload: 'Upload',
        uploadDocs: 'Upload docs',
        waiverOfPremium: 'Waiver of premium',
        wol: 'WOL',
        wholeOfLife: 'Whole of life',
        title: 'Title',
        policyStartDate: 'Policy start date',
        lifeCoverAmount: 'Life cover amount (lump sum)',
        criticalIllnessCoverAmount: 'Critical illness cover amount (lump sum)',
        lifeOrEarlierCicCoverAmount: 'Life or earlier CIC amount (lump sum)',
        lifeCoverAmountAnnual: 'Life cover amount (annual)',
        criticalIllnessCoverAmountAnnual: 'Critical illness cover amount (annual)',
        lifeOrEarlierCicCoverAmountAnnual: 'Life or earlier CIC amount (annual)',
        lifeCover: 'Life cover',
        lifeOrEarlierCicCover: 'LOEC',
        cicCover: 'CIC cover',
        coverAmountLife: 'Life cover',
        coverAmountCic: 'CIC cover',
        coverAmountLifeOrEarlierCic: 'LOEC',
        coverAmountLifeSource: 'Life cover amount',
        coverAmountCicSource: 'CIC cover amount',
        coverAmountLifeOrEarlierCicSource: 'LOEC amount',
        deferredPeriodSource: 'Deferred period',
        coverAmountSource: 'Cover amount',
        selectAmount: 'No amount selected',
        unableToEarlyComplete: 'Unable to early complete products (complete case as normal)',
        issueSR: 'Protection Suitability Report requires reissue',
        shortlistedCanOnlyBeRecommended: 'Shortlisted protection can only be recommended',
      },

      commission: {
        modalHeading: 'Commission settings',
        commissionType: {
          full: 'Full',
          modified: 'Modified',
          nil: 'None',
        },
        fields: {
          commissionAmount: 'Commission amount',
          percentage: 'Percentage',
          indemnityRequired: 'Indemnity required',
        },
      },

      businessProtection: {
        noProducts: 'You currently have no products',
        canAddProducts: 'You can manually add business protection products by clicking on the button above',
        createProduct: 'Create business protection product',
        policyDetails: 'Policy details',
        benefits: 'Benefits',
        clients: 'Clients',
        provider: 'Provider',
        productName: 'Product name',
        premium: 'Premium',
        policyNumber: 'Policy number',
        startDate: 'Start date',
        endDate: 'End date',
        additionalDetails: 'Additional Details',
        additionalNotes: 'Additional Notes',
        addProduct: 'Add a product',
        selectProduct: 'Select product to add',
        selectFrequency: 'Frequency',
        premiumFrequency: 'Premium frequency',
        coverFrequency: 'Cover frequency',
        coverAmount: 'Cover amount',
        term: 'Term',
        premiumType: 'Premium type',
        underwritingLevel: 'Underwriting level',
        excessAmount: 'Excess amount',
        frequencies: {
          weekly: 'Weekly',
          monthly: 'Monthly',
          quarterly: 'Quarterly',
          annually: 'Annually',
          once: 'Once',
          invalid: 'Invalid',
        },
        benefitOptions: {
          groupLife: 'Group Life',
          groupPMI: 'Group PMI',
          groupASU: 'Group ASU',
          groupCIC: 'Group CIC',
          groupIP: 'Group IP',
          relevantLife: 'Relevant Life',
          keyMan: 'Key man dependency',
          PMI: 'Private Medical Insurance',
          groupSP: 'Shareholder protection',
          productTypeGroupPrivateMedicalInsurance: 'Group private medical insurance',
          employeeBenefit: 'Employee benefit',
        },
      },
      underwritingLevel: {
        underwritingLevelFullMedicalUnderwriting: 'Full medical underwriting',
        underwritingLevelInvalid: 'Invalid underwriting level',
        underwritingLevelMedicalHistoryDisregarded: 'Medical history disregarded',
        underwritingLevelMoratorium: 'Moratorium',
        underwritingLevelContinuedPersonalMedicalExclusions: 'Continued personal medical exclusions',
        underwritingLevelContinuedMoratorium: 'Continued moratorium',
      },
      hospitalList: {
        hospitalListExtended: 'Extended',
        hospitalListInvalid: 'Invalid',
        hospitalListStandard: 'Standard',
        hospitalListReduced: 'Reduced',
        hospitalListGuided: 'Guided',
      },
      reasonEntity: {
        client: 'Client Declined',
        provider: 'Provider Declined',
      },
      productStatus: {
        current: 'Current',
        invalid: 'Invalid product status',
        proposed: 'Proposed',
        selected: 'Selected',
        notProceeding: 'Not proceeding',
      },
      coverUnits: {
        one: '1 Unit',
        two: '2 Units',
        three: '3 Units',
        four: '4 Units',
        five: '5 Units',
      },
      indexation: {
        invalid: 'Invalid indexation',
        rpi: 'RPI',
        fixed: 'Fixed',
        awei: 'AWEI',
        level: 'Level',
        zero: '0%',
        one: '1%',
        two: '2%',
        three: '3%',
        four: '4%',
        five: '5%',
        six: '6%',
        seven: '7%',
        eight: '8%',
        nine: '9%',
        ten: '10%',
        greaterThanZero: '> 0%',
      },
      deferredPeriod: {
        zero: 'Zero',
        oneWeek: 'One week',
        twoWeeks: 'Two weeks',
        oneMonth: 'One month',
        twoMonths: 'Two months',
        threeMonths: 'Three months',
        sixMonths: 'Six months',
        oneYear: 'One year',
        twoYears: 'Two years',
      },
      benefitBasis: {
        monthly: 'Monthly',
        maximum: 'Maximum',
      },
      premiumType: {
        invalid: 'Invalid premium type',
        guaranteed: 'Guaranteed',
        reviewable: 'Reviewable',
      },
      assumptions: {
        cover_amount_INCOME_PROTECTION: 'Cover amount (monthly)',
        cover_amount_FAMILY_INCOME_BENEFIT: 'Cover amount (annual)',
        cover_amount_TERM: 'Cover amount (lump sum)',
        cover_amount_WHOLE_OF_LIFE: 'Cover amount (lump sum)',
        cover_amount_depreciation_rate: 'Policy interest rate',
        deferral_period_days: 'Deferral period',
        include_limited_benefit_payment_period: 'Include limited benefit payment plans',
        include_low_start: 'Exclude low start plans',
        indexation_percentage: 'Indexation percentage',
        indexation_type: 'Indexation type',
        payout_type: 'Payout type',
        premium_frequency: 'Premium frequency',
        premium_type: 'Premium type',
        term_years: 'Term length',
        term_age: 'Term age',
        protectionAssumptions: 'Health and Protection assumptions',
        protectionAssumptionsFor: 'Health and Protection assumptions for {name}',
        coverType: 'Cover type',
        policyInterestRateIfDecreasing: 'Policy interest rate (if decreasing term)',
        indexationIfLevel: 'Indexation (if level term)',
        premiumType: 'Premium type',
        excludeLowStartPlans: 'Exclude low start plans',
        criticalIllnessCoverAmount: 'Critical illness cover amount',
        lifeOrEarlierCicCoverAmount: 'Life or earlier CIC cover amount',
        waiverOfPremium: 'Waiver of premium',
        fractureCover: 'Fracture cover',
        benefitBasis: 'Benefit basis',
        benefitAmount: 'Benefit amount',
        policyInterestRate: 'Policy interest rate',
        coverAmountAnnual: 'Cover amount (annual)',
      },
      alternatives: {
        productDetails: 'Product details',
        productSelection: 'Product selection',
        alternativeProducts: 'Alternative products ({n})',
        productsNotQuoted: 'Products not quoted ({n})',
        coveredHeading: "What's covered",
        clients: 'Clients',
        benefits: 'Benefits',
        provider: 'Provider',
        productName: 'Product name',
        notQuotedReason: 'Reason',
        monthlyPremium: 'Monthly premium',
        viewAlternatives: 'View alternatives',
        alternatives: 'Alternatives ({n})',
        updateSelection: 'Update selection',
        protectionDetails: 'Health and Protection details',
        ageBanded: '- Age banded product',
        limitedPayment: '- Limited payment method',
        filters: {
          ageBanded: {
            label: 'Age Banded',
            all: 'All',
            ageBandedOnly: 'Age banded only',
            notAgeBanded: 'Not age banded',
          },
          paymentPeriod: {
            label: 'Payment Period',
            subLabel: 'Limited Payment Period Only',
          },
        },
      },
      quickQuote: {
        indicativeFigures: 'Indicative figures',
        seeAssumptions: 'See assumptions',
        startQuote: 'Start quote',
        fallbackText: 'Provide mortgage details to see protection estimates',
      },
      quoting: {
        protectionQuoting: 'Protection quoting',
        protectionSummary: 'Health and Protection summary',
        getFullQuotes: 'Get full quotes',
        selectAClient: 'Select a client',
        mortgage: 'Mortgage',
        mortgagePayment: 'Mortgage payment',
        mortgageTerm: 'Mortgage term',
        totalMortgageDebt: 'Total mortgage debt',
        netIncome: 'Net income',
        totalCostOfProtection: 'Total cost of protection',
        benefits: 'Benefits',
        selectBenefit: 'Select benefit',
        selectClient: 'Select client',
        saveBenefit: 'Save benefit',
        removeBenefit: 'Remove benefit',
        addABenefit: '+ Add a benefit',
        clearBenefits: 'Clear Benefits',
        editCommissions: 'Edit Commissions',
        addFromRequirementsPage: '+ Add from requirements page',
        selectABenefit: 'Select a benefit',
        showMoreOptions: 'Show more options',
        showFewerOptions: 'Show fewer options',
        usefulNumbers: 'Useful numbers',
        agreedTermAssuranceNeeds: 'Agreed term assurance needs',
        agreedFamilyProtectionNeeds: 'Agreed family protection needs',
        agreedIncomeProtectionNeeds: 'Agreed income protection needs',
        protectionBudget: 'Health and Protection budget',
        thisIsAQuoteEstimate: 'This is a quote estimate. To get more accurate results, Get Full Quotes',
        requiresIncome: 'This client requires employment and income details before Income Protection can be sourced',
        indicativeQuoteDoesNotSupport: 'Indicative quote does not support quote estimation for joint benefits',
        indicativeQuoteDoesNotSupportWol: 'Indicative quote does not support quote estimation for whole of life',
        additionalDeferredPeriodMustBeGreater:
          'Additional deferred period must be greater than initial deferred period',
        fields: {
          clients: 'Client(s)',
          additionalCoverAmount: 'Additional cover amount (monthly)',
          additionalDeferredPeriod: 'Additional deferred period',
          benefitBasis: 'Benefit basis',
          coverAmountType: 'Cover amount type',
          coverAmountAnnual: 'Cover amount (annual)',
          coverAmountLumpSum: 'Cover amount (lump sum)',
          coverAmountMonthly: 'Cover amount (monthly)',
          coverAmountMonthlyTooltip: 'Insurers look to cover between 50 and 70% of a clients monthly salary.',
          deferralPeriodDays: 'Deferral period (days)',
          deferredPeriod: 'Deferred period',
          excludeLowStartPlans: 'Exclude low start plans',
          fractureCover: 'Fracture cover',
          includeDualDeferredPeriods: 'Include dual deferred periods',
          includeLimitedBenefitPaymentPeriod: 'Include limited benefit payment period',
          indexation: 'Indexation',
          initialCoverAmount: 'Initial cover amount (monthly)',
          initialDeferredPeriod: 'Initial deferred period',
          policyInterestRate: 'Policy interest rate',
          premiumType: 'Premium type',
          termLengthYears: 'Term length (years)',
          termAgeYears: 'To age (years)',
          termAge: 'To age',
          termYears: 'Term',
          totalPermanentDisability: 'Total permanent disability',
          unitsOfCover: 'Level of cover',
          costAmount: 'Cost amount',
          costFrequency: 'Cost frequency',
          waiverOfPremium: 'Waiver of premium',
          underwritingLevel: 'Underwriting type',
          hospitalList: 'Hospital list',
          outpatientCover: 'Outpatient cover',
          excessAmount: 'Excess amount',
          cancerCover: 'Cancer cover',
          dentalAndOptical: 'Dental and Optical',
          therapies: 'Therapies',
          physiotherapy: 'Physiotherapy',
          sixWeekOption: 'Six week option',
          travelInternational: 'Travel cover',
          privateGpCover: 'Private GP cover',
          mentalHealth: 'Mental health cover',
          additionalNotes: 'Additional notes',
          fullCover: 'Full cover',
          limitedCover: 'Limited cover',
          limitedCoverAmount: 'Limited cover amount',
          outpatientFullCover: 'Outpatient full cover',
        },
      },
      verifyDetails: {
        heading: 'Verify details',
        toRetrieveLiveQuotesYouNeed: 'To retrieve live quotes you need to verify the following information:',
      },
      sourcing: {
        getResults: 'Get results',
        updateResults: 'Update results',
        availableSolutions: 'Available solutions',
        title: 'Protection Sourcing',
        usefulNumbers: 'Useful numbers',
        requiresIncome: 'Client employment details and incomes are required before sourcing',
        mortgageDebt: 'Mortgage debt',
        mortgagePayments: 'Mortgage payments',
        mortgageTerm: 'Remaining term',
        familyProtectionNeeds: 'FIB needs',
        incomeProtectionNeeds: 'IP needs',
        lifeCriticalIllnessNeeds: 'Life & CIC needs',
        protectionBudget: 'Protection budget',
        combinedProtectionBudget: 'Protection budget',
        protectionSourcing: 'Protection sourcing',
        noProductsAvailable: 'No products available for these benefits',
        protectionSourcingCredentialsError: `Missing iPipeline credentials. Go to 'My Settings' to enter them. If you have already entered your credentials, ensure they are valid. If this issue persists, contact support`,
        headings: {
          combination: 'Combination solution',
          single: 'Single policy solution',
          multi: 'Multi policy solution',
          all: 'All',
          other: 'Other',
          month: 'month',
        },
        productDetails: {
          overview: 'Overview',
          documents: 'Documents ({n})',
          notQuoted: 'Not Quoted ({n})',
          alternatives: 'Alternatives ({n})',
        },
        table: {
          columnHeadings: {
            product: 'Product',
            clientsCovered: 'Clients covered',
            benefits: 'Benefits',
            alternatives: 'Alternatives',
            notQuoted: 'Not quoted',
            cost: 'Cost',
            warnings: 'Warnings',
            provider: 'Provider',
            clients: 'Client(s)',
          },
        },
      },
      productType: {
        termAssurance: 'Term assurance',
        decreasingTermAssurance: 'Decreasing term assurance',
        levelTermAssurance: 'Level term assurance',
        familyIncomeBenefit: 'Family income benefit',
        incomeProtection: 'Income protection',
        incomeProtectionDualDefer: 'Income protection dual defer',
        incomeProtectionStandardDefer: 'Income protection standard defer',
        invalid: 'Invalid product type',
        mortgagePaymentProtection: 'Mortgage payment protection',
        mortgageProtection: 'Mortgage protection',
        otherTypeOfIncomeProtection: 'Other type of income protection',
        privateMedicalInsurance: 'Private medical insurance',
        sickPay: 'Sick pay',
        wholeOfLife: 'Whole of life',
        coreAccidentSickness: 'Core accident and Sickness',
        childAccidentSickness: 'Child accident and Sickness',
        sportsLifestyle: 'Sports and Lifestyle cover',
        specialistHealthcareCover: 'Specialist healthcare cover',
        pmi: 'Private medical insurance',
        productTypeWholeOfLife: 'Whole of Life',
        productTypeTerm: 'Term',
        productTypeSportsLifestyle: 'Sports Lifestyle',
        productTypeSpecialistHealthcareCover: 'Specialist Healthcare Cover',
        productTypeSickPay: 'Sick Pay',
        productTypeShareholderProtection: 'Shareholder Protection',
        productTypeRelevantLife: 'Relevant Life',
        productTypePrivateMedicalInsurance: 'Private Medical Insurance',
        productTypePmi: 'PMI',
        productTypeOther: 'Other',
        productTypeMortgageProtection: 'Mortgage Protection',
        productTypeMortgagePaymentProtection: 'Mortgage Payment Protection',
        productTypeLevelTerm: 'Level Term',
        productTypeInvalid: 'Invalid',
        productTypeIncomeProtection: 'Income Protection',
        productTypeGroupPmi: 'Group PMI',
        productTypeGroupPrivateMedicalInsurance: 'Group Private Medical Insurance',
        productTypeGroupLife: 'Group Life',
        productTypeGroupIp: 'Group IP',
        productTypeGroupCic: 'Group CIC',
        productTypeGroupAsu: 'Group ASU',
        productTypeFamilyIncomeBenefit: 'Family Income Benefit',
        productTypeDecreasingTerm: 'Decreasing Term',
        productTypeCoreAccidentSickness: 'Core Accident Sickness',
        productTypeChildAccidentSickness: 'Child Accident Sickness',
        productKeyMan: 'Key Man',
        employeeBenefit: 'Employee Benefit',
      },
      productBenefitFrequency: {
        weekly: 'Weekly',
        monthly: 'Monthly',
        annual: 'Annual',
        lumpSum: 'Lump sum',
      },
      productPremiumFrequency: {
        invalid: 'Invalid frequency',
        monthly: 'Monthly',
        annual: 'Annual',
      },
      products: {
        title: 'Products ({n})',
        unableToApply: 'Unable to apply for product. Please try again later or contact support.',
      },
      documents: {
        insuranceApplication: 'Insurance application',
        insuranceIllustration: 'Insurance illustration',
        insuranceKeyFacts: 'Insurance key facts',
        comparisonReport: 'Comparison report',
        policyLetter: 'Policy letter',
        buttonNames: {
          view: 'View',
          uploadReplacement: 'Upload replacement',
          upload: 'Upload',
        },
      },
      missingFields: {
        missingBenefits: 'This product does not contain any benefits.',
        missingAlternatives: 'This product does not have any alternatives.',
        missingNotQuoted: 'This product does not have any not quoted items associated with it.',
      },
    },

    reminders: {
      title: 'My reminders',
      reminder: 'Reminder',
      addReminder: 'Add reminder',
      assignReminder: 'Assign reminder to',
      assignee: 'Assignee',
      group: 'Group',
      noGroups: 'No groups',
      individual: 'Individual',
      assignedTo: 'Assigned to {assigneeName}',
      setAReminder: 'Set a reminder',
      completedReminders: 'Completed ({completeNotes})',
      skipReminder: 'Skip reminder',
      wouldYouLikeToSetAReminder:
        'Would you like to set a reminder to contact the client before their initial rate expires?',
      wouldYouLikeToSetAReminderProtection:
        'Would you like to set a reminder to contact the client and do a Health and Protection review?',
      wouldYouLikeToSetAReminderGI:
        'Would you like to set a reminder to contact the client before their policy expires?',
      fallbackMessage: 'You have no reminders for this case',
      noReminders: 'You currently have no reminders',
      heading: 'Reminders',
      lastSaved: `Last saved by {firstName} {lastName}`,
      createdBy: `Created by {firstName} {lastName}`,
      modalHeading: 'Create new reminder',
      modalEditHeading: 'Edit your reminder',
      placeholder: 'Type your reminder here...',
      saveChanges: 'Save changes',
      dueDate: 'Due date',
      dueTime: 'Due time (24h)',
      today: 'Due today',
      todayLowercase: 'due today',
      tomorrow: 'Due tomorrow',
      past: `Due {deadline}`,
      future: `Due {deadline}`,
      review: 'Review',
      general: 'General',
      reminderType: 'Reminder type',
      remortgageReview: 'Remortgage review',
      protectionReview: 'Health and Protection review',
      lenderName: 'Lender: {value}\n',
      productName: 'Product name: {value}\n',
      initialRate: 'Initial rate: {value}\n',
      rateEnd: 'Rate end: {value}\n',
      svr: 'SVR: {value}\n',
      unassigned: 'Unassigned',
      overdue: 'overdue',
      unableToComplete: 'Unable to mark complete. Owner required',
      unableToIncomplete: 'Unable to mark incomplete. Owner required',
      table: {
        emptyField: '-',
        today: 'Today',
        todayDeadline: 'Today · {deadline}',
        tomorrowDeadline: 'Tomorrow · {deadline}',
        tomorrow: 'Tomorrow',
        nextWeek: 'Next week',
        yesterday: 'Yesterday · {deadline}',
        past: `{deadline} · {time}`,
        future: `{deadline} · {time}`,
        header: {
          assignedTo: 'Assigned To',
          reminder: 'Reminder',
          reminderType: 'Reminder type',
          clients: 'Clients',
          case: 'Case',
          lender: 'Lender',
          due: 'Due',
        },
        type: {
          general: 'General',
          review: 'Review',
          standard: 'Standard',
          templates: 'Template',
        },
      },
      filterBar: {
        assignee: 'Assigned to',
        applyFilters: 'Apply filters',
        caseType: 'Case type',
        resetAll: 'Reset all',
        primaryType: 'Reminder type',
        organisation: 'Organisation',
      },
    },

    generalInsurance: {
      excludesPanels: '{error}',
      propertyDetailsMissing: `Quotes not requested. Please add {value}`,
      clientDetailsMissing: `{value}`,
      heading: 'Or sell it yourself',
      headingNoRefer: 'Home insurance',
      errorMessage: 'Complete property details to retrieve quotes',
      deleteProduct: 'Remove recommendation',
      confirmRemoveProduct: 'Yes, remove recommendation',
      removeProductModalText:
        'Are you sure you want to remove this recommendation? You will have to start a new quote to recommend a new general insurance policy.',
      giQuoting: 'Home insurance quoting',
      homeInsuranceProducts: 'Home insurance products',
      addAManualProduct: 'Add a manual product',
      disabledAddProductButtonTooltipText:
        'Please add a property and specify the use of that property before creating a home insurance product',
      referToUinsure: 'Refer to Uinsure',
      quickQuote: {
        compareQuotes: 'Compare quotes',
        seeAssumptions: 'See assumptions',
        apply: 'Apply',
      },
      compareQuotes: {
        table: {
          heading: {
            compareGIQuotes: 'Compare GI quotes',
            button: {
              select: 'Select',
            },
          },
        },
      },
      errorMessages: {
        wrongCredentials: `We received an error when calling {provider}. Please check your credentials`,
        somethingWrong:
          'The panel returned an error when we requested quotes. This is often because of the options you have selected.',
        propertyDetails: 'There are no quotes available based on the property details',
        propertyDetailsPanel: `{provider} could not provide quotes based on the property details`,
        checkCredentials: `Unable to retrieve results from {provider} or {otherProvider}. Please check your credentials`,
        didNotQuote: `Because of the selected options, {provider} was not able to quote for this case`,
        timeout: `{provider} did not return results within 30 seconds so they have been excluded from results`,
        paymentShieldNoOccupation:
          "PaymentShield require each client's occupation to be set in order to provide quotes",
        propertyInfo: 'You must set property type, year built and number of bedrooms to quote for home insurance',
        noProperty: 'There is no property attached to this case',
        providerError: '{provider} returned an error: {msg}',
        failedToFetchCommissions: 'Failed to fetch commissions. Please try again or contact support.',
        noCommissions: 'No valid commissions available. Please check your panels and try again.',
        cannotApplyNoCredentials: `You cannot apply this product right now because your {provider} credentials are missing. Go to 'My Settings' to enter them. If you have already entered your credentials, ensure they are valid. If this issue persists, contact support.`,
        noCredentials: `Missing home insurance credentials. Go to 'My Settings' to enter them. If you have already entered your credentials, ensure they are valid. If this issue persists, contact support.`,
      },
      statuses: {
        saved: 'Saved',
        recommended: 'Recommended',
        applicationInProgress: 'App in progress',
        current: 'Current',
        expired: 'Expired',
        giProductStatusInvalid: 'Status Invalid',
        markAsSaved: 'Mark as saved',
        markAsRecommended: 'Mark as recommended',
        markAsAppInProgress: 'Mark as app in progress',
        markAsCurrent: 'Mark as current',
        markAsExpired: 'Mark as expired',
      },
      provider: 'Provider',
      underwriter: 'Underwriter',
      type: 'Type',
      annualPremium: 'Annual premium',
      startDate: 'Start date',
      renewal: 'Renewal',
      status: 'Status',
      actions: 'Actions',
      manual: 'Manual',
      monthly: '12 monthly payments',
      annual: '1 annual payment',
      defaqtoRating: 'Defaqto rating',
      apply: 'Apply',
      slashMonth: ' / month',
      slashYear: ' / year',
      commission: 'Commission',
      assumptions: {
        assumptions: 'Assumptions',
        endorsements: 'Endorsements',
        additional: 'Additional eligibility',
        declaration: 'Declaration',
        declarationText: 'All the information provided above is correct to the best of my knowledge.',
        mustAccept: 'You must answer "yes" to each question before continuing.',
        continue: 'Continue',
        bankDetails: 'Bank details for payments',
        back: 'Go back',
        errorHeader: 'Error when talking to {provider}',
        error:
          'An error occurred when communicating with {provider}. Please try reloading the page and if the error continues, please consult our support team. If you log into your {provider} portal you may be able to continue this case from there.',
        bankDetailsFields: {
          sortCode: 'Sort code',
          accountNumber: 'Account number',
          directDebitDay: 'Day to take direct debit payments',
          paymentFrequency: 'Payment frequency',
          accountName: 'Name on account',
          bankName: 'Bank name',
          branchName: 'Branch name',
          monthly: 'Monthly',
          annually: 'Annually',
        },
        industry: 'Industries',
        industries: {
          warning: 'You must select an industry for this client',
          industry: 'Industry in which {clientName} works',
        },
        errors: {
          sortCode: 'Sort code should be of the form 12-34-56',
          accountNumber: 'Account number should be eight digits long',
          dd: 'You must enter a day between 1 and 27',
          freq: 'You must select a payment frequency',
          accName: 'You must enter the account name',
          bankName: 'You must enter the bank name',
          branchName: 'You must enter the branch name',
        },
      },
      noProducts: 'No products found',
      recommend: 'Recommend',
      recommendedProduct: 'Recommended product',
      viewFullProductDetails: 'View full product details',
      numQuotes: 'quotes',
      applicationComplete: 'Application complete',
      buildings: 'Buildings',
      contents: 'Contents',
      terrorism: 'Terrorism',
      flood: 'Flood',
      residentialContents: 'Residential contents',
      residentialBuildingsAndContents: 'Residential buildings and contents',
      buildingsAndContents: 'Buildings and contents',
      buildingsOnly: 'Buildings only',
      landlordContents: `Landlord's contents`,
      btlbuildingsAndContents: "Buildings and landlord's contents",
      btlbuildingsOnly: 'Buildings only',
      contentsOnly: 'Contents only',
      btlcontentsOnly: "Landlord's contents only",
      buildingsAndCommonContents: 'Buildings and common contents',
      terrorismInsurance: 'Terrorism insurance',
      floodInsurance: 'Flood insurance',
      insuranceType: 'Insurance type',
      fields: {
        excess: 'Excess',
        legalExpenses: 'Legal expenses cover',
        personalPossessions: 'Personal possessions cover',
        accidentalDamage: 'Accidental damage cover',
      },
      included: 'Included',
      notIncluded: 'Not included',
      assumptionQuestionsIntro:
        'Please carefully read and check that each of the following statements is true before continuing.',
      yesToAll: 'All of the above statements are true',
      filterBar: {
        cover: {
          title: 'Cover',
          excess: 'Desired excess',
          claimFreeYears: 'Claim free years',
          complexExcess: 'Set separate excesses',
          noComplexExcess: 'Remove separate excesses',
          contentsExcess: 'Contents excess',
          buildingsExcess: 'Buildings excess',
        },
        highValue: {
          title: 'High value items',
          add: 'Add high value item',
          add2: 'Add high value item',
          shouldAdd:
            'You should add mobile phones valued above £500, pedal cycles over £1,000 and any other items valued over £2,500',
          itemType: 'Item type',
          value: 'Value',
          description: 'Description',
          outsideTheHome: 'Covered outside the home',
          atHome: 'Covered at home',
          save: 'Save',
        },
        tenancy: {
          directTenancyAgreement: 'Direct tenancy agreement',
          directTenancyAgreementTooltip:
            'When occupied will the property be let under a tenancy agreement directly between the letting agent or landlord and each tenant? The customer must tell us if the property is let under a tenancy agreement that is directly in place between them (as the landlord or the letting agent) and each tenant.{br}If the tenancy agreement is between the letting agent and the tenant then the letting agent would be expected to keep a set of keys.',
          tenancyRequirementsMet: 'Tenancy requirements met',
          tenancyRequirementsTooltip: `<list><item>
The initial fixed term of the tenancy agreement must be for a minimum period of 6 months, excluding Scotland. Acceptable forms of tenancy agreements are:
{br}a) An Assured Shorthold tenancy agreement as defined within the House Act 1998 (as amended);
{br}b) A Company Residential tenancy (company let) created after 28th February 1997 where the tenant is a Private Limited Company (Ltd) or Public Limited Company (Plc) and the property is let purely for residential purposes to an employee of the tenant;
{br}c) A written common law residential tenancy agreement created after 1st October 2010 between individuals where the rent is in excess of £100,000 per annum;
{br}d) For tenancies in Scotland, as Short Assured Tenancy or Assured Tenancy as defined in the Housing (Scotland) Act 1998 or a Private Residential tenancy agreement created after the 1st December 2017 as defined within the Private Housing (Tenancies) (Scotland) Act 2016.
</item><item>
The following checks must be carried out before the commencement of the tenancy agreement or the policy, whichever is later;
{br}a) A credit check obtained from a licensed credit referencing company showing no CCJs in the preceding 3 years, no outstanding CCJs and no undischarged bankruptcies
{br}b) Two forms of acceptable identification, one of which must be photographic
{br}c) Affordability checks must be carried out on all tenants by the purchaser or a licensed referencing company, which confirms that the tenants have the reasonable means to pay the rent i.e. that their income, or combined income for tenancies with multiple tenants, is sufficient to pay the agreed rent in full
{br}If the tenant(s) cannot meet the requirements of the credit check, a guarantor must be sought who can meet the requirements above.
{br}If the tenant is a company, a company reference must be carried out showing no CCJs and the agreed rent must not be greater than 85% of the company's credit limit.
</item><item>
The first month's rent must have been received in cash or cleared funds.
</item><item>
Where applicable and as required by the relevant laws within each country of the UK, all necessary pre-grant notices must have been issued to the tenant to meet any regulatory requirements. The requirements may also differ depending on whether the policy is purchased in conjunction with a new or an existing tenancy agreement. Examples of pre-grant notices may include:
{br}a) Energy Performance Certificate
{br}b) Gas Safety Certificate
{br}c) How to Rent guide</item></list>`,
          title: 'Tenancy',
        },
        tenants: {
          title: 'Tenants',
          tenantsWillBeStudents: 'Tenants will be students',
          tenantsWillBeOnBenefits: 'Rent will be paid with benefits',
          tenantsWillBeOnBenefitsTooltip:
            'Is the property let to tenants funding their rent through the Department of Work and Pensions?',
          moreThanSixTenants: 'More than six tenants',
          moreThanSixTenantsTooltip: 'Are there more than 6 individual tenants occupying the property?',
          propertyContainsBedsits: 'Property contains bedsits',
          propertyContainsBedsitsTooltip:
            'Is the property a bedsit or divided into individual self-contained units each with individual cooking facilities?',
          tenantsAreOver18: 'Tenants are over 18',
        },
        features: {
          title: 'Features',
          includePersonalPossessionsCover: 'Personal possessions cover',
          includeAccidentalCover: 'Accidental damage cover',
          includeFamilyLegalProtection: 'Family legal protection',
          landlordLegalExpenses: "Landlord's legal cover",
          includeContentsPlus: '£100k contents limit',
          landlordContentsPlus: "£35k landlord's contents limit",
          includeHomeEmergencyCover: 'Home emergency cover',
          landlordHomeEmergencyCover: "Landlord's emergency cover",
          manufactureOfDrugsCover: 'Include manufacture of drugs cover',
          rentGuarantee: 'Include rent guarantee',
        },
      },
      noLongerAvailable: 'Cannot show recommended product',
      removeProduct: 'Remove product',
      badProduct: `Although you have recommended a product from quote reference "{quoteRef}", it cannot be viewed at this time. This may be because it was sourced by another user and you do not have permission to view it in the insurance provider's system, or because the quote has expired. You can view more details of the product on the <a>home insurance products page</a>.`,
      specifiedItems: {
        clocks: 'Clocks',
        coinCollection: 'Coin collection',
        cupsSheildsTrophiesandMasonicRegalia: 'Cups, shields, trophies and masonic regalia',
        curios: 'Curios',
        furs: 'Furs',
        goldItems: 'Gold items',
        guns: 'Guns',
        jewelleryWatches: 'Jewellery / watches',
        medalCollection: 'Medal collection',
        musicalInstrumentsAmateur: 'Musical instruments (amateur)',
        paintings: 'Paintings',
        pearls: 'Pearls',
        photographicEquipmentAmateur: 'Photographic equipment (amateur)',
        pianos: 'Pianos',
        pictures: 'Pictures',
        preciousMetals: 'Precious metals',
        sculptures: 'Sculptures',
        silverItems: 'Silver items',
        sportingGunsandShootingEquipment: 'Sporting guns and shooting equipment',
        stampCollection: 'Stamp collection',
        statues: 'Statues',
        tapestries: 'Tapestries',
        worksofArt: 'Works of art',
        clothing: 'Clothing',
        contactLenses: 'Contact lenses',
        denturesincludingCrowns: 'Dentures (including crowns)',
        electricWheelchairs: 'Electric wheelchairs',
        gokart: 'Go-kart',
        golfBuggy: 'Golf buggy',
        hearingAids: 'Hearing aids',
        homeComputerEquipmentegLaptop: 'Home computer equipment (e.g. laptop)',
        medicalEquipment: 'Medical equipment',
        modelAircraft: 'Model aircraft',
        pedalCyclesOver1000: 'Pedal cycles over £1000',
        portableElectronicItemegiPodiPad: 'Portable electronic item (e.g. iPod/iPad)',
        pramsAndPushchairs: 'Prams and pushchairs',
        soundEquipment: 'Sound equipment',
        spectacles: 'Spectacles',
        sportsEquipmentExcludingWinterSportsandPedalCycles:
          'Sports equipment (excluding winter sports and pedal cycles)',
        sportsEquipmentWinterSports: 'Sports equipment (winter sports)',
        wheelchairsSelfPropelled: 'Wheelchairs (self-propelled)',
        mobilePhone: 'Mobile phone',
        value: 'Value',
        description: 'Description',
        placesCovered: 'Places covered',
        atHome: 'At home',
        outsideHome: 'Outside home',
        insideAndOutsideTheHome: 'Inside and outside the home',
      },
      giProductForm: {
        title: {
          buildings: 'Buildings insurance policy',
          contents: 'Contents insurance policy',
          buildingsAndContents: 'Buildings and contents insurance policy',
          landlordsContents: `Landlord's contents insurance policy`,
          buildingsAndLandlordsContents: `Buildings and landlord's contents insurance policy`,
          terrorism: 'Terrorism insurance policy',
          flood: 'Flood insurance policy',
          other: 'Other insurance policy',
        },
        sourcedGIProductCannotBeEdited: 'Sourced home insurance product cannot be edited',
        noGIProductFoundWithThatId: 'No home insurance product found with that id {id}',
        invalidHomeInsuranceProductType: 'Invalid home insurance product type',
        backToPolicies: 'Back to policies',
        policyDetails: 'Policy details',
        contents: 'Contents',
        accidentalDamage: 'Accidental damage',
        policyStatus: 'Policy status',
        policyNumber: 'Policy number',
        payment: 'Payment',
        paymentFrequency: 'Payment frequency',
        buildingsSumAssured: 'Buildings sum assured',
        rebuildCost: 'Rebuild cost',
        excess: 'Excess',
        yearsNoClaims: 'Years no claims',
        contentsSumAssured: 'Contents sum assured',
        includesLegalCover: 'Includes legal cover',
        legalCoverPremium: 'Legal cover premium',
        legalCoverPremiumTooltip: 'If a separate legal cover premium applies, enter it here (optional)',
        includesPersonalPossessionsCover: 'Includes personal possessions cover',
        personalPossessionsSumAssured: 'Personal possessions sum assured',
        personalPossessionsPremium: 'Personal possessions premium',
        personalPossessionsPremiumTooltip:
          'If a separate personal possessions premium applies, enter it here (optional)',
        includesHomeEmergencyCover: 'Includes home emergency cover',
        homeEmergencyPremium: 'Home emergency premium',
        homeEmergencyPremiumTooltip: 'If a separate home emergency premium applies, enter it here (optional)',
        includesAccidentalDamageCover: 'Includes accidental damage cover',
        accidentalDamagePremium: 'Accidental damage premium',
        accidentalDamagePremiumTooltip: 'If a separate accidental damage premium applies, enter it here (optional)',
        rentalCover: 'Loss of Rent and/or Alternative Accommodation',
        longer: 'Longer',
      },
      overflowButtons: {
        edit: 'Edit',
        view: 'View',
        delete: 'Delete',
      },
    },

    userSettings: {
      title: 'My settings',
      credentials: {
        protectionDetails: 'Add your protection login details',
        homeInsuranceDetails: 'Home insurance login details',
        protectionExplanation:
          'If you use iPipeline, you can add your protection login details here to sync your sourcing panel. You can also add this later on from your settings.',
        homeInsuranceExplanation:
          'If you use Uinsure or PaymentShield to source home insurance quotes, you can add your login details to sync your panel. You can also add this later on from your settings.',
        title: 'Credentials',
        ipipeline: 'iPipeline (Assureweb)',
        ipipelineExplanation:
          'This will be your Assureweb login details, this should start with <strong>web****</strong>',
        uinsure: 'Uinsure',
        paymentshield: 'PaymentShield',
        santander: 'Santander',
        lenders: 'Lenders',
        santanderExplanation:
          'Enter your Santander broker code used to log in to their intermediary website. It is typically a five digit number.',
        runChecksButton: 'Run check',
        labels: {
          apiKey: 'API key',
          email: 'Email address',
          emailOrUserId: 'Email Address or User ID',
          passKey: 'Passkey',
          password: 'Password',
          sellerId: 'Seller ID',
          username: 'Username',
          santander: {
            brokercode: 'Santander broker code',
          },
        },
        banners: {
          credentialsValid: 'Credentials valid',
          newCreditsValid: 'Credentials valid, please save the changes',
          unableToValidate: 'Unable to validate your credentials with {provider}, please check and try again.',
          providerUnavailable:
            '{provider} service is unavailable to validate your credentials, please try again later.',
          checkCreds: 'Before saving check if new credentials are valid',
        },
      },
      emailAndCalendar: {
        defaultMessage: 'Add an account to get started',
        unauthorisedAccount: 'Unable to authorise account',
        connectedAccounts: 'Connected accounts',
        addNewAccount: 'Add account',
        unableToAddAccount: 'You can only add one email account for each consumer.',
        graphAndNylasInfo: 'Please note that only Microsoft Office 365/Exchange accounts are currently supported.',
        onboardingExplainer:
          "You'll be able to schedule meetings from Acre. You can also add this later on from your settings.",
        removeAccount: 'Remove',
        accountTypes: {
          microsoft: 'Microsoft',
          google: 'Google',
          yahoo: 'Yahoo',
          exchange: 'Exchange',
        },
        consumers: {
          nylas: 'Nylas',
          graph: 'Graph',
        },
      },

      subNav: {
        credentials: 'Credentials',
        emailAndCalendar: 'Email and calendar',
        featureFlags: 'Feature Flags',
      },
    },

    mi: {
      caseDetails: 'Case Details',
      firmStats: 'Summary Statistics',
      advisorStats: 'Summary Statistics by Advisor',
      applyFilters: 'Apply filters',
      noReports: 'No reports',
      total: 'Total',
      revenueTypeTitle: 'Showing',
      commissionPeriodTitle: 'Period:',
      predictedRevenueTypes: {
        revenue: 'Mortgage volume',
        predictedRevenue: 'Predicted revenue',
      },
      subnav: {
        title: 'Reports',
        currentPipeline: 'Current Pipeline',
        cancellationReason: 'Cancellation Reason',
        caseStatusWaterfall: 'Case Status Waterfall',
        revenue: 'Revenue',
        other: 'Download Reports',
        acrecsv: 'Global CSV Download',
        filterButton: 'Filter Report',
        customiseButton: 'Customise Report',
        commissions: 'Commissions',
        rmar: 'RMAR',
      },
      caseStatus: {
        PRE_RECOMMENDATION: 'Pre-recommendation',
        PRE_APPLICATION: 'Pre-application',
        LEAD: 'Lead',
        REVIEW: 'Review',
        POST_RECOMMENDATION_REVIEW: 'Post-recommendation Review',
        APPLICATION_SUBMITTED: 'Application Submitted',
        AWAITING_VALUATION: 'Awaiting Valuation',
        AWAITING_OFFER: 'Awaiting Offer',
        OFFER_RECEIVED: 'Offered',
        EXCHANGE: 'Exchanged',
        COMPLETE: 'Complete',
      },
      cancellationReason: {
        NO_RESPONSE: 'No Response',
        CLIENT_DECLINED_PRODUCT: 'Customer Declined Product',
        NO_PRODUCT_AVAILABLE: 'No Product Available',
        DUPLICATE_CASE: 'Duplicate Case',
        INCORRECT_CONTACT_DETAILS: 'Incorrect Contact Details',
        CLIENT_NO_LONGER_REQUIRES_PRODUCT: 'Client No Longer Requires Product',
        CLIENT_OBTAINED_PRODUCT_ELSEWHERE: 'Client Obtained Product Elsewhere',
        OTHER: 'Other',
        TOTAL: 'Total',
      },
      commissionReports: {
        commissionsThreshold: 'Threshold totals per period',
        showThresholdView: 'Show threshold view',
        hideThresholdView: 'Hide threshold view',
        allCommissions: 'All commissions',
        columns: {
          threshold: 'Threshold',
          from: 'From',
          to: 'To',
          noCommissions: 'No. Commissions',
          periodName: 'Period name',
          total: 'Total commission',
          amount: 'Amount',
          due: 'Due',
          type: 'Type',
          status: 'Status',
          clients: 'Client(s)',
          property: 'Property',
        },
      },
      filterBar: {
        advisors: 'Advisors',
        lenders: `Lenders`,
        createdDate: `Case created date`,
        paymentCreatedDate: `Payment created date`,
        paymentCollectedDate: `Payment collected date`,
        caseTypes: `Case types`,
        revenueType: 'Revenue types',
        paymentDate: 'Payment date',
        paymentStatus: 'Payment status',
        clearAll: 'Clear all',
        selectAll: 'Select all',
        applyFilters: 'Apply filters',
        resetAll: 'Reset all',
        organisations: 'Organisations',
        period: 'Period',
        from: 'From',
        to: 'To',
      },
      table: {
        total: 'Total',
      },
      modal: {
        heading: 'Customise report view',
        viewByTitle: 'By:',
        viewDatesByTitle: 'Showing:',
      },
      viewDateBy: {
        caseCreatedAt: 'Case Created Date',
        ledgerCreatedAt: 'Payment Created Date',
        receivedDate: 'Payment Received Date',
      },
      viewBy: {
        advisor: 'Advisor',
        lender: 'Lender',
        Introducer: 'Introducer',
        caseType: 'Case Type',
        yearMonth: 'Month case was created',
        ledgerType: 'Revenue Type',
      },
      pages: {
        currentPipeline: {
          title: 'Current pipeline',
        },
        cancellationReason: {
          title: 'Case cancellation reason',
        },
        caseWaterfall: {
          title: 'Case status waterfall',
        },
        revenue: {
          title: 'Revenue',
        },
        organisationcsv: {
          title: 'Management Information',
          titleSubHeader:
            'Here are some handy reports for you to download. If there is something more specific you need, let us know.',
          openCasesAggregate: 'Aggregate open cases',
          openCasesLabel: 'This provides a list of totals for open cases per broker',
          allOpenCases: 'All open cases',
          allOpenCasesLabel: 'This provides a list of all open cases in the firm',
          completedCasesAggregate: 'Aggregate completed cases',
          completedCasesAggrgateLabel:
            'This provides a list of total complete cases per broker within a specified date range',
          allCompletedCases: 'All completed cases',
          allCompletedCasesLabel:
            'This provides a list of total complete cases in the firm within a specified date range',
          downloadReport: 'Download report',
          dateModal: {
            heading: 'Select date range',
            from: 'From',
            to: 'To',
          },
        },
        other: {
          title: 'Select a report to download',
          columns: {
            title: 'Report type',
            description: 'Description',
          },
          actions: {
            download: 'Download',
            createCustomReport: 'Create custom report',
          },
          forms: {
            download: {
              title: 'Download report',
              submit: 'Download CSV',
              createReport: 'Create report',
              report: 'Report',
              error: 'An issue has occurred running this report. Please try again or contact support.',
              timeOut: 'There was a timeout retrieving the report information. Please try again or contact support.',
              downloadXlsx: 'Download XLSX',
              RMARWarningMessage:
                "Ledgers labelled 'Reference Fee' will be excluded from the report, as their relevance to specific sections cannot be determined",
            },
          },
          custom: {
            columnsToInclude: 'Columns to include',
            reportInfo: 'Report information',
            reportType: 'Create report based on',
          },
        },
        globalcsv: {
          title: 'Acre Global MI Download',
          titleSubHeader: 'This facility is strictly for the use of authorised Acre personnel.',
          casesAggregate: 'Statistics by Advisor for all cases',
          casesAggregateLabel: 'Statistics by Advisor for all cases',
          allOpenCases: 'All cases',
          allOpenCasesLabel: 'Summary details for each case.',
          users: 'Users',
          userLabel: 'Name, role and email for all current users',
          downloadReport: 'Download report',
        },
        commissions: {
          table: {
            amount: 'Amount',
            due: 'Due',
            type: 'Type',
            status: 'Status',
          },
        },
      },
    },

    introducers: {
      emailChange: {
        confirmation: `Are you sure you want to change this email address to {newEmail}? Doing so will sign out the selected user and require them to reset their password before they can sign in again.`,
      },
      list: {
        addBulk: 'Upload list',
        companyHeader: 'Company',
        emailHeader: 'Email',
        phoneHeader: 'Phone',
        addressHeader: 'Address',
        noIntroducers: 'You currently have no introducers, to add introducers please contact us',
        noIntroducersEnabled:
          'You currently have no introducers, click the button above to add introducers and see them here',
        addIntroducer: 'Add introducer',
        showTerminatedIntroducers: 'Show disabled',
      },
      bulk: {
        alreadyExists: 'The user already exists',
        created: 'Created introducer',
        failed: 'Could not create introducer: ',
        title: 'Bulk create introducers',
        download: 'Download Excel template for bulk upload',
        clear: 'Clear',
        proceed: 'Proceed',
        intro: `You can use this form to upload a spreadsheet of introducers in one go. This can be useful if migrating
              from another system. They will need to be in the format of the file you can download with the
              'Download Excel template' button below, with the introducer firms on the first sheet and the
              relevant commissions on the second.`,
        uploadFile: 'Upload a file',
        errors: 'We encountered errors reading your file. Please correct them before trying again:',
        readComplete: `This is how we have read your data. If it looks okay, click 'proceed' to create your introducers.`,
        results: 'We attempted to load your data and received the following results:',
        line: 'Line',
        columns: {
          Reference: 'Reference',
          CompanyName: 'Company Name',
          CompanyEmail: 'Company Email',
          CompanyPhone: 'Company Phone',
          Address: 'Address',
          Town: 'Town',
          Postcode: 'Postcode',
          ManagerFirstname: 'Manager First Name',
          ManagerSurname: 'Manager Surname',
          ManagerEmail: 'Manager Email',
          ManagerMobile: 'Manager Mobile',
          ManagerOfficePhone: 'Manager Office Phone',
        },
        commission: 'When case is of type {CaseType} pay {Amount} as {PaymentType}.',
        wait: 'Please wait... this can take up to 30 seconds per introducer',
      },
      companyDetails: {
        title: 'Company info',
        titleWithCompanyName: '{name} company information',
        name: 'Company name',
        website: 'Company website',
        phone: 'Company phone',
        email: 'Company email',
        details: 'Contact details',
        contactDetails: 'Company contact details',
        branding: 'Company branding',
        addContact: 'Add contact',
        editUser: 'Edit user',
        addUser: 'Add user',
        advanced: 'Advanced',
        profile: 'Profile',
        uploadPhoto: 'Upload photo',
        removePhoto: 'Remove photo',
        organisationIdentifier: 'Organisation identifier',
        organisationIdentifierError:
          'Must contain a website domain followed by a unique identifier, e.g. company.website.com/01',
        organisationIdentifierTooltip:
          'The identifier must be unique across the acre platform. To prevent clashes, prefix their identifier with a unique qualifier, for instance "your.domain.com/01".',
        contactCardPreview: {
          description: 'This is how it will look in client portal:',
          fromOrganisation: 'from {orgName}',
          message: 'Message',
          email: 'Email',
          bookMeeting: 'Book meeting',
          phone: 'Call',
          mobilePhone: 'Call Mobile',
        },
      },
      contacts: {
        title: 'Contacts',
        userDetails: 'User details',
        titleWithCompanyName: '{name} contacts',
        singleContactTitle: "{name}'s information",
        noContacts: 'You currently have no contacts for this Introducer, to add contacts please contact us',
        detailsHeading: 'Contact details',
        commissionHeading: 'Commission',
        labels: {
          firstName: 'First name',
          lastName: 'Surname',
          emailAddress: 'Email address',
          mobileNumber: 'Mobile phone',
          phoneNumber: 'Office phone',
          userIdentifier: 'User identifier',
          role: 'Role',
          multiFactorAuth: 'Multi-Factor Authentication',
          enabled: 'Enabled',
          disabled: 'Disabled',
          noPermsTooltip: 'You do not have sufficient permissions to make this change. Please contact your Principal.',
          currentUserTooltip: 'Users cannot update their own two-factor authentication',
          userIdentifierTooltip:
            'The identifier must be unique across the acre platform. To prevent clashes, we recommend you prefix the identifier with a unique qualifier, for instance "your.domain.com/01".',
        },
        organisationManagement: {
          principal: 'Principal',
          firmManager: 'Firm Manager',
          serviceAccount: 'Service Account',
          advisor: 'Advisor',
          administrator: 'Administrator',
        },
      },
      accessControl: {
        title: 'Access control',
        subtitle: 'Access to introducer',
        companyEmail: 'Company email',
        companyName: 'Company name',
        principal: 'Principal',
        canAccessIntroducer: 'Can access this introducer',
        yourAuthorisedRepresentatives: 'Your authorised representatives',
      },
      addIntroducerForm: {
        heading: 'Add introducer',
        companyHeading: 'Introducer company details',
        managerHeading: 'Company manager',
        submit: 'Save and Close',
        cancel: 'Cancel',
        companyAddress: 'Company address',
        complaintsAddress: 'Complaints address',
      },
    },
    goCardless: {
      blurb: {
        title: `Collect one-off payments instantly with GoCardless. Direct from your customer's bank account to yours.`,
        first: `The current options for payments are limited: cards have expensive transaction fees; bank transfers offer a
            poor customer experience; bank debit is not optimised for one-off payments.`,
        second: `Instant Bank Pay is designed to complement bank debit using open banking. These bank-to-bank payments are
            confirmed instantly, which means better visibility for you and your customers. Save time chasing one-off
            payments and create a smoother customer experience.`,
        third: `Acre has partnered with GoCardless to ensure a seamless experience for you and your clients. You can request
            payments directly from within Acre CRM and your clients can pay in just a few clicks from Client Portal. No
            more manual reconciliation, no more chasing card numbers, more doing what you do best - giving your clients
            great financial advice.`,
        signup: 'Sign up',
        later: 'Maybe later',
      },
      link: {
        title: 'Link your GoCardless account to Acre',
        refresh: 'Refresh login to GoCardless',
        already: 'Your GoCardless account is linked',
        alreadySub: 'Your GoCardless account is already linked to Acre.',
        status: 'Account status',
        ready: 'Your GoCardless account is ready for use.',
        validation:
          'You must verify your account with GoCardless before using it within Acre. Please log in to their portal and provide any required documents.',
        checking: 'Checking status...',
        login: 'Log in to GoCardless',
        whatIs: 'What is GoCardless and how do I get it?',
        notEnabled: 'This page is not enabled for your account',
        wrongOrg: 'You cannot link a GoCardless account for another organisation.',
        options: {
          title: 'How do you take payments?',
          ibp: 'Send my clients a request to pay using Instant Bank Payments when I click the GoCardless button in accounting',
          dd: 'Ask new clients to set up a Direct Debit mandate when onboarding to client portal and then automatically charge amounts to the mandate when I click the GoCardless button in accounting',
          which: 'If you do not know which option to choose, select the first one.',
        },
      },
      callback: {
        invalidRequest: 'Invalid request - expecting code and state parameters.',
        goBack: '<a>Click here</a> to return to the payments page to try again.',
        error: 'Received error in callback:',
      },
    },
    accounting: {
      search: 'Search',
      amount: 'Amount',
      amountDue: 'Amount',
      dueDate: 'Due',
      startDate: 'Start date',
      endDate: 'End date',
      amountPaid: 'Amount paid',
      amountDatePaid: 'Amount / Date paid',
      settledDate: 'Date settled',
      status: 'Status',
      type: 'Type',
      source: 'Source',
      advisor: 'Advisor',
      organisations: 'Organisations',
      relatedUser: 'Related user',
      advisorIntroducer: 'Advisor / Introducer',
      club: 'Club',
      resetAll: 'Reset all',
      applyFilters: 'Apply filters',
      client: 'Client',
      clients: 'Client/s',
      property: 'Property',
      total: 'Total',
      clientsOnly: 'Show clients only',
      protectionQuoteReference: 'Ref no.',
      protectionPolicyNumber: 'Policy number',
      addAdditionalCredit: 'Add additional credit',
      paymentDirection: 'Payment direction',
      payee: 'Client/s',
      introducerOrganisation: 'Introducer organisation',
      introducer: 'Introducer',
      lender: 'Lender',
      addOneOffPayment: 'Add/edit one off payment',
      addRecurringPayment: 'Add recurring payment',
      insurer: 'Health and Protection provider',
      provider: 'Provider',
      noInsurers: 'No Health and Protection providers',
      caseOwner: 'Case owner',
      quickSearch: 'Quick Search',
      isTier: 'Include in tier commission',
      period: 'Period',
      quickSearchTooltip:
        'Quick search only applies to the first 100 records. Please use the filters available to narrow the search data.',
      quickSearchPreview:
        'Search has moved. Use this new search function to quickly find and manage entries in accounting. This search prioritises the first 100 loaded records. Narrow down search data using filters for more efficient searches or scroll down to load more records.',
      paymentDirections: {
        credit: 'Credit',
        clawBackCredit: 'Clawback Advisor (Credit)',
        debit: 'Debit',
        clawBackDebit: 'Clawback Firm (Debit)',
      },
      predictedRevenue: {
        title: 'Total predicted revenue',
        feeType: {
          protectionProcFee: 'Health and Protection proc fee',
          mortgageProcFee: 'Mortgage proc fee',
          giProcFee: 'Home Insurance proc fee',
          PREADVICE: 'Pre-advice fee',
          PRERECOMMENDATION: 'Pre-recommendation fee',
          PREAPPLICATION: 'Pre-application fee',
          OFFER: 'Offer fee',
          LEGAL_COMPLETION: 'Legal completion fee',
          LEGAL_EXCHANGE_FIXED: 'Legal exchange fee',
          LEGAL_EXCHANGE_PERCENT: 'Legal exchange fee',
          LEGAL_EXCHANGE: 'Legal exchange fee',
        },
        commissionFrequency: {
          MONTHLY: 'months',
          ANNUALLY: 'years',
        },
      },

      ledgerPaymentTypes: {
        GeneralInsuranceProcFee: 'GI Procuration Fee',
        MortgageLegalCompletionCombined: 'Completion Fee (Combined)',
        MortgageLegalCompletionFixed: 'Completion Fee (Fixed)',
        MortgageLegalCompletionPercentage: 'Completion Fee (Percentage)',
        MortgageOfferCombined: 'Offer Fee (Combined)',
        MortgageOfferFixed: 'Offer Fee (Fixed)',
        MortgageOfferPercentage: 'Offer Fee (Percentage)',
        MortgageProcFee: 'Mortgage Procuration Fee',
        PreMortgageAdviceCombined: 'Pre-Advice Fee (Combined)',
        PreMortgageAdviceFixed: 'Pre-Advice Fee (Fixed)',
        PreMortgageAdvicePercentage: 'Pre-Advice Fee (Percentage)',
        MortgageLegalExchangeFixed: 'Legal Exchange Fee (Fixed)',
        MortgageLegalExchangePercentage: 'Legal Exchange Fee (Percentage)',
        MortgageLegalExchangeCombined: 'Legal Exchange Fee (Combined)',
        PreMortgageApplicationCombined: 'Pre-Application Fee (Combined)',
        PreMortgageApplicationFixed: 'Pre-Application Fee (Fixed)',
        PreMortgageApplicationPercentage: 'Pre-Application Fee (Percentage)',
        PreMortgageRecommendationCombined: 'Pre-Recommendation Fee (Combined)',
        PreMortgageRecommendationFixed: 'Pre-Recommendation Fee (Fixed)',
        PreMortgageRecommendationPercentage: 'Pre-Recommendation Fee (Percentage)',
        ProtectionProcFee: 'Health and Protection Procuration Fee',
        LandRegistryReportFee: 'Title Report Fee',
        IntroducerProtectionCommission: 'Introducer Health and Protection Commission',
        AdvisorProtectionCommission: 'Advisor Health and Protection Commission',
        IntroducerMortgageCommission: 'Introducer Mortgage Commission',
        AdvisorMortgageCommission: 'Advisor Mortgage Commission',
        CaseManagerCommission: 'Case Manager Commission',
        ReferrerCommission: 'Referrer Commission',
        AdministratorCommission: 'Administrator Commission',
        LeadQualifierCommission: 'Lead Qualifier Commission',
        WatcherCommission: 'Watcher Commission',
        ReviewerCommission: 'Reviewer Commission',
        Clawback: 'Clawback',
        MortgageReferenceFee: 'Mortgage Reference Fee',
        ValuationFee: 'Valuation Fee',
        RelatedUserCommission: 'Related User Commission',
        WillReferralFee: 'Will Referral Fee',
        MasterBrokerReferralFee: 'Master Broker Referral Fee',
        PensionReferralFee: 'Pension Referral Fee',
        OtherReferralFee: 'Other Referral Fee',
        ConveyancingReferralFee: 'Conveyancing Referral Fee',
        AdvisorMortgageTierCommission: 'Advisor Mortgage Tier Commission',
        AdvisorProtectionTierCommission: 'Advisor Health and Protection Tier Commission',
        ClubFee: 'Club Fee',
        IntroducerGeneralInsuranceCommission: 'Introducer General Insurance Commission',
        AdvisorGeneralInsuranceCommission: 'Advisor General Insurance Commission',
        AdvisorConveyancingCommission: 'Advisor Conveyancing Commission',
      },
      ledgerPaymentSourceTypes: {
        Client: 'Clients',
        Advisor: 'Advisors',
        Club: 'Clubs',
        Lender: 'Lenders',
        Introducer: 'Introducers',
        MasterBroker: 'Master brokers',
        Network: 'Networks',
        Packager: 'Packagers',
        LandRegistry: 'Land Registry',
        Insurer: 'Insurer',
        User: 'User',
      },
      outboundSettled: {
        title: 'Outbound settled payments',
      },
      outboundPayments: {
        title: 'Outbound payments due',
      },
      paymentsDue: {
        title: 'Payments due',
        noData: 'You currently have no payments due',
      },
      paymentsReceived: {
        title: 'Payments settled',
        noData: 'You currently have no payments settled',
      },
      actions: {
        markPaid: 'Mark paid',
        writeOff: 'Write off',
        cancel: 'Cancel',
        viewCase: 'View case',
        removePayment: 'Remove payment',
      },
      cancelPayment: {
        cancelPayment: 'Cancel payment',
        selectPayment: 'Select payment',
      },
      popup: {
        amount: 'Amount',
        date: 'Date',
        payee: 'Payee',
        status: 'Status',
        paid: 'Paid',
        cancelled: 'Cancelled',
        failed: 'Failed',
        awaiting: 'Waiting for client',
      },
      subnav: {
        title: 'Accounting',
        paymentsDue: 'Payments due',
        paymentsSettled: 'Payments settled',
        outboundPayments: 'Outbound payments',
        outboundSettled: 'Outbound settled',
      },
      statuses: {
        SETTLED: 'Paid',
        DUE: 'Due',
        WRITTEN_OFF: 'Written off',
        PARTIALLY_PAID: 'Partially paid',
        PARTIALLY_WRITTEN_OFF: 'Partially written off',
        IN_FORCE: 'In force',
        OVERDUE: 'Overdue',
        CLOSE: 'Close',
        COLLECTED: 'Collected',
        AWAITING: 'Waiting for client',
        FAILED: 'Client payment failed',
        LIVE: 'Live',
      },
      totals: {
        amountDue: 'Amount due',
        amountPaid: 'Amount paid',
        totalLedgers: 'Number of payments',
      },
      request: 'Request payment',
      gc: {
        noGC: {
          header: 'Sign up for GoCardless today',
          signUp: 'Sign up',
          later: 'Maybe later',
        },
      },
    },
    commission: {
      heading: 'Commissions',
      cardHeading: 'Commission Scenarios',
      addCommission: '+ Add a commission',
      copyCommissionTitle: 'Copy commission',
      copyCommission: 'Copy from another advisor',
      copyCommissionFromContact: 'Copy from another contact',
      searchAdvisers: 'Search advisors',
      searchContacts: 'Search contacts',
      userRelationType: {
        caseOwnerType: 'Case owner',
        relatedUserCommission: 'Related user',
      },
      table: {
        caseType: 'Case type',
        amount: 'Amount',
        paymentType: 'Payment type',
        whenPayable: 'When payable',
        lastUpdated: 'Last updated',
        status: 'Status',
        edit: 'Edit',
        copy: 'Copy',
        remove: 'Remove',
        active: 'Active',
        inActive: 'Inactive',
        noData: 'You currently have no commissions',
        relationshipToCase: 'Relationship to case',
        conditionalOnIntroducer: 'Conditional on introducer',
        conditionalOnUser: 'Conditional on related user',
      },
      paymentType: {
        invalidCommissionType: 'Invalid commission type',
        costPerLead: 'Cost per lead',
        revenueShare: 'Revenue share',
        tier: 'Tier Commission',
      },
      paymentDue: {
        LEAD: 'Lead',
        COMPLETE: 'Complete',
        PRE_RECOMMENDATION: 'Pre-recommendation',
        PRE_APPLICATION: 'Pre-application',
        APPLICATION_SUBMITTED: 'Application submitted',
        AWAITING_VALUATION: 'Awaiting valuation',
        AWAITING_OFFER: 'Awaiting offer',
        OFFER_RECEIVED: 'Offer received',
        EXCHANGE: 'Exchanged',
        NOT_PROCEEDING: 'Not proceeding',
        COLLECTED: 'Collected',
        IMPORTING: 'Importing',
      },
      frequency: {
        monthly: 'Monthly ',
        annually: 'Annually',
        quarterly: 'Quarterly',
        weekly: 'Weekly',
        once: 'One off',
      },
      amountBy: {
        fixed_value: 'Pound',
        percentage: 'Percentage',
      },
      revenueType: {
        giCommission: 'GI commission',
        mortgageProcFee: 'Mortgage proc fee',
        referrals: 'Referrals',
        protectionCommission: 'Health and Protection proc fee',
        caseFees: 'Case fees',
        conveyancing: 'Conveyancing',
      },
      deductionType: {
        introducerCommission: 'Introducer commission',
        creditSearch: 'Credit search',
        landRegistry: 'Land registry',
        mortgageClubFee: 'Mortgage club fee',
        mortgageReference: 'Mortgage reference',
        networkFee: 'Network fee',
        postage: 'Postage',
        valuationCost: 'Valuation cost',
      },
      condition: {
        canBeAnyRelatedUser: 'Can be any user',
        canBeAnyRelatedIntroducer: 'Can be any introducer',
        isOneOfTheFollowing: 'Is one of the following',
        isNotOneOfTheFollowing: 'Is not one of the following',
      },
      relationshipType: {
        administrator: 'Administrator',
        caseManager: 'Case manager',
        leadQualifier: 'Lead qualifier',
        referrer: 'Referrer',
        reviewer: 'Reviewer',
        watcher: 'Watcher',
      },
      modal: {
        title: 'Commission options',
        cancel: 'Cancel',
        done: 'Done',
        commisionIsPayedWhen: 'The commission is paid when',
        tierCommissionsIncomplete: '*Incomplete. Thresholds are required for tier commissions.',
        fields: {
          caseType: 'Case type',
          paymentType: 'Payment type',
          whenPayable: 'When payable',
          amount: 'Amount',
          numberOfPayments: 'Number of months paid',
          commissionFrequency: 'Commission frequency',
          amountBy: 'Amount by',
          revenueTypes: 'Included in revenue',
          deductionTypes: 'Deduction before split',
          conditionalOnIntroducer: 'Conditional on introducer?',
          hasUserRelationToCase: 'Commission type',
          introducer: 'The introducer',
          introducers: 'Introducer(s)',
          conditionalOnRelatedUser: 'Conditional on related user?',
          relatedUser: 'The related user',
          users: 'User(s)',
          relatedUserRelationshipType: 'Relationship to case',
          userRelationshipType: 'User relationship to case',
          threshold: 'Threshold',
          thresholdFrom: 'Threshold from',
          thresholdTo: 'Threshold to',
          addThreshold: '+ Add threshold',
          period: 'Period',
          noUpperThreshold: 'No upper threshold',
          unlimited: 'Unlimited',
        },
      },
      errors: {
        revenueSelection: 'Cannot select Health and Protection commission and Mortgage proc fee',
      },
    },
    calendar: {
      busy: 'Busy',
      meetingWith: 'Meeting with {user}',
      createEvent: 'Create event',
      cancel: 'Cancel',
      meetingTitleLabel: 'Meeting title',
      attendees: 'Attendees',
      clients: 'Clients',
      loading: 'Loading calendar',
      setupNeeded: 'You must first set up your email and calendar account in your settings',
      userNotAuthorised: 'This broker does not have an email set up',
      eventNoteBody: '{title} - on {startDate} at {startTime} with {participants} (booked by {bookedBy})',
      fields: {
        eventCategories: 'Categories',
        isTeamsMeeting: 'Teams meeting',
        eventDescription: 'Description',
        eventTitle: 'Title',
        meetingTemplate: 'Meeting template',
        categories: {
          orange: 'Orange',
          purple: 'Purple',
          yellow: 'Yellow',
          green: 'Green',
          red: 'Red',
          blue: 'Blue',
        },
      },
    },
    notifications: {
      NEW_DOCUMENT: {
        BASE: 'New documents uploaded for <span>{caseType}</span> for {clientName}',
        CREDIT_REPORT: 'New credit report available for <span>{caseType}</span> for {clientName}',
      },
      NEW_SECURE_MESSAGE: 'New message from <span>{sender}</span>',
      whoAndWhen: 'By {who} • {when}',
      title: 'Notifications',
      today: 'Today',
      yesterday: 'Yesterday',
      emptyHeading: 'All caught up!',
      emptyText: "We'll keep you notified of any new activity",
      clearAll: 'Clear all',
    },
    competencies: {
      heading: 'Permissions',
      fcaHeading: 'FCA Permissions',
      complianceHeading: 'Compliance Permissions',
      accountingHeading: 'Accounting',
      competency: {
        competent: 'Competent',
        provisional: 'Provisionally competent',
        none: 'Not permitted',
      },
      labels: {
        createCaseLedger: 'Create case ledgers',
        editCaseLedger: 'Edit case ledgers',
        deleteCaseLedger: 'Delete case ledgers',
        mortgage: 'Mortgage',
        equityRelease: 'Equity release',
        protection: 'Health and Protection',
        generalInsurance: 'GI',
        debtCon: 'Debt consolidation',
        complianceInformation: 'View Compliance Information',
        addComplianceNotes: 'Add notes to Case in Review',
        approveCases: 'Approve Cases in Review',
        viewCaseNotes: 'View Flags When Case In Review ',
      },
      tooltips: {
        debtCon: 'Ability to progress cases which involve debt consolidation.',
        complianceInformation: 'Ability to see extended case data related to compliance. e.g. extended eIDV data.',
        addComplianceNotes:
          'Ability to add notes to cases in compliance review.  Note: separate permission is required to approve cases.',
        approveCases: 'Ability to approve a case in compliance review.',
        viewCaseNotes:
          'Ability to only view the flags and not action it. Note: This permission does not allow to see the actual data, it needs to be used in conjunction with other permission that allows you to see the case, like regulated/compliance/viewcasedetail or case/view_case',
      },
    },
    featureFlags: {
      buttons: {
        override: 'Override',
        change: 'Change',
        setOnOrg: 'Set on Organisation',
        addOverride: 'Add Override',
        addUserOverride: 'Add User Override',
        cancel: 'Cancel',
        removeFlag: 'Remove flag',
        setFlag: 'Set flag',
        addFlag: 'Add feature flag',
      },
      orgBanner: {
        text: 'The feature flags shown below reflect the current app state ',
        link: 'unless they are overridden for your specific user',
      },
      userBanner: {
        text: 'The feature flags shown below are the overrides for this specific user',
      },
      editInCompanySettingsBanner: {
        text: 'To edit the feature switches below, please visit the',
        link: ' company settings',
      },
      headings: {
        user: 'User Feature Flags',
        automation: 'Automation Flags',
        club: 'Club Flags',
        global: 'Global Feature Flags',
        org: 'Organisation Feature Flags',
        unset: 'Unset Feature Flags',
        editUserFlag: 'Edit User Feature Flag',
        overrideFlag: 'Override Feature Flag',
        changeFlag: 'Change Feature Flag',
        setFlag: 'Set Feature Flag',
        internal: 'Internal Flags',
      },
      table: {
        flagName: 'Flag Name',
        flagDesc: 'Flag Description',
        flagStatus: 'Flag Status',
        unknown: 'Unknown flag',
        enabled: 'Enabled',
        disabled: 'Disabled',
      },
      addOverride: 'Add an override for this user, so this value is always set',
      unknownFlag: 'Unknown flag "{flag}"',
      editFlag: 'Update the value of this feature flag on the {entity}',
      overrideFlag: 'Set the value of this feature switch on the {entity}, overriding the global setting',
      setFlag: 'Set the value of this feature switch on the {entity}, since it is not set elsewhere',
      flag: 'Flag',
      noFlags: 'No flags',
    },
    feeScenarios: {
      feeScenarioOnThisCase: 'Fee scenario on this case',
      feeType: 'Fee type',
      feeAmount: 'Fee amount',
      editFeeScenarios: 'Edit fee scenario',
      subHeader: "You can change or edit the fee scenario on this case, this won't change any fee scenarios elsewhere.",
      feeScenarioTitle: `Fees scenario {scenarioTitle}`,
    },
    globalWarnings: {
      fixThis: 'Fix this',
      signOut: 'Sign out',
      switchBack: 'Switch back',
      warning: 'Warning',
      giAutoRefer: `Auto-refer is active for your organisation, but we won't be able to refer cases until you enter your Uinsure credentials.`,
      supportMessage: `Signed in as an {supportRole} for {supportedOrg}`,
      moreInformation: 'More information',
    },
    relationType: {
      referrer: 'Referrer',
      administrator: 'Administrator',
      leadQualifier: 'Lead qualifier',
      caseManager: 'Case manager',
      watcher: 'Watcher',
      reviewer: 'Reviewer',
      caseOwner: 'Case owner',
    },
    complianceDashboard: {
      advisorConsumerDuty: 'Advisor Consumer Duty',
      firmConsumerDuty: 'Firm Consumer Duty',
      networkConsumerDuty: 'Network Consumer Duty',
      productCoverage: 'Product coverage',
      daysValue: '{days} days',
      vulnerableClientService: 'Vulnerable client service',
      productsAndServices: 'Products & Services',
      priceAndValue: 'Price & Value',
      consumerUnderstanding: 'Consumer Understanding',
      customerSupport: 'Customer Support',
      productTypes: {
        protection: 'Protection',
        home_insurance: 'Home insurance',
        conveyancing: 'Conveyancing',
        surveys: 'Surveys',
        wills_and_probate: 'Wills and probate',
      },
      cohortTypes: {
        vulnerable: 'Vulnerable clients ({total})',
        non_vulnerable: 'Non-vulnerable clients ({total})',
      },
      advice: 'Advice',
      eligibleCases: 'Eligible cases',
      advised: 'Advised',
      referred: 'Referred',
      coverage: 'Your total',
      cohort: 'Cohort',
      averageCaseFees: 'Avg. case fees',
      averageReviewGrade: 'Avg. grade',
      averageTimeToCompletion: 'Avg. time to completion',
      averageInterestRate: 'Avg. interest rate',
      completions: 'Completions',
      cancellations: 'Cancellations',
      casesWithAmendedFees: 'Cases with amended fees',
      industryAverage: 'Industry average',
      networkAverage: 'Network average',
      averageTermIncrease: 'Avg. term increase',
      termIncreasePercentage: 'Cases with term increase',
      debtConsolidationPercentage: 'Debt-con % of case',
      averageDebtConsolidationValue: 'Avg. debt-con value',
      lendingIntoRetirementPercentage: 'Lending into retirement',
      interestOnlyProductsPercentage: 'Interest-only products',
      viewTheseCases: 'View these cases',
      metricsTooltips: {
        heading: 'What does this mean?',
        avgTimeToApplication: 'Average time (in days) from case creation to application submitted.',
        avgTimeToCompletion: 'Average time (in days) from case creation to completion.',
        avgNumberProtectionProducts:
          'The average number of recommended protection products on cases with at least one protection recommendation.',
        completions: 'Number of cases in complete, as a percentage of all cases.',
        cancellations: 'Number of cases in not proceeding, as a percentage of all cases.',
        casesWithAmendedFees:
          'Number of cases where fees were amended from a standard fee scenario, as a percentage of all cases.',
        casesWithResubmissions:
          'The percentage of cases that were moved to application submitted more than once. Excludes rate tracking changes (cases where only the initial rate on the recommended mortgage was changed).',
        productTransfersOrRemos:
          'Number of remortgage cases where the recommended mortgage is with the same lender as the existing.',
        avgSourceRank: 'The average ranking of recommended mortgages based on their true cost at the time of sourcing.',
        casesWithFeesAddedToLoan:
          'The number of cases where the recommended mortgage includes fees added to the loan, as a percentage of all cases.',
        clientPortalLogin: 'The number of cases where at least one client has logged into the client portal.',
      },
      productCoverageTooltips: {
        heading: 'What does this mean?',
        eligible_cases: {
          protection:
            'The total number of cases eligible for protection advice, including FTB / Purchase only, House moves, Remortgages, Protection, Business Protection, and Buy-to-Let or Buy-to-Let remortgage cases where the client is not a limited company.',
          home_insurance:
            'The total number of cases eligible for home insurance advice. This includes all mortgage case types as well as home insurance.',
          conveyancing:
            'The total number of cases eligible for conveyancing advice. This includes FTB / Purchase only, House move and Buy-to-Let mortgage cases.',
          surveys:
            'The total number of cases eligible for a survey referral. This includes FTB / Purchase only, House move and Buy-to-Let mortgage cases.',
          wills_and_probate:
            'The total number of cases eligible for a wills and probate referral. This includes all case types.',
        },
        advised_cases: {
          protection: 'The total number of eligible cases where a protection product was recommended.',
          home_insurance: 'The total number of eligible cases where a home insurance product was recommended.',
          conveyancing:
            'Not applicable. These services are provided through referrals and cannot be directly advised as part of an eligible case.',
          surveys:
            'Not applicable. These services are provided through referrals and cannot be directly advised as part of an eligible case.',
          wills_and_probate:
            'Not applicable. These services are provided through referrals and cannot be directly advised as part of an eligible case.',
        },
        referred_cases: {
          protection: 'The total number of eligible cases where a protection referral was made.',
          home_insurance: 'The total number of eligible cases where a home insurance referral was made.',
          conveyancing: 'The total number of eligible cases where a conveyancing referral was made.',
          surveys: 'The total number of eligible cases where a survey referral was made.',
          wills_and_probate: 'The total number of eligible cases where a wills and probate referral was made.',
        },
        coverage_percentage: {
          protection: 'The percentage of eligible cases where protection was either advised or referred.',
          home_insurance: 'The percentage of eligible cases where home insurance was either advised or referred.',
          conveyancing: 'The percentage of eligible cases where a conveyancing referral was made.',
          surveys: 'The percentage of eligible cases where a survey referral was made.',
          wills_and_probate: 'The percentage of eligible cases where a wills and probate referral was made.',
        },
        network_coverage_percentage: {
          protection:
            'The percentage of eligible cases where protection was either advised or referred across the regulating network.',
          home_insurance:
            'The percentage of eligible cases where home insurance was either advised or referred across the regulating network.',
          conveyancing:
            'The percentage of eligible cases where conveyancing referral was made across the regulating network.',
          surveys: 'The percentage of eligible cases where a survey referral was made across the regulating network.',
          wills_and_probate:
            'The percentage of eligible cases where a wills and probate referral was made across the regulating network.',
        },
        industry_coverage_percentage: {
          protection:
            'The percentage of eligible cases where protection was either advised or referred across the entire Acre platform.',
          home_insurance:
            'The percentage of eligible cases where home insurance was either advised or referred across the entire Acre platform.',
          conveyancing:
            'The percentage of eligible cases where conveyancing referral was made across the entire Acre platform.',
          surveys: 'The percentage of eligible cases where a survey referral was made across the entire Acre platform.',
          wills_and_probate:
            'The percentage of eligible cases where a wills and probate referral was made across the entire Acre platform.',
        },
      },
      pieChartTooltips: {
        heading: 'What does this mean?',
        caseType: '{labelOne} accounts for {labelTwo} of the cases in this query.',
        loanAmount: '{labelTwo} of mortgage cases in this query have a loan amount between {labelOne}.',
        loanAmountWithoutRange: '{labelTwo} of mortgage cases in this query have a loan amount of {labelOne}.',
        feeDistribution:
          '{labelTwo} of cases in this query involve fees to be paid by the client of between {labelOne}.',
        feeDistributionWithoutRange:
          '{labelTwo} of cases in this query involve fees to be paid by the client of {labelOne}.',
        lenders: '{labelOne} accounts for {labelTwo} of the recommended mortgages in this query.',
        healthAndProtectionProviders:
          '{labelOne} accounts for {labelTwo} of the recommended health and protection products in this query.',
        caseFlags: '{labelOne} is flagged for {labelTwo} of the cases in this query.',
        initialRateLengthDistribution:
          '{labelOne} initial rate periods account for {labelTwo} of recommended mortgage products in this query.',
        interestRateType:
          '{labelOne} interest rate types account for {labelTwo} of recommended mortgage products in this query.',
        cancellationReason:
          '{labelOne} was the reason provided for {labelTwo} of the cases marked as not proceeding in this query.',
        caseGrades: '{labelTwo} of grades cases in this query have a {labelOne} grade.',
        protectionBenefits:
          '{labelOne} product types account for {labelTwo} of recommended health & protection products in this query.',
      },
      vulnerableTooltips: {
        heading: 'What does this mean?',
        cohort: {
          vulnerable:
            'The number of cases where at least one client is flagged as potentially vulnerable on the client record.',
          non_vulnerable:
            'The number of cases where no clients were flagged as potentially vulnerable on the client record.',
        },
        avg_case_fees: {
          vulnerable: 'Average case fees across all cases in cohort (cases with a vulnerable client).',
          non_vulnerable: 'Average case fees across all cases in cohort (cases without a vulnerable client).',
        },
        avg_days_to_completion: {
          vulnerable:
            'Average time (in days) from case creation to completion for cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Average time (in days) from case creation to completion for cases in cohort (cases without a vulnerable client).',
        },
        avg_interest_rate: {
          vulnerable:
            'Average interest rate for all recommended mortgages across cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Average interest rate for all recommended mortgages across cases in cohort (cases without a vulnerable client).',
        },
        avg_review_grade: {
          vulnerable:
            'Average review grade across all cases sampled for file review in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Average review grade across all cases sampled for file review in cohort (cases without a vulnerable client).',
        },
        completions_percentage: {
          vulnerable:
            'Number of cases in complete, as a percentage of all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Number of cases in complete, as a percentage of all cases in cohort (cases without a vulnerable client).',
        },
        cancellations_percentage: {
          vulnerable:
            'Number of cases in not proceeding, as a percentage of all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Number of cases in not proceeding, as a percentage of all cases in cohort (cases without a vulnerable client).',
        },
        lending_into_retirement_percentage: {
          vulnerable:
            'Number of cases with a lending into retirement risk flag in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Number of cases with a lending into retirement risk flag in cohort (cases without a vulnerable client).',
        },
        interest_only_percentage: {
          vulnerable:
            'Number of cases where the recommended mortgage type is interest only across all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Number of cases where the recommended mortgage type is interest only across all cases in cohort (cases without a vulnerable client).',
        },
        debt_consolidation_percentage: {
          vulnerable:
            'Number of cases with debt consolidation borrowing as a percentage of all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Number of cases with debt consolidation borrowing as a percentage of all cases in cohort (cases without a vulnerable client).',
        },
        avg_debt_consolidation: {
          vulnerable:
            'Average value of debt to be consolidated across all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Average value of debt to be consolidated across all cases in cohort (cases without a vulnerable client).',
        },
        cases_with_term_increase_percentage: {
          vulnerable:
            'Number of cases where the recommended mortgage term extends beyond the term of the current mortgage for all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Number of cases where the recommended mortgage term extends beyond the term of the current mortgage for all cases in cohort (cases without a vulnerable client).',
        },
        avg_term_increase: {
          vulnerable:
            'Average increase (in days) between end date of a recommended mortgage and the end date of current mortgage across all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Average increase (in days) between end date of a recommended mortgage and the end date of current mortgage across all cases in cohort (cases without a vulnerable client).',
        },
        cases_with_amended_fees_percentage: {
          vulnerable:
            'Number of cases where fees were amended from a standard fee scenario, as a percentage of all cases in cohort (cases with a vulnerable client).',
          non_vulnerable:
            'Number of cases where fees were amended from a standard fee scenario, as a percentage of all cases in cohort (cases without a vulnerable client).',
        },
      },
    },
    userDashboard: {
      title: 'Your performance',
      companyTitle: 'Company performance',
      otherTitle: 'Performance for {name}',
      networkTitle: 'Network performance',
      intro: 'Here are your stats for the period below',
      companyIntro: 'Here are your company stats for the period below',
      networkIntro: 'Here are your network stats for the period below',
      performance: 'Performance',
      current: 'This period',
      previous: 'Previous',
      productSection: {
        title: 'Product penetration',
      },
      tableType: {
        revenue: 'Revenue',
        protection: 'Health and Protection',
        homeInsurance: 'Home insurance',
        conveyancing: 'Conveyancing',
        wills: 'Wills',
        surveys: 'Surveys',
      },
      caseType: {
        completed: 'Completed',
        inFlight: 'In flight',
        both: 'Both',
      },
      periods: {
        MONTH: 'Last 30 days',
        MONTH_TO_DATE: 'Month to date',
        NINETY_DAYS: 'Last 90 days',
        CALENDAR_QUARTER: 'Quarter to date',
        YEAR: 'Last 365 days',
        CALENDAR_YEAR: 'Year to date',
      },
      protectionTypes: {
        product_type_term: 'Term',
        product_type_income_protection: 'Income protection',
        product_type_other: 'Other',
        product_type_family_income_benefit: 'Family income benefit',
        product_type_whole_of_life: 'Whole of life',
        product_type_pmi: 'Private medical insurance',
        product_type_relevant_life: 'Relevant life',
        product_key_man: 'Key man',
        product_type_group_life: 'Group life',
        product_type_group_asu: 'Group ASU',
        product_type_group_pmi: 'Group private medical insurance',
        product_type_group_ip: 'Group income protection',
        product_type_group_cic: 'Group critical illness',
        product_type_group_private_medical_insurance: 'Group private medical insurance',
        product_type_employee_benefit: 'Employee benefit',
      },
      tables: {
        user: {
          rank: 'Rank',
          penetration: 'Penetration',
          declined: 'Declined',
          instructed: 'Instructed',
          total: 'Total',
          referred: 'Referred',
          with_linked_case: 'Linked case',
          sold: 'Advised',
          mortgage_cases: 'Eligible cases',
          other_cases: 'Health and Protection cases',
          product_type: 'Product type',
        },
        revenue: {
          rank: 'Rank',
          advisor_name: 'Advisor',
          organisation_name: 'Firm',
          mortgage_cases: 'All cases',
          other_cases: 'Unlinked protection only',
          time_to_complete: 'Time to complete',
          pre_app_revenue: 'Pre application',
          post_app_revenue: 'Post application',
          completed_revenue: 'Completed',
          total_revenue: 'Total revenue',
          per_case: 'Per case',
          cross_sells: 'Cross-sales',
          density: 'Cross-sales per case',
        },
        protection: {
          rank: 'Rank',
          advisor_name: 'Advisor',
          organisation_name: 'Firm',
          penetration: 'Penetration',
          mortgage_cases: 'Eligible cases',
          protection_only: 'Unlinked protection only',
          cases_with_protection: 'With Health and Protection',
          cases_with_linked_protection: 'With linked Health and Protection',
          with_recommended_product: 'Recommended/sold product',
          total_cases_with_protection: 'Total with Health and Protection',
        },
        gi: {
          rank: 'Rank',
          advisor_name: 'Advisor',
          organisation_name: 'Firm',
          penetration: 'Penetration',
          mortgage_cases: 'Eligible cases',
          cases_with_sold_gi: 'With sold GI',
          cases_with_referred_gi: 'With referred GI',
          total_cases_with_gi: 'Total GI sold/referred',
        },
        conveyancing: {
          rank: 'Rank',
          advisor_name: 'Advisor',
          organisation_name: 'Firm',
          penetration: 'Penetration',
          mortgage_cases: 'Eligible cases',
          referred: 'Referred',
          instructed: 'Instructed',
          declined: 'Declined',
        },
        wills: {
          rank: 'Rank',
          advisor_name: 'Advisor',
          organisation_name: 'Firm',
          penetration: 'Penetration',
          mortgage_cases: 'Eligible cases',
          referred: 'Referred',
        },
        surveys: {
          rank: 'Rank',
          advisor_name: 'Advisor',
          organisation_name: 'Firm',
          penetration: 'Penetration',
          mortgage_cases: 'Eligible cases',
          referred: 'Referred',
        },
      },
      metrics: {
        cases_completed: 'Cases completed',
        tlv: 'Total loan value',
        revenue: 'Total revenue',
        soc: 'Average speed of completion',
        product_transfer_remo_percentage: 'Product transfers / Remos',
        cancellations_percentage: 'Cancellations',
        cases_with_amended_fees_percentage: 'Cases with amended fees',
        cases_with_resubmissions: 'Cases with multiple submissions',
        completions_percentage: 'Completions',
        avg_days_to_completion: 'Avg. time to completion',
        avg_days_to_application: 'Avg. time to application',
        avg_number_of_protection_products: 'Avg. Protection Products',
        client_portal_login_percentage: 'Client portal login',
        avg_source_rank: 'Avg. sourcing rank',
        cases_with_fees_added_to_loan_percentage: 'Cases with fees added to loan',
      },
      crossSell: {
        title: 'Cross-sell opportunities',
        blurb: `Out of your {count} eligible cases in progress, {notReferred} have clients
              at risk by not having {item}.`,
        gi: {
          title: 'Home insurance opportunities',
          item: 'home insurance cover',
        },
        protection: {
          title: 'Health and Protection opportunities',
          noAdvice: 'With no Health and Protection ',
          onCase: 'With Health and Protection on case',
          linked: 'With linked Health and Protection case',
        },
        conveyancing: { title: 'Conveyancing opportunities', item: 'a suitable lawyer in place' },
        eligible: 'Eligible but not referred',
        referred: 'Referred',
        converted: 'Referred and converted or sold directly',
        declined: 'Declined',
      },
      graphs: {
        title: 'Business splits',
        loan_amount: 'Loan amount',
        property_value: 'Property value',
        cancellation_reason: 'Cancellation reason',
        case_status: 'Case status',
        case_type: 'Case type',
        introducers: 'Introducers',
        lenders: 'Lenders',
        protection: 'Health and Protection providers',
        case_flags: 'Case flags',
        noData: 'No data for {s}',
        case_fee_distribution: 'Case fee distribution',
        initial_rate_length_distribution: 'Initial rate length distribution',
        interest_rate_type: 'Interest rate type',
        case_grades: 'Case grades',
        case_grades_details: 'Case grades - {count, plural, one {# grade} other {# grades}}',
        protection_benefits: 'Protection Products',
        protection_by_product: 'Health and Protection product types',
        avgGradesReview:
          'The distribution of case grades (A, B, C, D) as a percentage of the total number of cases sampled for file review in this time period.',
      },
    },
    integrations: {
      no: {
        title: 'Integrations',
        blurb:
          'This page is not currently enabled for your account. If you need API access or to use our Zapier integration, please speak to your Customer Success contact.',
      },
      docs: {
        header: 'API documentation',
        mainAPI: 'Production API Swagger file',
        uatAPI: 'UAT API Swagger file',
      },
      details: {
        header: 'Auth and API locations',
        find: 'Our API endpoints can be found at',
        auth: 'Auth',
        token: 'Token',
        refresh: 'Refresh',
        oidc: 'OIDC',
        generate: `You can generate an OAuth access token using
            <oauthlink>any standard OAuth 2.0 library</oauthlink> and a client app created above with the
            following endpoints:`,
        headers: `Access tokens should be supplied as a secure cookie called {auth} and you will
          need to supply a header called {xapikey} with the value {xapikeyval}`,
      },
      zapier: {
        title: 'Zapier is the easiest way to integrate with Acre',
        intro:
          'Zapier is an online automation tool that connects your apps and services. You can connect two or more apps to automate repetitive tasks without coding or relying on developers to build the integration.',
        intro2:
          'Acre is fully integrated with Zapier, allowing you to automate tasks and bring data in and out of the system whilst connecting to over 5000 other pieces of software including Outlook, Mailchimp, Google and Dialpad.',
        cta: '<a>Click here</a> to access the integration.',
      },
      oauth: {
        title: 'OAuth applications',
        heading:
          'OAuth applications are outside applications registered with Acre to be allowed access to the Acre API. You will need an application to be able to read or edit data.',
        register: 'Register application',
        modal: {
          title: 'OAuth application',
          save: 'Save',
          name: 'Application name',
          homepage: 'Homepage URL',
          callback: 'OAuth callback URL',
          privacy: 'Privacy policy URL',
          tos: 'Terms of service URL',
          logo: 'Logo URL',
          missingURL: 'You must enter a URL in this field',
          errNeedAName: 'You must enter a name for your application',
          errBadURL: 'Invalid URL',
          logoInfo: 'Images should be 200px wide and no more than 100px high',
        },
        result: {
          title: 'OAuth application created',
          id: 'Application ID',
          secret: 'Application secret',
          intro:
            'Your application has been created. You will need the following details in order to authenticate to our OAuth servers:',
          warning:
            'This information will be shown ONCE and cannot be viewed again later so please ensure you have recorded it before closing this window.',
        },
        errSave: 'Could not save app due to a server problem',
        errLoad: 'Could not load webhooks',
      },
      users: {
        title: 'OAuth users',
        heading:
          'OAuth users are Acre users who are authenticated through our OAuth server so they can be used in outside applications such as Zapier. You will be able to view them in the <l>main users list</l> too.',
        add: 'Add user',
        userModal: {
          title: 'Add an OAuth user',
          new: "We have created your new user. The user's password is {password}. Please make sure you retain this now as it will not be possible to view it again.",
          first_name: 'First name',
          last_name: 'Last name',
          email_address: 'Email address',
          can_edit: 'Can update cases?',
          notEmpty: 'You must enter a value for this field',
          badEmail: 'This field must be a valid email address',
          explanation:
            'Please enter the details of the new user you wish to create. You will be able to edit permissions etc after creating the user by using the edit button.',
        },
        resetPasswordModal: {
          title: 'Reset password?',
          text: "Are you sure you want to reset the user's password?",
          new: "The user's new password is {password}. Please make sure you retain this now as it will not be possible to view it again.",
          button: 'Reset password',
        },
      },
      tooltips: {
        edit: 'Edit',
        delete: 'Delete',
        reset: 'Reset password',
      },
      webhooks: {
        title: 'Webhooks',
        heading: 'Webhooks allow you to build or set up integrations, which subscribe to certain events in Acre.',
        create: 'Create a webhook',
        cols: {
          url: 'URL',
          event: 'Event types',
          cond: 'Conditions',
          created: 'Created',
          lastrun: 'Last run',
          err: 'Errors',
          edit: 'Edit',
          delete: 'Delete',
          status: 'Status',
        },
        statuses: {
          active: 'Active',
          disabled: 'Disabled',
          suspended: 'Suspended',
        },
        errNoRows: 'No webhooks have been created',
        errServer: 'An error occurred fetching webhooks',
        errSave: 'Could not save webhook due to a server problem',
        modal: {
          title: 'Webhook',
          cond: 'Conditions',
          condCols: {
            cond: 'Condition type',
            rev: 'Reverse',
            sel: 'Selected options',
            del: 'Delete',
          },
          addCond: 'Add condition',
          addHeader: 'Add header',
          noRows: 'No rows',
          url: 'Endpoint URL',
          headers: 'Headers',
          headerCols: {
            name: 'Header name',
            val: 'Value',
            del: 'Delete',
          },
          explain:
            'To edit a row, double click on it. When you are done, hit enter or click outside the row to save or hit "escape" to cancel editing. You must hit "Save" to persist your changes.',
          errAtLeastOne: 'You must have at least one condition',
          errType: 'You should normally have either an Event Type or Action Type condition',
          errGeneral: 'One or more conditions above contains an error that must be rectified before continuing',
          errHeader: 'One or more rows above lacks a header name or value',
          errURL: 'You must enter a valid URL',
          save: 'Save',
          select: 'Select a condition type',
        },
      },
    },
    problemReport: {
      reportAProblem: 'Report a problem',
      title: 'Issue title',
      describeProblem: 'Describe a problem you are having',
      situation: 'What were you attempting to do?',
      expectation: 'What did you expect to happen?',
      problem: 'What actually happened?',
      screenshot: 'Would you like to send a screenshot of the CRM along with your request?',
      sendToCustomerSupport: 'Send to customer support',
    },
    riskReport: {
      riskReportFor: 'Risk report for',
      refreshRiskReport: 'Refresh risk report',
      getRiskReport: 'Get risk report',
      generate: 'Generate risk report',
      moreInfoModal: {
        heading: 'More information needed for risk report',
        subHeading: 'You need to complete the following information before you can see a risk report for your clients:',
        caseHeading: 'Case missing values',
        missingValues: {
          employment_status: 'Employment status',
          standardised_occupation_code: 'Job title',
          total_guaranteed_annual: 'Annual income',
          date_of_birth: 'Age',
          smoker_status: 'Smoker status',
          first_name: 'First name',
          last_name: 'Last name',
          gender: 'Gender',
          title: 'Title',
        },
      },
      mortgageDetailsModal: {
        title: 'Generate risk report',
        mortgageAmount: 'Mortgage amount',
        mortgageRepaymentType: 'Mortgage repayment type',
        mortgageMonthlyPayment: 'Mortgage monthly payment',
        mortgageTerm: 'Mortgage term',
        postCode: 'Postcode',
      },
      riskReportBody: {
        lifeInsuranceTitle: 'Life insurance',
        incomeProtectionTitle: 'Income protection',
        lifeAndCICTitle: 'Life + CIC',
        lifeCICIPTitle: 'Life + CIC + IP',
        lifeInsuranceBio: '{percentage}% chance of death during mortgage term',
        incomeProtectionBio: '{percentage}% chance of being off for 2+ months during mortgage term',
        lifeAndCICBio: '{percentage}% chance of suffering a serious illness or dying',
        lifeCICIPTitleBio: '{percentage}% chance of needing at least one type of cover',
      },
    },
    caseDetailsTotals: {
      summary: 'Summary',
      specifiedLoanAmount: 'Specified loan amount',
      loanAmountTooltip: 'The loan amount manually entered on the case.',
      borrowingRequirement: 'Borrowing requirement',
      fundingRequirements: 'Funding requirements',
      repaidMortgageDetected: 'Repaid mortgage detected',
      repaidMortgageDetectedWarningMessage:
        'A continuing mortgage has been marked as repaid, which may affect calculations. To fix this, try removing and adding the mortgage again. Contact support if needed.',
      needsChecking: 'Needs checking',
      totalDeposits: 'Total deposits',
      generalDifferences: 'There are discrepancies with these numbers, please check for accuracy.',
      mortgageAndFundingDifference:
        'Funding requirements do not match total recommended mortgage amount, please check for accuracy.',
      mortgageAndBorrowingDifference: `The total recommended mortgage amount doesn't match the borrowing requirement, please check for accuracy.`,
      mortgageAndLoanDifference: `You've recommended a mortgage amount that doesn't match the originally specified loan amount. Would you like to update it to match the recommendation?`,
      updateSpecifiedLoanAmount: 'Update specified loan amount to match',
      outstandingAndRemortgageDifference:
        'Total outstanding mortgage balance does not match remortgage amount, please check for accuracy.',
      totalRecommendedMortgageAmount: 'Total recommended mortgage amount',
      propertyPrice: 'Property price',
      totalOutstandingMortgageBalance: 'Total outstanding mortgage balance',
      remortgageAmount: 'Remortgage amount',
      totalAdditionalBorrowingAmount: 'Total additional borrowing amount',
      totalBalanceDownPaymentBorrowing: 'Balance + down payment + additional borrowing',
      totalBorrowingRequirement: 'Total borrowing requirement',
      currentEquity: 'Current equity',
      equityAvailableForPurchase: 'Equity available for purchase',
      additionalCapital: 'Additional capital',
      sharedOwnershipDeduction: 'Shared ownership deduction',
      sharedOwnershipDeductionValue: 'Shared ownership deduction ({value})',
      sharedOwnershipDeductionTooltip:
        'This is the portion of the property being purchased by the shared ownership scheme provider.',
      helpToBuyLoanAmount: 'Help to buy loan amount',
      sharedEquityLoan: 'Shared equity loan',
      address: 'Address',
      value: 'Value',
      price: 'Price',
      outstandingMortgage: 'Outstanding mortgage',
      purchasePriceEquityCapital: 'Purchase price - equity to be used - additional capital',
      purchasePriceEquityCapitalDownPayment: 'Purchase price - equity to be used - down payment',
      differenceInBorrowingAndLoanAmount: 'Difference in loan amount and borrowing',
      ltvCard: {
        loanRequired: 'Loan required',
        calculatedLtv: 'Calculated LTV',
        clientShare: 'Client share',
        valueOfPurchaseProperty: 'Value of property being purchased',
        valueOfRemortgageProperty: 'Value of property being remortgaged',
      },
    },
    changeContactInfoModal: {
      title: 'Are you sure you want to change this?',
      desc: `If the client has already registered on the client portal with this email/phone number, they will be required to register again and will not be able to access the client portal with their previous information.`,
      continue: 'Save changes',
      goBack: 'Go back',
    },
    propertyData: {
      graphs: {
        socioeconomicGrade: 'Socioeconomic grade',
        propertyType: 'Property type',
        carOwnership: 'Car ownership',
        tenure: 'Tenure',
        qualifications: 'Qualifications',
        reportedCrimes: 'Reported crimes within 200m',
      },
    },
    paymentCalendar: {
      moreThanSixPaymentInArrears: 'More than 6 payments in arrears',
      default: 'Default',
      specialArrangement: 'Special arrangement',
      onePaymentInArrears: '1 payment in arrears',
      twoPaymentInArrears: '2 payments in arrears',
      threePaymentInArrears: '3 payments in arrears',
      fourPaymentInArrears: '4 payments in arrears',
      fivePaymentInArrears: '5 payments in arrears',
      sixPaymentInArrears: '6 payments in arrears',
      upToDate: 'Up to date',
    },
  },
}

export default messagesBrokerCRM
