import { dashboardsFiltersDiscovery } from '../api/dashboards_filters'
import { Resolvers } from '../generated/resolvers'

const DashboardsFilter: Resolvers = {
  Query: {
    dashboardsFiltersDiscovery,
  },
}

export default DashboardsFilter
