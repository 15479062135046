import { differenceInMilliseconds, differenceInYears } from 'date-fns'

import { Client, ClientVersion, Maybe, Relationship } from '../../generated/resolvers'

// currently natural clients can have the is_natural_person property set to
// null, undefined and true, only a non natural client would have this explicitly as false
export const isNaturalClient = (
  client: Pick<Client, 'is_natural_person' | 'first_name' | 'last_name' | 'middle_name'>,
) =>
  client &&
  client.is_natural_person !== false &&
  !!(
    ('first_name' in client && client.first_name) ||
    ('last_name' in client && client.last_name) ||
    ('middle_name' in client && client.middle_name)
  )

export const getDetailsOfNaturalClientsOnly = (clients: ClientVersion[]) =>
  clients.filter((client) => isNaturalClient(client.details)).map((client) => client.details)

export const hasDualNaturalClients = (clients: ClientVersion[]) =>
  clients.reduce((acc, client) => {
    if (isNaturalClient(client.details)) {
      return ++acc
    }
    return acc
  }, 0) === 2

export const isNonNaturalClient = (client: Pick<Client, 'is_natural_person' | 'organisation_name'>) =>
  client &&
  'is_natural_person' in client &&
  client.is_natural_person === false &&
  !!('organisation_name' in client && client.organisation_name)

// These are default formatting which can be replaced in siutuations where
// different formats are needed such as adding punctuation
const defaultFormatNaturalClient = ({ first_name, last_name }: Pick<Client, 'first_name' | 'last_name'>) =>
  `${first_name || ''} ${last_name || ''}`.trim()

const defaultFormatNonNaturalClient = ({ organisation_name }: Pick<Client, 'organisation_name'>) =>
  organisation_name || ''

type GetClientName = {
  client: Pick<Client, 'first_name' | 'last_name' | 'middle_name' | 'is_natural_person' | 'organisation_name'>
  formatNaturalClient?: (
    client: Pick<Client, 'is_natural_person' | 'first_name' | 'last_name' | 'middle_name'>,
  ) => string
  formatNonNaturalClient?: (client: Pick<Client, 'organisation_name'>) => string
}

// Handles getting the name of both natural and non-natural clients. It uses
// the above formatting methods by default, however these can be overriden using
// custom formatters (such as one that get's only the first name, rather than
// the client's full name).
export const getClientName = ({
  client,
  formatNaturalClient = defaultFormatNaturalClient,
  formatNonNaturalClient = defaultFormatNonNaturalClient,
}: GetClientName) => {
  let result: Maybe<string> | undefined = ''
  if (isNaturalClient(client)) {
    result = formatNaturalClient(client)
  }

  if (isNonNaturalClient(client)) {
    result = formatNonNaturalClient(client)
  }

  return result
}

export const ageComparator = (clientA: Client, clientB: Client) => {
  const dobA = clientA.date_of_birth
  const dobB = clientB.date_of_birth
  return dobA && dobB ? differenceInMilliseconds(new Date(dobA), new Date(dobB)) : 0
}

export const findEldestClient = (clients: Client[]) => {
  if (!clients.length) return undefined
  const sortedClients = clients.sort(ageComparator)
  return sortedClients[0]
}

export const isClientRetired = (client: Client) => {
  const age = getClientAge(client)
  const retirementAge = client.retirement_age
  return Boolean(retirementAge) && age >= retirementAge!
}

export const getClientAge = (client: Client) => {
  return differenceInYears(new Date(), new Date(client.date_of_birth!))
}

export const filterNaturalClients = (clients: Client[]) => {
  return clients.filter(isNaturalClient)
}

export const unwrapClientVersion = (client: ClientVersion): Client => {
  return client.details
}

export const wrapClient = (client: Client): ClientVersion => {
  return { id: client.id, details: client }
}

export const getClientCurrentAddressAsString = (clientDetails?: Maybe<Client>) => {
  const { addresses } = clientDetails || {}
  const currentAddresses = (addresses || []).filter((address) => address.is_current_correspondence)

  if (currentAddresses.length === 0) return null
  const { address1, address2, address3, posttown, postcode } = currentAddresses[0].address
  const allAddressParams = [address1, address2, address3, posttown, postcode]
  return allAddressParams.filter((param) => !!param).join(', ')
}

import omitTypename from '../helpers/omitTypename'

type DividedRelationships = {
  relevantPersonRelationship: null | Relationship
  remainingRelationships: Relationship[]
}

/**
 * The function that separates out relationship to the relevant person of interest
 * and the rest of the relationships in the relationships attribute
 * @param relationships all the relationships on the client
 * @param relevantPersonId person whose relationship needs to be separated out from the rest
 */
export const separateRelationships = (
  relevantPersonId: string,
  relationships?: Relationship[] | null,
): DividedRelationships => {
  if (!relationships) {
    return {
      relevantPersonRelationship: null,
      remainingRelationships: [],
    }
  }

  return relationships.reduce<DividedRelationships>(
    (acc, relationship) => {
      if (relationship.relationship_reference === relevantPersonId) {
        acc.relevantPersonRelationship = omitTypename(relationship)
      } else {
        acc.remainingRelationships.push(omitTypename(relationship) as Relationship)
      }
      return acc
    },
    {
      relevantPersonRelationship: null,
      remainingRelationships: [],
    },
  )
}
