import { useCallback, useState } from 'react'
import { FetchResult } from '@apollo/client'
import { useCaseContext, usePermissionsContext } from '@broker-crm-contexts'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { get, isEmpty, isNil } from 'lodash'
import { Field, useForm } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { testHandle, useFormatMessage } from '@acre/utils'
import {
  CaseDetailsFlag,
  CaseDetailsFlagFlagType,
  CaseDetailsFlagReview,
  GateName,
  Maybe,
  useCreateCaseFlagReviewMutation,
} from '@acre/graphql'
import {
  Button,
  BUTTON_SECONDARY,
  BUTTON_TYPE_BUTTON,
  BUTTON_TYPE_SUBMIT,
  Card,
  Checkbox,
  ColourId,
  NoteFooter,
  Tag,
  UnsetSectionDivider,
} from '@acre/design-system'

import { flagToMessage } from '../CaseFlag.helpers'
import { canMarkNoFurtherReviewFlags, havePermissionForFlag } from '../CaseFlagNotes.helpers'
import CaseFlagNoteFields from './CaseFlagNoteFields'

export const messagePrefix = 'cases.flags.note'

type Props = {
  id: string
  name: string
  flag: CaseDetailsFlag
  onClickClearFlag: (handleSendReviewClick: () => Promise<FetchResult<any> | undefined>) => void
  disabled?: boolean
}

const newReview = { reviewer_notes: '', skip_future_reviews: false }

const CaseFlagNote = ({ id, name, flag, onClickClearFlag }: Props) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()
  const form = useForm()

  const { id: caseId } = useCaseContext()
  const permissions = usePermissionsContext()

  const haveAddCaseNotePermission = Boolean(permissions?.gates?.includes(GateName.REGULATED_COMPLIANCE_ADDCASENOTES))
  const hasEditAMLCaseFlagsPermission = Boolean(permissions?.gates?.includes(GateName.REGULATED_COMPLIANCE_APPROVEAML))
  const hasEditAdviceCaseFlagsPermission = Boolean(
    permissions?.gates?.includes(GateName.REGULATED_COMPLIANCE_APPROVEADVICE),
  )

  const [showSubmit, setShowSubmit] = useState<boolean>(false)
  const [clearFlag, setClearFlag] = useState<boolean>(true)

  const [unsavedReviewIndex, setUnsavedReviewIndex] = useState<Maybe<number>>(null)

  const currentReviewFieldName = unsavedReviewIndex ? `${name}.reviews[${unsavedReviewIndex - 1}]` : null

  const [createCaseFlagReview, { loading: createCaseFlagReviewLoading }] = useCreateCaseFlagReviewMutation()

  const handleAddClick = useCallback(() => {
    form.mutators.push(`${name}.reviews`, newReview)
    setShowSubmit(true)
    const reviewsLength = get(form.getState().values, `${name}.reviews`).length
    setUnsavedReviewIndex(reviewsLength)
  }, [form, name])

  const reviewNotes = form.getFieldState(`${currentReviewFieldName}.reviewer_notes`)?.value
  const reviewGrade = form.getFieldState(`${currentReviewFieldName}.review_grade`)?.value

  // When clear flag is set to false, the submit button should be disabled if there are no review notes
  const disableSubmitUnclearFlag = !clearFlag && isEmpty(reviewNotes)

  const handleSendReviewClick = useCallback(async () => {
    // If clear flag is true make sure the confirmation modal appears,
    // otherwise add the review notes without setting the flag to be true
    if (clearFlag) {
      onClickClearFlag(handleSendReviewClick)
      setUnsavedReviewIndex(null)
      setShowSubmit(false)
      return
    }

    if (isEmpty(reviewNotes) || isNil(unsavedReviewIndex)) return

    const res = await createCaseFlagReview({
      variables: {
        input: {
          case_id: caseId,
          flag_id: flag.flag_id,
          reviewed_case_version: 0,
          reviewer_notes: reviewNotes,
          review_grade: reviewGrade,
          review_passed: clearFlag,
        },
      },
    })

    if (!res) return

    setUnsavedReviewIndex(null)
    setShowSubmit(false)

    return res
  }, [
    caseId,
    clearFlag,
    createCaseFlagReview,
    flag.flag_id,
    onClickClearFlag,
    reviewGrade,
    reviewNotes,
    unsavedReviewIndex,
  ])

  return (
    <Card id={`${id}Card`} padding={theme.spacing(4)} border={false}>
      <Field name={name}>
        {({ input }) => {
          const flagType = input.value.flag_type as CaseDetailsFlagFlagType

          const flagTypeMessageId = flagToMessage(flagType)

          const flagName = flagTypeMessageId
            ? formatMessage(flagTypeMessageId.toLowerCase())
            : CaseDetailsFlagFlagType.InvalidFlagType

          const hasApprovePermission = havePermissionForFlag(
            flagType,
            hasEditAMLCaseFlagsPermission,
            hasEditAdviceCaseFlagsPermission,
          )

          const canMarkNoFurtherReview = canMarkNoFurtherReviewFlags.includes(flagType)

          const isGradedReview = [
            CaseDetailsFlagFlagType.FlagManualReview,
            CaseDetailsFlagFlagType.FlagThirdPartyReview,
            CaseDetailsFlagFlagType.FlagAutomatedSample,
          ].includes(flagType)

          const reviewsWithGrade: CaseDetailsFlagReview[] = [
            ...input.value.reviews.filter((review: CaseDetailsFlagReview) => !isEmpty(review.review_grade)),
          ]

          const sortedReviews = reviewsWithGrade.sort((a, b) => {
            const dateA = a.created_at ? new Date(a.created_at).getTime() : 0
            const dateB = b.created_at ? new Date(b.created_at).getTime() : 0
            return dateB - dateA
          })

          const latestReview = sortedReviews[0]

          return (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h2" mr={2} fontSize={theme.typography.pxToRem(26)} fontWeight={700}>
                    <FormattedMessage id={`${messagePrefix}.heading`} />
                  </Typography>
                  <Tag text={flagName} colourID={isGradedReview ? ColourId.Yellow : ColourId.LightRed} />
                </Box>
                {isGradedReview && latestReview?.review_grade && (
                  <Box mr={2} data-testid={testHandle(`${id}SummaryGrade`)}>
                    <Typography variant="h2" fontSize={theme.typography.pxToRem(26)} fontWeight={700}>
                      <FormattedMessage
                        id={`${messagePrefix}.grade`}
                        values={{ value: latestReview.review_grade?.replace('GRADE_', '') }}
                      />
                    </Typography>
                  </Box>
                )}
              </Box>

              <UnsetSectionDivider margin="size32" />

              <CaseFlagNoteFields
                fieldName={name}
                showSubmit={showSubmit}
                hasApprovePermission={hasApprovePermission}
                displayGrade={isGradedReview}
              />

              <NoteFooter
                id={id}
                left={
                  <Box mt={1}>
                    {showSubmit ? (
                      <>
                        <Box display="flex" mb={3}>
                          <Checkbox
                            id={`${id}ClearFlagCheckbox`}
                            label={formatMessage(`${messagePrefix}.allowCaseToProceed`)}
                            checked={clearFlag}
                            disabled={!hasApprovePermission}
                            onChange={() => {
                              setClearFlag(!clearFlag)
                            }}
                          />
                          {clearFlag && canMarkNoFurtherReview && (
                            <Box ml={2}>
                              <Field
                                name={`${name}.reviews[${unsavedReviewIndex! - 1}].skip_future_reviews`}
                                type="checkbox"
                              >
                                {({ input }) => (
                                  <Checkbox
                                    id={`${id}NoFurtherReviewCheckbox`}
                                    label={formatMessage(`${messagePrefix}.noFurtherReviewRequired`)}
                                    disabled={!hasApprovePermission}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </Box>
                          )}
                        </Box>
                        <Button
                          data-testid={testHandle(`${id}SaveReview`)}
                          id={`${id}SaveReview`}
                          type={BUTTON_TYPE_SUBMIT}
                          buttonStyle={BUTTON_SECONDARY}
                          onClick={handleSendReviewClick}
                          disabled={!(haveAddCaseNotePermission || hasApprovePermission) || disableSubmitUnclearFlag}
                          isLoading={createCaseFlagReviewLoading}
                        >
                          <FormattedMessage id={`${messagePrefix}.saveReview`} />
                        </Button>
                      </>
                    ) : (
                      <Button
                        data-testid={testHandle(`${id}WriteAnotherReview`)}
                        id={`${id}WriteAnotherReview`}
                        type={BUTTON_TYPE_BUTTON}
                        buttonStyle={BUTTON_SECONDARY}
                        onClick={handleAddClick}
                        disabled={
                          !havePermissionForFlag(
                            flagType,
                            hasEditAMLCaseFlagsPermission,
                            hasEditAdviceCaseFlagsPermission,
                          )
                        }
                      >
                        + <FormattedMessage id={`${messagePrefix}.writeAnotherReview`} />
                      </Button>
                    )}
                  </Box>
                }
              />
            </>
          )
        }}
      </Field>
    </Card>
  )
}

export default CaseFlagNote
