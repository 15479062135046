import React, { useMemo } from 'react'
import { Box, Stack, Table, TableBody, TableCell, TableContainer } from '@mui/material'
import { get, isEmpty, isNil, uniq } from 'lodash'

import { testHandle, useFormatMessage } from '@acre/utils'
import { H2, HeadingTagName } from '@acre/design-system'

import { getPaddingForTitleSize } from './DataComponent.helpers.tsx'
import { DataComponentSchema } from './DataComponent.types'
import DataComponentArray from './DataComponentArray'
import { StyledTableRow } from '../../ReviewMode.styles'

type Props = {
  schema: DataComponentSchema
  data: unknown
  messagePrefix: string
  titlePrefix?: string
  titleStyledAs?: HeadingTagName
}

const DataComponent = ({ schema, data, messagePrefix, titlePrefix, titleStyledAs = 'h6' }: Props) => {
  const formatMessage = useFormatMessage()

  const sectionTags = useMemo(() => uniq(schema.children.map((field) => field.sectionTag)), [schema.children])
  const titleBottomPadding = useMemo(() => getPaddingForTitleSize(titleStyledAs), [titleStyledAs])

  return sectionTags.map((tag) => {
    const sectionFields = schema.children.filter((field) => field.sectionTag === tag)

    const rows = sectionFields?.map((field) => {
      const fieldValue = get(data, field.keyPath)

      if (field.kind === 'array' && !isEmpty(fieldValue)) {
        return (
          <DataComponentArray
            key={field.keyPath}
            field={field}
            array={fieldValue}
            messagePrefix={messagePrefix}
            titlePrefix={titlePrefix}
          />
        )
      }

      if (field.kind === 'value' && field.hideNull === true && isNil(fieldValue)) {
        return
      } else if (field.kind === 'value') {
        return (
          <StyledTableRow key={field.keyPath}>
            <TableCell
              component="th"
              scope="row"
              width="60%"
              sx={{ verticalAlign: 'top' }}
              data-testid={testHandle(`${field.keyPath}Label`)}
            >
              {formatMessage(field.messageId)}
            </TableCell>
            <TableCell align="right" data-testid={testHandle(`${field.keyPath}Value`)}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {field.Formatter && field.Formatter(fieldValue)}
              </Box>
            </TableCell>
          </StyledTableRow>
        )
      }
    })

    const renderArrayTables = sectionFields.some((field) => field.kind === 'array')
    const filteredRows = rows.filter((row) => row)

    if (isEmpty(filteredRows)) return

    return renderArrayTables ? (
      <Stack spacing={4} key={tag}>
        {rows}
      </Stack>
    ) : (
      <TableContainer key={tag}>
        <Box px={1} pb={titleBottomPadding}>
          <H2 styledAs={titleStyledAs}>{formatMessage(`${messagePrefix}.${tag}`)}</H2>
        </Box>
        <Table size="small">
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    )
  })
}

export default React.memo(DataComponent)
