/* eslint no-console: 0 */
import { add, differenceInMonths, format } from 'date-fns'
import isEmpty from 'lodash/isEmpty'

import envConfig from '@acre/config'

import { ClientAddress, ErcPeriodInput, Maybe, MortgageStatus, PropertyVersion, TermUnit } from '../generated/resolvers'
import { Integer } from '../types'

export type ClientPayloadAddress = Array<ClientAddress>

export enum ExceptionType {
  InvalidType = 'INVALID_TYPE',
  Business = 'BUSINESS',
  ServiceNotAvailable = 'SERVICE_NOT_AVAILABLE',
  Infrastructure = 'INFRASTRUCTURE',
  Unexpected = 'UNEXPECTED',
  SecurityViolation = 'SECURITY_VIOLATION',
}

type Exception = {
  id: string
  type: ExceptionType
  timestamp: string
  description: string
  statusCode?: number
}

export class LutherException extends Error {
  id: string
  type: ExceptionType
  description: string
  statusCode: number
  url: string

  constructor(message: string, status: number, url: string, exception: Exception) {
    super(exception.description)
    this.name = 'LutherException'
    this.statusCode = status

    this.url = url
    this.id = exception.id
    this.type = exception.type
    this.description = exception.description
    this.message = exception.description
  }
}

export class GraphqlException extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, Error.prototype)
    this.name = 'GraphqlException'
  }
}

// @TODO FRON-554 move tokens to a config setup
export const ADDRESS_LOOKUP_API_TOKEN = envConfig.ADDRESS_LOOKUP_API_TOKEN
export const ADDRESS_LOOKUP_API_URL = envConfig.ADDRESS_LOOKUP_API_URL

export const withoutFields = (input: { [key: string]: any }, fields: Array<string>) => {
  return {
    ...Object.keys(input).reduce((obj, field) => {
      if (!fields.includes(field)) return { ...obj, [field]: input[field] }
      return obj
    }, {}),
  }
}

export const getPropertyValue = (
  property: PropertyVersion | null,
  status: MortgageStatus,
  preferenceTargetPropertyVal: string,
): Integer => {
  if (
    (status === MortgageStatus.StatusCurrent ||
      status === MortgageStatus.StatusLenderProposed ||
      status === MortgageStatus.StatusProposed) &&
    property
  ) {
    return property.details.valuation ? parseInt(property.details?.valuation) : parseInt(preferenceTargetPropertyVal)
  }

  return preferenceTargetPropertyVal ? parseInt(preferenceTargetPropertyVal) : 0
}

export const getEndDate = (startDate?: Maybe<string>, term?: Maybe<Integer>, termUnit?: Maybe<TermUnit>): string => {
  if (!startDate || !term || !termUnit) return ''

  if (termUnit === TermUnit.TermDays) {
    return format(add(new Date(startDate), { days: term }), 'yyyy-MM-dd')
  }

  if (termUnit === TermUnit.TermMonths) {
    return format(add(new Date(startDate), { months: term }), 'yyyy-MM-dd')
  }

  if (termUnit === TermUnit.TermYears) {
    return format(add(new Date(startDate), { years: term }), 'yyyy-MM-dd')
  }

  return ''
}

export const getProposedStartDate = (status: MortgageStatus) => {
  if ([MortgageStatus.StatusLenderProposed, MortgageStatus.StatusProposed].includes(status)) {
    // if status is proposed/lender proposed return start date of two months from today
    return format(add(new Date(), { days: 60 }), 'yyyy-MM-dd')
  }
}

export const getTermDateForRemortgage = (
  ercList: ErcPeriodInput[],
  term: number,
  initialRateEndDate: string,
): number => {
  if (isEmpty(ercList) && isEmpty(initialRateEndDate)) {
    return term
  }

  if (ercList) {
    // get a list of all the dates in the erc array
    const ercEndDateList: string[] = []
    if (!isEmpty(initialRateEndDate)) {
      ercEndDateList.push(initialRateEndDate)
    }

    ercList.forEach((period) => {
      if (period.period_fixed_end_date) {
        ercEndDateList.push(period.period_fixed_end_date)
      }
    })

    if (ercEndDateList.length > 0 && term !== 0) {
      //get the nearest date from the list
      const nearestDate = ercEndDateList.reduce((a, b) => {
        return new Date(a) > new Date(b) ? b : a
      })

      // As any parsed end dates will be set to midnight, we need to make sure we don't lose a day.
      let today = new Date().setHours(0, 0, 0, 0)
      // calculate differnece between the nearest day and now
      // subtract those, if within 6 months subtract the diff to the term
      const diffInMonths = Math.round(differenceInMonths(new Date(nearestDate), today))
      if (diffInMonths < 6 && diffInMonths >= 0) {
        return term - diffInMonths
      }
      ///if not within 6 months return term - a month
      return term - 1
    }
  }
  return term
}
