import { useContext, useMemo } from 'react'
import { isGateThreePointSevenExceptionCollection } from '@broker-crm-utils'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Typography } from '@mui/material'
import { format, isAfter, isBefore, parseISO, subMonths } from 'date-fns'
import { FormattedMessage, IntlShape } from 'react-intl'
import { NavigateFunction } from 'react-router-dom'

import {
  doTheseVerificationsNotFail,
  isCaseInReview,
  isGeneralProtection,
  isHomeInsurance,
  UserContext,
} from '@acre/utils'
import {
  Case,
  CaseStatus,
  ClientVersion,
  CreateGeneralInsuranceProduct,
  Deposit,
  formatAsPercentage,
  GeneralInsuranceProvider,
  GeneralInsuranceQuoteStage,
  getClientName,
  GetClientsQuery,
  isNonNaturalClient,
  Maybe,
  Mortgage,
  MortgageReason,
  MortgageStatus,
  NoteType,
  ProviderId,
  UpdateCaseStatusRequestUpdateType,
  useGeneralInsuranceProductsQuery,
  useGetGeneralInsuranceQuoteQuery,
  User,
  useUpdateGeneralInsuranceProductMutation,
  Verification,
  VerificationCollection,
} from '@acre/graphql'

import {
  eidvHashMatchVerificationId,
  eidvNeedsToBeRunVerificationId,
  eidvRunIsTooOldVerificationId,
  verificationEidvProofAddressExists,
  verificationEidvProofAddressVerified,
  verificationEidvProofIdExists,
  verificationEidvProofIdVerified,
  verificationValidEidvCheckStatus,
} from '../../../../../sections/FactFind/FactFindIdVerification/FactFindIdVerificationCard.helpers'
import {
  hasCredentialsForGIProvider,
  isSourcedGIProduct,
  postRecommendationGIProductStages,
} from '../../../../GeneralInsurance/GeneralInsurance.helpers'
import {
  collectionTemplates,
  exceptionTaskAndCollectionTemplates,
  Gate,
  getVerificationTemplates,
} from './CaseVerifications.fixtures'
import {
  Action,
  CollectionKey,
  DecoratedCollection,
  DecoratedVerification,
  ExceptionTaskOrCollectionKey,
  VerificationKey,
} from './CaseVerifications.types'

export const VALID_VARIABLE_NAMES = ['client_id', 'case_id', 'property_id']

//
// Decorate collections and verifications with additional functionality
// such as translatable message keys, and on-click actions
//

export const getCaseSelectedMortgage = (caseDetails: Case) => {
  const { mortgages } = caseDetails
  const selectedMortgage = (mortgages || []).find(({ status }) => status === MortgageStatus.StatusSelected)
  return selectedMortgage
}

export const decorateCollection = (
  collection: VerificationCollection,
  status?: Maybe<CaseStatus>,
  currentMortgageId?: Maybe<String>,
  lenderProposedMortgageId?: Maybe<String>,
): DecoratedCollection => {
  const { passed, name, collection_id, verifications } = collection
  if (isGateThreePointSevenExceptionCollection(!!passed, name, status)) {
    return {
      ...exceptionTaskAndCollectionTemplates[collection_id as ExceptionTaskOrCollectionKey],
      collection,
    }
  }
  if (collection.collection_id === '98b0d7d4-cf0f-4c6e-ac83-336e77dfbe4b') {
    const hasFailedCurrentMortgage = verifications?.find(
      (verification) => verification.mortgage_id === currentMortgageId,
    )

    const hasFailedLenderProposedMortgage = verifications?.find(
      (verification) => verification.mortgage_id === lenderProposedMortgageId,
    )

    if (hasFailedCurrentMortgage) {
      return {
        message: 'Complete mortgage details',
        action: Action.Redirect,
        pathname: '/cases/:case_id/fact-find/properties',
        collection,
      }
    }
    if (hasFailedLenderProposedMortgage) {
      return {
        message: 'Complete mortgage details',
        action: Action.Redirect,
        pathname: '/cases/:case_id/products',
        collection,
      }
    }
  }

  return {
    ...collectionTemplates[collection.collection_id as CollectionKey],
    collection,
  }
}

export const decorateVerification = (
  verification: Verification,
  collection_id?: string,
  currentMortgageId?: Maybe<string>,
  lenderProposedMortgageId?: Maybe<string>,
  clientId?: string,
): DecoratedVerification => {
  const { verification_id } = verification
  let templateKey = verification_id

  if (verification_id === 'd7e04033-2d6a-4de5-8fb8-11bc7442eae7') {
    templateKey = `${templateKey}/protection_referral`
  }

  const decoratedVerifications = getVerificationTemplates()
  const decoratedVerification = decoratedVerifications[templateKey as VerificationKey]

  if (documentRequirementCollectionIds.includes(collection_id as Gate)) {
    return {
      ...decoratedVerification,
      action: Action.Redirect,
      pathname: '/cases/:case_id/documents',
      verification,
    }
  }

  if (collection_id === Gate.GATE_3_5_1) {
    const hasFailedCurrentMortgage = verification.mortgage_id === currentMortgageId
    const hasFailedLenderProposedMortgage = verification.mortgage_id === lenderProposedMortgageId

    if (hasFailedCurrentMortgage) {
      return {
        ...decoratedVerification,
        pathname: '/cases/:case_id/fact-find/properties',
        verification,
      }
    }

    if (hasFailedLenderProposedMortgage) {
      return {
        ...decoratedVerification,
        pathname: '/cases/:case_id/products',
        verification,
      }
    }
  }

  // Verification for `At least one set of bank details must be provided`
  if (verification.verification_id === '4d4fdb5c-bd61-432d-a18e-1dd17d0fd554') {
    const verificationWithClientId = { ...verification, client_id: clientId }
    return { ...decoratedVerification, verification: verificationWithClientId }
  }

  return {
    ...decoratedVerification,
    verification,
  }
}

export const buildCollectionAction = (
  decoratedCollection: DecoratedCollection,
  caseId: string,
  clientId: string,
  navigate: NavigateFunction,
) => {
  const { action, pathname } = decoratedCollection

  if (!action || !pathname) return

  const newPathname = pathname.replace(':case_id', caseId).replace(':client_id', clientId)

  navigate(newPathname)
}

// Currently only handles action === 'redirect'. This may be extended
// in the future, but for simplicity switches/if statements aren't
// included for now
//
// Client verifications don't have a case ID, but we want to redirect
// to their case fact-find, so we provide a fallback case ID not found
// on the verification itself

export const buildAction = (
  decoratedVerification: DecoratedVerification,
  fallbackCaseId: string,
  fallbackClientId: string,
  triggerInitialDisclosure: (clientIds: string[]) => void,
  navigate: NavigateFunction,
) => {
  const { action, pathname, verification } = decoratedVerification
  if (!action) return

  if (action === Action.Redirect && pathname) {
    const variableNames = extractPathnameVariableNames(pathname)
    try {
      const newPathname = replacePathnameVariables(
        pathname,
        verification,
        variableNames,
        fallbackCaseId,
        fallbackClientId,
      )

      navigate(newPathname)
    } catch (err) {
      console.warn(err)
    }
  } else if (action === Action.IDD) {
    return () => triggerInitialDisclosure([verification.client_id!])
  }
}

export const extractPathnameVariableNames = (pathname: string): string[] => {
  const pattern = /:([a-z_]+)/g
  const matches = pathname.match(pattern) || []
  return matches.map((match) => match.substring(1, match.length))
}

export const hasValidVariableNames = (variableNames: string[], validNames: string[]) =>
  variableNames.every((name) => validNames.includes(name))

export const replacePathnameVariables = (
  pathname: string,
  verification: Verification,
  variableNames: string[],
  fallbackCaseId: string,
  fallbackClientId: string,
) => {
  if (!hasValidVariableNames(variableNames, VALID_VARIABLE_NAMES)) {
    throw new Error('Invalid variable names used to build pathname')
  }

  return variableNames.reduce((acc, name) => {
    let value = ''
    if (name === 'case_id') {
      value = verification[name] || fallbackCaseId
    } else if (name === 'client_id') {
      value = verification[name] || fallbackClientId
    } else {
      value = verification[name as keyof Verification] as string
    }
    return acc.replace(`:${name}`, value)
  }, pathname)
}

export const verificationComparator = (item1: VerificationCollection, item2: VerificationCollection) => {
  if (item1.passed && !item2.passed) return 1
  if (!item1.passed && item2.passed) return -1
  return 0
}

export const getDonorsFromDeposits = (deposits: Deposit[]) => {
  return deposits.reduce((acc: ClientVersion[], deposit: Deposit) => {
    return [...acc, ...(deposit.donors || [])]
  }, [])
}

export const MAX_VERIFICATIONS = 3

export const buildReminderInitialValues = (
  user: User,
  intl: IntlShape,
  lenderName: string,
  mortgage?: Mortgage | null,
  mortgageReason?: MortgageReason | null,
  earliestGIProductEndDate?: Date,
) => {
  const formatMessage = (id: string, value?: string) => intl.formatMessage({ id }, { value })

  let deadline, title, body

  if (mortgage && mortgage.mortgage_product) {
    const { initial_rate_end_date, mortgage_product } = mortgage

    // Set the reminder deadline to six months before the initial period end date
    const endDate = initial_rate_end_date
    const endOfInitialPeriod = endDate ? parseISO(endDate) : null
    let sixMonthsBeforeInitialPeriod = endOfInitialPeriod && subMonths(endOfInitialPeriod, 6)
    const now = new Date()
    if (sixMonthsBeforeInitialPeriod && isAfter(now, sixMonthsBeforeInitialPeriod)) {
      deadline = now && format(now, 'yyyy-MM-dd')
    } else {
      deadline = sixMonthsBeforeInitialPeriod && format(sixMonthsBeforeInitialPeriod, 'yyyy-MM-dd')
    }

    // Create a generic body text based on the details of the mortgage product
    const productName = mortgage_product.product_name!
    const initialRate = formatAsPercentage(mortgage_product.initial_pay_rate)
    const svr = formatAsPercentage(mortgage_product.standard_variable_rate)
    const rateEnd = endOfInitialPeriod ? format(endOfInitialPeriod, 'dd/MM/yyyy') : undefined

    title = formatMessage('reminders.remortgageReview')
    body = ''
    body = body.concat(formatMessage('reminders.lenderName', lenderName))
    body = body.concat(formatMessage('reminders.productName', productName))
    body = body.concat(formatMessage('reminders.initialRate', initialRate))
    body = body.concat(formatMessage('reminders.rateEnd', rateEnd))
    body = body.concat(formatMessage('reminders.svr', svr))
  }

  if (mortgageReason === MortgageReason.ReasonProtection) {
    title = formatMessage('reminders.protectionReview')
  }

  if (isHomeInsurance(mortgageReason)) {
    deadline = earliestGIProductEndDate && format(subMonths(earliestGIProductEndDate, 1), 'yyyy-MM-dd')
  }

  return {
    title,
    body,
    deadline,
    assignee_id: user?.id,
    primary_type: NoteType.Review,
  }
}

export const getVerificationClientDisplayName = (
  decoratedVerification: DecoratedVerification,
  clients: ClientVersion[],
  directors: GetClientsQuery['clients'],
  loading: boolean,
) => {
  const { verification } = decoratedVerification
  const { client_id, verification_id } = verification
  const noBankDetailsVerificationId = '4d4fdb5c-bd61-432d-a18e-1dd17d0fd554'

  // Some verifications may be duplicated because they have to be done for
  // both clients on a case. We append a label containing the client's name
  // to distinguish between them.
  let fullName = null
  if (client_id && !loading) {
    // Collect the clients from donors as well as top level clients
    const clientsAndDonors: ClientVersion[] = clients.reduce((clients: ClientVersion[], client: ClientVersion) => {
      const deposits = client.details.deposits
      if (!deposits) return [...clients, client]
      return [...clients, client, ...getDonorsFromDeposits(deposits)]
    }, [])

    const client = clientsAndDonors.find((client: ClientVersion) => client.id === client_id)

    // If natural clients exists get full client name
    // If a non-natural client and directors exist use first director to render the name
    // Hide full name for the no bank details verification
    if (client && noBankDetailsVerificationId !== verification_id) {
      fullName = getClientName({ client: client!.details })
    } else if (isNonNaturalClient(clientsAndDonors[0].details) && directors.length > 0) {
      const director = directors[0] as ClientVersion
      fullName = getClientName({
        client: director!.details,
      })
    }
  }

  return fullName ? `(${fullName})` : ''
}

export const useGeneralInsuranceProducts = (caseId: string) => {
  const user = useContext(UserContext)
  const [updateGiProduct, { loading: updatingProduct }] = useUpdateGeneralInsuranceProductMutation()

  // Get GI Products for the case
  const { data: products, loading: productsLoading } = useGeneralInsuranceProductsQuery({
    variables: { input: { filter_case_ids: [caseId] } },
  })

  // Get the individual products from the query
  const caseProducts = useMemo(() => {
    return products?.generalInsuranceProducts?.general_insurance_products || []
  }, [products])

  // currently a case can only have one sourced GI product
  const localProduct = caseProducts.find((giProduct) => isSourcedGIProduct(giProduct))

  const hasRequiredGICredentials = hasCredentialsForGIProvider(user, localProduct?.details?.provider)

  // Get the quote using the reference
  const { data: quotes, loading: quoteLoading } = useGetGeneralInsuranceQuoteQuery({
    variables: { case_id: caseId, quote_id: localProduct?.details?.quote_reference! },
    skip: !localProduct?.details?.quote_reference || !hasRequiredGICredentials,
  })

  // Is this quote completed now?
  const quoteIsComplete = quotes?.generalInsuranceQuote?.quote?.details?.status === GeneralInsuranceQuoteStage.Current

  const calculateProviderId = (external_system: string | null | undefined) => {
    switch (external_system) {
      case GeneralInsuranceProvider.Paymentshield:
        return ProviderId.SbgProviderIdPaym
      case GeneralInsuranceProvider.Uinsure:
        return ProviderId.SbgProviderIdUins
      default:
        return ProviderId.SbgProviderIdInvalid
    }
  }

  return {
    loading: productsLoading || quoteLoading || updatingProduct,
    markProductsComplete: async () => {
      if (quoteIsComplete && localProduct?.general_insurance_product_id) {
        await updateGiProduct({
          variables: {
            // We can ! here because for `quoteIsComplete` to be true they must exist
            id: localProduct.general_insurance_product_id,
            input: {
              details: {
                status: GeneralInsuranceQuoteStage.Current,
                premium_amount: localProduct!.details!.premium_amount,
                buildings_cover_level: localProduct!.details!.buildings_cover_level,
                contents_cover_level: localProduct!.details!.contents_cover_level,
                provider_id: calculateProviderId(quotes?.generalInsuranceQuote?.quote?.external_system),
              },
            },
          },
        })
      }
    },
  }
}

export const getEarliestGIProductEndDate = (giProductsOnCase: CreateGeneralInsuranceProduct[]) => {
  let earliestEndDate
  for (const giProduct of giProductsOnCase) {
    if (
      !giProduct.details?.end_date ||
      !giProduct.details?.status ||
      !postRecommendationGIProductStages.includes(giProduct.details.status)
    )
      continue

    const giProductEndDate = new Date(giProduct.details.end_date)
    if (!earliestEndDate || isBefore(giProductEndDate, earliestEndDate)) {
      earliestEndDate = giProductEndDate
    }
  }

  return earliestEndDate
}

// Verification codes used to check if the `Submit Case Check` option should be enabled in the tasks
export const caseCheckSubmissionCollectionIds = [Gate.GATE_3_1_2, Gate.GATE_3_8, Gate.GATE_3_1_3, Gate.GATE_4_4]

// Verifications that require passed status for the `Submit Case Check` task to be enabled
export const presalesCheckCollectionIds = [
  Gate.GATE_2_2,
  Gate.GATE_3_1_1,
  Gate.GATE_3_2,
  Gate.GATE_3_3,
  Gate.GATE_3_4,
  Gate.GATE_3_5_1,
  Gate.GATE_3_6,
  Gate.GATE_3_7_1,
]

export const applicationSubmittedCollectionIds = [
  Gate.GATE_2_2,
  Gate.GATE_3_2,
  Gate.GATE_3_3,
  Gate.GATE_3_4,
  Gate.GATE_3_4_1,
  Gate.GATE_3_5_1,
  Gate.GATE_3_5_3,
  Gate.GATE_3_6,
  Gate.GATE_3_6_2,
  Gate.GATE_3_7_1,
  Gate.GATE_3_7_2,
  Gate.GATE_3_8,
  Gate.GATE_4_1,
  Gate.GATE_4_2,
  Gate.GATE_4_4,
]

export const earlyStageCollectionIds = [
  Gate.GATE_2_2,
  Gate.GATE_3_1_1,
  Gate.GATE_3_1_2,
  Gate.GATE_3_2,
  Gate.GATE_3_3,
  Gate.GATE_3_4,
  Gate.GATE_3_4_1,
  Gate.GATE_3_5_1,
  Gate.GATE_3_5_2,
  Gate.GATE_3_5_3,
  Gate.GATE_3_6,
  Gate.GATE_3_6_1,
  Gate.GATE_3_6_2,
]

export const documentRequirementCollectionIds = [
  Gate.GATE_3_1_4,
  Gate.GATE_3_9,
  Gate.GATE_4_6,
  Gate.GATE_5_1,
  Gate.GATE_6_1,
  Gate.GATE_7_1,
  Gate.GATE_8_1,
  Gate.GATE_9,
]

export const doRelevantCollectionsPass = (collections: VerificationCollection[], relevantCollectionIds: string[]) => {
  return collections.every(({ collection_id, passed }) => {
    if (relevantCollectionIds.includes(collection_id)) return passed
    return true
  })
}

export const doApplicationSubmittedCollectionsPass = (collections: VerificationCollection[]) =>
  doRelevantCollectionsPass(collections, applicationSubmittedCollectionIds)

export type validTransitionStatusType =
  | UpdateCaseStatusRequestUpdateType.Recommendation
  | UpdateCaseStatusRequestUpdateType.ApplicationSubmitted
  | UpdateCaseStatusRequestUpdateType.AwaitingValuation
  | UpdateCaseStatusRequestUpdateType.AwaitingOffer
  | UpdateCaseStatusRequestUpdateType.Exchange
  | UpdateCaseStatusRequestUpdateType.Offered
  | UpdateCaseStatusRequestUpdateType.Complete

// Exception collection are collections which do not respect the above rules
// so should be added using some new conditionals
export const getExceptionCollections = (
  collections: VerificationCollection[],
  hasClientAcceptedProtectionAdvice?: Maybe<boolean>,
  caseStatus?: Maybe<CaseStatus>,
  preferenceMortgageReason?: Maybe<MortgageReason>,
  hidePublishSrTask?: boolean,
  hidePublishProtectionSrTask?: boolean,
) => {
  const exceptionCollections = collections.filter(({ passed, name }) => {
    if (
      !hidePublishSrTask &&
      preferenceMortgageReason !== MortgageReason.ReasonBusinessProtection &&
      preferenceMortgageReason !== MortgageReason.ReasonProtection &&
      preferenceMortgageReason !== MortgageReason.ReasonGeneralInsurance &&
      name?.includes('Gate 3.7.1') &&
      passed &&
      caseStatus === CaseStatus.PreRecommendation
    ) {
      return true
    }

    if (!hidePublishProtectionSrTask && hasClientAcceptedProtectionAdvice && name?.includes('Gate 3.7.2') && passed) {
      return true
    }

    return false
  })

  if (exceptionCollections.length > 0) {
    const updatedCollections = exceptionCollections.map((collection) => {
      if (collection.name?.includes('3.7.1')) {
        let updatedCollectionId = 'publishMortgageSuitabilityReport'
        return { ...collection, collection_id: updatedCollectionId }
      }
      if (collection.name?.includes('3.7.2')) {
        let updatedCollectionId = 'publishProtectionSuitabilityReport'
        return { ...collection, collection_id: updatedCollectionId }
      } else {
        return collection
      }
    })

    return updatedCollections
  } else return []
}

// If all docs have been uploaded and verified but validEdivCheckStatusVerification is failing
// that means that the compliance officer needs to manually override the id check
// in order to pass Gate 4.1
export const hasUploadedDocsButRequiresApproval = (collections: VerificationCollection[]) => {
  // Find the verification that checks if the eidv has a valid status
  const validEidvCheckStatusVerification = collections
    .find((collection) => collection.collection_id === Gate.GATE_4_1)
    ?.verifications?.find((verification) => verification.verification_id === verificationValidEidvCheckStatus)

  // Below we are checking that all of the following are true:
  // 1. Eidv has been run
  // 2. All details required for eidv check are present
  // 3. Eidv run is recent
  // 4. All documents required for eidv have been uploaded and verified
  const hasNotFailedNonStatusRelatedEidvVerifications = doTheseVerificationsNotFail({
    verificationIdsToBeChecked: [
      eidvNeedsToBeRunVerificationId,
      eidvHashMatchVerificationId,
      eidvRunIsTooOldVerificationId,
      verificationEidvProofIdExists,
      verificationEidvProofAddressExists,
      verificationEidvProofIdVerified,
      verificationEidvProofAddressVerified,
    ],
    allVerificationCollections: collections,
  })

  // Checking that validEdivCheckStatusVerification exists but did not pass
  // checking that validEdivCheckStatusVerification has not passed is requried to make sure that
  return (
    validEidvCheckStatusVerification != null &&
    !validEidvCheckStatusVerification.passed &&
    hasNotFailedNonStatusRelatedEidvVerifications
  )
}

export const groupCollectionsToOptionalAndNonOptional = (collections: VerificationCollection[]) => {
  return collections.reduce(
    (acc, collection) => {
      const areAllVerificationsOptional = collection.verifications?.every(({ optional }) => optional)

      return {
        optional: areAllVerificationsOptional ? [...acc.optional, collection] : acc.optional,
        nonOptional: areAllVerificationsOptional ? acc.nonOptional : [...acc.nonOptional, collection],
      }
    },
    { optional: [] as VerificationCollection[], nonOptional: [] as VerificationCollection[] },
  )
}

export const doCollectionsPass = (collections: VerificationCollection[], exceptionCollectionGates: Gate[] = []) =>
  collections.every((collection) => {
    if (
      exceptionCollectionGates.includes(collection.collection_id as Gate) ||
      !collection.required_for_next_transition ||
      collection.outstanding_optional
    ) {
      return true
    }
    return Boolean(collection.passed)
  })

export const shouldShowPreSalesCheckTask = (collections: VerificationCollection[], caseStatus: CaseStatus) => {
  if (isCaseInReview(caseStatus)) {
    return false
  }
  // Check that all collections that are not part of caseCheckSubmission collection ids pass
  // and if some of the caseCheckSubmission collections fail then show button
  const everyCollectionPreCaseCheckPassed = doCollectionsPass(collections, caseCheckSubmissionCollectionIds)

  const preSalesCheckCollectionsFail = collections.some(
    (collection) => caseCheckSubmissionCollectionIds.includes(collection.collection_id as Gate) && !collection.passed,
  )

  const everyCollectionPreDocApprovalRequiredPassed = doCollectionsPass(collections, [
    ...caseCheckSubmissionCollectionIds,
    Gate.GATE_4_1,
  ])

  const uploadedDocsButRequiresApproval = hasUploadedDocsButRequiresApproval(collections)

  return (
    (everyCollectionPreCaseCheckPassed && preSalesCheckCollectionsFail) ||
    (everyCollectionPreDocApprovalRequiredPassed && uploadedDocsButRequiresApproval)
  )
}

export const ChevronIcon = <ArrowForwardIosIcon sx={{ height: '16px', width: '16px' }} />

const messagePrefix = 'caseOverview.verifications.transitions'
const protectionMessagePrefix = 'caseOverview.verifications.protectionTransitions'
const giMessagePrefix = 'caseOverview.verifications.giTransitions'

type CaseTransitionMsgMap = { [key in validTransitionStatusType]?: { taskName: string; modalMessage: string } }

const normalCaseTransitionMsgMap: CaseTransitionMsgMap = {
  [UpdateCaseStatusRequestUpdateType.AwaitingValuation]: {
    taskName: `${messagePrefix}.awaitingValuation.taskName`,
    modalMessage: `${messagePrefix}.awaitingValuation.modalMessage`,
  },
  [UpdateCaseStatusRequestUpdateType.AwaitingOffer]: {
    taskName: `${messagePrefix}.awaitingOffer.taskName`,
    modalMessage: `${messagePrefix}.awaitingOffer.modalMessage`,
  },
  [UpdateCaseStatusRequestUpdateType.ApplicationSubmitted]: {
    taskName: `${messagePrefix}.applicationSubmitted.taskName`,
    modalMessage: `${messagePrefix}.applicationSubmitted.modalMessage`,
  },
  [UpdateCaseStatusRequestUpdateType.Offered]: {
    taskName: `${messagePrefix}.offered.taskName`,
    modalMessage: `${messagePrefix}.offered.modalMessage`,
  },
  [UpdateCaseStatusRequestUpdateType.Exchange]: {
    taskName: `${messagePrefix}.exchange.taskName`,
    modalMessage: `${messagePrefix}.exchange.modalMessage`,
  },
  [UpdateCaseStatusRequestUpdateType.Complete]: {
    taskName: `${messagePrefix}.complete.taskName`,
    modalMessage: `${messagePrefix}.complete.modalMessage`,
  },
}

const protectionCaseTransitionMsgMap: CaseTransitionMsgMap = {
  [UpdateCaseStatusRequestUpdateType.ApplicationSubmitted]: {
    taskName: `${protectionMessagePrefix}.applicationSubmitted.taskName`,
    modalMessage: `${protectionMessagePrefix}.applicationSubmitted.modalMessage`,
  },
}

const giCaseTransitionMsgMap: CaseTransitionMsgMap = {
  [UpdateCaseStatusRequestUpdateType.Recommendation]: {
    taskName: `${giMessagePrefix}.recommendation.taskName`,
    modalMessage: `${giMessagePrefix}.recommendation.modalMessage`,
  },
  [UpdateCaseStatusRequestUpdateType.ApplicationSubmitted]: {
    taskName: `${giMessagePrefix}.applicationSubmitted.taskName`,
    modalMessage: `${giMessagePrefix}.applicationSubmitted.modalMessage`,
  },
  [UpdateCaseStatusRequestUpdateType.Complete]: {
    taskName: `${giMessagePrefix}.complete.taskName`,
    modalMessage: `${giMessagePrefix}.complete.modalMessage`,
  },
}

export const getTaskTextByStatus = (
  status: validTransitionStatusType,
  preferenceMortgageReason: Maybe<MortgageReason>,
) => {
  const isProtectionCase = isGeneralProtection(preferenceMortgageReason)
  const isHomeInsuranceCase = isHomeInsurance(preferenceMortgageReason)

  let caseTransitionMsgMap = normalCaseTransitionMsgMap
  if (isProtectionCase) caseTransitionMsgMap = protectionCaseTransitionMsgMap
  if (isHomeInsuranceCase) caseTransitionMsgMap = giCaseTransitionMsgMap

  const unknownTransition = {
    taskName: `${messagePrefix}.unknownTransition.taskName`,
    modalMessage: `${messagePrefix}.unknownTransition.modalMessage`,
  }

  return caseTransitionMsgMap[status] || unknownTransition
}

export const addOptionalLabel = (optional: boolean, string: string, reviewMode?: boolean) => {
  return optional ? (
    <Typography fontSize={reviewMode ? 12 : 14} component="span">
      <strong>
        <FormattedMessage id="generic.optionalLabel" />
      </strong>{' '}
      {string}
    </Typography>
  ) : (
    string
  )
}
