import { useCallback, useRef } from 'react'
import { FetchResult } from '@apollo/client'
import { Box } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FormattedMessage } from 'react-intl'

import { testHandle, useOpen } from '@acre/utils'
import { CaseDetailsFlag, CaseVersion, GetCaseDocument, useCreateCaseFlagReviewMutation } from '@acre/graphql'
import { ConfirmationModal } from '@acre/design-system'

import { CaseFlagNote } from './CaseFlagNote'

type Props = {
  caseData: CaseVersion
}

type ConfirmationModalSaveChangesProps = {
  id: string
  isVisible: boolean
  loading: boolean
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmationModalSaveChanges = ({
  id,
  isVisible,
  loading,
  onCancel,
  onConfirm,
}: ConfirmationModalSaveChangesProps) => (
  <ConfirmationModal
    id={id}
    isVisible={isVisible}
    handleClose={onCancel}
    footer={{
      primaryButton: {
        isLoading: loading,
        onClick: onConfirm,
        id: 'ConfirmCaseFlagNoteConfirmationModal',
        text: 'cases.flags.note.confirmation.yesClearFlag',
      },
      secondaryButton: {
        onClick: onCancel,
        isDisabled: false,
        id: 'CancelCaseFlagNoteConfirmationModal',
      },
    }}
  >
    <FormattedMessage id="cases.flags.note.confirmation.areYouSureMessage" />
  </ConfirmationModal>
)

const CaseFlagNotes = ({ caseData }: Props) => {
  const flagToClear = useRef<CaseDetailsFlag | null>(null)
  const sendLatestReview = useRef<(() => Promise<FetchResult<any> | undefined>) | null>(null)
  const { isVisible: isModalVisible, open: openModal, close: closeModal } = useOpen(false)

  const [createCaseFlagReview, { loading: createCaseFlagReviewLoading }] = useCreateCaseFlagReviewMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetCaseDocument, variables: { id: caseData.id } }],
  })

  const flags = caseData.details.flags

  const handleClear = useCallback(() => {
    closeModal()

    // only reset the flag to clear in case the user tries to
    // clear this flag again without sending review to advisor
    flagToClear.current = null
  }, [closeModal])

  const handleConfirm = useCallback(async () => {
    if (!flagToClear.current) return

    // should save the notes when clearing a flag
    if (sendLatestReview.current) {
      const latestReview = await sendLatestReview.current()

      if (latestReview) {
        sendLatestReview.current = null
      }
    }

    const currentReview = flagToClear.current.reviews?.slice(-1)?.[0]

    // pass the notes which are added as the last element in the array of reviews, if empty pass empty string
    const updatedCaseWithClearedFlag = await createCaseFlagReview({
      variables: {
        input: {
          case_id: caseData.id,
          flag_id: flagToClear.current.flag_id,
          reviewed_case_version: 0,
          review_passed: true,
          review_grade: currentReview?.review_grade,
          reviewer_notes: currentReview?.reviewer_notes || '',
          skip_future_reviews: currentReview?.skip_future_reviews || false,
        },
      },
    })

    if (updatedCaseWithClearedFlag) {
      closeModal()
      flagToClear.current = null
    }
  }, [caseData.id, closeModal, createCaseFlagReview])

  return (
    <>
      <Form
        // this form is for all flag components, we need to submit for just one
        onSubmit={() => {}}
        mutators={{ ...arrayMutators }}
        initialValues={{
          flags,
        }}
      >
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="flags">
              {({ fields }) =>
                fields.map((name, index) => {
                  const flag = fields.value[index]
                  const key = fields.value[index].flag_id
                  return (
                    <Box key={key} mb={4}>
                      <CaseFlagNote
                        data-testid={testHandle(`${index}CaseFlagNote`)}
                        id={`${index}CaseFlagNote`}
                        name={name}
                        flag={flag}
                        onClickClearFlag={(sendLatestUnsavedReviewNote) => {
                          flagToClear.current = flag
                          sendLatestReview.current = sendLatestUnsavedReviewNote
                          openModal()
                        }}
                      />
                    </Box>
                  )
                })
              }
            </FieldArray>
            <ConfirmationModalSaveChanges
              id="ConfirmationModalSaveChanges"
              isVisible={isModalVisible}
              onConfirm={handleConfirm}
              onCancel={() => {
                handleClear()
                form.reset()
              }}
              loading={createCaseFlagReviewLoading}
            />
          </form>
        )}
      </Form>
    </>
  )
}

export default CaseFlagNotes
