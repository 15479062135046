import { get, isEmpty, omit, pick } from 'lodash'
import * as yup from 'yup'

import {
  ERROR_AFTER_LABEL,
  ERROR_BALANCE_DATE_AFTER_START,
  ERROR_DATE_GENERIC,
  ERROR_FUTURE,
  ERROR_GREATER_THAN_0_ON_PART_AND_PART_MORTGAGE,
  ERROR_GREATER_THAN_ZERO,
  ERROR_INPUT_MUST_BE_NUMBER,
  ERROR_INPUT_MUST_BE_POSITIVE_NUMBER,
  ERROR_INTEREST_RATE_TOO_HIGH_FOR_MORTGAGE,
  ERROR_LESS_THAN,
  ERROR_MUST_BE_IN_FUTURE,
  ERROR_NON_NEGATIVE,
  ERROR_PERCENTAGE,
  ERROR_POSITIVE,
  ERROR_REQUIRED,
  isInTheFuture,
  isNil,
  isObjectEmpty,
  isValidDate,
  unwrapErrors,
  validateIsBefore,
} from '@acre/utils'
import {
  CanBeAddedToLoan,
  CreateMortgageInput,
  Maybe,
  Mortgage,
  MortgageClubFee,
  MortgageClubFeeInput,
  MortgageProduct,
  MortgageProductActualInput,
  MortgageProductFee,
  MortgageProductFeeType,
  MortgageStatus,
  omitTypename,
  RateTiersType,
  TermUnit,
} from '@acre/graphql'

import { RepaymentTypes } from '../../forms/common/types'

export type CreateMortgageRequestBody = Pick<CreateMortgageInput, 'mortgageInput' | 'mortgageProductInput'>

export type MortgageState = Pick<
  Mortgage,
  | 'lender_proposed'
  | 'raw_results_reference'
  | 'early_repayment_charge_amount'
  | 'interest_only_amount'
  | 'monthly_payment'
  | 'mortgage_amount'
  | 'outstanding_balance'
  | 'repayment_amount'
  | 'revert_monthly_payment'
  | 'status'
  | 'term_unit'
  | 'term'
  | 'mortgage_start_date'
  | 'dip_amount'
  | 'dip_status'
  | 'calculated_values'
  | 'fees_to_be_added'
  | 'assumes_continuing'
  | 'has_arrears'
  | 'arrears_summary'
  | 'mortgage_account_number'
  | 'provider_application_reference'
  | 'valuation_type'
  | 'initial_rate_end_date'
  | 'selected_mortgage_club_code'
> &
  Pick<
    MortgageProduct,
    | 'product_type'
    | 'product_code'
    | 'arrangement_fee'
    | 'booking_fee'
    | 'chaps_fee'
    | 'fees'
    | 'cashback'
    | 'cashback_paid_on'
    | 'deeds_release_fee'
    | 'disbursement_fee'
    | 'early_repayment_charge_applies_until'
    | 'early_repayment_charge_applies'
    | 'early_repayment_charge_periods'
    | 'fees_total'
    | 'initial_pay_rate'
    | 'initial_rate_period_months'
    | 'initial_rate_period'
    | 'is_porting'
    | 'lender_name'
    | 'lender_reference'
    | 'mortgage_class'
    | 'mortgage_discharge_fee'
    | 'product_name'
    | 'rate_tiers'
    | 'standard_variable_rate'
    | 'valuation_fee'
    | 'proc_fee_fixed'
    | 'proc_fee_percentage'
    | 'max_ltv_available'
    | 'true_cost_over_initial_period'
  > & {
    first_or_second_charge?: Maybe<boolean>
    early_repayment_charge?: Maybe<number>
    property_secured_ids?: string[] | null
    mortgage_product_id?: string | null
    mortgage_id?: string | null
    repayment_type?: Maybe<RepaymentTypes>
    mortgage_club_name?: string | null
    invalidFieldNet?: string | null
    invalidFieldGross?: string | null
    outstanding_balance_date?: string | null
  }

export const ercFieldsSchema = yup.object().shape({
  erc_charge: yup
    .number()
    .notRequired()
    .nullable()
    .typeError(ERROR_INPUT_MUST_BE_NUMBER)
    .positive(ERROR_POSITIVE)
    // checking for 100% on ui, but due to formatting for api need to pass 10000000
    .max(10000000, ERROR_PERCENTAGE),
  period_fixed_end_date: yup
    .string()
    .nullable()
    .notRequired()
    .test('isValid', ERROR_DATE_GENERIC, (value) => !value || isValidDate(value)),
})

export const existingMortgageProductSchema = yup.object().shape({
  selected_mortgage_club_code: yup.string().nullable(),
  net_proc_fee_fixed: yup
    .string()
    .nullable()
    .test('isRequired', ERROR_REQUIRED, function (this, value) {
      const selected_mortgage_club_code: string = this.resolve(yup.ref('selected_mortgage_club_code'))
      const net_proc_fee_percentage: string = this.resolve(yup.ref('net_proc_fee_percentage'))

      if (selected_mortgage_club_code && isNil(value) && isNil(net_proc_fee_percentage)) {
        return false
      }
      return true
    }),
  net_proc_fee_percentage: yup
    .string()
    .nullable()
    .test('isRequired', ERROR_REQUIRED, function (this, value) {
      const selected_mortgage_club_code: string = this.resolve(yup.ref('selected_mortgage_club_code'))
      const net_proc_fee_fixed: string = this.resolve(yup.ref('net_proc_fee_fixed'))

      if (selected_mortgage_club_code && isNil(value) && isNil(net_proc_fee_fixed)) {
        return false
      }
      return true
    }),
  proc_fee_fixed: yup
    .number()
    .nullable()
    .test('isRequired', ERROR_REQUIRED, function (this, value) {
      const selected_mortgage_club_code: string = this.resolve(yup.ref('selected_mortgage_club_code'))
      const proc_fee_percentage: string = this.resolve(yup.ref('proc_fee_percentage'))

      if (selected_mortgage_club_code && isNil(value) && isNil(proc_fee_percentage)) {
        return false
      }
      return true
    }),
  proc_fee_percentage: yup
    .number()
    .nullable()
    .test('isRequired', ERROR_REQUIRED, function (this, value) {
      const selected_mortgage_club_code: string = this.resolve(yup.ref('selected_mortgage_club_code'))
      const proc_fee_fixed: string = this.resolve(yup.ref('proc_fee_fixed'))

      if (selected_mortgage_club_code && isNil(value) && isNil(proc_fee_fixed)) {
        return false
      }
      return true
    }),
  max_ltv_available: yup
    .number()
    .typeError(ERROR_INPUT_MUST_BE_NUMBER)
    .min(0, ERROR_NON_NEGATIVE)
    .nullable()
    .test('isRequired', ERROR_REQUIRED, function (this: yup.TestContext) {
      const prefix = (value: string) => `${this.path.split('rate_tiers')[0]}${value}`
      const isLenderProposed = get(this.options.context, prefix('lender_proposed'))
      if (isLenderProposed) {
        return false
      }
      return true
    }),
  fees: yup
    .array<MortgageProductFee>()
    .of(
      yup.object<MortgageProductFee>().shape({
        amount: yup.string().nullable().required(ERROR_REQUIRED),
        timing: yup.string().required(ERROR_REQUIRED),
        fee_type: yup
          .string()
          .required(ERROR_REQUIRED)
          .test('isValid', ERROR_REQUIRED, (value) => value !== MortgageProductFeeType.InvalidFeeType),
        can_be_added_to_loan: yup
          .string()
          .required(ERROR_REQUIRED)
          .test('isValid', ERROR_REQUIRED, (value) => value !== CanBeAddedToLoan.InvalidCanBeAddedToLoan),
      }),
    )
    .nullable(),
})

export const mortgageProductSchema = yup.object().shape({
  lender_name: yup.string().nullable(),
  lender_reference: yup.string().nullable(),
  mortgage_class: yup.string().nullable(),
  product_name: yup.string().nullable(),
  initial_pay_rate: yup
    .number()
    .nullable()
    .typeError(ERROR_INPUT_MUST_BE_NUMBER)
    .min(0.01, ERROR_INPUT_MUST_BE_POSITIVE_NUMBER)
    .max(4000000, ERROR_INTEREST_RATE_TOO_HIGH_FOR_MORTGAGE),
  rate_tiers: yup.array<RateTiersType>().of(
    yup.object<RateTiersType>().shape({
      rate: yup.number().nullable().max(10000000, ERROR_PERCENTAGE).min(0, ERROR_NON_NEGATIVE),
      rate_type: yup.string().nullable(),
      period_fixed_end_date: yup
        .string()
        .nullable()
        .test('isAfterPreviousRateEndDate', ERROR_AFTER_LABEL, function (this: yup.TestContext, period_fixed_end_date) {
          const prefix = (value: string) => `${this.path.split('rate_tiers')[0]}${value}`
          const values = this.options.context
          // previous rate end dates must be before subsequent rate end dates
          const previousRateTiersArrayIndex = Number(this.path.split('rate_tiers')[1].replace(/[^0-9]/g, '')) - 1
          if (previousRateTiersArrayIndex < 0) return true
          const rateTiers = get(values, prefix('rate_tiers'))
          const previousEndDate = rateTiers?.[previousRateTiersArrayIndex]?.period_fixed_end_date
          return period_fixed_end_date ? validateIsBefore(previousEndDate, period_fixed_end_date) : true
        })
        .test('isValid', ERROR_DATE_GENERIC, (value) => !value || isValidDate(value))
        .test('isInTheFuture', ERROR_MUST_BE_IN_FUTURE, function (this: yup.TestContext, period_fixed_end_date) {
          const prefix = (value: string) => `${this.path.split('rate_tiers')[0]}${value}`
          const isLenderProposed = get(this.options.context, prefix('lender_proposed'))
          if (isLenderProposed) {
            return !period_fixed_end_date || isInTheFuture(period_fixed_end_date) || period_fixed_end_date === ''
          }
          return true
        })
        .test('isLast', ERROR_REQUIRED, function (this: yup.TestContext, period_fixed_end_date) {
          const prefix = (value: string) => `${this.path.split('rate_tiers')[0]}${value}`
          const values = this.options.context
          const rateTiers = get(values, prefix('rate_tiers'))
          const doesRateTiersExist = isObjectEmpty(rateTiers[0])
          const rateTiersArrayIndex = Number(this.path.split('rate_tiers')[1].replace(/[^0-9]/g, ''))
          const isEndOfTermUnchecked = rateTiers?.[rateTiersArrayIndex]?.until_end_of_term
          if (!doesRateTiersExist && !isEndOfTermUnchecked && !period_fixed_end_date) {
            return false
          }
          return true
        }),
    }),
  ),
  proc_fee_percentage: yup.number().min(0, ERROR_NON_NEGATIVE).nullable(),
  proc_fee_fixed: yup.number().typeError(ERROR_INPUT_MUST_BE_NUMBER).min(0, ERROR_NON_NEGATIVE).nullable(),
  is_porting: yup.boolean().nullable(),
  early_repayment_charge_applies: yup.boolean().nullable(),
  early_repayment_charge_periods: yup.array().of(ercFieldsSchema).notRequired(),
  max_ltv_available: yup.number().typeError(ERROR_INPUT_MUST_BE_NUMBER).min(0, ERROR_NON_NEGATIVE).nullable(),
})

function hasEnteredSomeProcFees(yupContext: yup.TestContext) {
  const proc_fee_fixed: string = yupContext.resolve(yup.ref('proc_fee_fixed'))
  const proc_fee_percentage: string = yupContext.resolve(yup.ref('proc_fee_percentage'))
  const net_proc_fee_fixed: string = yupContext.resolve(yup.ref('net_proc_fee_fixed'))
  const net_proc_fee_percentage: string = yupContext.resolve(yup.ref('net_proc_fee_percentage'))

  return proc_fee_fixed || proc_fee_percentage || net_proc_fee_fixed || net_proc_fee_percentage
}

// mortgage_amount, outstanding_balance, interest_only_amount validations are tested in MortgageRepaymentFields.spec.tsx
export const mortgageSchema = yup.object().shape(
  {
    monthly_payment: yup.number().typeError(ERROR_INPUT_MUST_BE_NUMBER).min(0, ERROR_NON_NEGATIVE).nullable(),
    outstanding_balance: yup
      .number()
      .typeError(ERROR_INPUT_MUST_BE_NUMBER)
      .min(0, ERROR_NON_NEGATIVE)
      .nullable()
      .test('isRequired', ERROR_REQUIRED, function (this, value) {
        const status: string = this.resolve(yup.ref('status'))

        // Make sure to only run validation if mortgage status is current
        if (status === MortgageStatus.StatusCurrent) {
          const hasProcFees = hasEnteredSomeProcFees(this)
          if (hasProcFees && !value) {
            return false
          }

          const interest_only_amount: string = this.resolve(yup.ref('interest_only_amount'))
          const repayment_amount: string = this.resolve(yup.ref('repayment_amount'))
          // if either interest only amount or repayment amount are present and
          // outstanding balance is not entered than make outstanding balance a required field
          if ((!isNil(interest_only_amount) || !isNil(repayment_amount)) && isNil(value)) {
            return false
          }
        }
        return true
      }),
    outstanding_balance_date: yup
      .string()
      .typeError(ERROR_DATE_GENERIC)
      .nullable()
      .notRequired()
      .test('isValid', ERROR_DATE_GENERIC, (value) => !value || isValidDate(value))
      .test('isInTheFuture', ERROR_FUTURE, function (this, value) {
        const status: string = this.resolve(yup.ref('status'))
        if (status === MortgageStatus.StatusCurrent && value && isInTheFuture(value)) {
          return false
        }
        return true
      })
      .when('mortgage_start_date', {
        is: (mortgage_start_date: string) => isValidDate(mortgage_start_date),
        then: (schema) =>
          schema
            .typeError(ERROR_DATE_GENERIC)
            .test('Compare dates', ERROR_BALANCE_DATE_AFTER_START, function (this, outstanding_balance_date) {
              const mortgage_start_date = this.resolve<string>(yup.ref('mortgage_start_date'))
              if (mortgage_start_date === outstanding_balance_date) return true
              const hasBothValues = mortgage_start_date && outstanding_balance_date
              return !hasBothValues || validateIsBefore(mortgage_start_date, outstanding_balance_date)
            }),
      }),
    mortgage_start_date: yup
      .string()
      .typeError(ERROR_DATE_GENERIC)
      .nullable()
      .test('isValid', ERROR_DATE_GENERIC, (value) => !value || isValidDate(value))
      .test('isInTheFuture', ERROR_FUTURE, function (this, value) {
        const status: string = this.resolve(yup.ref('status'))
        if (status === MortgageStatus.StatusCurrent && value && isInTheFuture(value)) {
          return false
        }
        return true
      }),
    mortgage_amount: yup
      .number()
      .typeError(ERROR_INPUT_MUST_BE_NUMBER)
      .min(0, ERROR_NON_NEGATIVE)
      .nullable()
      .test('isRequired', ERROR_REQUIRED, function (this, value) {
        const status: string = this.resolve(yup.ref('status'))

        // Make sure to only run validation if mortgage status is NOT current
        if (status !== MortgageStatus.StatusCurrent) {
          const hasProcFees = hasEnteredSomeProcFees(this)
          if (hasProcFees && !value) {
            return false
          }

          const interest_only_amount: string = this.resolve(yup.ref('interest_only_amount'))
          const repayment_amount: string = this.resolve(yup.ref('repayment_amount'))
          const repayment_type: string = this.resolve(yup.ref('repayment_amount'))

          // if either interest only amount, repayment type, or repayment amount are present and
          // mortgage amount is not entered than make mortgage amount a required field
          if ((!isNil(interest_only_amount) || !isNil(repayment_amount) || !isNil(repayment_type)) && isNil(value)) {
            return false
          }
        }
        return true
      }),
    repayment_type: yup
      .string()
      .nullable()
      .test('isRequired', ERROR_REQUIRED, function (this, value) {
        const interest_only_amount: string = this.resolve(yup.ref('interest_only_amount'))
        const repayment_amount: string = this.resolve(yup.ref('repayment_amount'))
        const mortgage_amount: string = this.resolve(yup.ref('mortgage_amount'))
        const outstanding_balance: string = this.resolve(yup.ref('outstanding_balance'))

        // Check that at leas one of the following fields is non-null
        const isRepaymentTypeRequired =
          !isNil(interest_only_amount) ||
          !isNil(repayment_amount) ||
          !isNil(mortgage_amount) ||
          !isNil(outstanding_balance)

        // at least one of the relevant fields is entered and if there is no repayment type
        // then make this field required
        if (isRepaymentTypeRequired && !value) {
          return false
        }

        return true
      }),
    interest_only_amount: yup
      .string()
      .nullable()
      .test('isLessThanMortgageAmount', ERROR_LESS_THAN, function (this, interest_only_amount) {
        const mortgage_amount: string = this.resolve(yup.ref('mortgage_amount'))
        const outstanding_balance: string = this.resolve(yup.ref('outstanding_balance'))
        const loan_amount: string = mortgage_amount || outstanding_balance
        const repayment_type: string = this.resolve(yup.ref('repayment_type'))

        // If repayment type is not part and part, this field wouldn't show up
        // so no need to compare it to mortgage amount
        // also when no value is entered we don't need to compare it to the mortgage amount either
        if (repayment_type !== RepaymentTypes.PartAndPart || !interest_only_amount) {
          return true
        }

        // If interest_amount is greater than loan_amount (mortgage_amount || outstanding_balance)
        // then trigger this validation
        if (loan_amount && parseInt(interest_only_amount) >= parseInt(loan_amount)) {
          return false
        }

        return true
      })
      .test('isGreaterThan0', ERROR_GREATER_THAN_0_ON_PART_AND_PART_MORTGAGE, function (this, interest_only_amount) {
        const repayment_type: string = this.resolve(yup.ref('repayment_type'))
        const mortgage_amount: string = this.resolve(yup.ref('mortgage_amount'))
        const outstanding_balance: string = this.resolve(yup.ref('outstanding_balance'))
        const loan_amount: string = mortgage_amount || outstanding_balance

        // If repayment_type is not part and part, this field wouldn't show
        // so no need to validate it
        // also if no loan amount has been entered so far, there is no value in  validating this field either
        if (repayment_type !== RepaymentTypes.PartAndPart || !loan_amount) {
          return true
        }

        // Alternatively, make sure that the interest_only amount is present and it's greater than 0
        if (!interest_only_amount || parseInt(interest_only_amount) <= 0) {
          return false
        }
        return true
      }),
    revert_monthly_payment: yup.number().typeError(ERROR_INPUT_MUST_BE_NUMBER).min(0, ERROR_NON_NEGATIVE).nullable(),
    term: yup
      .number()
      .nullable()
      .typeError(ERROR_INPUT_MUST_BE_NUMBER)
      .min(1, ERROR_GREATER_THAN_ZERO)
      // If the term unit has been selected, then an associated
      // term must be included too
      .when('term_unit', {
        is: (term_unit: TermUnit) => !isNil(term_unit) && term_unit !== TermUnit.InvalidTermUnit,
        then: (schema) => schema.nullable().typeError(ERROR_INPUT_MUST_BE_NUMBER).required(ERROR_REQUIRED),
      }),
    // Conversely, if the user as entered a term, then a
    // term unit must be included too
    term_unit: yup
      .string()
      .nullable()
      .when('term', {
        is: (term: TermUnit) => !!term,
        then: (schema) =>
          schema.nullable().test('validTermUnit', ERROR_REQUIRED, function (this, term_unit) {
            return term_unit !== TermUnit.InvalidTermUnit
          }),
      }),
    first_or_second_charge: yup.boolean().nullable(),
  },
  [['term', 'term_unit']],
)

export const schema = mortgageSchema.concat(mortgageProductSchema)

export const validate = unwrapErrors(schema)

export const fullSchema = schema.concat(existingMortgageProductSchema)
export const validateFullSchema = unwrapErrors(fullSchema)

export const formatBooleanAsNumber = (arg: boolean) => (arg ? 2 : 1)
export const formatNumberAsBoolean = (num: number): boolean => num === 2
export const formatNumber = (arg: string) => parseInt(arg)

const updateMortgageClubProcFees = (
  newMortgageProcFee: MortgageClubFee,
  existingMortgageClubProcFees?: Maybe<MortgageClubFeeInput[]>,
) => {
  switch (true) {
    case Boolean(
      existingMortgageClubProcFees?.length &&
        existingMortgageClubProcFees?.find(
          (mortgage) => mortgage.mortgage_club_code === newMortgageProcFee.mortgage_club_code,
        ),
    ):
      return existingMortgageClubProcFees?.map((item) =>
        item.mortgage_club_code === newMortgageProcFee.mortgage_club_code ? newMortgageProcFee : item,
      )
    case existingMortgageClubProcFees?.length &&
      !existingMortgageClubProcFees?.find(
        (mortgage) => mortgage.mortgage_club_code === newMortgageProcFee.mortgage_club_code,
      ):
      return [...existingMortgageClubProcFees!, newMortgageProcFee]
    default:
      return [newMortgageProcFee]
  }
}

export const stateToInput = (
  values: MortgageState,
): Pick<CreateMortgageInput, 'mortgageInput' | 'mortgageProductInput'> => {
  const {
    first_or_second_charge,
    early_repayment_charge,
    status,
    product_type,
    has_arrears,
    repayment_amount,
    repayment_type,
    property_secured_ids,
    term,
    mortgage_amount,
    assumes_continuing,
    arrears_summary,
    mortgage_account_number,
    provider_application_reference,
    valuation_type,
    mortgage_start_date,
    outstanding_balance_date,
    mortgage_club_name,
    rate_tiers,
  } = values

  const mortgageProduct: MortgageProductActualInput = omitTypename(
    pick(values, [
      'fees_total',
      'product_code',
      'lender_reference',
      'mortgage_class',
      'product_name',
      'initial_pay_rate',
      'rate_tiers',
      'proc_fee_percentage',
      'proc_fee_fixed',
      'is_porting',
      'early_repayment_charge_applies',
      'early_repayment_charge_periods',
      'max_ltv_available',
      'selected_mortgage_club_code',
      'net_proc_fee_fixed',
      'net_proc_fee_percentage',
      'fees',
      'cashback',
    ]),
  )

  if (mortgageProduct.cashback) {
    mortgageProduct.cashback_paid_on = 'Completion'
  }

  // Create the mortgage input
  const mortgageInput = {
    ...pick(
      // repayment type is a field we use internally, so no need to submit it
      omit(values, 'repayment_type', 'mortgage_club_name'),
      [...Object.keys(mortgageSchema.fields), 'raw_results_reference'],
    ),
    repayment_amount: repayment_type === RepaymentTypes.InterestOnly ? '0' : repayment_amount,
    first_or_second_charge: !isNil(first_or_second_charge) ? formatBooleanAsNumber(first_or_second_charge) : null,
    status: status || MortgageStatus.StatusCurrent,
    term: term ? formatNumber(term.toString()) : null,
    mortgage_amount: mortgage_amount,
    property_secured_ids: property_secured_ids ?? [],
    assumes_continuing: assumes_continuing ?? [],
    has_arrears: !isNil(has_arrears) ? has_arrears : null,
    arrears_summary: !isEmpty(arrears_summary) ? arrears_summary : null,
    mortgage_account_number: mortgage_account_number,
    provider_application_reference: provider_application_reference,
    valuation_type: valuation_type,
    selected_mortgage_club_code: mortgageProduct?.selected_mortgage_club_code,
    mortgage_start_date: mortgage_start_date,
    outstanding_balance_date: outstanding_balance_date,
  }

  const rateTiers = rate_tiers?.filter((rateTier) => rateTier && !isObjectEmpty(rateTier))
  const filteredRateTiers = filterRateTiers(rateTiers)

  const newMortgageProcFee = {
    mortgage_club_code: mortgageProduct?.selected_mortgage_club_code,
    mortgage_club_name: mortgage_club_name,
    net_proc_fee_percentage: mortgageProduct?.net_proc_fee_percentage,
    proc_fee_percentage: mortgageProduct?.proc_fee_percentage,
    proc_fee_fixed: mortgageProduct?.proc_fee_fixed,
    net_proc_fee_fixed: mortgageProduct?.net_proc_fee_fixed,
  }

  const mortgageClubProcFees = updateMortgageClubProcFees(newMortgageProcFee, mortgageProduct.mortgage_club_proc_fees)

  mortgageProduct.mortgage_club_proc_fees
    ? mortgageProduct.mortgage_club_proc_fees.map((item) =>
        item.mortgage_club_code === newMortgageProcFee.mortgage_club_code ? newMortgageProcFee : item,
      )
    : [newMortgageProcFee]
  // Create the product input
  const mortgageProductInput = {
    ...mortgageProduct,
    early_repayment_charge: !isNil(early_repayment_charge) ? String(parseFloat(String(early_repayment_charge))) : null,
    rate_tiers: filteredRateTiers,
    product_type: product_type,
    mortgage_club_proc_fees: mortgageClubProcFees,
  }

  if (!mortgageProductInput.early_repayment_charge_applies) {
    delete mortgageProductInput.early_repayment_charge_periods
    // @ts-ignore
    delete mortgageProductInput.early_repayment_charge
  }

  return omitTypename({ mortgageProductInput, mortgageInput })
}

export const getMortgageAndProductInputValues = (mortgage: MortgageState) => {
  const productFeeFieldToRemoveNet = mortgage.invalidFieldNet
  const productFeeFieldToRemoveGross = mortgage.invalidFieldGross

  let cleanValues = mortgage
  if (productFeeFieldToRemoveNet && productFeeFieldToRemoveGross) {
    cleanValues = omit(mortgage, [
      productFeeFieldToRemoveNet,
      productFeeFieldToRemoveGross,
      'invalidFieldNet',
      'invalidFieldGross',
    ])
  } else if (productFeeFieldToRemoveNet) {
    cleanValues = omit(mortgage, [productFeeFieldToRemoveNet, 'invalidFieldNet'])
  } else if (productFeeFieldToRemoveGross) {
    cleanValues = omit(mortgage, [productFeeFieldToRemoveGross, 'invalidFieldGross'])
  }
  return stateToInput(cleanValues)
}

const filterRateTiers = (rate_tiers?: Maybe<RateTiersType[]>) => {
  return rate_tiers?.map((tier, index) => {
    let updatedTier = omit(tier, 'until_end_of_term')

    // For last tier replace period_fixed_length_months with 0
    if (index === rate_tiers.length - 1 && !tier?.period_fixed_end_date) {
      return { ...updatedTier, period_fixed_length_months: 0 }
    }

    // omit `until_end_of_term` as it's a field only used by FE
    return { ...updatedTier, period_fixed_length_months: null }
  })
}

export const initialRateTiers: MortgageProduct['rate_tiers'] = [
  { rate: null, rate_type: null, period_fixed_end_date: null },
  { rate: null, rate_type: null, period_fixed_end_date: null, until_end_of_term: true },
]

export const MORTGAGE_INITIAL_VALUES = {
  rate_tiers: initialRateTiers,
  status: MortgageStatus.StatusCurrent,
  has_arrears: false,
  arrears_summary: null,
  fees_total: '0',
}
