import { generateUniqueId } from '@acre/utils'
import { AdditionalLoans, FeeType, Maybe, Mortgage, NewFee, PredictedRevenueEntry } from '@acre/graphql'

import { getSelectedMortgages } from '../getPredictedRevenueFromMortgage/getPredictedRevenueFromMortgage'

export const getPredictedRevenueFromClient = (
  caseFees?: NewFee[],
  mortgages?: Partial<Mortgage>[],
  preferenceLoanAmount?: Maybe<string>,
  additionalLoans?: AdditionalLoans[],
): PredictedRevenueEntry[] => {
  const selectedMortgages = getSelectedMortgages(mortgages)
  const mortgageAmount = sumUpMortgagesMortgageAmount(selectedMortgages) || Number(preferenceLoanAmount)
  const totalAdditionalLoanAmount = additionalLoans?.reduce((sum, loan) => {
    const loanAmount = Number(loan?.loan_amount)
    if (loanAmount && !isNaN(loanAmount)) {
      sum += loanAmount
    }
    return sum
  }, 0)
  const totalMortgageAmount = mortgageAmount + (totalAdditionalLoanAmount || 0)

  const caseFeesDict = getCaseFees(caseFees, totalMortgageAmount)
  return Object.entries(caseFeesDict || {}).map(([key, value]) => {
    const feeType = key as string
    const val = value as number

    return {
      id: generateUniqueId(),
      value: val,
      fee_type: feeType,
      payer: 'client',
      commission: null,
      frequency: null,
      commission_period: null,
    }
  })
}

export const getCaseFees = (caseFees?: NewFee[], mortgageAmount?: number) => {
  return caseFees?.reduce(
    (acc, { fee_type, value }) => {
      if (fee_type === FeeType.InvalidFeeType) {
        return acc
      }

      const key = fee_type.replace('_FIXED', '').replace('_PERCENT', '')

      const feeTypeExists = !!acc[key]

      const convertedValue = getFeeFixedValue({
        feeType: fee_type,
        value: Number(value),
        mortgageAmount: Number(mortgageAmount),
      })

      if (feeTypeExists && convertedValue) {
        acc[key] += convertedValue
      } else if (convertedValue) {
        acc[key] = convertedValue
      }

      return acc
    },
    {} as Record<string, number>,
  )
}

export const getFeeFixedValue = ({
  value,
  feeType,
  mortgageAmount,
}: {
  value: number
  feeType: FeeType
  mortgageAmount?: number
}): number | null => {
  if (feeType.includes('FIXED') && !isNaN(value)) {
    return Math.round(value * 100) / 100
  }

  if (feeType.includes('PERCENT') && !isNaN(value) && mortgageAmount && !isNaN(mortgageAmount)) {
    const converted = (value / 100) * mortgageAmount
    const rounded = Math.round(converted * 100) / 100
    return rounded
  }

  return null
}

export const sumUpMortgagesMortgageAmount = (mortgages?: Partial<Mortgage>[]) => {
  return mortgages?.reduce((acc, mortgage) => {
    const mortgageAmount = Number(mortgage?.mortgage_amount)
    if (mortgageAmount && !isNaN(mortgageAmount)) {
      acc += mortgageAmount
    }
    return acc
  }, 0)
}
